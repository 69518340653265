import React,{Component} from 'react'
import Grid from '@material-ui/core/Grid';
import {Dialog, DialogContent, IconButton, TextField } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { AutoComp, AutoCompSelect, MyDatePicker, MySelect } from '../../components/textField';
import {DeleteOutline } from '@material-ui/icons';
import api from '../../services/api';
import { format } from 'date-fns'; 


let tipo_conta = [{"value":"0","label":"Conta PJ"},{"value":"1","label":"Conta PF"}];


export default class Janela extends Component {

    constructor(props){
        super();
        this.state = {

            empresa_id: props.empresa_id,
            mes_fechado:props.mes_fechado,
            mes_inicial:'2000,1,1',
            usuario_id: localStorage['usuario_id'],
            permission: localStorage['permission'],
            unidadeMostra:false,
            po:props.po,
            status:[{"value":"0","label":"Validar"},{"value":"1","label":"Aguardando"},{"value":"2","label":"Realizado"},{"value":"3","label":"Verificado"},{"value":"4","label":"Pix Imediato"},{"id":"5","value":"5","label":"Nota de débito"}],
            recorrente:[{"value":"1","label":"1"},{"value":"2","label":"2"},{"value":"3","label":"3"},{"value":"4","label":"4"},{"value":"5","label":"5"},{"value":"6","label":"6"},{"value":"7","label":"7"},{"value":"8","label":"8"},{"value":"9","label":"9"},{"value":"10","label":"10"},{"value":"12","label":"12"},{"value":"12","label":"12"}],
            recorrenteQt:1,
            deleta:false,
            janelaData:[-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
            aguardando:0,
            atualizado:0,
            updated:0
            

        }
    }
   

    async componentDidMount(){

       
       let mes_inicial = '2000,1,1';

       
       if(this.state.mes_fechado/1>new Date().getMonth()+1){
        
        mes_inicial = new Date().getFullYear()-1+','+this.state.mes_fechado+',1';
       }else{
       
        mes_inicial = (new Date().getFullYear())+','+this.state.mes_fechado+',1';
       }
       
       this.setState({
        mes_inicial:mes_inicial
       })

       let titulo="";

       // ABRIR PO EXISTENTE
       if(this.state.po>0){
          
        api.get('/get/getCashflowJanela.php?id='+this.state.po+'&sheet=cashflow&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
            if (result.status === 200) {

                let status=[];
                
                if(result.data[0]['tipo']==='0'){
                    titulo = 'Receita PO '+this.state.po;
                    status = [{"value":"0","label":"Validar"},{"value":"1","label":"Faturado"},{"value":"2","label":"Realizado"}];
                }else{
                    titulo = 'Despesa PO '+this.state.po;
                    status = [{"value":"0","label":"Validar"},{"value":"1","label":"Aguardando"},{"value":"2","label":"Realizado"},{"value":"3","label":"Verificado"},{"value":"4","label":"Pix Imediato"},{"id":"5","value":"5","label":"Nota de débito"}];
                }

                let findUnidade = 0;
                for(let i=0;i<this.props.unidades.length;i++){
                    if(result.data[0]['unidade_id'] === this.props.unidades[i].id){
                        findUnidade = 1
                    }
                }
                
                if(findUnidade===0){
                    
                    
                    api.get('get/get.php?sheet=unidades&id='+result.data[0]['unidade_id']+'&col_id=id&empresa_id=no&r='+Math.random(10000)).then(result2 => {
                        
                        let unidade_nome = 'Unidade desconhecida';
                        if(result2.data[0].nome){
                            unidade_nome = result2.data[0].nome;
                        }
                        
                        this.setState({
                            dataPo:result.data,
                            unidadeMostra:result2.data[0].nome,
                            titulo:titulo,
                            status:status
                        })

                    })
                }else{
                    this.setState({
                        dataPo:result.data,
                        titulo:titulo,
                        status:status
                    })

                }

                //
                
                
      
            }})
        }
        // CRIAR NOVO PO
        else{
            
            if(this.state.po==='r'){
                titulo = 'Nova receita';
            }else{
                titulo = 'Nova desepesa';
            }
            this.setState({
                dataPo:[{'id':this.state.po,'valor':0,'empresa_id':this.state.empresa_id,'alterado_id':localStorage['usuario_id'],'dt_vencimento':new Date().toISOString().slice(0, 10),'dt_emissao':new Date().toISOString().slice(0, 10),'nf_chegada':new Date().toISOString().slice(0, 10),'dt_competencia':new Date().toISOString().slice(0, 10)}],
                titulo:titulo
            })

        }

    }


    // FORMAT DATE

        formatDate = (date) => {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [day, month, year].join('/');
        }


        // VOID

        void = () => {

        }


        // CHECK
        toCheck = () => {
            this.toLoad();
        }


        // CONFIRM DELETAR

        confereDeleta = () => {

            this.setState({
                deleta:true,
                
            })
        }

        // DELETAR

        deletarCashflow = () => {

            api.get('/delete/deleteActive.php?id='+this.state.po+'&sheet=cashflow&r='+Math.random(10000)).then(result => {
            
                if (result.status === 200) {
                    
                    this.props.salvaJanela(this.props.po)
                
                    }
                    
                })

        }


        // ALTERA JANELA


        changeJanela = (e,w) => {
        
        
            let janelaFields = ['valor','dt_vencimento','dt_emissao','dt_competencia','pj_id','projeto_id','plano_conta_id','unidade_id','conta_id','cashflow_status','nf','nf_link','comentario','pj_banco','pj_agencia','pj_conta_corrente','pj_cc_tipo','valor_original','nf_chegada'];
            let dataPo = this.state.dataPo;

          
            if(janelaFields[w]==='cashflow_status' && e==='1'){
                this.setState({
                    aguardando:1
                })
            }


            if(janelaFields[w]==='dt_vencimento' || janelaFields[w]==='dt_emissao' || janelaFields[w]==='dt_competencia' || janelaFields[w]==='nf_chegada'){
                
                e = format(e,'yyyy-MM-dd');
            }


            if(w===0 || w===17){
                e = e.replace(',','');
                e = e.replace(',','');

                if(!dataPo[0][janelaFields[17]]){
                    dataPo[0][janelaFields[17]]=e
                    this.setState({
                        updated:this.state.updated+1
                    })
                    
                }
            }

            dataPo[0][janelaFields[w]] = e;

            if(w>12 && w<17){
                dataPo[0]['atualizado'] = 1;
            }

            this.setState({
                dataPo:dataPo
            })

            
        }

        // RECORRENTE

        changeRecorrente = (e) => {
            
            this.setState({
                recorrenteQt:e
            })

           
        }
        // SALVA JANELA

        salvaJanela = () => {

            let ok_salvar = true

            let dataPo = this.state.dataPo;

            let plano_conta = this.props.plano_contas;

            let plano_ativo = null;
            
            for(let i=0;i<plano_conta.length;i++){

                if(dataPo[0].plano_conta_id===plano_conta[i].id){
                    plano_ativo = plano_conta[i].label;
                }

            }

            if(plano_ativo){
               
                if(plano_ativo.slice(0, 1)==='3' && dataPo[0].projeto_id<1){
                    alert('Esse plano de contas exige um projeto atrelado.')
                    ok_salvar = false
                }
            }

            dataPo[0]['empresa_id']=this.props.empresa_id;


           
            let dt_vencimento = dataPo[0]['dt_vencimento'].split('-');
            let today = new Date()
           
            if(dataPo[0]['cashflow_status']>0 && dt_vencimento[1]/1<this.state.mes_fechado/1 && dt_vencimento[0]/1<new Date().getFullYear()){
                
                ok_salvar = false
            }

            
            if(dt_vencimento[0]>today.getFullYear()){
                
                ok_salvar = true
            }
          
            if(ok_salvar===false){
                
                alert('Data de vencimento anterior ao mês fechado.')
            }

            if(this.state.aguardando>0 && this.state.dataPo[0]['nf_link']===''){
                
                alert('Você precisa indicar o link da nota fiscal.')
                ok_salvar = false;
            }

            if(ok_salvar){
              
                api.post('update/saveCashflow.php?token='+this.props.bankToken+'&r='+Math.random(10000),JSON.stringify({ 
                    
                    dataPo: dataPo,
                    recorrente:this.state.recorrenteQt,
                    aguardando:this.state.aguardando,
                    usuario_id:this.state.usuario_id,
                    
                })).then(result => {
                        
                   
                    if (result.status === 200) {
                        
                      
                        this.props.salvaJanela(this.props.po);
                
                    }})
                
            }
            
        }


render(props){
return (
    

        <div>       
           
        <Dialog
        open={this.props.janelaOpen}
        onClose={()=>this.props.fechaJanela()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <div className="pd30">
        <DialogContent>
        
          
          {this.state.dataPo ? this.state.dataPo.map((po,objectId)=>(
          <Grid key={objectId+this.state.updated} container spacing={2}>

          <Grid item xs={7}><div className="mini-titulo mt10">{this.state.titulo}</div></Grid>
          <Grid item xs={4}>{this.state.deleta ? <div onClick={()=>this.deletarCashflow()} className="bt bgcolor_5">Deletar com certeza?</div> : ''}</Grid>
          <Grid item xs={1} className="tr"><IconButton onClick={()=>this.confereDeleta()} size="medium"><DeleteOutline color="secondary"/></IconButton></Grid>
          
            <Grid item xs={3}>
            <CurrencyTextField
                    autoFocus={true}
                    fullWidth={true}
                    label="Valor"
                    currencySymbol="R$"
                    outputformat="number"
                    onBlur={e => {
                      this.changeJanela(e.target.value,0)
                    }}
                    defaultValue={po.valor}
                    variant="outlined"
                  />
        
            </Grid>

            <Grid item xs={3}>
            <CurrencyTextField
                    fullWidth={true}
                    label="Valor Original"
                    currencySymbol="R$"
                    outputformat="number"
                    onBlur={e => {
                      this.changeJanela(e.target.value,17)
                    }}
                    defaultValue={po.valor_original}
                    variant="outlined"
                  />
        
            </Grid>

            <Grid item xs={3}>
                <MyDatePicker
                    onChange={(e)=>this.changeJanela(e,1)} 
                    view={["year", "month","date"]}  
                    format="dd/MM/yyyy" 
                    label="Vencimento" 
                    minDate={this.state.mes_fechado ? this.state.mes_inicial : '2020,0,1'}
                    startDate={po.dt_vencimento}
                />
            </Grid>

            
            

            <Grid item xs={3}>
                <MyDatePicker
                     onChange={(e)=>this.changeJanela(e,3)}
                    view={["year", "month","date"]}  
                    format="dd/MM/yyyy" 
                    label="Competencia"
                    startDate={po.dt_competencia}
                />
            </Grid>

           
            <Grid item xs={this.state.po>0 ? 3 : 3}>
            {this.state.unidadeMostra ? 
                <TextField
                    autoComplete="off"
                    fullWidth={true}
                    label="Unidade"
                    InputProps={{ readOnly: true }}
                    defaultValue={this.state.unidadeMostra}
                    variant="outlined"
                />
            :
            
            <MySelect list={this.props.unidades ? this.props.unidades : []} initValue={po.unidade_id} label="Unidade" change={(e)=>this.changeJanela(e,7)}/>
            
            }
                </Grid>
           

            <Grid item xs={this.state.po>0 ? 3 : 3}>
                <MySelect list={this.props.contas ? this.props.contas : []} initValue={po.conta_id} label="Conta bancária" change={(e)=>this.changeJanela(e,8)}/>
            </Grid>

            
            <Grid item xs={6}>
                <AutoCompSelect notSave={true} list={this.props.projetos} label={po.projeto ? po.projeto : "Projeto"} blur={()=>this.void()} change={(e)=>this.changeJanela(e,5)}/>
            </Grid>

            {this.state.po>0 ? 
            ''
            :
            <Grid item xs={3}>
                <MySelect list={this.props.grupo ? this.props.grupo : []} initValue={po.empresa_id} label="Empresa" change={(e)=>this.props.mudaEmpresa(e)}/>
            </Grid>
            }
            

            <Grid item xs={this.state.po>0 ? 6 : 3}>
                <AutoCompSelect notSave={true} list={this.props.plano_contas} label={po.plano_conta ? po.plano_conta : "Plano de contas"} blur={()=>this.void()} change={(e)=>this.changeJanela(e,6)}/>
            </Grid>


            <Grid item xs={6}>
                <AutoComp empresa_id={this.state.empresa_id} sheet="users" tipo="freelaforn" label={po.pj ? po.pj : "Fornecedor"} blur={()=>this.void()} change={(e)=>this.changeJanela(e,4)}/>
            </Grid>
           

           

            {po.id>0 ? 
            <Grid item xs={12}>

                
            <Grid key={objectId} container spacing={2}>
            <Grid item xs={3}>

                    <TextField
                            autoComplete="off"
                            fullWidth={true}
                            label="Banco Fornecedor"
                            onBlur={e => {
                            this.changeJanela(e.target.value,13)
                            }}
                            defaultValue={po.pj_banco}
                            variant="outlined"
                        />

                </Grid>

                <Grid item xs={3}>

                    <TextField
                            autoComplete="off"
                            fullWidth={true}
                            label="Agência Fornecedor"
                            onBlur={e => {
                            this.changeJanela(e.target.value,14)
                            }}
                        
                            defaultValue={po.pj_agencia}
                            variant="outlined"
                        />

                </Grid>

                <Grid item xs={3}>

                    <TextField
                            autoComplete="off"
                            fullWidth={true}
                            label="CC Fornecedor"
                            onBlur={e => {
                            this.changeJanela(e.target.value,15)
                            }}
                        
                            defaultValue={po.pj_conta_corrente}
                            variant="outlined"
                        />

                </Grid>


                <Grid item xs={3}>

                <MySelect list={tipo_conta} initValue={po.pj_cc_tipo} label="Conta Tipo" change={(e)=>this.changeJanela(e,16)}/>

                </Grid>
                
                </Grid>
            
            </Grid>

            : ''}
                <Grid item xs={3}>

                    <TextField
                            autoComplete="off"
                            fullWidth={true}
                            label="Nota fiscal"
                            onBlur={e => {
                            this.changeJanela(e.target.value,10)
                            }}
                        
                            defaultValue={po.nf}
                            variant="outlined"
                        />

                </Grid>

                <Grid item xs={3}>
                <MyDatePicker
                    onChange={(e)=>this.changeJanela(e,2)} 
                    view={["year", "month","date"]}  
                    format="dd/MM/yyyy" 
                    label="Emissão" 
                    startDate={po.dt_emissao}
                />
            </Grid>

                <Grid item xs={3}>

                    <TextField
                            autoComplete="off"
                            fullWidth={true}
                            label="Link da nota fiscal"
                            onBlur={e => {
                            this.changeJanela(e.target.value,11)
                            }}
                        
                            defaultValue={po.nf_link}
                            variant="outlined"
                        />

                </Grid>

                <Grid item xs={3}>

                        <MyDatePicker
                            onChange={(e)=>this.changeJanela(e,18)} 
                            view={["year", "month","date"]}  
                            format="dd/MM/yyyy" 
                            label="Data de chegada NF" 
                            startDate={po.nf_chegada}
                        />

                </Grid>

                <Grid item xs={8}>

                    <TextField
                            autoComplete="off"
                            fullWidth={true}
                            label="Observação"
                            onBlur={e => {
                            this.changeJanela(e.target.value,12)
                            }}
                        
                            defaultValue={po.comentario}
                            variant="outlined"
                        />

                </Grid>

                <Grid item xs={4}>
                <MySelect list={this.state.status} initValue={po.cashflow_status} label="Status" change={(e)=>this.changeJanela(e,9)}/>
            </Grid>


        </Grid>
        
          )) : ''}
          <div className="mt20">
        <Grid container spacing={3}>
        <Grid item xs={3}>
          <div className="bt bgcolor_5 large" onClick={()=>this.props.fechaJanela()} color="primary">
            Cancelar
          </div>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>{this.state.po>0 ? '' : <MySelect list={this.state.recorrente} label="Recorrente" change={(e)=>this.changeRecorrente(e)}/>}</Grid>
          <Grid item xs={3}>
          <div className="bt bgcolor_1 large" onClick={(w)=>this.salvaJanela()} color="secondary">
            Salvar
          </div>
          </Grid>
          </Grid>
          </div>
          </DialogContent>
        </div>
      </Dialog>
      
        </div>

        
    )
    
}
}
