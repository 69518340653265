import React, { Component } from "react";
import {Grid} from '@material-ui/core';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import {Helmet} from 'react-helmet';
import Meta from "../../components/meta";
import { Redirect } from "react-router";

const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
    MuiIconButton: {
      root: {
          color:'#ddd',
        '&:hover': {
          color: teal[100]
        }
      }
    }
  }

},

  
)



const colors=["#A456FF","#a7ffeb","#F9D48E"];
const colorsFont=["#ffffff","black","black"];

let nomeMeses=['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];
let nomeMeses_ingles=['Jan','feb','Mar','Apr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dec'];
let nomeSemana=['Seg','Ter','Qua','Qui','Sex','Sab','Dom'];
let nomeSemana_ingles=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
let tipos = ['','Kickoff','PPM','Shoot day','Offline','Online'];

let dt = new Date();
let year  = dt.getFullYear();

export default class Calendar extends Component {

  

  state = {
    token: localStorage.getItem("tokens"),
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    id:this.props.match.params.id,
    loading:true, 
    mesAtivos:[],
    diasInfo:[],
    mesesDiasOffIni:[],
    mesesDiasOffFim:[],
    mesesDias:[],
    editText:0,
    nomeMeses:nomeMeses,
    nomeSemana:nomeSemana,
    logo:'logo____.svg'
  }
 

  

  // LOADING

  toLoad = () => {
    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }


  


  async componentDidMount(){
    
    
    this.calendarInfo();
    
    this.setPage();
    

  }


  // MONTA CALENDARIO
  calendar = () => {
        
       
        // DAYS IN MONTH
        
        let nrDays = [];
        let firstDay = [];
        let mesesDias = [];
        let mesesDiasOffIni = [];
        let mesesDiasOffFim = [];
        let totalCasas = 35;
        let mesAtivos = this.state.mesesAtivos;
        
        
        for(let a=0;a<3;a++){
            nrDays.push([]);
            firstDay.push([]);
        for(let i=0;i<12;i++){
            
            nrDays[a].push(new Date((year+a-1),(i+1), 0).getDate());
            firstDay[a].push(new Date((year+a-1),i, 0).getDay());

           

        }

    }

    
 
   
    
    
    for(let i=0;i<mesAtivos.length;i++){
      
      mesesDias.push([]);
      mesesDiasOffIni.push([]);
      mesesDiasOffFim.push([]);
      if(mesAtivos[i]>0){

        // DIAS VAZIOS INICIO
       
        for(let s=0;s<firstDay[Math.floor(i/12)][i-(Math.floor(i/12)*12)];s++){
          mesesDiasOffIni[i].push(1);
        }
          
        if(mesesDiasOffIni[i].length>=5){
          totalCasas=42;
        }else{
          totalCasas=35;
        }


        // DIAS MES
        for(let a=0;a<nrDays[Math.floor(i/12)][i-(Math.floor(i/12)*12)];a++){
          if((mesesDias[i].length+mesesDiasOffIni[i].length)<totalCasas){
            mesesDias[i].push(year+Math.floor(i/12)-1);
          }
      }

      
      // DIAS VAZIOS FINAL
      for( let c=0;(mesesDias[i].length+mesesDiasOffIni[i].length+mesesDiasOffFim[i].length)<totalCasas;c++){
        mesesDiasOffFim[i].push(1);
      }
  }

    
    
    
  }

    this.setState({
        nrDays:nrDays,
        firstDay:firstDay,
        mesesDias:mesesDias,
        mesesDiasOffIni:mesesDiasOffIni,
        mesesDiasOffFim:mesesDiasOffFim,
        
    })
  
    setTimeout(() => {
        this.setState({
            mesAtivos:mesAtivos
        })
    }, 30);
  
}

  

  // SALVA PAGINA

  setPage = async () => {

    
    const thisPage = '/budgets';
    localStorage.setItem("page",thisPage);
    api.get('update/setPage.php?id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&page='+thisPage+'&token='+this.state.token).then(result => {
        
      if (result.status === 200) {
       
        if(result.data==='LOGOUT'){
          window.location.href='/wrong';
        }
        
      }})
  }

  // PEGA INFOS CALENDARIO

  calendarInfo = () => {
    
    let diasInfo = [];
    
    api.get('get/calendar2.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
          
          this.setState({

            projeto_id:result.data.budget[0].projeto_id,
            versao:result.data.budget[0].nome,
            projeto:result.data.projeto[0].nome,
            cliente:result.data.projeto[0].cliente,
            eventos:result.data.linhas,
            empresa:result.data.empresa[0].nome,
            logo:result.data.empresa[0].logo_crono,

          })

          let diaIni = result.data.diaIni;
          let diaFim = result.data.diaFim;

          let anoIni = diaIni.split('-')[0]/1;
          let anoFim = diaFim.split('-')[0]/1;

          let mesIni = diaIni.split('-')[1]/1+12;
          let mesFim = diaFim.split('-')[1]/1+12;

          
          
          if(anoIni/1<year){
            mesIni-=12;
          }

          if(anoIni/1>year){
            mesIni+=12;
          }

          if(anoFim/1<year){
            mesFim-=12;
          }

          
          if(anoFim/1>year){
            mesFim+=12;
          }

          
          
          let mesesAtivos = [];
          
          for(let i=0;i<36;i++){
             mesesAtivos.push(0);
          }


          for(let i=(mesIni-1);i<mesFim;i++){
            mesesAtivos[i]=2;
          }

          
          
          this.setState({
            diasInfo:diasInfo,
            mesesAtivos:mesesAtivos
          })

         
          this.calendar()
          this.endLoad();
          
  }})
  }


  // CLICA PARA EDITAR O NOME

  editNomeVersao = () => {

    this.setState({
      editNome:1,
    })

   }

   // EDITA O NOME DA VERSÃO

   setNomeVersao = (e) => {

    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+e.target.value+'&id='+this.state.id+'&sheet=budget&col=nome&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

            if(e.target.value!==""){
              this.setState({
                editNome:0,
                versao: e.target.value
              })
            }else{
              this.setState({
                editNome:0,
              })
            }
      }
    })
   }



   // ALTERA CALENDARIO

   changeCal = (y,m,d,w,v) => {

        let diasInfo  =  this.state.diasInfo;

        if(v!==""){
          diasInfo[this.montaData(y,m,d)+'-'+w] = v;
        }


        api.get('update/updateCalendar.php?budget_id='+this.state.id+'&dia='+this.montaData(y,m,d)+'&w='+w+'&texto='+v+'&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {

              this.setState({
                diasInfo:diasInfo,
                editText:-1
              })

          }})
      
   }




   // EDIT TEXTO CALENDARIO

   editTextField = (w) => {

    this.setState({
        editText: w,
    })

  }


  //  ALTERA TIPO CALENDARIO

  mudaTipo = (d) => {

    
    let diasInfo  =  this.state.diasInfo;

    let tipo = diasInfo[d];
    if(tipo===null){
      tipo=0;
    }else{
      tipo++;
      if(tipo>5){
        tipo=0;
      }
    }

    diasInfo[d] = tipo;
        
    api.get('update/updateCalendar.php?budget_id='+this.state.id+'&dia='+d+'&tipo='+tipo+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        this.setState({
          diasInfo:diasInfo,
          
        })

      }})

        

  }

  // NOME DIA

  nomeDia = (m,s) => {

    m+=1;
    if(m<10){
      m='0'+m;
    }

    s = s-(Math.floor(s/7)*7);
    return this.state.nomeSemana[s]+', '+m;
  }



  //  MONTA DATA

  montaData = (y,m,d) => {

    if(m<12){
      y--;
    }

    m = (m-(Math.floor(m/12)*12)+1);
    if(m<10){
      m='0'+m;
    }
    d = (d+1);

    if(d<10){
      d='0'+d;
    }

    
    return (y+'-'+m+'-'+d);
    
  }

    // SHOW MES

    showMes = (w) => {

      
      let mesAtivos = this.state.mesAtivos;

      if(mesAtivos[w]===1){
        mesAtivos[w]=2;
      }else{
        mesAtivos[w]=1;
      }
      
      
      this.setState({
        mesAtivos:mesAtivos
      })

    }


   // INGLES

   ingles = () => {
    
    this.setState({
      nomeMeses:nomeMeses_ingles,
      nomeSemana:nomeSemana_ingles
    })

   }

  

  render(){

  



  return (

    
    <ThemeProvider theme={theme}>
          {this.state.permission > 3 ? <Redirect to='/login'/> : ''}
<Meta title={"Calendar | "+this.state.id + ' - ' +this.state.cliente + ' ' + this.state.projeto} />
        <div className="conteudo print">
           
        <Helmet>
                    
                    {/* Or include in-file CSS */}
                    <style>
                       {`
                            body {
                                background: white;
                            }

.dia-semana{
    width: 13vw;
    padding: 3px;
    padding-top: 6px;
    font-size: 10px;
    height:105px
}

.box-calendar{
    width: 13vw;
    border: 1px solid rgb(165, 165, 165);
}

.box-calendar-off{
    width: 13vw;
    border: 1px solid rgb(172, 172, 172);
}

.box-calendar-high{
    width: 13vw;
    border: 1px solid #f0f0f0;
}

.box-calendar:hover{
    border: 1px solid #888;
}
.calendar-mes{
    display: flex;
    justify-content: space-between;
    
}

.box-calendar{
    
}

.box-calendar.cor0{
    background-image: -webkit-linear-gradient(top, #8f8f8f, #8f8f8f 20%, transparent 20%, transparent 100%);
    border: 1px solid #5f5f5f;
}

.box-calendar.cor1{
    background-image: -webkit-linear-gradient(top, #333, #333 20%, transparent 20%, transparent 100%);
    border: 1px solid #5f5f5f;
    color: white;
}


.txt-dia{
    font-size: 10px;
    width: 100%;
    height: 40px;
    padding: 5px 5px;
    color: #f0f0f0;
}

.txt-dia.c2{
    
    color: #444;
}

.menu{
    display: none !important;
}

.footer{
    display: none !important;
}

body{
    background: white !important;
    color: #333 !important;
}

.print{
    color:#333
    
}

.titulo{
    font-size: 30px;
}
.page{
    min-height: 792px;
    
}

                       `}
                    </style>
                </Helmet>

          {this.state.mesAtivos.map((mes,objectId) =>(
          
          <div>

          {mes>1 ? 
            
            <div className="page">

          <Grid container spacing={0}>
            <div className="wp100"></div>
              <Grid item xs={2}><div onClick={()=>this.ingles()}className="logo-calendar-print" style={{backgroundImage:`url('http://treatments.run/landscape/img/upload/${this.state.logo}')`}}></div></Grid>
           
                <Grid item xs={10}><div className="titulo print mt30 tr"><div className="r color_1"></div><div className="r"></div>{' '+this.state.cliente + ' ' + this.state.projeto + ' | ' + this.state.empresa+' '}</div></Grid>
                

            <div className="mt5 wp100">

            <Grid container className='br5 bgcolor_blk color_0 linha-nome-mes'>
              <Grid item xs={11}><div onClick={(w)=>this.showMes(objectId)} className="micro-titulo mt5">{this.state.nomeMeses[objectId-(Math.floor(objectId/12)*12)]} {year+(Math.floor(objectId/12)-1)}</div></Grid>
              
            </Grid>

            </div>

            {mes===1 ? '' : 
          
            <Grid container spacing={0}>

          {this.state.mesesDiasOffIni[objectId].map((dias,objectIdOffini)=>(
              <Grid key={objectIdOffini} item xs className={"box-calendar-off br5"}><div className="dia-semana b"></div></Grid>
          ))}
              
          {this.state.mesesDias[objectId].map((dias,objectId2)=>(
            
            
          <Grid id={this.montaData(dias,objectId,objectId2)} item xs className={this.state.diasInfo[this.montaData(dias,objectId,objectId2)] ? "box-calendar br5 cor1" : "box-calendar br5"  }>

            {
              dias>0 ? 
                  <div>
                    <div className="dia-semana b">{this.nomeDia(objectId2,(objectId2+this.state.mesesDiasOffIni[objectId].length))} <b> {this.state.diasInfo[this.montaData(dias,objectId,objectId2)] ? tipos[this.state.diasInfo[this.montaData(dias,objectId,objectId2)]] : ''  }</b>
                    <div className="mt5">

                    {this.state.eventos[this.montaData(dias,objectId,objectId2)] ? this.state.eventos[this.montaData(dias,objectId,objectId2)].map((evento,objectId3)=>(
                    
                    <div style={{backgroundColor:colors[evento.cor],color:colorsFont[evento.cor]}} className="evento-print br5">{evento.nome}</div> 

                    
                  
                  )) : ''}


                      
                      
                    </div>
                    </div>
                    
                    
                    



                  </div> : <div className="dia-semana b"></div>
                  }
          </Grid>
         
          ))}

          {this.state.mesesDiasOffFim[objectId].map((dias,objectIdOffFim)=>(
              <Grid key={objectIdOffFim} item xs draggable={true} className={"box-calendar-off br5 "}><div className="dia-semana b"></div></Grid>
          ))}

      
          </Grid>
        }
          </Grid>
          </div>
          : ''}
          </div>

          ))}

          </div>

    </ThemeProvider>
  )
}

}
  
 