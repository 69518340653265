import React, { Component } from "react";
import {Grid,ThemeProvider,createTheme} from '@material-ui/core';
import api from '../../services/api';
import {Helmet} from 'react-helmet';
import Meta from "../../components/meta";
import ProgressBar from '../../components/progress';
import {deepPurple,teal} from '@material-ui/core/colors';
import { Redirect } from "react-router";
import MyResponsivePie from "./pie";

const theme = createTheme (
{
  palette: {
    type: "dark",
    primary: deepPurple,
    secondary: teal,
  
},
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
    
  }
}
)






export default class FilmCard extends Component {


  state = {
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    id:this.props.match.params.id,
    link:["https://docs.google.com/spreadsheets/d/1A3dERCTOmfcBWZL_ddMetYeDRPiGrUTG/edit#gid=374446833","https://docs.google.com/spreadsheets/d/1A3dERCTOmfcBWZL_ddMetYeDRPiGrUTG/edit#gid=1208490164","https://docs.google.com/spreadsheets/d/1A3dERCTOmfcBWZL_ddMetYeDRPiGrUTG/edit#gid=474331245","https://docs.google.com/spreadsheets/d/1A3dERCTOmfcBWZL_ddMetYeDRPiGrUTG/edit#gid=374446833","https://docs.google.com/spreadsheets/d/1A3dERCTOmfcBWZL_ddMetYeDRPiGrUTG/edit#gid=1933215359","https://docs.google.com/spreadsheets/d/1A3dERCTOmfcBWZL_ddMetYeDRPiGrUTG/edit#gid=1694401742","https://docs.google.com/spreadsheets/d/1A3dERCTOmfcBWZL_ddMetYeDRPiGrUTG/edit#gid=2124149320","https://docs.google.com/spreadsheets/d/1A3dERCTOmfcBWZL_ddMetYeDRPiGrUTG/edit#gid=1593700838"],

  }


  async componentDidMount(){
    

    this.budgetInfo()
  }




  // LOADING

  toLoad = () => {
    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  

  // PEGA INFORMAÇÕES DO BUDGET

  budgetInfo = async () => {

    this.toLoad()
  
      api.get('get/filmcard.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {


       
          this.setState({

            projeto:result.data.projeto[0].nome,
            cliente:result.data.projeto[0].cliente,
            empresa:result.data.empresa[0].nome,
            diretor:result.data.diretor[0].nome,
            pie:result.data.pie,
            tags:result.data.tags,
            diarias:result.data.diarias,
            pre_light:result.data.pre_light,
          })
        
          
          this.endLoad()
        }

      
          
      })
          

      
       

  }

  openCard = (w) => {
    window.open(w)
  }



render() {
    return (
  
      
      <ThemeProvider theme={theme}>
            {this.state.permission > 2 ? <Redirect to='/login'/> : ''}
<Meta title={"Film Card | "+this.state.id + ' - ' +this.state.cliente + ' ' + this.state.projeto} />
          <div className="conteudo print">
             
          <Helmet>
                      
                    
                      <style>
                        
                         {`
                              
  .menu{
      display: none !important;
  }
  
  .footer{
      display: none !important;
  }


  
 

  
                         `}
                         
                      </style>
                  </Helmet>

                  {this.state.loading ? <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/> : ''}
          
                 
                  <div className="page">
                  
                  <div className="filmcard w50 pd40 bgcolor_6 br5">

                  {this.state.diarias ? 
                  <Grid container spacing={0}>
                
                      <Grid item xs={12}><div className="sub-titulo"><div className="l color_1">{this.state.diretor}</div><div className="l pd10"></div>{this.state.cliente + ' ' + this.state.projeto}</div></Grid>
                      
                      
                  </Grid>

                 :''}

                  {this.state.diarias ? 
                  <Grid container spacing={0} className="mt5 mb30">
                  <Grid item xs={12}><div className="mini-titulo">{this.state.diarias+' '+this.state.pre_light+' pré-light'}</div></Grid>
                 
                  </Grid>

                  :''}

                  <Grid container spacing={0} className="mt5">
                  <Grid item xs={6}></Grid>
                  <Grid item xs={2} className="tc">$</Grid>
                  <Grid item xs={2} className="tc">$$</Grid>
                  <Grid item xs={2} className="tc">$$$</Grid>
                  </Grid>
                  
                  
                  {this.state.tags ? this.state.tags.map((tags,objectID)=>(
                  <Grid key={objectID} container spacing={0} className="mt5">
                    <Grid item xs={5} onClick={()=>this.openCard(this.state.link[objectID])} className="pd10 tc cl bgcolor_7 br5">{tags.label}</Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} className={tags.value===0 ? "pd10 bgcolor_5 br5" : "pd10 bgcolor_8 br5"}></Grid>
                    <Grid item xs={2} className={tags.value===1 ? "pd10 bgcolor_1 br5" : "pd10 bgcolor_8 br5"}></Grid>
                    <Grid item xs={2} className={tags.value===2 ? "pd10 bgcolor_2 br5" : "pd10 bgcolor_8 br5"}></Grid>
                  </Grid>

                  )) : ''}

                
                  <Grid container spacing={0} className="">
                      <Grid item xs>
                          <div className="pie-card mt50">
                          {this.state.pie ? <MyResponsivePie money={true} data={this.state.pie} color="rgb(164,86,255,"/> : ''}
                              </div>
                              <div className="legenda-pie-card">Split</div>
                              
                      </Grid>
                  </Grid>

              </div>
                    
            </div>
  
            </div>

                      


      </ThemeProvider>
    )
  }
  
  }
  
  
  
