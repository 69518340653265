import React, { Component} from "react";

import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';

import Meta from "../../components/meta";
import { Grid} from "@material-ui/core";

import api from "../../services/api";



const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
 

}
  
)



export default class Wrong extends Component {


    state = {
        
        token: localStorage.getItem("tokens")
        
    }

        
    componentDidMount(){
        this.login()
    }
    
    login = () => {
      
        api.post("get/menu.php?token="+this.state.token).then(result => {
      
            if (result.status === 200) {
              this.setState({
                logins: result.data.logins
                
            })

            }})
    }


    swapLogin  = (email,senha) => {
    
        api.post("get/auth.php?email="+email+"&senha="+senha+'&r='+Math.random(10000)).then(result => {
          
        
          if (result.status === 200) {
        
            //localStorage['tokens']=(result.data.split('|')[1]);
            localStorage['tk_nav']=(result.data.split('|')[1]);
            localStorage['empresa_id'] = (result.data.split('|')[0]);
            localStorage['permissao'] = (result.data.split('|')[2]);
            localStorage['usuario_id'] = (result.data.split('|')[3]);
            localStorage['page'] = (result.data.split('|')[4]);
            
            
    
            if(result.data.split('|')[0]){
            
            window.location.href = result.data.split('|')[4];
            }
            
          } 
        })
      }

  render(){


  return (

   
    <ThemeProvider theme={theme}>
       
       
<Meta title="Login Errado"/>
      
        
      <div className="conteudo">
      <div className="titulo">Ops..</div>
          <div className="mini-titulo mt30 mb30">Escolha um outro login para acessar esta página.</div>

          {this.state.logins ? this.state.logins.map((login,objectId)=>(
                                
                      <div key={objectId} onClick={()=>this.swapLogin(login.email,login.senha)} className="bgcolor_6 listLine cl">          
                <Grid  container spacing={3}>
                
                    <Grid item xs={1}><div className="img_user_micro" style={login.foto==="" ? {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'}  : {backgroundImage: 'url(https://www.landscape.to/img/upload/'+login.foto+')'}}></div></Grid>
                    <Grid item xs={4}><div className="listItem">{login.nome}</div></Grid>
                    
                    <Grid item xs={3}><div className="listItem">{login.empresa}</div></Grid>
                    
                    
                </Grid>
                </div>
        
        
        )) : ''}

        </div>
          
       

    </ThemeProvider>
  )


}
}