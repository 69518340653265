import React, { useState} from "react"
import { BrowserRouter as Router, NavLink, Route, Redirect } from "react-router-dom";

import './App.css';
import './components/header/style.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PrivateRoute from './PrivateRoute';
import { ReactComponent as LogoLandscape } from '../src/svg/logo.svg'
import Site from './pages/site';
import Footer from './components/footer';
import Config from './pages/config';
import Empresas from './pages/config/empresas';
import Pauta from './pages/pauta/pauta';
import Budget from './pages/budget';
import Calendar from './pages/budget/calendar';
import PrintCalendar from './pages/budget/printCalendar';
import Projeto from './pages/projeto';
import Login from './pages/login';
import Wrong from './pages/login/wrong';
import Cashflow from './pages/cashflow';
import Despesas from './pages/cashflow/despesas';
import Receitas from './pages/cashflow/receitas';
import Horas from './pages/horas';
import Decupagem from './pages/budget/decupagem';
import Office from './pages/budget/office';
import OfficeSanta from './pages/budget/office_santa';
import OfficeHorizontal from './pages/budget/office_santa_horizontal';
import OfficeMagma from './pages/budget/office_magma';
import OfficeLiquor from './pages/budget/office_liquor';
import Sheet from './pages/budget/sheet';
import Users from './pages/users';
import api from './services/api'
import MultiLogin from './components/multiLogin'
import { AuthContext } from "./context/auth";
import Meta from "./components/meta";
import View from "./pages/projeto/view";
import Viewsheet from "./pages/projeto/viewsheet";
import Reembolso from "./pages/reembolso";
import Vitra from "./pages/vitra";
import Pnl from "./pages/report/pnl";
import Ebitda from "./pages/report/pnl_ebitda";
import Fpna from "./pages/report/pnl_fpna";
import FpnaOld from "./pages/report/pnl_fpna_old";
import Caches from "./pages/report/caches";
import FilmCard from "./pages/budget/filmcard";
import Prestacao from './pages/projeto/prestacao';
import ReportPDF from './pages/report/pdf';
import Pix from './pages/pix/pix';
import PixFore from './pages/pixfore/pix-fore';
import XRay from './pages/report/xray';
import CacheTeste from './pages/projeto/cacheteste';
import Spread from './pages/spread';
import Verbas from './pages/verbas';
import pixReport from './pages/pix/report'
import Breakeven from './pages/breakeven/breakeven'
    
    let isMenu = 0;
    let token = 0;
  function App() {
    
    
     // LOGOUT

     function logOut() {
       
      delete localStorage['tokens'];
      delete localStorage['usuario_id'];
      delete localStorage['permission'];
      delete localStorage['tk_nav'];
      window.location.href = '/login';

     }
  
  

    const existingTokens = JSON.parse(localStorage.getItem("tokens"));
    const [authTokens, setAuthTokens] = useState(existingTokens);
    const [linhaLinks, setlinhaLinks] = useState("");
    const [nomeEmpresa, setNomeEmpresa] = useState("");
    const [login, setLogins] = useState([]);
    const [redPage, setRed] = useState("");
    const [showMenu, setShowMenu] = useState("listaLinks itens w70 f fj va");
    
    
    if(localStorage.getItem("tokens")==="undefined"){
      delete localStorage['tokens'];
      token=0;
      setShowMenu("listaLinks itens w70 f fj va")

    }
    

    const setTokens = (data) => {
      localStorage.setItem("tokens", JSON.stringify(data));
      setAuthTokens(data);
      
    }
   

     // ARRAY COMPARE

     const compare = ( a, b ) => {
        if ( a.empresa < b.empresa ){
          return -1;
        }
        if ( a.empresa > b.empresa ){
          return 1;
        }
        return 0;
      }

    
    
    // IMPORT MENU
    
    if(isMenu===0){
      if(localStorage.getItem("tokens")!==null){
        if(localStorage.getItem("tk_nav")===null){
          token = localStorage.getItem("tokens").replace('"','').replace('"','');
        }else{
          token=localStorage.getItem("tk_nav");
        }
    }

    
    if(token){
      
      isMenu=1;
   
    api.post("get/menu.php?token="+token).then(result => {
      
      if (result.status === 200) {
       
        let logins = result.data.logins;
        logins.sort(compare)
        
        setLogins(logins)
        //
       
        if(!localStorage.getItem('empresa')){
          setNomeEmpresa(result.data.empresa)
          localStorage.setItem('empresa', result.data.empresa);
        }else{
          setNomeEmpresa(localStorage.getItem('empresa'))
        }
        const arrLinks = result.data.menu;
        setlinhaLinks(arrLinks.map((linksNomes,objectID) => (<div key={objectID} className="menu-item"><NavLink to={"/"+linksNomes.link}>{linksNomes.nome}</NavLink></div>)))
        setShowMenu('listaLinks show itens w70 f fj va');
        const thisPage = window.location.pathname.replace('`','/').replace('`','/').replace('`','/').replace('`','/');
        if(thisPage==='/' || thisPage==='/login'){
          if(( window.innerWidth <= 800 )){
            
            setRed(<Redirect to='/reembolso'/>)
          
          }else{

            setRed(<Redirect to={localStorage.getItem("page")}/>)
            
          }
        }
      }

      
    })

        
    

    }

  }
  
  async function goHome(){
    
    window.location.href='/vitra';

  }
  function swapLogin(empresa_id,nome_empresa) {
   
    localStorage.setItem('empresa_id',empresa_id);
        
    localStorage.setItem('empresa',nome_empresa);

    if(localStorage.getItem("page")){
        window.location.href = localStorage.getItem("page");
    }else{
      window.location.href = '/vitra';
    }
      
  }

    
    
    // SHOW MENU

  
    
return (

    
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      
<Meta title="Welcome"/>
          <Router>
        
        <div>
        
             
              <div>
              {window.location.pathname.indexOf('/pix-fore') < 0 && <nav className="w100 h90 bgcolor_7 menu">
                        <div className="menu">
                        
                        <div className="logo w30 f" onClick={()=>goHome()}> 
                        <LogoLandscape className="logo-landscape"/> <div className="mt35 mini-titulo color_1">{nomeEmpresa}</div>
                        </div>
                          
                            <div className={showMenu}>
                                  {linhaLinks}
                                    <MultiLogin logins={login} logOut={logOut} swapLogin={(nome,email)=>swapLogin(nome,email)}/>
                                </div>
                                
                        </div>

                    </nav>}

              
            </div>



          
          {redPage}
          
          <Route exact path="/" component={Site}/>

          <Route exact path="/viewsheet/:id" component={Viewsheet}/>
          <Route exact path="/cacheteste/:id" component={CacheTeste}/>
          <Route exact path="/pix/:id" component={Pix}/>
          <Route exact path="/breakeven/:id" component={Breakeven}/>
          <Route exact path="/pix-fore/:id" component={PixFore}/>
          <Route exact path="/post/:id" component={Sheet}/>
          <Route exact path="/outlook/:id" component={Sheet}/>
          <Route exact path="/seamless/:id" component={Sheet}/>
          <Route exact path="/sheetedit/:id" component={Sheet}/>
          <Route exact path="/prestacao/:id" component={Prestacao}/>
          <Route exact path="/verbas/:id" component={Verbas}/>
          <Route path="/login" component={Login} />
          <Route path="/google" component={Login} />
          <PrivateRoute path="/users" page="users" component={Users} />
          <PrivateRoute path="/projetos" component={Projeto} />
          <PrivateRoute path="/view/:id" component={View} />
          <PrivateRoute path="/calendar/:id" component={Calendar} />
          <PrivateRoute path="/print_calendar/:id" component={PrintCalendar} />
          <PrivateRoute path="/cashflow" component={Cashflow} />
          <PrivateRoute path="/despesas/" component={Despesas} />
          <PrivateRoute path="/receitas/" component={Receitas} />
          <PrivateRoute path="/horas/" component={Horas} />
          <PrivateRoute path="/budgets" component={Budget} />
          <PrivateRoute path="/empresas" component={Empresas} />
          <PrivateRoute path="/config" component={Config} />
          <PrivateRoute path="/decupagem/:id" component={Decupagem} />
          <PrivateRoute path="/sheet/:id" component={Sheet} />
          <PrivateRoute path="/office/:id" component={Office} />
          <PrivateRoute path="/filmcard/:id" component={FilmCard} />
          <PrivateRoute path="/officesanta/:id" component={OfficeSanta} />
          <PrivateRoute path="/officehorizontal/:id" component={OfficeHorizontal} />
          <PrivateRoute path="/officemagma/:id" component={OfficeMagma} />
          <PrivateRoute path="/officeliquor/:id" component={OfficeLiquor} />
          <PrivateRoute path="/wrong/" component={Wrong} />
          <PrivateRoute path="/reembolso/" component={Reembolso} />
          <PrivateRoute path="/vitra/" component={Vitra} />
          <PrivateRoute path="/report/" component={Pnl} />
          <PrivateRoute path="/ebitda/" component={Ebitda} />
          <PrivateRoute path="/fpna/" component={Fpna} />
          <PrivateRoute path="/fpna_old/" component={FpnaOld} />
          <PrivateRoute path="/caches/" component={Caches} />
          <PrivateRoute path="/reportPDF/:id" component={ReportPDF} />
          <PrivateRoute path="/xray/:id" component={XRay} />
          <PrivateRoute path="/pauta" component={Pauta} />
          <PrivateRoute path="/spread" component={Spread} />
          <PrivateRoute path="/pix_report" component={pixReport} />
          


          
          {window.location.pathname.indexOf('/pix-fore') < 0 && <Footer/>}
        </div>
      </Router>
    </AuthContext.Provider>
    </MuiPickersUtilsProvider>
  )
}




export default App;
