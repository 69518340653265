import React, { Component} from "react";
import {deepPurple,teal} from '@material-ui/core/colors';
import { Menu, MenuItem,Grid, ThemeProvider,createTheme, Tooltip, IconButton,Zoom, TextField, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent, ListItemText } from '@material-ui/core'
import Meta from '../../components/meta'
import api from '../../services/api'
import ProgressBar from '../../components/progress';
import Bloco from './Bloco'
import BlocoApi from './BlocoApi'
import BlocoApiTopline from './BlocoApiTopline'
import BlocoFee from './BlocoFee'
import BlocoComissoes from './BlocoComissoes'
import BlocoComissoesAPI from './BlocoComissoesAPI'
import BlocoContract from  './BlocoContract'
import { Assignment, CalendarToday, Delete, FeaturedPlayList, InsertDriveFile, PhotoCamera, Tune, Visibility, VisibilityOff } from "@material-ui/icons";
import CurrencyFormat from "react-currency-format";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {AutoComp, MyDatePicker, MySelect } from "../../components/textField";
import { Redirect } from "react-router";
import { BarLoader } from "react-spinners";
import axios from "axios";
import { withStyles } from "@material-ui/styles";
import Sticky from 'react-sticky-el';


const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
    MuiIconButton: {
      root: {
          color:'#ddd',
        '&:hover': {
          color: teal[100]
        }
      }
    }
  }
}
)

const StyledMenuItem = withStyles((theme) => ({
  root: {
      '&:focus': {
      backgroundColor: deepPurple,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: 'white',
      },
      },
  },
  }))(MenuItem);

export default class Sheet extends Component {


  // VARIAVEIS INICIAIS

  state = {
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    token: localStorage['tokens'],
    id:this.props.match.params.id,
    loading:true,
    perc:0,
    versao:"",
    cliente:"",
    projeto:"",
    live_action:[],
    time_interno:[],
    fornecedores_post:[],
    copias:[],
    ancine:[],
    anchorEl:false,
    openStartBudgetModal:false,
    sub_nucleos:['Direcao','Producao','Seguranca e testes','Fotografia','Equipamento de Camera','Luz e Infra','Arte','Locacao','Figurino e Make','Producao de Elenco','Elenco','Transporte','Alimentacao','Acompanhamento'],
    sub_nucleos_display: ['Direção','Produção','Segurança e testes','Fotografia','Equipamento de Câmera','Luz e Infra','Arte','Locação','Figurino e Make','Produção de Elenco','Elenco','Transporte','Alimentação','Acompanhamento'],
    sub_nucleos_api: ['Back-office','Producao Executiva','Pesquisa Criativa','Direcao','Atendimento'],
    sub_nucleos_display_api: ['Back-office','Produção Executiva','Pesquisa Criativa','Direção','Atendimento'],
    valor_direcao:0,
    valor_executiva:0,
    sub_nucleos_valor: [0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    sub_nucleos_ativos: [0,1,1,1,1,1,1,1,1,1,1,1,1,1],
    valor_live_action:0,
    valor_time_interno: [0],
    valor_fornecedores_post: [0],
    valor_copias: [0],
    valor_ancine: [0],
    valor_api:[0],
    valor_total:0,
    uploading: false,
    preencheComissao:0,
    cargos:[],
    sub_nucleo_ativo:0,
    total_producao:0,
    impostos:0,
    blocos: ['Live Action','Time Interno','Fornecedores','Copias','Ancine'],
    blocosAtivos:[1,1,1,1,1,0,0],
    valores_blocos:[0,0,0,0,0,0,0],
    monta:false,
    fee_edit:-1,
    api_edit:-1,
    comissao_edit:-1,
    total_fee:0,
    total_comissao:0,
    alertOpen:false,
    salvo:true,
    showHide:0,
    cidade:'',
    aproNomes:['Pré-Produção','Produção','Cenografia','Locação/Estúdio','Equipe Técnica','Equipamento','Alimentação/Transporte','Elenco','Passagem e Hospedagem','Pós Produção','Custos COVID','Outros'],
    ce:null,
    ce_ep:null,
    ce_diretor:null,
    ce_coord:null,
    ce_landscape:null,
    ce_ok:false,
    onlyPost:0
  }


  // LOADING

  toLoad = () => {
    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }



// ASSIM QUE MONTA O COMPONENTE SHEET

  componentDidMount(){

    this.setPage();  
    this.budgetInfo(this.state.id);

    
    if(window.location.href.split('seamless').length>1){
      
      this.setState({

        seamless:1
      })
    }

    if(window.location.href.split('post').length>1){
      
      this.setState({

        onlyPost:1
      })
    }

    if(window.location.href.split('sheetedit').length>1){
      
      this.setState({

        sheetedit:1
      })
    }
    
    if(!this.state.usuario_id && window.location.href.split('post').length===0){
      this.setState({
        showHide:1,
        sub_nucleos_ativos:[0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      })
    }
      
  }

  
// SALVA PAGINA

setPage = async () => {

  localStorage.setItem("page",'/budgets');

  api.get('update/setPage.php?id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&page=/budgets&token='+this.state.token+'&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {
      
  

      if(result.data==='LOGOUT'){
        window.location.href='/wrong';
      }
      
    }})
}


  // PEGA INFORMAÇÕES DO BUDGET

  budgetInfo = async (id) => {

     // NUCLEOS SICÁRIOS
 
     if(this.state.empresa_id/1===23){
       
      this.setState({
        sub_nucleos_display: ['Pré-produção','Produção','Segurança e testes','Equipe','Equipamento de Câmera','Luz e Infra','Arte','Cenografia','Figurino','-','Elenco','Transporte','Alimentação','Acompanhamento'],
    
      })
    }

    // ATIVOS API

    if(this.state.empresa_id/1===4){
    
      this.setState({
        sub_nucleos_valor: [0,0,0,0,0],
        sub_nucleos_ativos: [0,0,0,0,0],
      })

    }
    
    this.toLoad()
    this.setState({
      blocosAtivos:[],
      monta:false,
      anchorEl:null
    })
      api.get('get/budgets.php?id='+id+'&user_id='+this.state.usuario_id+'&r='+Math.random(10000)).then(result => {
        
       
      if (result.status === 200) {
         
          let versao = result.data.budget[0].nome;
          if(result.data.nome_versao_original){
            versao=result.data.nome_versao_original
          }

          let budget_empresa_id = result.data.projeto[0].empresa_id;

          if(this.state.empresa_id/1===1){
            budget_empresa_id = 1;
          }

          let ce_ok =  false;

          if(result.data.budget[0].ce_diretor/1!==0 && result.data.budget[0].ce_ep/1!==0 && result.data.budget[0].ce_coord/1!==0 && result.data.budget[0].ce_landscape/1!==0){
            ce_ok = true
          }
           
          console.log('FPOST',result.data.unidade[0].a_fornecedores_post)
          this.setState({
            id:id,
            projeto_id:result.data.budget[0].projeto_id,
            versao:versao,
            budget_empresa_id:budget_empresa_id,
            liquor_id:result.data.budget[0].liquor_id,
            liquor_valor:result.data.liquor_valor/1,
            api_id:result.data.budget[0].api_id,
            projeto:result.data.projeto[0].nome,
            cliente:result.data.projeto[0].cliente,
            budget_status:result.data.budget[0].budget_status,
            impostos:result.data.budget[0].impostos,
            a_liveaction:result.data.unidade[0].a_live_action,
            a_time_interno:result.data.unidade[0].a_time_interno,
            a_fornecedores_post:result.data.unidade[0].a_fornecedores_post,
            a_copias:result.data.unidade[0].a_copias,
            a_ancine:result.data.unidade[0].a_ancine,
            presets:result.data.presets,
            subPresets:result.data.subPresets,
            fee:[['Live Action','Time Interno','Fornecedores','Copias','Ancine'],[result.data.budget[0].live_action,result.data.budget[0].time_interno,result.data.budget[0].fornecedores_post,result.data.budget[0].copias,result.data.budget[0].ancine,result.data.budget[0].fee,result.data.budget[0].mesa]],
            liquor:result.data.empresa[0].liquor,
            ec:result.data.empresa[0].ec,
            api:result.data.empresa[0].api,
            office:result.data.office,
            versoes:result.data.versoes,
            rtv:result.data.rtv,
            liquor_checked:result.data.budget[0].liquor_checked,
            api_permission:result.data.api_permission,
            api_permission2:result.data.api_permission2,
            nome_versao_original:result.data.nome_versao_original,
            ce:result.data.budget[0].ce,
            ce_diretor:result.data.budget[0].ce_diretor,
            ce_ep:result.data.budget[0].ce_ep,
            ce_coord:result.data.budget[0].ce_coord,
            ce_landscape:result.data.budget[0].ce_landscape,
            ce_ok:ce_ok,
            api_tipo:result.data.api_tipo
          })

          
          if(result.data.rtv){
            this.setState({
              start_nome:result.data.rtv[0]['nome'],
              start_contato:result.data.rtv[0]['email'],

            })

          }
          
          
          
          this.getBudget();
          

      }})
       

  }


  // PEGA LINHAS DO BUDGET

    getBudget = async() => {

      api.get('get/lines.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
          
          // SE FOR PLANILH API, RETIRA O CUSTO DAS LINHAS QUE RESUMIDAS DO VALOR API PARA NÃO DUPLICAR O CUSTO FINAL

          if(this.state.empresa_id/1===4 && this.state.api_tipo/1===1){
            result.data['api']['unico'] = []
          }

          
          this.setState({
            live_action:result.data[this.state.blocos[0]],
            time_interno:result.data[this.state.blocos[1]],
            fornecedores_post:result.data[this.state.blocos[2]],
            copias:result.data[this.state.blocos[3]],
            ancine:result.data[this.state.blocos[4]],
            api_list:result.data['api'],
            comissao: result.data['comissao'],
            controle:result.data,
            blocosAtivos:[this.state.a_liveaction/1,this.state.a_time_interno/1,this.state.a_fornecedores_post/1,this.state.a_copias/1,this.state.a_ancine/1,this.state.api/1],
            
            monta:true,
          
          })
          
        
          this.calc_ini();
          
          
          

      }})

    }

  
// CALCULO INICIAL DA PLANILHA APรS O LOADING

  calc_ini = () => {
    
    for(let i=0;i<this.state.sub_nucleos_valor.length;i++){

      let sub_nucleos_valor = this.state.sub_nucleos_valor;

      if(this.state.empresa_id/1!==4){
        sub_nucleos_valor[i] = this.state.controle['Live Action'][this.state.sub_nucleos[i]].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )
      }else{
        sub_nucleos_valor[i] = this.state.controle['Api Itens'][this.state.sub_nucleos_api[i]].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )
      }
    

    this.setState({
      valor_live_action: this.state.valor_live_action+this.state.controle['Live Action'][this.state.sub_nucleos[i]].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 ),
      sub_nucleos_valor: sub_nucleos_valor
    
    })
  }

  setTimeout(() => {

    this.setState({
        valor_bloco_live: this.state.sub_nucleos_valor.reduce((a,v) =>  a = a + (v) , 0 ),
        valor_bloco_api_itens: this.state.sub_nucleos_valor.reduce((a,v) =>  a = a + (v) , 0 ),
    });
    

    }, 20);

    setTimeout(() => {

      
        this.setState({
          valor_time_interno:[this.state.controle["Time Interno"]['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )],
          valor_fornecedores_post:[this.state.controle["Fornecedores"]['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )/1],
          valor_copias:[this.state.controle['Copias']['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )],
          valor_ancine:[this.state.controle['Ancine']['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )],
          valor_api:[this.state.controle['api']['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )],
          total_producao:this.state.valor_bloco_live+this.state.valor_time_interno[0]/1+this.state.valor_fornecedores_post[0]/1+this.state.valor_copias[0]/1+this.state.valor_ancine[0]/1+this.state.valor_api[0]/1,
        })

        
      },30)
      
      
      setTimeout(() => {
      this.setState({
        valores_blocos:[this.state.valor_bloco_live/1,this.state.valor_time_interno[0]/1,this.state.valor_fornecedores_post[0]/1,this.state.valor_copias[0]/1,this.state.valor_ancine[0]/1,this.state.valor_api[0]/1],
        total_producao:this.state.valor_bloco_live/1+this.state.valor_time_interno[0]/1+this.state.valor_fornecedores_post[0]/1+this.state.valor_copias[0]/1+this.state.valor_ancine[0]/1+this.state.valor_api[0]/1,
      })

      if(this.state.empresa_id/1===4){

        this.setState({
          valor_executiva:this.state.sub_nucleos_valor[1],
          valor_direcao:this.state.sub_nucleos_valor[3]
        })

      }

    },40)

    setTimeout(() => {
      
      this.calc_total_fee()
      
      },50)

      
  }



  // CALCULO TOTAL DO FEE

  calc_total_fee = () => {
    
    let total_producao = this.state.valor_bloco_live/1+this.state.valor_time_interno[0]/1+this.state.valor_fornecedores_post[0]/1+this.state.valor_copias[0]/1+this.state.valor_ancine[0]/1+this.state.valor_api[0]/1;
    this.setState({
      total_producao:total_producao,
      total_fee: this.state.valor_bloco_live/1*this.state.fee[1][0]/100+this.state.valor_time_interno[0]/1*this.state.fee[1][1]/100+this.state.valor_fornecedores_post[0]/1*this.state.fee[1][2]/100+this.state.valor_copias[0]/1*this.state.fee[1][3]/100+this.state.valor_ancine[0]/1*this.state.fee[1][4]/100+total_producao*this.state.fee[1][5]/100+total_producao*this.state.fee[1][6]/100,
    })

    this.setState({
      total_comissao: 0,
    })
    this.calc_total_comissao();

  }



  // CALCULO TOTAL DA COMISSรO

  calc_total_comissao = () => {
    
    //const comissao = 0;
    
    if(this.state.empresa_id/1===4){

      for(let i=0;i<this.state.comissao['unico'].length;i++){
          
        if(this.state.comissao['unico'][i]['tipo'] === '0'){this.setState({total_comissao: this.state.total_comissao+this.state.comissao['unico'][i]['percentual']/1 })}
        if(this.state.comissao['unico'][i]['tipo'] === '1'){this.setState({total_comissao: this.state.total_comissao+this.state.comissao['unico'][i]['percentual']/1*(this.state.valor_direcao/100)})}
        if(this.state.comissao['unico'][i]['tipo'] === '2'){this.setState({total_comissao: this.state.total_comissao+this.state.comissao['unico'][i]['percentual']/1*this.state.valor_executiva/100})}
        
      }

    }else{
    
        for(let i=0;i<this.state.comissao['unico'].length;i++){
          
          if(this.state.comissao['unico'][i]['tipo'] === '0'){this.setState({total_comissao: this.state.total_comissao+(this.state.comissao['unico'][i]['percentual']/1*this.state.valor_bloco_live/1)/100}) }
          if(this.state.comissao['unico'][i]['tipo'] === '1'){this.setState({total_comissao: this.state.total_comissao+this.state.comissao['unico'][i]['percentual']/1*(this.state.valor_time_interno[0]/100+this.state.valor_fornecedores_post[0]/100)})}
          if(this.state.comissao['unico'][i]['tipo'] === '2'){this.setState({total_comissao: this.state.total_comissao+this.state.comissao['unico'][i]['percentual']/1*this.state.total_producao/100})}
          if(this.state.comissao['unico'][i]['tipo'] === '3'){this.setState({total_comissao: this.state.total_comissao+this.state.comissao['unico'][i]['percentual']/1*this.state.total_fee/100}) }
          if(this.state.comissao['unico'][i]['tipo'] === '4'){this.setState({total_comissao: this.state.total_comissao+((this.state.total_producao/1+this.state.total_fee/1)/(1-this.state.comissao['unico'][i]['percentual']/100)-(this.state.total_producao/1+this.state.total_fee/1))}) }
          if(this.state.comissao['unico'][i]['tipo'] === '5'){this.setState({total_comissao: this.state.total_comissao+this.state.comissao['unico'][i]['percentual']/1 })}

        }

    }


    let lines_api = this.state.api_list['unico'];
    let valor_bloco = 0;
    let valor_total_calc_api = Math.floor((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1+((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)))/50+1)*50
          for(let i=0;i<lines_api.length;i++){

              valor_bloco += Math.floor(lines_api[i].o_diaria*lines_api[i].o_qt*valor_total_calc_api/100/50)*50;
           }


    this.setState({
      valor_total: Math.floor((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1+((this.state.total_producao/1+this.state.total_fee/1+this.state.valor_api/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.valor_api/1+this.state.total_comissao/1)))/50)*50,
      valorBlocoTopline:valor_bloco
    })

        

    this.endLoad();

  }



  // UPDATE DE VALORES

  upControle = async(bloco,sub_nucleo,index,col,valor) => {
   
  
  // BLOCO LIVE ACTION
    if(bloco === "Live Action" || bloco === "Api Itens"){
    
      let controle = this.state.controle;
      

      let nucleos = this.state.sub_nucleos;

      if(bloco === "Api Itens"){

        nucleos = this.state.sub_nucleos_api;
      }


      controle[bloco][nucleos[sub_nucleo]][index][col] = valor;
      controle[bloco][nucleos[sub_nucleo]][index]['active'] = 1;
      
         
            
            setTimeout(() => {
              this.setState({
                salvo:false,
              })

              let sub_nucleos_valor = this.state.sub_nucleos_valor;
              sub_nucleos_valor[sub_nucleo] = this.state.controle[bloco][nucleos[sub_nucleo]].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )

      
              }, 10);

              setTimeout(() => {
                

                this.setState({
                    controle:controle,
                    valor_bloco_live: this.state.sub_nucleos_valor.reduce((a,v) =>  a = a + (v) , 0 ),
                    valor_bloco_api_itens: this.state.sub_nucleos_valor.reduce((a,v) =>  a = a + (v) , 0 )
                });
                
                }, 20);

                setTimeout(() => {
                this.setState({
                  valores_blocos:[this.state.valor_bloco_live/1,this.state.valor_time_interno[0]/1,this.state.valor_fornecedores_post[0]/1,this.state.valor_copias[0]/1,this.state.valor_ancine[0]/1,this.state.valor_api[0]/1],
                  total_producao:this.state.valor_bloco_live/1+this.state.valor_time_interno[0]/1+this.state.valor_fornecedores_post[0]/1+this.state.valor_copias[0]/1+this.state.valor_ancine[0]/1+this.state.valor_api[0]/1,
                  
                })

                if(this.state.empresa_id/1===4){

                  this.setState({
                    valor_executiva:this.state.sub_nucleos_valor[1],
                    valor_direcao:this.state.sub_nucleos_valor[3]
                  })

                }

                
              },30)

              setTimeout(() => {
                
                this.calc_total_fee()
                },40)


    }else{
        // TODOS OUTROS BLOCOS

        let controle = this.state.controle;

        controle[bloco]['unico'][index][col] = valor;
        controle[bloco]['unico'][index]['active'] = 1;

        this.setState({
          controle:controle,
        })

        
          setTimeout(() => {
          if(bloco === "Time Interno"){
            this.setState({valor_time_interno:[this.state.controle[bloco]['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )]})
          }
          if(bloco === "Fornecedores"){
            this.setState({valor_fornecedores_post:[this.state.controle[bloco]['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )]})
          }
          if(bloco === "Copias"){
            this.setState({valor_copias:[this.state.controle[bloco]['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )]})
          }
          if(bloco === "Ancine"){
            this.setState({valor_ancine:[this.state.controle[bloco]['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )]})
          }
          if(bloco === "api"){
            this.setState({valor_api:[this.state.controle[bloco]['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )]})
          }

          
        },20)
        setTimeout(() => {
          this.setState({
            salvo:false,
          })
          this.setState({
            valores_blocos:[this.state.valor_bloco_live/1,this.state.valor_time_interno[0]/1,this.state.valor_fornecedores_post[0]/1,this.state.valor_copias[0]/1,this.state.valor_ancine[0]/1,this.state.valor_api[0]/1],
            total_producao:this.state.valor_bloco_live/1+this.state.valor_time_interno[0]/1+this.state.valor_fornecedores_post[0]/1+this.state.valor_copias[0]/1+this.state.valor_ancine[0]/1+this.state.valor_api[0]/1,
          })
          this.calc_total_fee()
        },30)
    
    }
  
  }
 




  // SOLICITAR BUDGET LIQUOR

  newLiquor = (e) => {

    this.toLoad()

    api.get('insert/newLiquor.php?empresa_id='+localStorage['empresa_id']+'&cliente='+this.state.cliente+'&atendimento_id='+this.state.usuario_id+'&budget_id='+this.state.id+'&nome='+this.state.projeto+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

       
        this.setState({
          liquor_valor:1,
          liquor_id:result.data,
        })

        this.endLoad()
      }

    })
  }


    // SOLICITAR BUDGET API

    newApi = (e) => {

      api.get('insert/newApi.php?empresa_id='+localStorage['empresa_id']+'&cliente='+this.state.cliente+'&atendimento_id='+this.state.usuario_id+'&budget_id='+this.state.id+'&nome='+this.state.projeto+'&r='+Math.random(10000)).then(result => {
          
        if (result.status === 200) {
          this.setState({
            api_id:result.data
          })
          
        }
  
      })
    }
  

   // CLICA PARA EDITAR O NOME

   editNomeVersao = () => {

    this.setState({
      editNome:1,
    })

   }

   // EDITA O NOME DA VERSAO

   setNomeVersao = (e) => {

    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+e.target.value+'&id='+this.state.id+'&sheet=budget&col=nome&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

            if(e.target.value!==""){
              this.setState({
                editNome:0,
                versao: e.target.value
              })
            }else{
              this.setState({
                editNome:0,
              })
            }
      }
    })
   }

 
   // QUANDO SE TROCA O FORNECEDOR
   
   changeFornecedor = (keyid,e,nomeFornecedor,custo,bloco,sub_nucleo) => {
    
    this.setState({
      salvo:false,
    })

    let controle = this.state.controle;

    controle[bloco][this.state.sub_nucleos[sub_nucleo]][keyid]['fornecedor'] = nomeFornecedor;

    this.setState({
      controle:controle
    })
 
     setTimeout(() => {
       this.upControle(bloco,sub_nucleo,keyid,'o_valor',custo)
     }, 0);
     
     
 
   }
   
  
// QUAL TEXTFIELD DO API ESTAMOS EDITANDO

defineApiEdit = (w) => {
  
  this.setState({
      api_edit:w,
      
  })
}

// API TEXTFIELD

blurFieldApi = async(e,w,id) => {

  api.get('update/updateApi.php?budget_id='+this.state.id+'&sheet=linha&id='+id+'&col=o_valor&value='+e.target.value+'&empresa_id='+this.state.empresa_id).then(result => {
        
    if (result.status === 200) {

            this.setState({
              salvo:false,
            })

            let api_list = this.state.api_list;
            api_list['unico'][w]['o_valor'] = e.target.value

            

              this.setState({
                  api_list:api_list,
                  api_edit:-1,
                  
              })
              setTimeout(() => {
              this.upControle('api','unico',w,'o_valor',e.target.value)
              
              },20)
            }
  })
}

// API2 TEXTFIELD

blurFieldApi2 = async(e,w,id) => {

  this.toLoad()

  api.get('update/updateApi.php?budget_id='+this.state.id+'&sheet=linha&id='+id+'&col=o_diaria&value='+e.target.value+'&empresa_id='+this.state.empresa_id).then(result => {
        
    if (result.status === 200) {

            this.setState({
              salvo:false,
            })

            let api_list = this.state.api_list;
            api_list['unico'][w]['o_diaria'] = e.target.value

            this.endLoad()

              this.setState({
                  api_list:api_list,
                  api_edit:-1,
                  
              })
              setTimeout(() => {
              this.upControle('api','unico',w,'o_diaria',e.target.value)
              
              },20)
            }
  })



 

}
  


// QUAL TEXTFIELD DO FEE ESTAMOS EDITANDO

defineFeeEdit = (w) => {
  this.setState({
      fee_edit:w,
      
  })
}

// FEE % TEXTFIELD

blurFieldFee = async(e,w) => {
 
  this.setState({
    salvo:false,
  })
  let newPer = e.target.value/1;
  
  let fee = this.state.fee;

  fee[1][w] = newPer;

    this.setState({
        fee:fee,
        fee_edit:-1,
        
    })
    setTimeout(() => {
      
      this.calc_total_fee()
    },20)

}


// QUAL TEXTFIELD DO FEE ESTAMOS EDITANDO


defineComissaoEdit = (w) => {

  this.setState({
      comissao_edit:w,
      
  })
}



// COMISSAO % TEXTFIELD

blurFieldComissao = async(e,w) => {
  

  this.setState({
      salvo:false,
    })

  let comissao = this.state.comissao;

  if(e.target.value!==''){
    comissao['unico'][w]['percentual'] = e.target.value;
    comissao['unico'][w]['active'] = 1;
  }
  

    this.setState({
        comissao:comissao,
        comissao_edit:-1
        
    })
    setTimeout(() => {
    this.calc_total_fee()
    },20)
}


// VISIBILIDADE NO CLICK DOS BLOCOS ATIVOS LIVE ACTION

upBlocoView = async(w) => {
  
  let u=0;
  if(this.state.sub_nucleos_ativos[w]===0){
     u=1;
  }else{
     u=0;
  }

  let sub_nucleos_ativos = this.state.sub_nucleos_ativos;

  sub_nucleos_ativos[w] = u;

  
  this.setState({
    sub_nucleos_ativos:sub_nucleos_ativos
  })

}


// LIQUOR SWITCH


liquor_switch = (e,bd_id) =>{

  let v=0;
  if(e.target.checked === false){
    v=0;
  }else{
    v=1;
  }

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+v+'&id='+this.state.id+'&sheet=budget&col=liquor_checked&r='+Math.random(10000))
  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+(v)+'&id='+bd_id+'&sheet=linha&col=o_qt&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {

        let blocosAtivos = this.state.blocosAtivos;

        blocosAtivos[2]=1;

          this.setState({
            liquor_checked:v,
            blocosAtivos:blocosAtivos
            
          })

          setTimeout(() => {
            this.updateFornecedores(v);
          }, 10);
          

          

    }
  })

}


// UPDATE FORNECEDORES

updateFornecedores = (v) => {

  let blocosAtivos = this.state.blocosAtivos;

  blocosAtivos[2]=0;

  let fornecedores_post = this.state.fornecedores_post;

  for(let i=0;i<fornecedores_post['unico'].length;i++){

    
    if(fornecedores_post['unico'][i]['nome']==='Liquor'){
      fornecedores_post['unico'][i]['o_qt']=v;
      
    }
  }

        this.setState({
          fornecedores_post:fornecedores_post,
          blocosAtivos:blocosAtivos
        
        })
        
        this.calc_ini();
        
        
        

  






}

// SWITCH API

api_switch = (e,line_id,bd_id) => {

  let v=0;
  if(e.target.checked === false){
    v=0;
  }else{
    v=1;
  }

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+v+'&id='+bd_id+'&sheet=linha&col=o_qt&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        
        let api_list = this.state.api_list;
        let controle = this.state.controle;

        api_list['unico'][line_id]['o_qt'] = v;

        controle['api']['unico'][line_id]['o_qt'] = v;

        
        this.setState({
          api_list:api_list,
          controle:controle
        })

        setTimeout(() => {

        this.setState({valor_api:[this.state.controle['api']['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )]})

        },20)

        setTimeout(() => {
                  
          this.calc_total_fee()
          
          },80)
      
      }

    })

}

// IMPORT BUDGET

importBudget = async(w,bloco,tipo,subPreset) => {

  this.setState({
    salvo:false,
  })
  this.toLoad()
  
  api.get('get/importBudget.php?id='+w+'&bloco='+bloco+'&tipo='+tipo+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      
      let sub_nucleos = 0;
      // SE FOR LIVE ACTION
      if(bloco===0){

        sub_nucleos = this.state.sub_nucleos;
      }else{
        sub_nucleos = ['unico'];
      }

        this.setState({
          import:result.data,
          
        })

        if(subPreset>0){
          
          sub_nucleos = [this.state.sub_nucleos[subPreset-1]]
        }

        
        let controle = this.state.controle;
        
        for(let i=0;i<sub_nucleos.length;i++){

          for(let a=0;a<controle[this.state.blocos[bloco]][sub_nucleos[i]].length;a++){
            
            let valor = 0;
            let o_qt = 1;
            let o_diaria = 1
            for(let b=0;b<this.state.import.length;b++){
              if(controle[this.state.blocos[bloco]][sub_nucleos[i]][a]['nome']===this.state.import[b]['nome']){
                valor=this.state.import[b]['o_valor'];
                o_qt=this.state.import[b]['o_qt'];
                o_diaria=this.state.import[b]['o_diaria'];
              }
            }
            
            controle[this.state.blocos[bloco]][sub_nucleos[i]][a]['comentario']="";
            controle[this.state.blocos[bloco]][sub_nucleos[i]][a]['fornecedor']="";
            controle[this.state.blocos[bloco]][sub_nucleos[i]][a]['active']=1;
            controle[this.state.blocos[bloco]][sub_nucleos[i]][a]['o_qt']=o_qt;
            controle[this.state.blocos[bloco]][sub_nucleos[i]][a]['o_diaria']=o_diaria;
            controle[this.state.blocos[bloco]][sub_nucleos[i]][a]['o_valor']=valor;
          }

        }
        
        // UP LIVE ACTION
        if(bloco===0){
          let subAtivos = this.state.sub_nucleos_ativos;
          this.setState({
            sub_nucleos_ativos: [1,1,1,1,1,1,1,1,1,1,1,1,1],
            controle:controle,
            
          })
          
          
          setTimeout(() => {
            this.setState({
              sub_nucleos_ativos: subAtivos
            })
            
          }, 20);
      }else{

        let blocosAtivos = this.state.blocosAtivos;

        blocosAtivos[bloco] = 1;



        this.setState({
          blocosAtivos:blocosAtivos
        });

          setTimeout(() => {

            let blocosAtivos = this.state.blocosAtivos;

            blocosAtivos[bloco] = 0;


            this.setState({
              blocosAtivos:blocosAtivos
            });
    
            
          }, 20);


      }


        this.calc_ini()
        
        
        

    }})

}



// NOVA LINHA

addlineTo = (w) => {

  this.setState({
    salvo:false,
  })

  this.toLoad();
  api.get('insert/newLinha.php?usuario_id='+this.state.usuario_id+'&empresa_id='+this.state.empresa_id+'&budget_id='+this.state.id+'&tipo='+w+'&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {
      
      let c = this.state.controle;

      c[this.state.blocos[w]]['unico'].push({'id':result.data,'nome':'Novo item','o_qt':1,'o_diaria':1,'o_valor':0,'active':1})

      this.setState({controle:c})
      this.endLoad();

    }})


  
}

// ABRE ALERT

abreAlert = () => {
  this.setState({
    alertOpen:true
  })
}

// FECHA ALERTA


fechaAlerta = () => {
  this.setState({
    alertOpen:false
  })
}

// DELETA BUDGET

deletaBudget = () => {
  
  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value=1&id='+this.state.id+'&sheet=budget&col=active&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      window.location.href = '/budgets/';
    }
  })
  
}

// SAVE PRESET

savePreset = (nome,bloco) => {

  api.post('insert/newPreset.php?&r='+Math.random(10000),JSON.stringify({ 
    empresa_id:this.state.empresa_id,
    nome: nome,
    tipo: bloco,
    budget_id: this.state.id,
    
  
  })).then(result => {
        
    if (result.status === 200) {
      
        api.get('get/budgets.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {
             
              this.setState({
              presets:result.data.presets,
                
              })

            }
          })

      }})
      

}



// SAVE PRESET

savePresetSub = (nome,bloco,nucleo) => {
  
  api.post('insert/newPresetSub.php?&r='+Math.random(10000),JSON.stringify({ 
    empresa_id:this.state.empresa_id,
    nome: nome,
    tipo: bloco,
    nucleo:nucleo,
    budget_id: this.state.id,
    
  
  })).then(result => {
        
    if (result.status === 200) {
      
        api.get('get/budgets.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {
             
              this.setState({
              subPresets:result.data.subPresets,
                
              })

            }
          })

      }})
      

}



// DELETE PRESET

deletaPreset = (id,bloco) => {

  api.get('delete/delete.php?sheet=presets&id='+id+'&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {

        api.get('get/budgets.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {
             
              this.setState({
              presets:result.data.presets,
              subPresets:result.data.subPresets,
                
              })

              

            }

          })

    }
  })
}


// DUPLICAR BUDGET

duplicarBudget = () => {
  if(this.state.salvo){
  
  api.get('insert/duplicarBudget.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {

    if (result.status === 200) {
      
      window.location.href = '/sheet/'+result.data;
    
    }

  })
}else{
  alert('Você precisa salvar o budget primeiro.')
}
}

// SAVE BUDGET


save = () => {

  this.toLoad()
  

  // COMISSAO

  let comissao_save = this.state.comissao;

  
  for(let i=0;i<comissao_save['unico'].length;i++){
      
  
    if(this.state.empresa_id/1!==4){
      if(comissao_save['unico'][i]['tipo'] === '0'){
        comissao_save['unico'][i]['valor'] = (comissao_save['unico'][i]['percentual']/1)*(this.state.valor_bloco_live/1)/100;
      }

      if(comissao_save['unico'][i]['tipo'] === '1'){
        comissao_save['unico'][i]['valor'] = (comissao_save['unico'][i]['percentual']/1)*(this.state.valor_time_interno[0]/100+this.state.valor_fornecedores_post[0]/100)
      }

      if(comissao_save['unico'][i]['tipo'] === '2'){
        comissao_save['unico'][i]['valor'] = (comissao_save['unico'][i]['percentual']/1)*(this.state.total_producao/100);
      }

      if(comissao_save['unico'][i]['tipo'] === '3'){
        comissao_save['unico'][i]['valor'] = (comissao_save['unico'][i]['percentual']/1)*(this.state.total_fee/100);
      }

      if(comissao_save['unico'][i]['tipo'] === '4'){
        comissao_save['unico'][i]['valor'] = ((this.state.total_producao/1+this.state.total_fee/1)/(1-(comissao_save['unico'][i]['percentual']/100))-(this.state.total_producao/1+this.state.total_fee/1));
      }

      if(comissao_save['unico'][i]['tipo'] === '5'){
        comissao_save['unico'][i]['valor'] = comissao_save['unico'][i]['percentual']/1;
      }
    }else{

      if(comissao_save['unico'][i]['tipo'] === '0'){
        comissao_save['unico'][i]['valor'] = comissao_save['unico'][i]['percentual']/1;
      }

      if(comissao_save['unico'][i]['tipo'] === '1'){
        comissao_save['unico'][i]['valor'] = Math.floor((comissao_save['unico'][i]['percentual']/1)*(this.state.valor_direcao)/100)
      }

      if(comissao_save['unico'][i]['tipo'] === '2'){
        comissao_save['unico'][i]['valor'] = Math.floor((comissao_save['unico'][i]['percentual']/1)*(this.state.valor_executiva)/100)
      }

    }

    
  

  }




  let saveFee = [this.state.fee[1]];
 
  let toSave =[];


  for(let i=0;i<this.state.sub_nucleos.length;i++){
    toSave = toSave.concat(this.state.controle['Live Action'][this.state.sub_nucleos[i]].filter(save => save.active === 1));
  }

  for(let i=0;i<this.state.sub_nucleos_api.length;i++){
    toSave = toSave.concat(this.state.controle['Api Itens'][this.state.sub_nucleos_api[i]].filter(save => save.active === 1));
  }


  for(let i=1;i<this.state.blocos.length;i++){
    toSave = toSave.concat(this.state.controle[this.state.blocos[i]]['unico'].filter(save => save.active === 1));
  }

  let api_valores = []

  api_valores = api_valores.concat(this.state.sub_nucleos_valor)

  let comissao = comissao_save['unico'].filter(save => save.active > -1);

  let valor_final = Math.floor((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1+((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)))/50+1)*50;

  api.post('update/save.php?id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&api='+this.state.api_id+'&r='+Math.random(10000),JSON.stringify({ 
    fee: saveFee,
    receita:(this.state.total_fee/1+this.state.valor_time_interno/1),
    comissao: comissao,
    lines: toSave,
    valor: valor_final,
    live_total: this.state.valor_bloco_live,
    fornecedores_post: this.state.valor_fornecedores_post[0],
    api_valores:api_valores
  
  })).then(result => {
        
    if (result.status === 200) {

      
        this.setState({
          
          salvo:true,
          monta:true,
        
        })
        
        if(this.state.api_id/1>0){
          
          this.getBudget()
        }else{
          this.calc_ini()
        }
        
        
    }} 
    )

    //this.endLoad()

}

// VER O PROJETO

viewProjeto = () => {
 
  window.location.href = '/view/'+this.state.projeto_id;
  
  
}

// START WINDOW

openStartBudget = () => {

  if(this.state.ce/0===1 || this.state.ce_ok || this.state.valor_bloco_live<=100000 || this.state.ec/1===0){

  this.toLoad();
  let cargos=[];

    api.get('/get/getSelect.php?id=1&sheet=users&col_id=cargo&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
          
          cargos[0] = result.data
          
          api.get('/get/getSelect.php?id=2&sheet=users&col_id=cargo&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
            if (result.status === 200) {
                
                cargos[1] = result.data
                
                api.get('/get/getSelect.php?id=2&sheet=projeto&col_id=projeto_status&closed=0&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
            
                  if (result.status === 200) {
                      
                      let projetos_startados = [{'value':0,'label':'Novo Projeto'}];

                      let projetos = result.data;

                      for(let i=0;i<projetos.length;i++){

                        projetos[i]['label'] = projetos[i]['label'].replace('  ',' ');

                      }
                      

                      projetos_startados = projetos_startados.concat(projetos);


                    api.get('/get/getSelect.php?id=3&sheet=users&col_id=cargo&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
            
                      if (result.status === 200) {
                          
                          cargos[2] = result.data
                
                          
                          this.endLoad();
                          
                          this.setState({
                          openStartBudgetModal:true,
                          projetos_startados:projetos_startados,
                          cargos:cargos,
                          start_valor: Math.floor((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1+((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)))/50+1)*50
                          
                
                        })
    
                        
                         
                    }})

                    
                     
                }})
            
                
          }})
        
    }})

  }else{

    alert('Os Execution Contracts precisam ser assinados.')
  }
    

    
    
}

// FECHA START WINDOW

fechaStart = () => {

  this.setState({
    openStartBudgetModal:false,

  })
}


// MUDA OS DADOS DO START

changeStart = (e,w) => {

  
  if(w===0){this.setState({start_valor:e.replace(',','')})}
  if(w===1){this.setState({start_condicao:e})}
  if(w===2){this.setState({start_prazo:e})}
  if(w===3){this.setState({start_nome:e})}
  if(w===4){this.setState({start_contato:e})}
  if(w===5){this.setState({start_projeto:e})}
  if(w===6){this.setState({start_comentario:e})}
  if(w===7){this.setState({cidade:e})}


}

// MUDA OS DADOS DE COMISSAO
changeStartComissao = (e,w) => {
  
  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+e+'&id='+w+'&sheet=comissao_budget&col=usuario_id&r='+Math.random(10000))
  this.setState({
    preencheComissao:this.state.preencheComissao+1
  })   
  
}


// START PROJETO

startProjeto = () => {

  if(this.state.start_valor && this.state.start_condicao && this.state.start_nome && this.state.start_contato && this.state.comfoto && this.state.start_prazo){
        this.setState({
          alertOpen:false
        })
        
        this.toLoad()
     
      api.post('insert/startProject.php?&r='+Math.random(10000),JSON.stringify({ 
        empresa_id:this.state.empresa_id,
        budget_id:this.state.id,
        start_valor: this.state.start_valor,
        start_condicao: this.state.start_condicao,
        start_nome: this.state.start_nome,
        start_contato: this.state.start_contato,
        start_prazo: this.state.start_prazo,
        start_projeto: this.state.start_projeto,
        start_comentario: this.state.start_comentario,
        user_id:this.state.usuario_id,
        cidade:this.state.cidade,
        liquor_checked:this.state.liquor_checked

      })).then(result => {
            
        if (result.status === 200) {
          
          
          if(this.state.api/1===2){

            // START PROJETO API TOPLINE

            api.get('insert/startAPITopline.php?id='+this.state.id+'&r='+Math.random(10000)).then(res=>{ 

              console.log(res.data)


              if(this.state.start_projeto>0){
            
                this.goProjeto(this.state.start_projeto);
    
              }else{

                this.goProjeto(this.state.projeto_id);
                
              }

            })


          }else{

            if(this.state.start_projeto>0){
            
                this.goProjeto(this.state.start_projeto);
    
              }else{

                this.goProjeto(this.state.projeto_id);
                
              }

          }

        }})



      }else{
        if(!this.state.comfoto){
          alert('Você precisa anexar um comprovante de aprovação.')
        }else{
          alert('Você precisa preencher todos os campos.')
        }
      }

}

// GO PROJETO

goProjeto = (id) =>{
              alert('Projeto criado com sucesso!')
              window.location.href ='../view/'+id;
}

// SHOW / HIDE

  showHide = () => {

    let showHide = this.state.showHide;
    let sub_nucleos_ativos = this.state.sub_nucleos_ativos;

    if(showHide===1){
      showHide = 0;
    }else{
      showHide = 1;
      sub_nucleos_ativos = [0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      
    }

    
    this.setState({
      showHide: showHide,
      sub_nucleos_ativos:sub_nucleos_ativos
    })

  }

  // UPLOAD IMAGEM

  fileSelect = (event) => {
    this.setState({ uploading: true }); 

    const fd = new FormData();
    fd.append('image',event.target.files[0],event.target.files[0].name)
    axios.post('https://treatments.run/landscape/upload.php?id='+this.state.id+'&sheet=budget',fd).then(res => {
       
        this.setState({ 
            uploading: false,
            comfoto:true
            }); 

            
    })
}

  // MENU VERSOES

  menuVersoes = (e) => {
    this.setState({
      anchorEl:e.currentTarget
    })
  }

  fechaMenu = () => {
    this.setState({
      anchorEl:null
    })
  }


  // EXPAND ALL

  expand = () => {
    if(!this.state.expand){
      this.setState({
        sub_nucleos_ativos:[0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        expand:true
      })
      
    }else{
      
      this.setState({
        sub_nucleos_ativos:[1,1,1,1,1,1,1,1,1,1,1,1,1,1],
        expand:false
      })
    }
  }

  // SHOW APRO

  showAproHandle = () => {

    if(this.state.showApro){
      this.setState({
        showApro:false
      })
    }else{
      this.setState({
        showApro:true
      })
    }
  }

 
  // VOID

  void = () => {
    
  }




  render() {
    return (

      <ThemeProvider theme={theme}>
        
        {this.state.permission > 2 ? <Redirect to='/login'/> : ''}
        <Meta title={"Budget "+this.state.id}/>
        <ProgressBar className="ztop" perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>

        
        <div className="conteudo">
        <Sticky className="holdstick" stickyClassName="sticky2">
            <Grid container spacing={3}>
                
                  <Grid item xs={8}><div className="titulo"><div className="l color_1">{this.state.projeto!=="" ? this.state.id : ''}</div><div className="l"></div>{this.state.budget_empresa_id/1===2 && this.state.budget_empresa_id/1!==this.state.empresa_id/1 ? ' LIQUOR | '+ this.state.cliente + ' ' + this.state.projeto : this.state.cliente + ' ' + this.state.projeto}</div></Grid>
                  <Grid item xs={this.state.usuario_id>0 ? 2:4} className="tr">{this.state.editNome ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onBlur={(e)=>this.setNomeVersao(e)} defaultValue={this.state.versao} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#444'}}}/> : <div onClick={()=>this.editNomeVersao()} className="pd15 mini-titulo">{this.state.versao}</div>}</Grid>
                  {this.state.usuario_id>0 ? <Grid item xs={1}><div className="bt large bgcolor_1" onClick={()=>this.duplicarBudget()}>Duplicar</div></Grid> : ''}
                  {this.state.usuario_id>0 ?  <Grid item xs={1}><div className="bt large bgcolor_6" onClick={(e)=>this.menuVersoes(e)}>Versões</div></Grid> : ''}

            </Grid>
            </Sticky>
            
            <div className="mt30"></div>
            
            {this.state.monta && this.state.empresa_id/1===4 ? <Bloco id={5} colorId={1} showHide={this.state.showHide} upControle={(a,b,c,d,e)=>this.upControle(a,b,c,d,e)} upBlocoView={(w)=>this.upBlocoView(w)} changeFornecedor={(a,b,c,d,e,f)=>this.changeFornecedor(a,b,c,d,e,f)} list={this.state.controle['Api Itens']} sub_nucleos={this.state.sub_nucleos_api} sub_nucleos_display={this.state.sub_nucleos_display_api} sub_nucleos_valor={this.state.sub_nucleos_valor} sub_nucleos_ativos={this.state.sub_nucleos_ativos}  display={true} valor_bloco={this.state.valor_bloco_api_itens} presets={this.state.presets[this.state.blocos[0]]} subPresets={this.state.subPresets} importBudget={(w,bloco,tipo,subpreset)=>this.importBudget(w,bloco,tipo,subpreset)} savePreset={(nome,bloco)=>this.savePreset(nome,bloco)} savePresetSub={(nome,bloco,nucleo)=>this.savePresetSub(nome,bloco,nucleo)} deletaPreset={(id,bloco)=>this.deletaPreset(id,bloco)}/> : ''}


            {this.state.blocosAtivos[0] === 0 && this.state.onlyPost!==1 ? <Bloco id={0} colorId={1} showHide={this.state.showHide} upControle={(a,b,c,d,e)=>this.upControle(a,b,c,d,e)} upBlocoView={(w)=>this.upBlocoView(w)} changeFornecedor={(a,b,c,d,e,f)=>this.changeFornecedor(a,b,c,d,e,f)} list={this.state.controle['Live Action']} sub_nucleos={this.state.sub_nucleos} sub_nucleos_display={this.state.sub_nucleos_display} sub_nucleos_valor={this.state.sub_nucleos_valor} sub_nucleos_ativos={this.state.sub_nucleos_ativos}  display={true} valor_bloco={this.state.valor_bloco_live} presets={this.state.presets[this.state.blocos[0]]} subPresets={this.state.subPresets} importBudget={(w,bloco,tipo,subpreset)=>this.importBudget(w,bloco,tipo,subpreset)} savePreset={(nome,bloco)=>this.savePreset(nome,bloco)} savePresetSub={(nome,bloco,nucleo)=>this.savePresetSub(nome,bloco,nucleo)} deletaPreset={(id,bloco)=>this.deletaPreset(id,bloco)}/> : ''}
            {this.state.blocosAtivos[1] === 0 && this.state.onlyPost!==1 ? <Bloco id={1} colorId={2} showHide={this.state.showHide} upControle={(a,b,c,d,e)=>this.upControle(a,b,c,d,e)} list={this.state.time_interno} sub_nucleos={['unico']} sub_nucleos_valor={this.state.valor_time_interno} valor_bloco={this.state.valor_time_interno[0]} sub_nucleos_ativos={[0]} savePreset={(nome,bloco)=>this.savePreset(nome,bloco)} presets={this.state.presets[this.state.blocos[1]]} importBudget={(w,bloco,tipo)=>this.importBudget(w,bloco,tipo)}/> : ''}
            {this.state.blocosAtivos[2] === 0 ? <Bloco id={2} colorId={3} showHide={this.state.showHide} upControle={(a,b,c,d,e)=>this.upControle(a,b,c,d,e)} list={this.state.fornecedores_post} sub_nucleos={['unico']} sub_nucleos_valor={this.state.valor_fornecedores_post} valor_bloco={this.state.valor_fornecedores_post[0]} liquor_id={this.state.liquor_id} liquor={this.state.liquor} liquor_valor={this.state.liquor_valor} sub_nucleos_ativos={[0]} addLine={1} addlineTo={(w)=>this.addlineTo(w)} presets={this.state.presets[this.state.blocos[2]]} importBudget={(w,bloco,tipo)=>this.importBudget(w,bloco,tipo)} savePreset={(nome,bloco)=>this.savePreset(nome,bloco)} newLiquor={()=>this.newLiquor()} liquor_switch={(e,id)=>this.liquor_switch(e,id)} liquor_checked={this.state.liquor_checked}/> : ''}
            {this.state.blocosAtivos[3] === 0 && this.state.onlyPost!==1 ? <Bloco id={3} colorId={4} showHide={this.state.showHide} upControle={(a,b,c,d,e)=>this.upControle(a,b,c,d,e)} list={this.state.copias} sub_nucleos={['unico']} sub_nucleos_valor={this.state.valor_copias} valor_bloco={this.state.valor_copias[0]} sub_nucleos_ativos={[0]} addLine={1} addlineTo={(w)=>this.addlineTo(w)} presets={this.state.presets[this.state.blocos[3]]} importBudget={(w,bloco,tipo)=>this.importBudget(w,bloco,tipo)} savePreset={(nome,bloco)=>this.savePreset(nome,bloco)}/> : ''}
            {this.state.blocosAtivos[4] === 0 && this.state.onlyPost!==1 ? <Bloco id={4} colorId={5} showHide={this.state.showHide} upControle={(a,b,c,d,e)=>this.upControle(a,b,c,d,e)}  list={this.state.ancine} sub_nucleos={['unico']} sub_nucleos_valor={this.state.valor_ancine} valor_bloco={this.state.valor_ancine[0]} sub_nucleos_ativos={[0]} addLine={1} addlineTo={(w)=>this.addlineTo(w)} presets={this.state.presets[this.state.blocos[4]]} importBudget={(w,bloco,tipo)=>this.importBudget(w,bloco,tipo)} savePreset={(nome,bloco)=>this.savePreset(nome,bloco)}/> : ''}
            {this.state.blocosAtivos[5] === 1 && this.state.monta && this.state.onlyPost!==1 ? <BlocoApi api_permission={this.state.api_permission} empresa_id={this.state.empresa_id} api_id={this.state.api_id} api_edit={this.state.api_edit} valor_api={this.state.valor_api[0]} list={this.state.api_list} valor_bloco={this.state.valor_api[0]} api_switch={(e,line_id,bd_id)=>this.api_switch(e,line_id,bd_id)} defineApiEdit={(w)=>this.defineApiEdit(w)} blurFieldApi={(e,w,id)=>this.blurFieldApi(e,w,id)} newApi={()=>this.newApi()}/> : ''}
            {this.state.monta && this.state.seamless!==1 && this.state.empresa_id/1!==4 && this.state.onlyPost!==1 ? <BlocoFee ativos={this.state.blocosAtivos} fee={this.state.fee} valores_blocos={this.state.valores_blocos} total_producao={this.state.total_producao} total_api={this.state.valor_api[0]} total_fee={this.state.total_fee} fee_edit={this.state.fee_edit} blurFieldFee={(e,w)=>this.blurFieldFee(e,w)} defineFeeEdit={(w)=>this.defineFeeEdit(w)}/> : ''}
            
            {this.state.monta && this.state.seamless!==1 && this.state.empresa_id/1!==23 && this.state.empresa_id/1!==4 && this.state.onlyPost!==1 ? <BlocoComissoes list={this.state.comissao} live_action={this.state.valor_bloco_live} post={this.state.valor_time_interno[0]/1+this.state.valor_fornecedores_post[0]/1} total_fee={this.state.total_fee} total_producao={this.state.total_producao} valor_bloco={this.state.total_comissao} comissao_edit={this.state.comissao_edit} defineComissaoEdit={(w)=>this.defineComissaoEdit(w)} blurFieldComissao={(e,w)=>this.blurFieldComissao(e,w)}/> : ''}
            {this.state.monta && this.state.seamless!==1 && (this.state.empresa_id/1===23 && this.state.permission<2) && this.state.onlyPost!==1 ? <BlocoComissoes list={this.state.comissao} live_action={this.state.valor_bloco_live} post={this.state.valor_time_interno[0]/1+this.state.valor_fornecedores_post[0]/1} total_fee={this.state.total_fee} total_producao={this.state.total_producao} valor_bloco={this.state.total_comissao} comissao_edit={this.state.comissao_edit} defineComissaoEdit={(w)=>this.defineComissaoEdit(w)} blurFieldComissao={(e,w)=>this.blurFieldComissao(e,w)}/> : ''}
            

            {this.state.monta && this.state.seamless!==1 && this.state.empresa_id/1===4 ? <BlocoComissoesAPI list={this.state.comissao} executiva={this.state.valor_executiva} direcao={this.state.valor_direcao} valor_bloco={this.state.total_comissao} comissao_edit={this.state.comissao_edit} defineComissaoEdit={(w)=>this.defineComissaoEdit(w)} blurFieldComissao={(e,w)=>this.blurFieldComissao(e,w)}/> : ''}

            {this.state.blocosAtivos[5] === 2 && this.state.monta && this.state.onlyPost!==1 ? <BlocoApiTopline api_permission={this.state.api_permission2} empresa_id={this.state.empresa_id} api_id={this.state.api_id} api_edit={this.state.api_edit} valor_api={this.state.valor_api[0]} list={this.state.api_list} valor_bloco={this.state.valor_api[0]} api_switch={(e,line_id,bd_id)=>this.api_switch(e,line_id,bd_id)} defineApiEdit={(w)=>this.defineApiEdit(w)} blurFieldApi={(e,w,id)=>this.blurFieldApi2(e,w,id)} valor_total={Math.floor((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1+((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)))/50+1)*50}/> : ''}

            {this.state.monta && this.state.valor_bloco_live>100000 && this.state.ec/1===1 && this.state.onlyPost!==1 ? <BlocoContract id={this.state.id} usuario_id={this.state.usuario_id} empresa_id={this.state.empresa_id} ce={this.state.ce} ce_diretor={this.state.ce_diretor} ce_ep={this.state.ce_ep} ce_coord={this.state.ce_coord} ce_landscape={this.state.ce_landscape}/> : ''}

            {this.state.monta && this.state.onlyPost!==1 ? 

            <div className="barra-valor">
              
              <div className="barra-gradiente"></div>
              <Grid container spacing={1} className="bgcolor_8 pd30 br5">
               
              
              {this.state.usuario_id>0 && this.state.budget_status<2 && this.state.budget_empresa_id/1===this.state.empresa_id/1 ?  <Grid item xs={this.state.budget_empresa_id/1===this.state.empresa_id/1 ? 1 : 1}>{this.state.budget_status<2 ? <Grid item xs={12}><div className={this.state.salvo===true ? "bt bgcolor_1" : "bt bgcolor_5"} onClick={()=>this.save()}>{this.state.loading ? <CircularProgress size={20} color="secondary"/> :'Salvar'}</div></Grid> : ''}</Grid> :''}
              {this.state.sheetedit===1 ?  <Grid item xs={2}><div className={this.state.salvo===true ? "bt bgcolor_1" : "bt bgcolor_5"} onClick={()=>this.save()}>{this.state.loading ? <CircularProgress size={20} color="secondary"/> :'Salvar'}</div></Grid> :''}
              
              {this.state.usuario_id>0 && this.state.budget_empresa_id/1===this.state.empresa_id/1 ?  <Grid item xs={this.state.budget_status<2 ? 1 : 2}>{this.state.budget_status<2 ? <Grid item xs={12}> <div className={this.state.salvo===true ? "bt bgcolor_2" : 'hide'} onClick={()=>this.openStartBudget()}>Aprovar</div></Grid>:<Grid item xs={12}><div onClick={()=>this.viewProjeto()} className="bt bgcolor_2">Projeto aprovado</div></Grid>}</Grid> : <Grid item xs='2'></Grid>}
              {this.state.usuario_id>0 && this.state.sheetedit!==1 ?
              <Grid item xs={3}>
              
                <Tooltip title="Resumo" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.showHide()}>{this.state.showHide===0 ? <Visibility/> : <VisibilityOff/>}</IconButton></Tooltip>
                <Tooltip title="Abrir todos os blocos" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.expand()}><Tune/></IconButton></Tooltip>
                <Tooltip title="Decupagem" placement="top" arrow TransitionComponent={Zoom}><IconButton target="_blank" href={'../decupagem/'+this.state.id}><Assignment/></IconButton></Tooltip>
                <Tooltip title="Film Card" placement="top" arrow TransitionComponent={Zoom}><IconButton target="_blank" href={'../filmcard/'+this.state.id}><FeaturedPlayList/></IconButton></Tooltip>
                <Tooltip title="Cronograma" placement="top" arrow TransitionComponent={Zoom}><IconButton target="_blank" href={'../calendar/'+this.state.id}><CalendarToday/></IconButton></Tooltip>
                <Tooltip title="Carta orçamento" placement="top" arrow TransitionComponent={Zoom}><IconButton target="_blank" href={'../office'+this.state.office+'/'+this.state.id}><InsertDriveFile/></IconButton></Tooltip>
                <Tooltip title="Deletar orçamento" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.abreAlert()}><Delete/></IconButton></Tooltip>
                
              
              </Grid>
              
            
              : <Grid item xs={1}></Grid>}

              <Grid item xs={1}><div className="mt10 lh20 color_9 l"><div>Live {Math.floor((this.state.valor_live_action/1)/Math.floor((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1+((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1))))*100)}% </div><span className="b"> {<CurrencyFormat value={Math.floor(this.state.valor_live_action/1)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</span></div></Grid>
              <Grid item xs={1}><div className="mt10 lh20 color_9 l"><div>Post {Math.floor((this.state.valor_fornecedores_post/1+this.state.valor_time_interno/1)/Math.floor((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1+((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1))))*100)}% </div><span className="b"> {<CurrencyFormat value={Math.floor(this.state.valor_fornecedores_post/1+this.state.valor_time_interno/1)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</span></div></Grid>
              {this.state.blocosAtivos[5] !== 2 ? 
              <Grid item xs={1}><div className="mt10 lh20 color_9 l"><div>Produção {Math.floor((this.state.total_producao/1+this.state.total_comissao/1)/Math.floor((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1+((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1))))*100)}% </div><span className="b"> {<CurrencyFormat value={Math.floor(this.state.total_producao/1+this.state.total_comissao/1)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</span></div></Grid>
                :''
              }
              <Grid item xs={1}><div className="mt10 lh20 color_9 l"><div>Impostos {this.state.impostos}% </div><span className="b"> {<CurrencyFormat value={Math.floor((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</span></div></Grid>
              
              {this.state.empresa_id/1!==4 ?
                <Grid item xs={1}>{this.state.seamless!==1 ? <div className="mt10 lh20 color_3"><div>Receita: <span>{(this.state.total_fee/1+this.state.valor_time_interno/1) > 0 ? Math.floor(100*(this.state.total_fee/1+this.state.valor_time_interno/1)/((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1+((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1))))) : <b>{this.state.empresa_id/1 === 4 ? 85.7: 0}</b>}% </span></div><span className="b">{<CurrencyFormat value={this.state.empresa_id/1 === 4 ? Math.floor((this.state.valor_api-(this.state.valor_api*14.35/100))) : Math.floor(this.state.total_fee/1+this.state.valor_time_interno/1)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</span></div>:''}</Grid>
              :
                <Grid item xs={1}>{this.state.seamless!==1 ? <div className="mt10 lh20 color_3"><div>Receita: <span>{<b>{this.state.empresa_id/1 === 4 ? Math.floor(this.state.total_producao/1/(this.state.total_producao+this.state.total_comissao/1)*100): 0}</b>}% </span></div><span className="b">{<CurrencyFormat value={this.state.empresa_id/1 === 4 ? Math.floor(((this.state.total_producao/1-this.state.total_comissao)/50)*50) : Math.floor(this.state.total_fee/1+this.state.valor_time_interno/1)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</span></div>:''}</Grid>
              }

              {this.state.blocosAtivos[5] === 2 ?
              <Grid item xs={1}>{this.state.seamless!==1 ? <div className="mt10 lh20 color_3"><div>Receita sem API: <span> </span></div><span className="b"><CurrencyFormat value={Math.floor(this.state.total_fee/1+this.state.valor_time_interno/1-this.state.valorBlocoTopline/1)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></span></div>:''}</Grid>
              : ''}

              {this.state.empresa_id/1!==4 ?
                <Grid item xs={2}><div className="mt10 sub-titulo color_2 tr">{<CurrencyFormat value={Math.floor((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1+((this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)/(1-this.state.impostos/100)-(this.state.total_producao/1+this.state.total_fee/1+this.state.total_comissao/1)))/50+1)*50} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div></Grid>
              :
                <Grid item xs={2}><div className="mt10 sub-titulo color_2 tr">{<CurrencyFormat value={Math.floor(((this.state.total_producao/1))/50+1)*50} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div></Grid>
              }
              </Grid>

              <div></div>
            </div> : 
            
            <div className="barra-valor">
              
              <div className="barra-gradiente"></div>
              <Grid container spacing={1} className="bgcolor_8 pd30 br5">
                {this.state.budget_status<2 && this.state.budget_empresa_id/1===this.state.empresa_id/1 && this.state.onlyPost/1!==1  ?  <Grid item xs={this.state.budget_empresa_id/1===this.state.empresa_id/1 ? 1 : 1}>{this.state.budget_status<2 ? <Grid item xs={12}><div className={this.state.salvo===true ? "bt bgcolor_1" : "bt bgcolor_5"} onClick={()=>this.save()}>{this.state.loading ? <CircularProgress size={20} color="secondary"/> :'Salvar'}</div></Grid> : ''}</Grid> :''}
                {this.state.onlyPost/1===1 ?  <Grid item xs={this.state.budget_empresa_id/1===this.state.empresa_id/1 ? 1 : 1}>{this.state.budget_status<2 ? <Grid item xs={12}><div className={this.state.salvo===true ? "bt bgcolor_1" : "bt bgcolor_5"} onClick={()=>this.save()}>{this.state.loading ? <CircularProgress size={20} color="secondary"/> :'Salvar'}</div></Grid> : ''}</Grid> :''}
              </Grid>
              </div>
              }
          </div>



          <Dialog
        open={this.state.alertOpen}
        onClose={()=>this.fechaAlerta()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
        <DialogTitle>{"Tem certeza que vai deletar?"}</DialogTitle>
        
        <DialogActions>
          <div className="bt w10 bgcolor_1" onClick={()=>this.fechaAlerta()} color="primary" autoFocus>
            Cancelar
          </div>
          <div className="bt w10 bgcolor_5" onClick={()=>this.deletaBudget()} color="secondary">
            Deletar
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.openStartBudgetModal}
        onClose={()=>this.fechaStart()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <div className="pd30">
        <DialogContent>
        
          <div className="mini-titulo mb20">Aprovar orçamento</div>
          
          <Grid container spacing={3}>
            <Grid item xs={4}>
                <CurrencyTextField
                    fullWidth={true}
                    label="Valor Vendido"
                    currencySymbol="R$"
                    outputformat="number"
                    onBlur={e => {
                      this.changeStart(e.target.value,0)
                    }}
                   
                    defaultValue={this.state.start_valor}
                    variant="outlined"
                  />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    autoComplete="off"
                    fullWidth={true}
                    label="Condição de pagamento"
                    onBlur={e => {
                      this.changeStart(e.target.value,1)
                    }}
                   
                    defaultValue=""
                    variant="outlined"
                  />
            </Grid>
            <Grid item xs={4}>
            <MyDatePicker 
                onChange={(e)=>this.changeStart(e,2)} 
                view={["year", "month","date"]}  
                format="dd/MM/yyyy" 
                label="Data de pagamento" 
                startDate={''}
              />
            </Grid>

            

            <Grid item xs={4}>
            <TextField
                    autoComplete="off"
                    fullWidth={true}
                    label="Nome para faturamento"
                    onBlur={e => {
                      this.changeStart(e.target.value,3)
                    }}
                    defaultValue={this.state.rtv ? this.state.rtv[0].nome : ''}
                    variant="outlined"
                  />
            </Grid>
            <Grid item xs={4}>

            <TextField
                    autoComplete="off"
                    fullWidth={true}
                    label="Contato para faturamento"
                    onBlur={e => {
                      this.changeStart(e.target.value,4)
                    }}
                   
                    defaultValue={this.state.rtv ? this.state.rtv[0].email : ''}
                    variant="outlined"
                  />

            </Grid>

            <Grid item xs={4}>
            <MySelect list={this.state.projetos_startados ? this.state.projetos_startados : []} initValue={0} label="O budget pertence a algum projeto?" change={(e)=>this.changeStart(e,5)}/>
            </Grid>
            
          </Grid>
          

          <div className="micro-titulo mt30 mb20">Comissões</div>
          
          <Grid container spacing={3}>
            {this.state.total_comissao>0 ? this.state.comissao['unico'].map((com,objectId)=>(
            <Grid item xs={4} key={objectId}>
              
              {
              com.split ? 
              
              <TextField
              autoComplete="off"
              fullWidth={true}
              inputProps={ 
                {readOnly: true}
              }
              label={com.cargo}
              onBlur={e => {
                this.void()
              }}
              defaultValue={'Split Automático'}
              variant="outlined"
            />

                  : 

                  <AutoComp empresa_id={this.state.empresa_id} sheet="users" tipo="comissao" label={com.cargo} blur={()=>this.void()} change={(e)=>this.changeStartComissao(e,com.id)}/>

              }
              
            </Grid>    
            )) : ''}
          </Grid>

          <Grid container spacing={3}>
          <Grid item xs={6}>
          <div className="micro-titulo mt30 mb20">Cidade do shooting</div>

          <TextField
                    autoComplete="off"
                    fullWidth={true}
                    label="Cidade do shooting"
                    onBlur={e => {
                      this.changeStart(e.target.value,7)
                    }}
                   variant="outlined"
                  />

          </Grid>
            <Grid item xs={6}>
              <div className="micro-titulo mt30 mb20">Comprovante de aprovação</div>
              <Grid container spacing={3}>

                <IconButton variant="contained" color={this.state.comfoto ? "primary" : "secondary"} onClick={()=>this.fileInput.click()}>{this.state.uploading ? 
                              <BarLoader
                                  
                              size={30}
                              color={"#33ab9f"}
                              loading={this.state.uploading}
                              />
                              : <PhotoCamera/>}</IconButton>
                <input type="file" onChange={this.fileSelect} style={{display:'none'}} ref={fileInput => this.fileInput = fileInput}></input>


              </Grid>

            </Grid>
          </Grid>

          <div className="micro-titulo mt30 mb20">Observações</div>
          
          <Grid container spacing={0}>

          <TextField
                    fullWidth={true}
                    label="Observações"
                    onBlur={e => {
                      this.changeStart(e.target.value,6)
                    }}
                    defaultValue=""
                    variant="outlined"
                  />

          </Grid>



          {this.state.loading ? '' : <div className="bt bgcolor_1 dialog w10 mt20" disableElevation onClick={()=>this.startProjeto()} color="secondary">
            Start
          </div>
          }
          <div className="bt bgcolor_5 w10 mt20" disableElevation onClick={()=>this.fechaStart()} color="primary" autoFocus>
            Cancelar
          </div>
          
          </DialogContent>
        </div>
      </Dialog>


      <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={()=>this.fechaMenu()}
            >
                    <StyledMenuItem><ListItemText primary="" /></StyledMenuItem>
                    {this.state.versoes ? this.state.versoes.map((v,objectId)=>(
                      
                      <StyledMenuItem key={objectId} onClick={()=>this.budgetInfo(v.id)}><ListItemText primary={v.nome + "| R$ " +v.valor } /></StyledMenuItem>
                      
                    )) : ''}
                                
      </Menu>
      

      </ThemeProvider>
    )
  }
}
