import { DialogTitle, Grid, IconButton, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { deepPurple, teal } from '@material-ui/core/colors'
import React, { Component } from 'react'
import Meta from '../../components/meta'
import chip from '../../img/chip.png';
import { ReactComponent as LogoLandscape } from '../../svg/logo.svg'
import './style.css';
import api from '../../services/api'
import InputMask from 'react-input-mask';
import ProgressBar from '../../components/progress';
import { MyDatePicker, MySelect } from '../../components/textField'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import axios from 'axios'
import CurrencyFormat from 'react-currency-format'
import {  Delete, InsertDriveFile, LockOpen, Payment, PhotoCamera, Receipt } from '@material-ui/icons'
import { ClipLoader } from 'react-spinners'
import { HourglassBottom } from '@mui/icons-material';

const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  overrides: {
      MuiTooltip: {
          tooltip: {
            fontSize: "11px",
            backgroundColor:'#666'
      }},
      MuiIconButton: {
        root: {
            color:'white',
          '&:hover': {
            color: 'white',
          }
        }
      }
    }

}
  
)


export default class verbas extends Component {

  state = {
    verba_id:0,
    nr:[0,0,0,0],
    permission: localStorage['permission']/1,
    usuario_id:localStorage['usuario_id'],
    link:this.props.match.params.id,
    login:false,
    verba:null,
    extras:[],
    verbaPermission:0,
    pagar:false,
    chaves:[{'value':-1,'label':'Selecione'},{'value':0,'label':'E-mail'},{'value':1,'label':'CPF'},{'value':2,'label':'Celular'},{'value':3,'label':'CNPJ'}],
    tiposVerba:[{'value':0,'label':'NOTA FISCAL'},{'value':1,'label':'CONTRATO'},{'value':2,'label':'RECIBO/OUTROS'}],
    selectVerba:0,
    chavePix:null,
    dataChavePix:null,
    mask:"99.999.999/9999-99",
    token_transfeera:null,
    id_lote:null,
    modalOK:false,
    photo:null,
    recibo_id:null,
    enviaOpen:false,
    desc:null,
    detailsDialog:false,
    detailsId:0,
    detailsDtEmissao:0,
    detailsNF:0,
    detailsCNPJ:0,
    detailsTipo:0,
    extraModal:false,
    splitModal:false,
    verbaData:null,
    verbaValor:null,
    transfeera_client_id:process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SANTA,
    transfeera_secret:process.env.REACT_APP_TRANSFEERA_SECRET_SANTA
    
  }




  // LOADING

  toLoad = () => {
    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
      showPass:false
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }



  // GET VERBA DATA

  componentDidMount(){
            
      
      api.get('get/getVerbaInfo.php?usuario_id='+this.state.usuario_id+'&md5='+this.state.link+'&r='+Math.random(10000)).then(result => {

        this.setState({
          verba_id:result.data.verba_id,
          verba:result.data.verba,
          extras:result.data.extras,
          total_extras:result.data.total_extras,
          projeto_nome:result.data.projeto,
          email_pj_verba:result.data.email_pj,
          verbaPermission:result.data.cargo
        })

        
        setTimeout(() => {
          this.getVerba()
        }, 100);
       
      })


    
            
  }




  setFocus = () => {
    if(!this.state.nr[0]===0){
      this.i1.focus()
    }

  }

  nextField = (e,w) => {
   
    let nr = this.state.nr
    nr[w] = e.target.value
    
      this.setState({
        nr:nr
      })
    
    if(w===0){
      this.i2.focus()
    }
    if(w===1){
      this.i3.focus()
    }
    if(w===2){
      this.i4.focus()
    }

  }

  login(e){

    let nr = this.state.nr
    nr[3] = e.target.value
    let pass = nr[0]+''+nr[1]+''+nr[2]+''+nr[3];

    this.i4.blur()
    this.toLoad()
    api.post('get/verbaLogin.php?r='+Math.random(10000),JSON.stringify({ 
      
      pass:pass,
      link:this.state.link

    
    })).then(result => {

      if (result.status === 200) {

          if(result.data>0){

            api.get('get/getVerbaInfo.php?id='+result.data+'&r='+Math.random(10000)).then(result => {

              
              let transfeera_client_id = process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SANTA;
              let transfeera_secret = process.env.REACT_APP_TRANSFEERA_SECRET_SANTA;
              
              if(result.data.cnpj_produtora!=='07842589000114'){

                transfeera_client_id = process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SAM
                transfeera_secret = process.env.REACT_APP_TRANSFEERA_SECRET_SAM
        
              }

              this.setState({
                login:true,
                verba:result.data.verba,
                transfeera_client_id:transfeera_client_id,
                transfeera_secret:transfeera_secret
              })

              setTimeout(() => {
                this.getVerba()
              }, 100);
             
            })

            
            
          }
          
          
          if(result.data==='no'){
            alert('Senha inválida.')
            window.location.reload()
          }
          if(result.data==='lim'){
            alert('Limite de tentativas expirado. Fale com a coordenação.')
            window.location.reload()
          }

          if(result.data==='fechado'){
            alert('O fechamento já foi enviado.')
            window.location.reload()
          }

          
      }
    }
      
      )
  }


  getVerba = () => {

    api.get('get/get.php?id='+this.state.verba[0]['id']+'&sheet=verba_cost&col_id=verba_id&empresa_id='+this.state.verba[0]['empresa_id']+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        this.setState({
          listRecibos:result.data
        })

        this.endLoad()
      }})


  }



    // ABRE ENTREGA

    enviarFechamento = () => {

      this.setState({
        enviaOpen:true
      })

      
  
    }

  // ABRE RECIBO

  abreRecibo = () => {

    this.setState({
      reciboOpen:true
    })

  }


  // FECHA RECIBO

  fechaRecibo = () => {

    this.setState({
      reciboOpen:false,
      enviaOpen:false
    })

  } 

    // FECHA RECIBO

    fechaDetails = () => {

      this.setState({
        detailsDialog:false
      })
  
    } 


  


  // UP RECIBO 

  upRecibo = (e,w) => {

    let recibo = this.state.recibo;

    recibo[w] = e.target.value;
  }

   // SET TIPO 

   setTipo = (e) => {
    this.setState({
      selectVerba:e
    })
  }


  // UPLOAD IMAGEM

  fileSelect = (event,recibo_id) => {

    this.setState({ uploading: true }); 

    const fd = new FormData();
    fd.append('image',event.target.files[0],event.target.files[0].name)
    axios.post('https://treatments.run/landscape/upload.php?id=0&sheet=no',fd).then(result => {
       
        this.setState({ 
            photo:result.data,
            uploading: false,
            comfoto:true,
            recibo_id:recibo_id

            }); 
            if(recibo_id){
            this.addRecibo()
            }
            
    })
}



  // NOVO RECIBO

  addRecibo = () => {

     api.get('update/update.php?empresa_id='+this.state.verba[0]['empresa_id']+'&value='+this.state.photo+'&id='+this.state.recibo_id+'&sheet=verba_cost&col=img&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {

          this.setState({ 
            recibo_id:null,
            photo: false,
            
            }); 


            this.getVerba();

        }})
    
  }


  // NOVO RECIBO SPLIT

  addReciboSplit = () => {

    if(this.state.photo && this.state.desc && this.state.valorPixSplit){

     let desc = this.state.desc;
     desc = desc.replace(/[^a-zA-Z0-9]/g, ' ');

     api.get('insert/newVerbaSplit.php?id='+this.state.verba_id+'&nome='+desc+'&tipo='+this.state.selectVerba+'&valor='+this.state.valorPixSplit+'&img='+this.state.photo+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {

          this.setState({ 
            valorPix:0,
            desc: '',
            splitModal:false
            }); 



            this.getVerba();

        }})
    }else{
      alert('Você precisa preencher os dois campos e subir a foto do recibo.')
    }
  }


  // DELETA SPLIT

  deleteSplit = (id) => {

    api.get('delete/delete.php?sheet=verba_cost&id='+id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        this.getVerba();


      }
    
    })

  }

  // ENTREGAR FECHAMENTO

  entregarFechamento = () => {

    this.toLoad();
   
    api.get('insert/entregarFechamento.php?verba_id='+this.state.verba[0]['id']+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
          
          window.location.reload()

      }})
      

  }


  // APROVAR FECHAMENTO

  aprovarFechamento = () => {

    this.toLoad();
    api.get('insert/aprovarFechamentoPIX.php?user_id='+this.state.usuario_id+'&verba_id='+this.state.verba_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        window.location.reload()

      }})

  }

  // OPEN DETAIL
  detail = (id,tipo,cnpj,nf,dt_emissao) =>{
    this.setState({
      detailsId:id,
      detailsDialog:true,
      detailsDtEmissao:dt_emissao,
      detailsCNPJ:cnpj,
      detailsNF:nf,
      detailsTipo:tipo
    })
  }

  // SET DETAIL
  setDetails = (e,w) => {
    if(w===0){
      this.setState({
        detailsTipo:e
      })
    }

    if(w===1){
      this.setState({
        detailsNF:e.target.value
      })
    }

    if(w===2){
      this.setState({
        detailsCNPJ:e
      })
    }

    if(w===3){
      e = this.formatDate(e);
      this.setState({
        detailsDtEmissao:e
      })
    }


  }


  // UP DETAILS

  upDetails = () => {

    api.get('update/upRecibo.php?id='+this.state.detailsId+'&dt_emissao='+this.state.detailsDtEmissao+'&nf='+this.state.detailsNF+'&cnpj='+this.state.detailsDtEmissao+'&tipo='+this.state.detailsTipo+'&r='+Math.random(10000)).then(res => {

      this.getVerba();

      this.setState({
        detailsDialog:false
      })
    })
  }

  // FORMAT DATE

    formatDate = (date) => {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    }

  // FORMAT DATE2

   formatDate2 = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
        
        ;

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
    }

  // RE ABRIR

  reAbrir = () => {

    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&sheet=verba&value=0&col=status&id='+this.state.verba_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

         
        window.location.reload()

      }})
  }



  // PAY

  
  startPay = () => {

    window.scrollTo(0,200)
    this.setState({
      pagar:true
    })

    if(!this.state.token_transfeera){
      this.tokenPix()
    }

  }


  // TOKEN PIX

tokenPix = () => {

  axios({
    method: 'post',
    url: 'https://login-api.transfeera.com/authorization', 
    params: {
            "grant_type": "client_credentials",
            "client_id": this.state.transfeera_client_id,
            "client_secret": this.state.transfeera_secret
    },
    data: {
            "grant_type": "client_credentials",
            "client_id": this.state.transfeera_client_id,
            "client_secret": this.state.transfeera_secret
    }
  }).then((response) => {
        
    
        // handle success
        
        this.setState({
          token_transfeera:response.data.access_token
        })

        
      
        
  }).catch(function (error) {
        // handle error
        
  })

  
}






// CRIAR LOTE
criarLote = () => {

  let verba = this.state.verba

  if(this.state.valorPix/1<=(verba[0]['valor']/1-verba[0]['usado']/1)){

      if(this.state.chavePix && this.state.dataChavePix && this.state.desc && this.state.valorPix){
      axios({
        method: 'post',
        url: 'https://api.transfeera.com/batch', 
        headers: {
                "Authorization": 'Bearer '+this.state.token_transfeera,
                "User-Agent": "Santa Transmedia (giovanna@sant.at)",
                "Content-Type": "application/json"
        },
        data: {
                "name": 'Adiantamento de verba - '+this.state.verba[0].projeto_id+' '+this.state.verba[0].cliente+' '+this.state.verba[0].nome,
                
        }
      }).then((response) => {
            // handle success
            
            
            this.setState({
                id_lote:response.data.id
            })

            setTimeout(() => {

              this.criarPix();
            }, 10);
          
      }).catch(function (error) {
            // handle error
            let errorAlert = [error];

            alert(errorAlert)
      })
      }else{
        alert('Você precisa preencher todos os dados!')
        
      }
      
    }else{
      alert('Valor de PIX maior do que o valor disponível.')
    }
    
  }

// CRIAR PIX

criarPix = () => {


  let pixTipoChave = this.state.chavePix;

  let pixChave = this.state.dataChavePix;

  let desc =this.state.desc;
  desc = desc.replace(/[^a-zA-Z0-9]/g, '');

  axios({
      method: 'post',
      url: 'https://api.transfeera.com/batch/'+this.state.id_lote+'/transfer', 
      headers: {
              "Authorization": 'Bearer '+this.state.token_transfeera,
              "User-Agent": "Santa Transmedia (giovanna@sant.at)",
              "Content-Type": "application/json",
              
      },
      data: {
              "value": this.state.valorPix,
              "payment_date": new Date().toISOString().split('T')[0],
              "idempotency_key": this.state.verba[0]['id']+'-'+this.state.valorPix+'-'+pixChave,
              "pix_description":'Adiantamento de verba - '+desc+' - '+this.state.verba[0].projeto_id+' '+this.state.verba[0].cliente+' '+this.state.verba[0].nome,
              "destination_bank_account": {
                  "pix_key_type": pixTipoChave,
                  "pix_key": pixChave,
                  "email": this.state.email_pj_verba
                }
                
      }
    }).then((response) => {
          // handle success
          
          this.transferir()
         
    }).catch(function (error) {
          // handle error
          
          let errorAlert = [error];
          alert(errorAlert)
    })

  
}


transferir = () => {


  axios({
      method: 'post',
      url: 'https://api.transfeera.com/batch/'+this.state.id_lote+'/close', 
      headers: {
              "Authorization": 'Bearer '+this.state.token_transfeera,
              "User-Agent": "Santa Transmedia (giovanna@sant.at)",
              "Content-Type": "application/json",
              
      }
    }).then((response) => {

          // handle success
          
          this.salvarPixBD()
          
         
    }).catch(function (error) {
          // handle error
          alert(error);
    })
  
}


// SALVAR BD

salvarPixBD = () => {
  let desc = this.state.desc
  desc = desc.replace(/[^a-zA-Z0-9]/g, '');

  api.get('insert/pagaVerbaPIX.php?id='+this.state.verba[0]['id']+'&valor='+this.state.valorPix+'&desc='+desc+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
       
      window.scrollTo(0,0)

      this.setState({
        modalOK:true
      })

      let verba = this.state.verba;
      verba[0]['usado'] = verba[0]['usado']/1+this.state.valorPix/1;
        setTimeout(() => {
          this.setState({
            pagar:null,
            modalOK:false,
            verba:verba
          })
        }, 1000);

        
       
    }})
}

  // MODAL SPLIT
  split = () =>{

    this.setState({
      splitModal:true
    })

  }


  // SPLIT NOW

  splitNow = () =>{
   
    api.get('insert/newVerbaSplit.php?id='+this.state.verba_id+'&r='+Math.random(10000)).then(result => {
          
      if (result.status === 200) {

        this.getVerba()

      }})

  }



// SETAR CHAVE
  setChave = (e) =>{

    let mask = "99.999.999/9999-99";
    let chavePix = '';

    if(e===0){
      mask = "";
      chavePix='EMAIL';
    }
    if(e===1){
      mask = "999.999.999-99";
      chavePix='CPF';
    }
    
    
    if(e===2){
      mask = "(99)99999-9999";
      chavePix='TELEFONE';
    }

    if(e===3){
      mask = "99.999.999/9999-99";
      chavePix='CNPJ';
    }
    this.setState({
      chavePix:chavePix,
      mask:mask
    })
  }

  setChavePix = (e) => {
    let dataChavePix = e.target.value
    if(this.state.chavePix!=='EMAIL'){
      dataChavePix =  dataChavePix.replace(/[^0-9]/g, "");
    }

    this.setState({
      dataChavePix:dataChavePix
    })
  }

  setValorPix = (e) => {
    
    let ok=0

    if(e.replace(',', "")/1>25000){
      alert('O limite de pagamento é de R$ 25.000.')
      ok=1
    }

    if(this.state.verba[0].valor-this.state.verba[0].usado<e.replace(',',"")/1){
      alert('O valor é mais alto que seu saldo.')
      ok=1
    }

    if(ok===0){
      this.setState({
        valorPix:e.replace(',',"")/1
      })
    }
  }


  setValorPixSplit = (e) =>{
    this.setState({
      valorPixSplit:e.replace(',',"")/1
    })
  }

  // DESC

  setDesc = (e) => {
    this.setState({
      desc:e
    })
  }

  // FECHA MODAL

  fechaModal = () => {
    this.setState({
      splitModal:false,
      extraModal:false
    })
  }

  // VERBA EXTRA

  verbaExtra = () => {
    this.setState({
      extraModal:true
    })
  }


  // UPDATE VERBA

  upVerba = (e,w) => {

    if(w==='data'){
      this.setState({verbaData:e})
    }

    if(w==='valor'){
      this.setState({verbaValor:e.target.value})
    }

  }

  // SOLICITAR VERBA

  solicitarVerba = () => {

    if(this.state.verbaValor && this.state.verbaData){
        let valor = this.state.verbaValor.replace(',','');
        let dataFormatada = (this.state.verbaData.getFullYear() + "-" + ((this.state.verbaData.getMonth() + 1)) + "-" + (this.state.verbaData.getDate() )) ; 
         
        api.get('insert/solicitaVerbaExtraPix.php?&verba_id='+this.state.verba_id+'&usuario_id='+this.state.usuario_id+'&data='+dataFormatada+'&valor='+valor+'&r='+Math.random(10000)).then(result => {
              
          if (result.status === 200) {
            
            window.location.reload()
            
            
          }})
      }else{
        alert('Você precisa preencher todos os dados.');
      }
  }

  // AUTORIZAR VERBA EXTRA

  autorizarExtra = (id) => {

    api.get('update/autorizarVerbaExtraPix.php?&verba_id='+id+'&r='+Math.random(10000)).then(result => {
              
      if (result.status === 200) {
        
        
        window.location.reload()
        
      }})
  

  }
  
  render() {
    return (
      

      <ThemeProvider theme={theme}>

      { this.state.permission>=0 ? 
      '' 
      :
        <div className='page_card_pix'>

            <div className='ls_card'>
              <div className='hold_card'>
                <div className='chip'><img src={chip}></img></div>
                <div className='logo_cartao'><LogoLandscape/></div>
              </div>
            </div>


          <div className='full-card pd30'>
            
            {!this.state.login? 
            <div>
              
              <div className='wp100 flex senhas'>
                <input ref={inputEl => (this.i1 = inputEl)} type='tel' className='senha' onChange={(e)=>this.nextField(e,0)}/>
                <input ref={inputEl => (this.i2 = inputEl)} type='tel' onFocus={()=>this.setFocus()} onChange={(e)=>this.nextField(e,1)} className='senha' value={this.state.nr2}/>
                <input ref={inputEl => (this.i3 = inputEl)} type='tel' onFocus={()=>this.setFocus()} onChange={(e)=>this.nextField(e,2)}  className='senha' value={this.state.nr3}/>
                <input ref={inputEl => (this.i4 = inputEl)} type='tel' onFocus={()=>this.setFocus()} onChange={(e)=>this.login(e)}  className='senha' value={this.state.nr4}/>
              </div>
              
              </div>
            :
            <div className='mt30 pagar'>
              <div className='mini-titulo color_7'>{this.state.verba[0]['plano']} <span className='mini-titulo color_7 mt10'>| R${this.state.verba[0]['valor']/1}</span></div>
              <div className='titulo-huge mt20'>R${this.state.verba[0]['valor']-this.state.verba[0]['usado']}
                
              </div>

              {this.state.pagar ?

               
                <div className='mt30'>
                      <a name="pagar"></a>
                      <div className=''>
                    
                      <CurrencyTextField
                          autoFocus={true}
                          fullWidth={true}
                          label="Valor"
                          currencySymbol="R$"
                          outputformat="number"
                          style={{ color: 'white' }}
                          onBlur={e => {
                            this.setValorPix(e.target.value)
                          }}
                        
                          variant="outlined"
                        />

                      </div>

                      <div className='mt20'> 
                      <TextField variant="outlined" label="Descrição" fullWidth={true} placeholder="Descrição" onBlur={(e)=>this.setDesc(e.target.value)} />
                      </div>

                      <div className='mt20'>
                      <MySelect list={this.state.chaves} initValue={-1} label="Tipo de Chave Pix" change={(e)=>this.setChave(e)}/>
                      </div>
                          
                        

                      {this.state.chavePix ? 
                      <div className='mt20'>
                          <InputMask
                            mask={this.state.mask}
                            disabled={false}
                            maskChar=" "
                            onBlur={(e)=>this.setChavePix(e)}
                            defaultValue={''}
                            >
                            {() =>   <TextField variant="outlined" autoFocus={true} label={this.state.chavePix} fullWidth={true} inputProps={this.state.id>0 ? { readOnly: true, } : { readOnly: false, }} placeholder={this.state.chavePix} />}
                        </InputMask>

                       
                        <div onClick={()=>this.criarLote()} className='bt  bgcolor_0 pd5 mt30'>Pagar Agora</div>
                      
                      </div>
                      :''}
                        
                      </div>

                      :
                      <div onClick={()=>this.startPay()} className='bt large bgcolor_0 pd5 mt30'>Pagar com PIX</div>
                    }
                  </div>
                  }

                  {this.state.loading ? <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/> : ''}
                  </div>

                 
            </div>

            }

            <div className='pd30 recibos'>
            {this.state.verba && this.state.usuario_id ? 
            <div>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div className='mini-titulo mt10'>{this.state.projeto_nome}</div>
                  <div className='sub-titulo mt10'>{this.state.verba[0].plano} R$ {this.state.verba[0].valor}</div>
                  {
                    this.state.total_extras/1>0 ? 
                    <div className='micro-titulo color_2 mt5'>R$ {this.state.total_extras} aguardando liberação</div>
                    :''
                  }
                </Grid>

                <Grid item xs={3}>
                 
                </Grid>
                <Grid item xs={3}>
                  {this.state.verba[0]['status']==='Em aberto' ? 
                    <div onClick={()=>this.verbaExtra()} className='bt large bgcolor_3 mt20'>Solicitar verba adicional</div>
                  :
                  ''
                  }
                </Grid>
              </Grid>

            </div>
                :''
              }


            {this.state.extras.length>0 ? 
              <div className='sub-titulo mt30'>Verbas solicitadas</div>
                  :''
            }

                  {this.state.extras.length>0 ? 

                      this.state.extras.map((extras,objectId)=>(
                        <Grid key={objectId} container spacing={0} className="line-budget mb1">
                          <Grid item xs={1} className="bgcolor_7"><div className="ff-ro eli">{extras.cashflow_id}</div></Grid>
                          <Grid item xs={2} className="bgcolor_8"><div className="ff-ro eli">{this.formatDate2(extras.dt)}</div></Grid>
                          <Grid item xs={6} className="bgcolor_7"><div className="ff-ro eli">Solicitado por {extras.usuario}</div></Grid>
                          <Grid item xs={2} className="tc bgcolor_8"><div className="ff-ro eli">{<CurrencyFormat value={extras.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/>}</div></Grid>
                          <Grid item xs={1} className="tc bgcolor_7">
                            {extras.status/1===0 ? 
                           <div>
                                
                                  {this.state.verbaPermission/1===4 ? 
                                  <IconButton onClick={()=>this.autorizarExtra(extras.id)}>
                                    <LockOpen className=""/>
                                  </IconButton>
                                  :
                                  <IconButton>
                                  <HourglassBottom className=""/>
                                  </IconButton>
                                  }
                                  
                                   </div>
                              :
                                <IconButton><Payment className=""/></IconButton>
                                
                            }
                          </Grid>
                        </Grid>
                      ))
                  :
                  ''}

            {this.state.verba ? 
              <div className='sub-titulo mt30'>PIX</div>
                :''
              }

          {this.state.verba ? 
              <div>
                  {this.state.listRecibos ? this.state.listRecibos.map((recibo,objectId)=>(


                    <div className='wp100'>

                    {recibo.sub/1===0 ?
                      <div className='wp100'>
                            <Grid key={objectId} container spacing={0} className="line-budget mb1">
                              <Grid item xs={9} className={recibo.sub/1===1 ? "bgcolor_8 verba-sub": "bgcolor_8"}><div className="ff-ro eli">{recibo.sub/1===1 ? '+ '+recibo.nome : recibo.nome}</div></Grid>
                              <Grid item xs={3} className="bgcolor_7 tc eli"><div className="ff-ro"><CurrencyFormat value={recibo.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                              
                              
                    
                    
                    <input type="file" onChange={(e)=>this.fileSelect(e,recibo.id)} style={{display:'none'}} ref={fileInput => this.fileInput = fileInput}></input>

                    
                    
                  </Grid>
                </div>

            :''}

                  </div>

                ))  : ''}
            </div>
            
            :''}

            {this.state.verba ? 

            <div>
                {this.state.listRecibos ?
                  <Grid container spacing={0} className="line-budget mb1">
                    <Grid item xs={10} className="bgcolor_7"><div className="ff-ro">Total de PIX</div></Grid>
                    <Grid item xs={2} className="bgcolor_7 tc b"><div className="ff-ro"><CurrencyFormat value={this.state.listRecibos.reduce((a,v) =>  v.sub/1===0 ? a = a + (v.valor/1) : a , 0 )} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                    
                  </Grid>
                
                 : ''}

                  
            </div>

            
            :''}


          {this.state.verba ? 
            <div className='sub-titulo mt30'>Recibos</div>
          
            :''
          }

          {this.state.verba ? 
          <div>
                  {this.state.listRecibos ? this.state.listRecibos.map((recibo,objectId)=>(


                    <div className='wp100'>

                    {recibo.sub/1===1 ?
                      <div className='wp100'>
                            <Grid key={objectId} container spacing={0} className="line-budget mb1">
                              <Grid item xs={5} className={"bgcolor_7"}><div className="ff-ro eli">{recibo.nome}</div></Grid>
                              <Grid item xs={1} className={"tc bgcolor_8"}><IconButton onClick={()=>this.detail(recibo.id,recibo.tipo,recibo.cnpj,recibo.nf,recibo.dt_emissao)}><Receipt className=""/></IconButton></Grid>
                              <Grid item xs={2} className="tc bgcolor_7"><div className="ff-ro eli">{this.state.tiposVerba[recibo.tipo/1]['label']}</div></Grid>
                              <Grid item xs={1} className="tc bgcolor_8">
                              <IconButton onClick={()=>this.deleteSplit(recibo.id)}><Delete className=""/></IconButton>
                              </Grid>
                    {recibo.img ? 
                    <Grid item xs={1} className="tc bgcolor_7">
                      <IconButton style={{ color: 'white' }} href={"https://treatments.run/landscape/img/upload/"+recibo.img} target="_blank" fontSize="small"><InsertDriveFile/></IconButton>
                      
                    </Grid>
                    :
                    <Grid item xs={1} className="tc bgcolor_8">
                      {recibo.split/1!==1 ?
                      
                          <IconButton  style={{ color: 'white' }} onClick={()=>this.fileInput.click()}>{this.state.uploading ? 
                            <ClipLoader
                              size={20}
                              color={"#ffffff"}
                              loading={this.state.uploading}
                            />

                      : <PhotoCamera className=""/>
                      
                      }</IconButton>
                      :''

                    }
                      
                      
                      </Grid>
                    }
                    <input type="file" onChange={(e)=>this.fileSelect(e,recibo.id)} style={{display:'none'}} ref={fileInput => this.fileInput = fileInput}></input>

                              
                              <Grid item xs={2} className="bgcolor_6 tc eli"><div className="ff-ro"><CurrencyFormat value={recibo.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                            
                    </Grid>
                  </div>

                  :''}

                  </div>

                ))  : ''}
            </div>
            
            :''}
            

            {this.state.verba ? 

            <div>
                {this.state.listRecibos ?
                  <Grid container spacing={0} className="line-budget mb1">
                    <Grid item xs={10} className="bgcolor_7"><div className="ff-ro">Total de recibos</div></Grid>
                    <Grid item xs={2} className="bgcolor_7 tc b"><div className="ff-ro"><CurrencyFormat value={this.state.listRecibos.reduce((a,v) =>  v.sub/1===1 ? a = a + (v.valor/1) : a , 0 )} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                  </Grid>
                
                : ''}

                  
            </div>


            :''}


          { 
          this.state.verba ?
          <div>
            {
            this.state.verba[0]['status']==='Em aberto' ? 
            <Grid item xs={12}><div className="bt large bgcolor_3 mt10" onClick={()=>this.split()}>Adicionar Recibo</div></Grid>
            :''
            }
          </div>
          :''
          }

            {this.state.verba ? <Grid item xs={12}>{this.state.verba[0]['status']==='Em aberto' ? '': <div>{this.state.permission/1===0 ? <div className="bt large bgcolor_2 mt20" onClick={()=>this.reAbrir()}>Re-abrir</div> : ''}</div>}</Grid> : ''}

            {this.state.verba ? 
            <Grid container spacing={0} className="line-budget mt30">
                        <Grid item xs={9}></Grid>
                        {this.state.verba ? <Grid item xs={12}>{this.state.verba[0]['status']==='Em aberto' ? <div className="bt large bgcolor_1" onClick={()=>this.enviarFechamento()}>Enviar fechamento</div>: ''}</Grid>:''}
                        {this.state.verba ? <Grid item xs={12}>{this.state.verba[0]['status']==='Entregue' && this.state.permission<2 ? <div className="bt large bgcolor_1" onClick={()=>this.aprovarFechamento()}>Aprovar fechamento</div> : ''}</Grid>:''}
                        
                        
                 </Grid>
                 
            :''}


            </div>

            
            <Dialog
                open={this.state.splitModal}
                onClose={()=>this.fechaModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
              >
                <DialogTitle>Novo Recibo</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                <Grid container spacing={1}>
                  
                <Grid item xs={2}>

                    <CurrencyTextField
                            autoFocus={true}
                            fullWidth={true}
                            label="Valor"
                            currencySymbol="R$"
                            outputformat="number"
                            style={{ color: 'white' }}
                            onBlur={e => {
                              this.setValorPixSplit(e.target.value)
                            }}
                          
                            variant="outlined"
                          />

                  </Grid>
                  <Grid item xs={2}>
                    <MySelect list={this.state.tiposVerba} initValue={0} label="Tipo de Verba" change={(e)=>this.setTipo(e)}/>
                  </Grid>

                  <Grid item xs={4}>

                  <TextField variant="outlined" label="Descrição" fullWidth={true}  placeholder="Descrição" onBlur={(e)=>this.setDesc(e.target.value)}/>
                
                  </Grid>

                 

                  <Grid item xs={2}>
                  <IconButton variant="contained" onClick={()=>this.fileInput.click()}>{this.state.uploading ? 
                          <ClipLoader
                          size={20}
                          color={"#ffffff"}
                          loading={this.state.uploading}
                          />

                          : <PhotoCamera className="mt5"/>}</IconButton>
            <input type="file" onChange={this.fileSelect} style={{display:'none'}} ref={fileInput => this.fileInput = fileInput}></input>
                  </Grid>
                  <Grid item xs={2}><div className="bt large bgcolor_1" onClick={()=>this.addReciboSplit()}>+</div></Grid>


                  <div className="mt20 wp100 mb30"></div>

                </Grid>

                </div>                    
                </DialogContent>
                
              </Dialog>


            <Dialog
                open={this.state.modalOK}
                fullWidth={true}
                maxWidth={'lg'}
              
              >
                
                <DialogContent>

                <div className="mini-titulo w100 tc mt50 mb50">Pagamento efetuado!</div>
                
                
                </DialogContent>
               
            </Dialog>


            <Dialog
                open={this.state.enviaOpen}
                onClose={()=>this.fechaRecibo()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>Entregar fechamento</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                      Todos seus recibos foram adicionados?
                      <div className="mt30"></div>
                      <Grid container spacing={1}>
                        
                  
                        <Grid item xs={6}><div className="bt  bgcolor_5" onClick={()=>this.fechaRecibo()}>Cancelar</div></Grid>
                       
                        <Grid item xs={6}><div className="bt  bgcolor_1" onClick={()=>this.entregarFechamento()}>Entregar</div></Grid>


                            
                        
                      </Grid>

                </div>                    
                </DialogContent>
                
              </Dialog>
       

              <Dialog
                open={this.state.detailsDialog}
                onClose={()=>this.fechaDetails()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>Dados do recibo</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                      Todos seus recibos foram adicionados?
                      <div className="mt30"></div>
                      <Grid container spacing={1}>
                  
                  
                  <Grid item xs={3}>
                    <MySelect list={this.state.tiposVerba} initValue={this.state.detailsTipo} label="Tipo de Verba" change={(e)=>this.setDetails(e,0)}/>
                  </Grid>

                  <Grid item xs={3}>

                  <TextField variant="outlined" label="Número NF" fullWidth={true}  placeholder="Número NF" defaultValue={this.state.detailsNF}  onBlur={(e)=>this.setDetails(e,1)}/>
                
                  </Grid>

                  <Grid item xs={3}>
                    <TextField variant="outlined" label="CNPJ/CPF" fullWidth={true}  placeholder="CNPJ/CPF" defaultValue={this.state.detailsCNPJ} onBlur={(e)=>this.setDetails(e.target.value,2)}/>
                  </Grid>

                  <Grid item xs={3}>
                  <MyDatePicker onChange={(e)=>this.setDetails(e,3)} view={["year", "month","date"]} format="dd/MM/yyyy" label="Data combinada" startDate={this.state.detailsDtEmissao}/>
                  </Grid>
                  </Grid>
                      <Grid container spacing={1}>
                        
                  
                        <Grid item xs={6}><div className="bt mt5  bgcolor_5" onClick={()=>this.fechaDetails()}>Cancelar</div></Grid>
                       
                        <Grid item xs={6}><div className="bt mt5 bgcolor_1" onClick={()=>this.upDetails()}>Atualizar</div></Grid>


                            
                        
                      </Grid>

                </div>                    
                </DialogContent>
                
              </Dialog>

              <Dialog
                open={this.state.extraModal}
                onClose={()=>this.fechaModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>Solicitar verba extra</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                <Grid container spacing={1}>
                  
                  <Grid item xs={5}>

                  <CurrencyTextField variant="outlined" label="Valor" fullWidth={true}  placeholder="Valor" onBlur={(e)=>this.upVerba(e,'valor')} currencySymbol="R$" outputformat="number"/>
                
                  </Grid>

                  <Grid item xs={5}>

                    <MyDatePicker onChange={(e)=>this.upVerba(e,'data')} view={["year", "month","date"]} format="dd/MM/yyyy"  label="Data combinada" startDate={this.state.dataAberta}/>
                  
                  </Grid>


                  <Grid item xs={2}>
                  <div className="bt large bgcolor_1" onClick={()=>this.solicitarVerba()} color="secondary">
                    Solicitar
                  </div>
                  
                  </Grid>


                </Grid>

                </div>                    
                </DialogContent>
                
              </Dialog>
       

       
        <Meta title="Verbas"/>

    </ThemeProvider>
    )
  }
}