import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";


function PrivateRoute({ component: Component, ...rest }) {
  const { authTokens } = useAuth();
  let where = window.location.href.split('view');

  let verbas = window.location.href.split('verbas');

  let cache = window.location.href.split('cacheteste');

  let page = 0;

  if(cache.length===2){
    page = 'cacheteste'
  }

  if(where.length===2){
    page = 'viewsheet'
  }

  if(verbas.length===2){
    page = 'verbas'
  }
  
  
  return (
   
    <Route
    
      {...rest}
      render={props =>
        
        authTokens ? (
          
          <Component {...props} />
        ) : (
          
          <Redirect
            to={props.match.params.id ? { pathname: '/'+page+'/'+props.match.params.id, state: { referer: props.location.pathname } } : { pathname: '/login/', state: { referer: props.location.pathname } }}
          />

        )
      }
    />
  );
}

export default PrivateRoute;