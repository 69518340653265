import React, { Component } from 'react';
import styled from 'styled-components';
import Sticky from 'react-sticky-el';

const ProgressTrack = styled.div`
    
    width: 100%;
    height: 3px;
    background: rgba(0,0,0,0);
    z-index: 150
`;


const ProgressLoad = styled.div`
    width: ${props => props.perc}%;
    height: 3px;
    position: fixed;
    top: 0px;
    background: #A456FF;
    opacity:${props => props.op};
    transition-duration: 0.1s, 1s;
    transition-property: width, opacity;
    z-index: 150
`;


export default class ProgressBar extends Component{
    
  
    render(){
        return(
            <Sticky>
            <div className="progress-top">
                <ProgressTrack>
                    <ProgressLoad op={this.props.op} perc={this.props.perc}/>
                </ProgressTrack>

            </div>
            </Sticky>
             )    
    
        }

    }
    