
import React, { Component } from 'react'
export default class index extends Component {

componentDidMount(){
        window.location.href='https://landscape.productions';
}
  render() {
    return (
        <div></div>
        
    )
  }
}
