import React, { useState } from 'react'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CalendarToday from '@material-ui/icons/CalendarToday';
import ReorderIcon from '@material-ui/icons/Reorder';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import { createTheme,MuiThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import Assignment from '@material-ui/icons/Assignment';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import CurrencyFormat from 'react-currency-format';


const colors = ["#DF7FFF","#7FDCA4","#A3D7C9","#F9D48E","#F9D48E"]
function Card(props) {

    const theme = createTheme (
        {
          palette: {
            type: "dark",
            primary: deepPurple,
            secondary: teal,
          
        },
        overrides: {
            MuiTooltip: {
                tooltip: {
                  fontSize: "11px",
                  backgroundColor:'#666'
            }},
            MuiIconButton: {
              root: {
                  color:'#ddd',
                '&:hover': {
                  color: colors[props.colorId/1-1]
                }
              }
            }
          }
      
      }
        
      )
    

    const [target,setTarget] = useState("");
    const dragStart = e => {
        
        const te = e.target;
       
        setTarget(te);
        
        e.dataTransfer.setData('card_id',te.id);

        
        setTimeout(() => {
            te.style.display = "none";
        }, 0);

        
    }

    const dragOver = e => {
        
        e.stopPropagation();
    }

    const dragEnd = e => {
        target.style.display = "none";
        
    }
    
    function fromToday(w) {
       
        var msDiff = new Date().getTime() - new Date(w).getTime();
        return Math.floor(msDiff / (1000 * 60 * 60 * 24));


    }

    return (
        <MuiThemeProvider theme={theme}>
        <div
        id={props.id}
        draggable={props.draggable}
        onDragStart={dragStart}
        onDragOver={dragOver}
        onDragEnd={dragEnd}
        >
            
        <div id={props.id+'-dr'} className="card br5">
               
               <div className="nome-budget l pd15">
                        {props.nome}
                </div>
                <IconButton className="r" onClick={props.open}><ExpandMoreIcon/></IconButton></div>
                        
         
            

            {props.list.map(lista => (
                <div key={lista.objectID} className="card-version br5">
                    
                    <Grid container spacing={0}>
                    
                        <Grid item xs={12}><div className="nome-versao">{lista.nome}</div></Grid>
                        
                           
                            <Grid item xs={9} className="menu-budget">
                                <Grid container spacing={2} justifyContent="flex-end">
                                    <Grid item xs={2} ><Tooltip title="Planilha" placement="top" arrow TransitionComponent={Zoom}><IconButton href={"/sheet/"+lista.id} target="_blank"><ReorderIcon/></IconButton></Tooltip></Grid>
                                    <Grid item xs={2}><Tooltip title="Decupagem" placement="top" arrow TransitionComponent={Zoom}><IconButton href={"/decupagem/"+lista.id}><Assignment/></IconButton></Tooltip></Grid>
                                    <Grid item xs={2}><Tooltip title="Cronograma" placement="top" arrow TransitionComponent={Zoom}><IconButton href={"/calendar/"+lista.id}><CalendarToday/></IconButton></Tooltip></Grid>
                                    <Grid item xs={2}><Tooltip title="Carta orçamento" placement="top" arrow TransitionComponent={Zoom}><IconButton href={"/office"+props.office+"/"+lista.id} target="_blank"><InsertDriveFile/></IconButton></Tooltip></Grid>
                                    <Grid item xs={2}><Tooltip title="Duplicar" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={(w)=>props.duplicate(lista.id)}><LibraryAdd/></IconButton></Tooltip></Grid>
                                </Grid>
                            </Grid>
                          
                        
                        <div className="valor-budget"><CurrencyFormat value={lista.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div>
                    </Grid>
                   
                    
                    
                    </div>
            ))}
            {props.list.length && props.status/1===2 ?  <div onClick={(w)=>props.novaVersao(props.id)} className={"bt large mb1 bgcolor_"+props.colorId}>Nova versão</div> : ''}
            {props.list.length ? <div className={"barra bgcolor_"+props.colorId}>{fromToday(props.open_date) +' dias por '+props.atendimento}{props.colorId/1===4?<div onClick={()=>props.caiu(props.id)} className="nao_aprovado r">Não foi aprovado</div>:''}</div> : ''}
        </div>

        </MuiThemeProvider>
    )
    
}

export default Card
