import React,{Component} from 'react'
import Grid from '@material-ui/core/Grid';
import CurrencyFormat from 'react-currency-format';
import api from '../../services/api';
import './style.css';

export default class Unidades extends Component {

  constructor(props){
    super();

    this.state = { 
      loading:true,
      loadPerc:0,
      mes: props.mes,
      unidade_id: props.unidade_id,
      empresa_id: props.empresa_id,
      budgets:[],
      valorTotal:0,

    }

  }

  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }

  async componentDidMount(){

    this.loadBudgets();
    
  }

   // LISTA DE PROJETO APROVADOS

   loadBudgets = async () => {
    this.toLoad();
    
    let mesNr = (this.state.mes.getMonth()/1 + 1);
    if(mesNr<10){
      mesNr='0'+mesNr;
    }
    const mes = this.state.mes.getFullYear()+'-'+mesNr;
    
    api.get('get/projetos.php?status=2&mes='+mes+'&empresa_id='+this.state.empresa_id+'&unidade_id='+this.state.unidade_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          if(result.data.length>0){

          
              this.setState({
                budgets:result.data,
                valorTotal: result.data.reduce((a,v) =>  a = a + v.valor/1 , 0 )
              })

              
              this.endLoad();
        
            
            

        }else{
          this.setState({
            budgets:[],
            valorTotal: 0
          })
          this.endLoad();
        }
          
          
         

      }})
    

  }

  openProjeto = (w) => {
    window.open('/view/'+w)
  }

    render(props){
    return (
        
        <div>
      <div className="mt20 mb30">
      <Grid container spacing={3}>
      
          <Grid item xs={9}><div className="mini-titulo">{this.props.nome}</div></Grid>
            
            <Grid item xs={3}>
            
            <div className="mini-titulo tr color_2"><CurrencyFormat value={this.state.valorTotal} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
            </Grid>

      </Grid>

      </div>

      {this.state.budgets.map(budget=>(

        
        <div className="line bgcolor_6 br5 pd15" onClick={(w)=>this.openProjeto(budget.projeto_id)}>
          <Grid container spacing={0} direction="row" justifyContent="center">
                
                <Grid item xs={1} className="color_2">{budget.id}</Grid>
                <Grid item xs={3}>{budget.cliente+' | '+budget.nome}</Grid>
                <Grid item xs={3}>{budget.atendimento}</Grid>
                <Grid item xs={4}>{budget.diretor}</Grid>
                <Grid item xs={1} className="color_2 tr"><CurrencyFormat value={budget.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></Grid>
                
                

          </Grid>
          
        </div>
       
        
      ))}


      <hr/>
      </div>

       
    )
    
}
}