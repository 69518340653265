import { Grid, ThemeProvider, createTheme } from '@material-ui/core'
import React, { Component } from 'react'
import TextField from '@mui/material/TextField';
import {deepPurple,teal} from '@material-ui/core/colors';
import CurrencyFormat from 'react-currency-format';
import update from 'react-addons-update';

const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },
   
    })
    


  

export default class Bloco extends Component {
    
    constructor(props){
        
        super()
        
        this.state = {
            lines:props.fee[0],
            ativos:props.ativos,
            fee:props.fee[1],
            fee_edit:-1,
            

        }

       
    }

    blurFieldFee = (e,w) => {

        this.setState(update(this.state, {
            fee: {
                [w]:{
                        $set: e.target.value
                  }
                
              }
            }));

            
        this.props.blurFieldFee(e,w)
    }


    render(props) {
        
       
        return (

                <ThemeProvider theme={theme}>
                    <div className="mt10 mb5">
                        <Grid container spacing={0} className="bgcolor_7 pd30 br5">
                        <Grid item xs={11} className="mini-titulo"><div className="mt10">Fee</div></Grid>
                        
                        </Grid>
                    </div>

                    
                    {this.state.lines.map((lines,objectId2)=>(
                        <div className={this.state.ativos[objectId2] === 0 ? "block" : 'none'}>
                        <Grid key={objectId2} container spacing={0} className="bgcolor_7 br5 mb1">
                            
                            <Grid item xs={10}><div className={"ff-ro bgcolor_"+(objectId2+1)}>{lines}</div></Grid>
                            <Grid item xs={1}>{this.props.fee_edit === objectId2 ? <TextField autoFocus={true} variant="outlined" fullWidth={true} placeholder={this.state.fee[objectId2]} onBlur={(e)=>this.blurFieldFee(e,objectId2)} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#444'}}}/> : <div onClick={(e)=>this.props.defineFeeEdit(objectId2)} className="ff-per bgcolor_6">{this.state.fee[objectId2]>0 ? this.state.fee[objectId2]+'%' : '0%'}</div>}</Grid>
                            <Grid item xs={1}><div className="ff-ro b"><CurrencyFormat value={this.props.valores_blocos[objectId2]*(this.state.fee[objectId2]/1)/100} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></div></Grid>
                            
                        </Grid>
                        </div>


                    ))}

                        
                        <Grid container spacing={0} className="bgcolor_7 br5 mb1">
                            
                            <Grid item xs={10}><div className={"ff-ro bgcolor_0"}>Fee global</div></Grid>
                            <Grid item xs={1}>{this.props.fee_edit === 5 ? <TextField autoFocus={true} variant="outlined" fullWidth={true} placeholder={this.state.fee[5]} onBlur={(e)=>this.blurFieldFee(e,5)} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#444'}}}/> : <div onClick={(e)=>this.props.defineFeeEdit(5)} className="ff-per bgcolor_6">{this.state.fee[5]+'%'}</div>}</Grid>
                            <Grid item xs={1}><div className="ff-ro b"><CurrencyFormat value={(this.props.total_producao+this.props.total_api)*(this.state.fee[5]/1)/100} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></div></Grid>
                            
                        </Grid>           
                        <Grid container spacing={0} className="bgcolor_7 br5 mb1">
                            
                            <Grid item xs={10}><div className={"ff-ro bgcolor_9"}>Mesa de compras</div></Grid>
                            <Grid item xs={1}>{this.props.fee_edit === 6 ? <TextField autoFocus={true} variant="outlined" fullWidth={true} placeholder={this.state.fee[6]} onBlur={(e)=>this.blurFieldFee(e,6)} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#444'}}}/> : <div onClick={(e)=>this.props.defineFeeEdit(6)} className="ff-per bgcolor_6">{this.state.fee[6]+'%'}</div>}</Grid>
                            <Grid item xs={1}><div className="ff-ro b"><CurrencyFormat value={(this.props.total_producao+this.props.total_api)*(this.state.fee[6]/1)/100} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></div></Grid>
                            
                        </Grid>  
                    
                    

                    <div className="mb5">
                        <Grid container spacing={0} className="bgcolor_7 mt5 br5">
                        <Grid item xs={11}><div className="mt5 pd15"><b>Sub-total</b></div></Grid>
                        <Grid item xs={1}><div className="pd20 r b"><CurrencyFormat value={this.props.total_fee} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                        </Grid>
                    </div>
                    

                
                </ThemeProvider>
            
        )
    }
}
