import React,{Component} from 'react'
import MyResponsivePie from './pie';
import Grid from '@material-ui/core/Grid';
import api from '../../services/api';
import './style.css';

export default class Pies extends Component {

    constructor(props){
        super();
        this.state = {

            empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
            loading:true,
            loadPerc:0,
            view:props.view,
            mes:props.mes,
            faturamento:0,
            projetos:0,
            pedidos_atendimento:0,
            pedidos_diretor:0,
            faturamento_diretor:0,
            receita_diretor:0,

        }
    }
   

    // LOADING

    toLoad = () => {
        this.setState({
        loadPerc: 2,
        loading:true,
        intervalLoad: setInterval(this.Loader, 200)
        })
    }
    

    endLoad = () => {  
        this.setState({
        loadPerc: 100,
        loading:false,
        intervalLoad:false,
        })
    }

    Loader = () =>{
        if(this.state.loadPerc<100){
            this.setState({
                loadPerc:this.state.loadPerc*1.4,
            })
        }
    }
    

    async componentDidMount(){
        
        this.loadPies();
    
    }

    // PIES

    loadPies = async () => {

    this.toLoad()
        let mes=0;
    if(this.state.view === '0'){

        let mesN = this.state.mes.getMonth()/1 + 1;
        if(mesN<10){
            mesN='0'+mesN;
        }
         mes = this.state.mes.getFullYear()+'-'+(mesN);
    }else{
         mes = this.state.mes.getFullYear();
    }
     
    
    
    api.get('get/pie.php?empresa_id='+this.state.empresa_id+'&view='+this.state.view+'&mes='+mes+'&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {


            this.setState({
                faturamento:result.data.Faturamento,
                projetos:result.data.Projetos,
                pedidos_atendimento:result.data.Pedidos_Atendimento,
                pedidos_diretor:result.data.Pedidos_Diretor,
                faturamento_diretor:result.data.Faturamento_Diretor,
                receita_diretor:result.data.Receita_Diretor,
            
            })
            this.endLoad()

        }})


    }

    render(){
    return (
        
        <div className="wp100 mt10">
            
        <Grid container spacing={0}>

           
        
        
          <Grid item xs>
          <div className="pie mt30">
          {this.state.faturamento !== 0 ? <MyResponsivePie money={true} data={this.state.faturamento} color="rgb(164,86,255,"/> : ""}
              </div>
              <div className="legenda-pie">Faturamento</div>
              
          </Grid>

          
          <Grid item xs>
          <div className="pie mt30">
          {this.state.projetos !== 0 ? <MyResponsivePie data={this.state.projetos} color="rgb(125,220,164,"/> : ""}
              </div>
              <div className="legenda-pie">Projetps</div>
              
          </Grid>

          <Grid item xs>
          <div className="pie mt30">
          {this.state.pedidos_atendimento !== 0 ? <MyResponsivePie data={this.state.pedidos_atendimento} color="rgb(163,215,201,"/> : ""}
              </div>
              <div className="legenda-pie">Pedidos</div>
              
          </Grid>

          <Grid item xs>
          <div className="pie mt30">
          {this.state.pedidos_diretor !== 0 ? <MyResponsivePie data={this.state.pedidos_diretor} color="rgb(249,212,142,"/> : ""}
              </div>
              <div className="legenda-pie duplo">Pedidos<br/>diretor</div>
              
          </Grid>

          <Grid item xs>
          <div className="pie mt30">
          {this.state.faturamento_diretor !== 0 ? <MyResponsivePie money={true} data={this.state.faturamento_diretor} color="rgb(249,165,142,"/> : ""}
              </div>
              <div className="legenda-pie duplo">Faturamento<br/>diretor</div>
              
          </Grid>
          
          
        </Grid>
        
        </div>
       
    )
    
}
}

