import { Grid, ThemeProvider, createTheme, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip, Zoom, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import { AutoComp } from '../../components/textField';
import CurrencyFormat from 'react-currency-format';
import Line from './Line';
import LineLiquor from './LineLiquor';
import { ReactComponent as Liquor } from '../../../src/svg/liquor.svg'
import { AddCircle, AssignmentTurnedIn, Ballot, Delete } from '@material-ui/icons';

const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
              fontSize: "11px",
              backgroundColor:'#666'
        }},
   
    }
})
    

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.palette.common.white,
        },
        },
    },
    }))(MenuItem);
  

export default class Bloco extends Component {
    
    constructor(props){
        super()
        this.state = {
            empresa_id: localStorage['empresa_id'],
            blocos: ['Live Action','Time Interno','Fornecedores','Copias','Ancine','Api Itens'],
            itens: ['comentario','o_qt','o_diaria','o_valor','nome'],
            editLine:0,
            deleteId:0,
            salvarPresetSub:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
            subpreset:[]
           
            
           
        }
    }


    componentDidMount(){
        console.log(this.props.list)
    }
   // SETA FOCO DO EDITAVEL
    
    focus = async(w) =>{
    
        if(w===0){
            setTimeout(() => {
                this.setState({editLine:w})
            }, 100);
            
        }else{
            this.setState({editLine:w})
        }

    }

    // UPDATE VALOR DO BLOCO

    upBlocoValor = (w,e,index,sub_nucleo) => {
        
        this.props.upControle(this.state.blocos[this.props.id],sub_nucleo,index,this.state.itens[w],e)
       
    }

    // UPDATE DO FORNECEDOR

    changeFornecedor = (keyid,e,nomeFornecedor,custo,sub_nucleo) => {
        
        this.props.changeFornecedor(keyid,e,nomeFornecedor,custo,this.state.blocos[this.props.id],sub_nucleo);

    }


    // ABRE MENU PRESET

    abreMenu = (event) => {


        this.setState({
            anchorEl: event.currentTarget
        })
        
        
      };

    // ABRE MENU PRESET SUB

    abreMenuSub = (event,w) => {

        

        this.setState({
            anchorElSub: event.currentTarget,
            subpreset:this.props.subPresets[w+1],
            presetNucleo:w+1
           
        })
        
        this.props.upBlocoView(w)
        
      };


      
    
      // FECHA MENU PRESET

    fechaMenu = (id) => {
        if(id>0){
            this.props.importBudget(id,this.props.id,1)
        }
        this.setState({
            anchorEl: null,
            anchorElSub: null,
            deleteId:id,
        })
      };


      // FECHA MENU PRESET

    fechaMenuSub = (id,p) => {
       
        if(id>0){
            this.props.importBudget(id,this.props.id,1,p)
        }
        this.setState({
            anchorEl: null,
            anchorElSub: null,
            deleteId:id,
        })
      };

    // EDITA NOME PRESET

    nomePreset = () => {
        
        this.setState({
            salvarPreset: true,
            
        })
    }


    // EDITA NOME PRESET

    nomePresetSub = (w) => {
        
        let salvarPresetSub = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
        salvarPresetSub[w]=1
        this.setState({
            salvarPresetSub: salvarPresetSub,
            
        })
    }
    // DEFINE NOME DO PRESET

    setNomeVersao = (e) => {
       
       this.setState({
        salvarPreset: false,
        anchorEl: null
    })
    }

    // DEFINE NOME DO PRESET NO ENTER
    keyPress = (e) => {
        
      if(e.keyCode === 13){
        this.props.savePreset(e.target.value,this.props.id)

        this.setState({
            salvarPreset: false,
            anchorEl: null
        })
      }
   
    }

    // DEFINE NOME DO PRESET NO ENTER
    keyPressSub = (e,w) => {
        
        if(e.keyCode === 13){
    
          this.props.savePresetSub(e.target.value,this.props.id,(w+1))
  
          this.setState({
              salvarPresetSub: false,
              anchorEl: null
          })
        }
     
      }


    // DELETA PRESET

    deletaPreset = (id,w,e) => {
        this.props.deletaPreset(id,w,e);
        this.setState({
            deleteId:0
        })
    }


    // OPEN LIQUOR PLANILHA

    openWindow = (w) => {
        window.open(w)
    }
    // VOID

    void = () => {

    }
    
    



   

    
    
    render(props) {
        
       
        return (

                <ThemeProvider theme={theme}>
                    <div className="mt10 mb5">
                        <Grid container spacing={0} className={"bgcolor_"+(this.props.colorId)+" pd30 br5"}>
                        <Grid item xs={6} className="mini-titulo"><div className="mt20">{this.state.blocos[this.props.id]}</div></Grid>
                        <Grid item xs={3} className="pd5 tr">
                            
                            <Tooltip title="Listar presets" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={(e)=>this.abreMenu(e)}><AssignmentTurnedIn/></IconButton></Tooltip>
                            <Tooltip title="Salvar como preset" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.nomePreset()}><AddCircle/></IconButton></Tooltip>
                            {this.state.deleteId>0 ? <Tooltip title="Deletar o preset" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={(id,bloco)=>this.props.deletaPreset(this.state.deleteId,this.props.id)}><Delete/></IconButton></Tooltip> :''}
                            {this.state.salvarPreset ? <TextField autoFocus={true} variant="outlined" onBlur={(e)=>this.setNomeVersao(e)} onKeyDown={(e)=>this.keyPress(e)} placeholder="Nome do preset" InputProps={{style: { height:0,padding: '22px 0px',backgroundColor:'#444'}}}/>  :  ''}
                        </Grid>
                        <Grid item xs={3}><AutoComp empresa_id={this.state.empresa_id} sheet="projeto" tipo="projeto_aprovado" label="Importar" blur={()=>this.void()} change={(e)=>this.props.importBudget(e,this.props.id,0)}/></Grid>
                        
                        
                        </Grid>
                    </div>
                    
                    {this.props.sub_nucleos.map((sub,objectId)=>(
                        <div key={objectId+'a'}>
                            <div key={objectId+'b'} className={this.props.display ? 'block mb5' : 'none'}>
                                <Grid key={objectId+'c'} onClick={this.state.editLine===0 ? (v)=>this.props.upBlocoView(objectId) : ()=>this.focus(0)} container spacing={0} className={"bgcolor_6 mt5 br5 line-bloco"}>
                                
                                    
                                
                                <Grid item xs={2} className=""><div className="pd20 b">{this.props.sub_nucleos_display ? this.props.sub_nucleos_display[objectId] : ''}</div></Grid>
                                
                                <Grid item xs={9}>


                                            <Tooltip title="Listar presets" placement="top" arrow TransitionComponent={Zoom}><IconButton style={{ color: 'gray' }} onClick={(e)=>this.abreMenuSub(e,objectId)}><AssignmentTurnedIn className="mt5" /></IconButton></Tooltip>
                                            <Tooltip title="Salvar como preset" placement="top" arrow TransitionComponent={Zoom}><IconButton style={{ color: 'gray' }} onClick={()=>this.nomePresetSub(objectId)}><AddCircle className="mt5" /></IconButton></Tooltip>
                                            {this.state.deleteId>0 ? <Tooltip title="Deletar o preset" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={(id,bloco)=>this.deletaPreset(this.state.deleteId,this.props.id)}><Delete/></IconButton></Tooltip> :''}
                                            {this.state.salvarPresetSub[objectId]===1 ? <TextField autoFocus={true} variant="outlined" onBlur={(e)=>this.setNomeVersao(e)} onKeyDown={(e)=>this.keyPressSub(e,objectId)} placeholder="Nome do preset" InputProps={{style: { height:0,padding: '22px 0px',backgroundColor:'#444'}}}/>  :  ''}
                                 

                                                </Grid>
                                
                                <Grid item xs={1}><div className="pd20 r"><CurrencyFormat value={this.props.sub_nucleos_valor[objectId]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                                </Grid>
                                </div>
                           
                                
                                        {this.props.sub_nucleos_ativos[objectId] === 1 ? '' : this.props.list[sub].map((lines,objectId2)=>(
                                            <div key={objectId2}>
                                                    {(lines.o_valor/1+this.props.showHide/1)===1 ? '' : 
                                                    <div>
                                                    {lines.nome==='Liquor Auto' ?
                                                        <LineLiquor liquor_checked={this.props.liquor_checked} liquor_switch={(e,id) => this.props.liquor_switch(e,id)} key={objectId2} keyid={objectId2} bloco={this.state.blocos[this.props.id]} id={lines.id} nome={lines.nome} comentario={lines.comentario} o_qt={lines.o_qt} o_diaria={lines.o_diaria} sub_nucleos={objectId} o_valor={lines.o_valor} plano_conta_id={lines.plano_conta_id} fornecedor={lines.fornecedor} editLine={this.state.editLine} upBlocoValor={(a,b,c,d) => this.upBlocoValor(a,b,c,d)} focus={(w)=>this.focus(w)} changeFornecedor={(a,b,c,d,e) => this.changeFornecedor(a,b,c,d,e)}/>
                                                    :
                                                        <Line key={objectId2} keyid={objectId2} bloco={this.state.blocos[this.props.id]} id={lines.id} nome={lines.nome} comentario={lines.comentario} o_qt={lines.o_qt} o_diaria={lines.o_diaria} sub_nucleos={objectId} o_valor={lines.o_valor} plano_conta_id={lines.plano_conta_id} fornecedor={lines.fornecedor} editLine={this.state.editLine} upBlocoValor={(a,b,c,d) => this.upBlocoValor(a,b,c,d)} focus={(w)=>this.focus(w)} changeFornecedor={(a,b,c,d,e) => this.changeFornecedor(a,b,c,d,e)}/>
                                                    }
                                                    </div>
                                                    
                                                    }
                                            </div>
                                                ))
                                                }

                                        {this.props.addLine===1 ? <div onClick={()=>this.props.addlineTo(this.props.id)} className="bt bgcolor_8"> Adicionar nova linha </div> : ''}
                        </div>
                    
                    ))}
                    

                    <div className="mb5">
                        <Grid container spacing={0} className={"bgcolor_"+(this.props.colorId)+" mt5 br5"}>
                        <Grid item xs={11}><div className="mt5 pd15"><b>Sub-total</b></div></Grid>
                        <Grid item xs={1}><div className="pd20 r b"><CurrencyFormat value={this.props.valor_bloco} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                        </Grid>
                    </div>
                    
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={()=>this.fechaMenu()}
                        >
                            <StyledMenuItem><ListItemIcon><Ballot fontSize="small" /></ListItemIcon><ListItemText primary="Selecione o preset" /></StyledMenuItem>
                            {this.props.presets.map((presets,objectId)=>(
                                <StyledMenuItem onClick={()=>this.fechaMenu(presets.id)}><ListItemIcon><Ballot fontSize="small" /></ListItemIcon><ListItemText primary={presets.nome} /></StyledMenuItem>
                       ))}

                    </Menu>


                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorElSub}
                        keepMounted
                        open={Boolean(this.state.anchorElSub)}
                        onClose={()=>this.fechaMenuSub()}
                        >
                            <StyledMenuItem><ListItemIcon><Ballot fontSize="small" /></ListItemIcon><ListItemText primary="Selecione o preset" /></StyledMenuItem>
                            {this.state.subpreset.map((presets,objectId)=>(
                                <StyledMenuItem onClick={()=>this.fechaMenuSub(presets.id,this.state.presetNucleo)}><ListItemIcon><Ballot fontSize="small" /></ListItemIcon><ListItemText primary={presets.nome} /></StyledMenuItem>
                            ))}

                    </Menu>
                
                </ThemeProvider>
            
        )
    }
}
