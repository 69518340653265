import React, { Component} from "react";
import Grid from '@material-ui/core/Grid';
import {TextInput, TextInputDelete} from '../../components/textField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import BudgetConfig from './budgetConfig';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Title from '../../components/title';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Switch from '@material-ui/core/Switch';
import './style.css';
import Meta from "../../components/meta";
import { FormGroup,FormControlLabel, TextField,IconButton, MenuItem } from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import { BarLoader } from "react-spinners";
import { Redirect } from "react-router";
import { Checkbox } from "@material-ui/core";
import axios from "axios";

const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },

}
  
)




export default class Config extends Component {

  

  state = {
    token: localStorage.getItem("tokens").replace('"','').replace('"',''),
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    listUnidades: [],
    listBank: [],
    listSkill:[],
    open:false,
    bd:"",
    key:0,
    w:0,
    liquor:false,
    api:false,
    loading:true,
    uploading:false,
    loadPerc:0,
    openPlano:false,
    cache_auto:false,
    img_upload:0,
    planosGrupo:false,
    classes: ({
      root: {
        flexGrow: 0
      },
      paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        padding: 0,
        margin: -2,
      },
      control: {
        padding: 10,
        marginTop: 5,
        background: "#fc3"
      }
    })
    
  }
 



  // TOLOAD

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }


  // SETA A ÚLTIMA PAGINA
  
  setPage = async () => {

    const thisPage = '/config';
    localStorage.setItem("page",thisPage);
    api.get('update/setPage.php?page='+thisPage+'&token='+this.state.token);
    
  }



  // POPULATE

  async componentDidMount(){
    
    this.setPage()
    this.getListUnidades()
    this.getBank()
    this.getSkills()
    this.setState({ loading: true })
    this.getParcerias();
    this.getPlanoContas()

  }

// LISTA PARCERIAS

getParcerias = async () => {
  this.toLoad()

  
  api.get('get/parcerias.php?id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
    
    if (result.status === 200) {
         
        this.setState({
          ec: result.data[0]['ec'],
          liquor: result.data[0]['liquor'],
          api: result.data[0]['api'],
          conta_santa:result.data[0]['conta_santa'],
          conta_liquor:result.data[0]['conta_liquor'],
          conta_sam:result.data[0]['conta_sam'],
          conta_filial:result.data[0]['conta_filial'],
          cache_auto:result.data[0]['cache_auto'],
          img_carta:result.data[0]['logo_carta'],
          img_carta_2:result.data[0]['logo_carta_2'],
          img_crono:result.data[0]['logo_crono'],
          cor_fonte:result.data[0]['cor_fonte'],
          cor_bg:result.data[0]['cor_bg']
        
        })

      
        this.endLoad()
        
    }});

}




  // LISTA UNIDADES

  getListUnidades = async () => {
    this.toLoad()
    api.get('get/unidades.php?id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      this.setState({listUnidades: []})
      if (result.status === 200) {

          this.setState({listUnidades: result.data})
          this.endLoad()
          
      }});

  }


  


  // LISTA DAS CONTAS BANCÁRIAS

  getBank = async () => {
    this.toLoad()
    api.get('get/get.php?sheet=banco&id='+this.state.empresa_id+'&col_id=empresa_id&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
          this.setState({listBank: result.data})
          this.endLoad()
          
      }});

  }


  // LISTA PLANO DE CONTAS


  getPlanoContas = async () => {
    this.toLoad()
    api.get('get/getPlanosProducao.php?empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
        console.log(result.data)
          this.setState({listPlano: result.data})
          this.endLoad()
          
      }});

  }

  // LISTA DAS DISCIPLINAS

  getSkills = async () => {
    this.toLoad()
    api.get('get/get.php?sheet=skill&id='+this.state.empresa_id+'&col_id=empresa_id&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({listSkill: result.data})
          this.endLoad()
          
      }});

  }

   
  // CONFIRM

    handleClickOpen = (bd,key,w) => {
      
        this.setState({
          open: true,
          bd:bd,
          key:key,
          w:w
        });
      };

    handleClose = () => {
        this.endLoad()
        this.setState({open: false,openPlano:false,openEditPlanoDialog:false});
      };
    
    handleConfima = () => {
     
      this.deleteBD(this.state.bd,this.state.key,this.state.w);
      this.setState({open: false});
      
      };

  

  // DELETAR

  deleteBD = async (bd,key,w)  => { 

      this.toLoad()
      
      api.get('delete/delete.php?sheet='+bd+'&id='+w+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
            if(bd==="unidades"){  
              delete this.state.listUnidades[key];
              this.setState({listUnidades: this.state.listUnidades})
              this.endLoad()
            }

            if(bd==="banco"){  
              delete this.state.listBank[key];
              this.setState({listBank: this.state.listBank})
              this.endLoad()
            }

            if(bd==="skill"){  
              delete this.state.listSkill[key];
              this.setState({listSkill: this.state.listSkill})
              this.endLoad()
            }

            if(bd==="comissao"){  
           
              this.getListUnidades()
            }

    
      }
    })
  }

  // ADICIONAR

    adicionarBD = async (bd,key,w)  => {

      this.toLoad()

      api.get('insert/'+bd+'.php?sheet='+bd+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {


          if(bd === "unidades"){
            this.getListUnidades()
          }

          if(bd === "banco"){
            this.getBank();
            this.setState({listBank: this.state.listBank})
          }

          if(bd === "skill"){
            this.getSkills();
           
            this.setState({listSkill: this.state.listSkill})
          }

      this.endLoad()

    }


    })
  }
  

  // SETAR VALORES NOS INPUTS

  setValue = () => {
    
  }

  handleChange = (event,v) => {
    
   
    if(event.target.checked === false){
      v=0;
    }else{
      if(event.target.name==='api2'){
        event.target.name='api'
        v=2;
      }else{
        v=1;
      }
    }

    
    api.get('update/update.php?value='+v+'&id='+this.state.empresa_id+'&sheet=empresa&col='+event.target.name+'&r='+Math.random(10000))
    if(v===2){
      event.target.name='api';
    }
    
    this.setState({ ...this.state, [event.target.name]: v });

    
  };






// ALTERAR CONTAS PADRAO

contaPadrao = (event,v) => {
    
   
  if(event.target.checked === false){
    v=0;
  }else{
    v=1;
  }

  api.get('update/update.php?value='+v+'&id='+this.state.empresa_id+'&sheet=empresa&col='+event.target.name+'&r='+Math.random(10000))
  this.setState({ ...this.state, [event.target.name]: event.target.checked });
};


// CHECK PLANOS

checkPlanos = (n,w) =>{
  let listPlano = this.state.listPlano

  listPlano.planos[n][w]['esconde'] = 1-listPlano.planos[n][w]['esconde'];

  api.get('update/upEscondePlano.php?plano='+listPlano.planos[n][w]['id']+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      
      this.setState({
        listPlano:listPlano
      })
        this.endLoad()

      }


    })
      

  }

  // ABRE JANELA NOVO PLANO

  openNovoPlano = (w) => {
    let listPlano = this.state.listPlano

    this.setState({
      planoDentro:listPlano.nucleos[w]['nome'],
      openPlano:true,
      planoAberto:listPlano.nucleos[w]['id']
      
    })
  }


  // ABRE JANELA EDIT PLANO

  openEditPlano = (n,w) => {
    let listPlano = this.state.listPlano
    
   
    let planosGrupo = [];

    for(let i=0;i<listPlano.nucleos.length;i++){
      
      
        listPlano.nucleos[i].label = listPlano.nucleos[i].nome
        listPlano.nucleos[i].value = i+1;
        
        planosGrupo.push(listPlano.nucleos[i])
      
    }


    this.setState({
      planoNome:listPlano.planos[n][w]['nome'],
      planoOrdem:listPlano.planos[n][w]['ordem'],
      idGrupo:listPlano.planos[n][w]['nucleo'],
      planosGrupo:planosGrupo,
      openEditPlanoDialog:true,
      planoAberto:listPlano.planos[n][w]['id']
      
    })
  }


  // NOME DO PLANO

  updateNomePlano = (e) => {

    this.setState({
      nomeNovoPlano:e,
      planoNome:e
    })
  }



  // ALTERAR GRUPO

  updateGrupo = (e) =>{
    this.setState({
      idGrupo:e
    })
  }

  // CRIA NOVO PLANO

  criaNovoPlano = () => {
    
    api.get('insert/novoPlanoConta.php?plano_base='+this.state.planoAberto+'&nome='+this.state.nomeNovoPlano+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
  
        
        this.setState({
          openPlano:false
        })
          
          this.getPlanoContas()
  
        }
  
  
      })
        

    
  }

  // UPDATE PLANO

  alterarPlano = () => {
    this.toLoad()
    api.get('update/planoContaExtra.php?plano_base='+this.state.planoAberto+'&nome='+this.state.planoNome+'&ordem='+this.state.planoOrdem+'&nucleo='+this.state.idGrupo+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
  
        
        this.setState({
          openEditPlanoDialog:false
        })
          
          this.getPlanoContas()
          this.endLoad()
        }
  
      })
    
  }

  // UPDATE ORDEM VALOR

  updateOrdemPlano = (e) => {
    this.setState({
      planoOrdem:e
    })
  }

  // UPLOAD IMAGEM

  fileSelect = (event) => {
    this.setState({ uploading: true }); 

    const fd = new FormData();
    fd.append('image',event.target.files[0],event.target.files[0].name)
    axios.post('https://treatments.run/landscape/upload.php?id='+this.state.empresa_id+'&sheet=empresa&img='+this.state.img_list,fd).then(res => {
       
          this.setState({ 
              uploading: false
              }); 
              this.getParcerias()
      })
}


// UPLOAD IMAGEM TESTE WEBHOOK

fileSelect2 = (event) => {
  this.setState({ uploading: true }); 

  const fd = new FormData();
  fd.append('image',event.target.files[0],event.target.files[0].name)
  axios.post('https://treatments.run/landscape/webhook/unecont.php?idDoc=9999&flagError=0&msgError=0&img='+this.state.img_list,fd).then(res => {
     
        this.setState({ 
            uploading: false
            }); 
            this.getParcerias()
    })
}




// SETA IMAGEM

setaImagem = (w) =>{
  let img_list = ['logo_carta','logo_carta_2','logo_crono'];
  this.setState({
    img_list: img_list[w]
  })

}

  // VOID

  void = () => {

  }

  render(){

    
    const classes = this.state.classes;

    
   

  return (

      
    <ThemeProvider theme={theme}>
      
      
      {(this.state.permission/1) > 1 ? <Redirect to='/wrong'/> : ''}
      
      <Meta title={"Config"}/>
      <div className={classes.root}>
          
          <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
          
          <div className="conteudo">
            <Title titulo="Config"/>

            <Grid container spacing={3}>
          
              <Grid item xs={4}>
                  <div className="mini-titulo">Unidades</div>
                  
                   <div className="mt20">

                    {this.state.listUnidades.map((op,objectID)=>(
                    
                    <div className="mt10">
                    <TextInputDelete
                    id={op.id}
                    sheet="unidades"
                    col="nome"
                    ro={objectID === 0 ? true : false}
                    key={objectID}
                    type="text" 
                    fullWidth="true"
                    clickFunction={() => this.handleClickOpen('unidades',objectID,op.id)}
                    defaultValue={op.nome}
                    />
                    </div>



                    ))}
                    </div>
                    
                    <div className="mt20"><div className="bt bgcolor_1" onClick={() => this.adicionarBD('unidades')}>Adicionar operação</div></div>

                    <div className="mini-titulo mt50">Parcerias</div>
                  
                  <FormGroup collumn>

                        <FormControlLabel
                          control={<Switch checked={this.state.liquor/1 === 0 ? false : true} onChange={(e) => this.handleChange(e)} name="liquor" id='0' />}
                          label="Liquor Post"
                        />

                        <FormControlLabel
                          control={<Switch checked={this.state.api/1 === 1 ? true : false} onChange={(e) => this.handleChange(e)} name="api" id='0' />}
                          label="LandscapeAPI as a Service"
                        />
                        <FormControlLabel
                          control={<Switch checked={this.state.api/1 === 2 ? true : false} onChange={(e) => this.handleChange(e)} name="api2" id='0' />}
                          label="LandscapeAPI Topline"
                        />

                    </FormGroup>


                    <div className="mini-titulo mt50">Execution Contract</div>
                  
                  <FormGroup collumn>

                        <FormControlLabel
                          control={<Switch checked={this.state.ec/1 === 0 ? false : true} onChange={(e) => this.handleChange(e)} name="ec" id='0' />}
                          label="Assinar"
                        />

                       

                    </FormGroup>


                    <div className="mini-titulo mt50 mb30">Caches internos automático no start</div>
                    {this.state.cache_auto ? 
                    <TextInput 
                        autocomplete={false}
                        sheet="empresa"
                        id={this.state.empresa_id}
                        col="cache_auto"
                        label={'Tempo de pagamento em dias' }
                        fullWidth={true}
                        defaultValue={this.state.cache_auto}
                        
                      /> : ''}



                    <div className="mini-titulo mt50 mb30">Logos</div>
                          <div>
                            <IconButton variant="contained" color={this.state.img_carta ? "primary" : "secondary"} onMouseEnter={()=>this.setaImagem(0)} onClick={()=>this.fileInput.click()}>{this.state.uploading ? 
                              <BarLoader
                                  
                              size={30}
                              color={"#33ab9f"}
                              loading={this.state.uploading}
                              />
                              : <PhotoCamera/>}</IconButton> Logo principal carta
                            </div>
                            <div>
                            <IconButton variant="contained" color={this.state.img_carta_2 ? "primary" : "secondary"} onMouseEnter={()=>this.setaImagem(1)} onClick={()=>this.fileInput.click()}>{this.state.uploading ? 
                            <BarLoader
                                
                            size={30}
                            color={"#33ab9f"}
                            loading={this.state.uploading}
                            />
                            : <PhotoCamera/>}</IconButton> Logo secundário carta
                            </div>
                            <div>
                            <IconButton variant="contained" color={this.state.img_crono ? "primary" : "secondary"} onMouseEnter={()=>this.setaImagem(2)} onClick={()=>this.fileInput.click()}>{this.state.uploading ? 
                            <BarLoader
                                
                            size={30}
                            color={"#33ab9f"}
                            loading={this.state.uploading}
                            />
                            : <PhotoCamera/>}</IconButton> Logo principal cronograma
                            </div>


                           

                            
                            
                          <input type="file" onChange={this.fileSelect} style={{display:'none'}} ref={fileInput => this.fileInput = fileInput}></input>
                          

                          <div className="mini-titulo mt50 mb30">Cores</div>

                          {this.state.liquor ? 
                          <TextInput 
                              autocomplete={false}
                              sheet="empresa"
                              id={this.state.empresa_id}
                              col="cor_fonte"
                              label={'Cor da fonte' }
                              fullWidth={true}
                              defaultValue={this.state.cor_fonte}
                              
                            /> : ''}

                          <div className="mt20"></div>

                          {this.state.liquor ? 
                          <TextInput 
                              autocomplete={false}
                              sheet="empresa"
                              id={this.state.empresa_id}
                              col="cor_bg"
                              label={'Cor da background' }
                              fullWidth={true}
                              defaultValue={this.state.cor_bg}
                              
                            /> : ''}



              </Grid>

              <Grid item xs={4}>
                  <div className="mini-titulo">Contas Bancárias</div>

                 

                    <div className="mt20">

                    <Grid container spacing={0}>
                      <Grid item xs>
                        <FormControlLabel
                              control={<Switch checked={this.state.conta_santa/1 === 0 ? false : true} onChange={(e) => this.contaPadrao(e)} name="conta_santa" id='0' />}
                              label="Itaú Santa"
                            />
                      </Grid>
                      <Grid item xs>
                        <FormControlLabel
                              control={<Switch checked={this.state.conta_liquor/1 === 0 ? false : true} onChange={(e) => this.contaPadrao(e)} name="conta_liquor" id='0' />}
                              label="Itaú Liquor"
                            />
                      </Grid>
                      <Grid item xs>
                        <FormControlLabel
                              control={<Switch checked={this.state.conta_sam/1 === 0 ? false : true} onChange={(e) => this.contaPadrao(e)} name="conta_sam" id='0' />}
                              label="Itaú Sam"
                            />
                      </Grid>

                      <Grid item xs>
                        <FormControlLabel
                              control={<Switch checked={this.state.conta_filial/1 === 0 ? false : true} onChange={(e) => this.contaPadrao(e)} name="conta_filial" id='0' />}
                              label="Itaú Filial"
                            />
                      </Grid>
                    </Grid>
                    {this.state.listBank.map((bank,objectID)=>(
                      <div className="mt10">
                    <TextInputDelete
                    id={bank.id}
                    sheet="banco"
                    col="nome"
                    setValue={this.setValue}
                    key={objectID}
                    
                    type="text" 
                    fullWidth="true"
                    clickFunction={() => this.handleClickOpen('banco',objectID,bank.id)}
                    defaultValue={bank.nome}
                    />
                    </div>
                    ))}
                    </div>
                    
                    <div className="mt20"><div className="bt bgcolor_1" onClick={() => this.adicionarBD('banco')}>Adicionar conta bancária</div></div>


              </Grid>

              <Grid item xs={4}>
                  <div className="mini-titulo">Disciplinas internas</div>

                    <div className="mt20">

                    {this.state.listSkill.map((skill,objectID)=>(
                      <div className="mt10">
                    <TextInputDelete
                    id={skill.id}
                    sheet="skill"
                    col="nome"
                    setValue={this.setValue}
                    key={objectID}
                    
                    type="text" 
                    fullWidth="true"
                    clickFunction={() => this.handleClickOpen('skill',objectID,skill.id)}
                    defaultValue={skill.nome}
                    />
                    </div>
                    ))}
                    </div>
                    
                    <div className="mt20"><div className="bt bgcolor_1" onClick={() => this.adicionarBD('skill')}>Adicionar disciplina</div></div>


              </Grid>


              

            </Grid>


            <hr></hr>

            <Title titulo="Planilha de orçamento"/>
            <Grid container spacing={3}>
            
              
              {this.state.listUnidades.map((uni,objectId)=> (  
                <Grid item xs={4}>
              <BudgetConfig 
              key={objectId}
              impostos={uni.impostos}
              live_action={uni.live_action}
              time_interno={uni.time_interno}
              fornecedores_post={uni.fornecedores_post}
              copias={uni.copias}
              ancine={uni.ancine}
              a_live_action={uni.a_live_action}
              a_time_interno={uni.a_time_interno}
              a_fornecedores_post={uni.a_fornecedores_post}
              a_copias={uni.a_copias}
              a_ancine={uni.a_ancine}
              sobrelucro={uni.sobrelucro}
              comissao={uni.comissao} 
              unidade_id={uni.id}  
              empresa_id={uni.empresa_id}  
              custo_hora={uni.custo_hora}  
              nome={uni.nome}
              crosscompany={uni.crosscompany}
              clickFunction={(c,o,i) => this.handleClickOpen(c,o,i)}
              />
              
            </Grid>

            ))}

            </Grid>
            <hr></hr>
            <Title titulo="Report E-mails"/>
            
            <Grid container spacing={3}>   
            {this.state.listUnidades.map((uni,objectId)=> ( 
              
              
               <Grid item xs={4}>
                <div className="mini-titulo mb30 mt50">{uni ? uni.nome : ''}</div>
                <TextInput 
                  autocomplete={false}
                  sheet="unidades"
                  id={uni.id}
                  col="emails1"
                  label={'E-mails Financeiro' }
                  fullWidth={true}
                  defaultValue={ uni ? uni.emails1 : '' }
                  
                />

                <div className="mb30"></div>
                <TextInput 
                  autocomplete={false}
                  sheet="unidades"
                  id={uni.id}
                  col="emails5"
                  label={ 'E-mails PO' }
                  fullWidth={true}
                  defaultValue={ uni ? uni.emails5 : '' }
                />

                <div className="mb30"></div>
                <TextInput 
                  autocomplete={false}
                  sheet="unidades"
                  id={uni.id}
                  col="emails6"
                  label={ 'E-mails PO Poxt' }
                  fullWidth={true}
                  defaultValue={ uni ? uni.emails6 : '' }
                />


                <div className="mb30"></div>
                <TextInput 
                  autocomplete={false}
                  sheet="unidades"
                  id={uni.id}
                  col="emails2"
                  label={ 'E-mails Coordenacao' }
                  fullWidth={true}
                  defaultValue={ uni ? uni.emails2 : '' }
                />
                
                <div className="mb30"></div>
                <TextInput 
                  autocomplete={false}
                  sheet="unidades"
                  id={uni.id}
                  col="emails3"
                  label={ 'E-mails Budgets' }
                  fullWidth={true}
                  defaultValue={ uni ? uni.emails3 : '' }
                />

                <div className="mb30"></div>
                <TextInput 
                  autocomplete={false}
                  sheet="unidades"
                  id={uni.id}
                  col="emails4"
                  label={ 'E-mails Projetos' }
                  fullWidth={true}
                  defaultValue={ uni ? uni.emails4 : '' }
                />

            </Grid>
            
             ))}
            </Grid>     


            <hr></hr>
              <Title titulo="Plano de contas"/>


              {this.state.listPlano ? this.state.listPlano.nucleos.map((nucleo,objectId)=>(
                <div>
                <div className="cl mb20 b color_2 mt20" onClick={()=>this.openNovoPlano(objectId)}>{nucleo.nome}</div>
                {
                    this.state.listPlano.planos[objectId].map((plano,objectId2)=>(
                      <div className="cl ml10"><div className="flex"><Checkbox onClick={()=>this.checkPlanos(objectId,objectId2)} checked={plano.esconde}/><div className="mt10" onClick={()=>this.openEditPlano(objectId,objectId2)}>{plano.nome}</div></div></div>
                    ))
                }
                </div>
              
              )):''}
          </div>



              


      </div>
    








      <Dialog
        open={this.state.open}
        onClose={()=>this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
        <DialogTitle>{"Tem certeza que vai deletar?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Na página de configuração uma ação pode alterar as propriedades de muitas informações do banco de dados, podendo deixar algumas inacessíveis ou desclassificadas.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disableElevation onClick={()=>this.handleClose()} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button variant="contained" disableElevation onClick={()=>this.handleConfima()} color="secondary">
            Estou ciente
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog
        open={this.state.openPlano}
        onClose={()=>this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
        <DialogTitle>{"Novo plano?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você irá criar um novo plano de contas dentro de <span className="b">{this.state.planoDentro}</span>.
          </DialogContentText>
          <TextField
                            autoComplete="off"
                            fullWidth={true}
                            label="Nome do Plano"
                            onBlur={e => {
                              this.updateNomePlano(e.target.value)
                            }}
                        
                            defaultValue={''}
                            variant="outlined"
                        />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disableElevation onClick={()=>this.handleClose()} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button variant="contained" disableElevation onClick={()=>this.criaNovoPlano()} color="secondary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={this.state.openEditPlanoDialog}
        onClose={()=>this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
      >
        <DialogTitle>{"Editar plano"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Altere o nome, grupo e a ordem do plano de contas.
          </DialogContentText>
          <div className="mt10">
          <TextField
                            autoComplete="off"
                            fullWidth={true}
                            label="Nome do Plano"
                            onBlur={e => {
                              this.updateNomePlano(e.target.value)
                            }}
                        
                            defaultValue={this.state.planoNome}
                            variant="outlined"
                        />
          </div>
          <div className="mt10">
          <TextField 
                fullWidth={true}
                className={classes.root}
                InputProps={{
                  style: { 
                    height:0,
                    padding: '25px 10px'},
                  }}
                select
                value={this.state.idGrupo}
                label={'Selecione o grupo'}
                onChange={(e)=>this.updateGrupo(e.target.value)}
                variant="outlined"
                placeholder="Selecione"
              >
                <MenuItem value={0}>{'Selecione'}</MenuItem>
                <MenuItem><div className="px bgcolor_6"></div></MenuItem>
                {this.state.planosGrupo ? this.state.planosGrupo.map((option,objectID) => (
                  <MenuItem key={objectID} value={option.value}>
                    {option.label}
            </MenuItem>
          )):''}

        </TextField>
        </div>

        <div className="mt10">
        <TextField
                            autoComplete="off"
                            fullWidth={true}
                            label="Ordem"
                            onBlur={e => {
                              this.updateOrdemPlano(e.target.value)
                            }}
                        
                            defaultValue={this.state.planoOrdem}
                            variant="outlined"
                        />
          </div>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" disableElevation onClick={()=>this.handleClose()} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button variant="contained" disableElevation onClick={()=>this.alterarPlano()} color="secondary">
            Editar
          </Button>
        </DialogActions>
      </Dialog>

    </ThemeProvider>
  )
}

}
  
 