import React, { Component } from 'react'
import { ResponsivePie } from '@nivo/pie'
import './style.css';


export default class MyResponsivePie extends Component {
   
  constructor(props){
    super();
    this.state = {
      data:props.data,
      color:props.color,
      angle:270
    }
  }

  async componentDidMount(){
    this.addColor();
    this.setState({
   
    })
  }



  addColor = () => {

    
      const tempArr=[{'value':0,'id':0}];
     
      for(let i=0; i<this.state.data.length; i++){
        tempArr[i] = {'value':this.state.data[i]['value'],'id':this.state.data[i]['id'],'label':this.state.data[i]['id'],'color':this.state.color+(1-i/13)+')'};
        
      }

      this.setState({
        data:tempArr
      })
      
  }

    render(props) {
        return (
            
          <ResponsivePie
            data={this.state.data}
            innerRadius={0.5}
            colors={{ datum: 'data.color' }}
            borderColor={{ theme: 'background' }}
            enableRadialLabels={false}
            enableArcLinkLabels={false}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor="#333333"
            radialLabelsLinkColor={{ from: 'color' }}
            enableSliceLabels={false}
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#333333"
            
            valueFormat={this.props.money ? value =>
              `R$${Number(value).toLocaleString('br-BR', {
                  minimumFractionDigits: 2,
              })} `
          :''}
            isInteractive={true}
            
            legends={[]}
        />
      
      
            
        )
    }
}

