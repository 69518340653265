import React, { Component } from 'react';
import styled from 'styled-components';


const ProgressTrack = styled.div`
    width: 100%;
    height: 3px;
    background: #3A3A3A;
`;


const ProgressLoad = styled.div`
    width: ${props => props.perc}%;
    height: 3px;
    background: #A456FF;
    opacity:${props => props.op};
    transition-duration: 6s, 1s;
    transition-property: width, opacity;
`;


export default class BarReport extends Component{
    

    render(){
        return(
            <div>
                <ProgressTrack>
                    <ProgressLoad op={this.props.op} perc={this.props.perc}/>
                </ProgressTrack>

            </div>

             )    
    
        }

    }
    