import { Grid, ThemeProvider, createTheme } from '@material-ui/core'
import React, { Component } from 'react'
import {deepPurple,teal} from '@material-ui/core/colors';
import CurrencyFormat from 'react-currency-format';
import TextField from '@mui/material/TextField';

const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },
   
    })
    


  

export default class Bloco extends Component {
    
    constructor(props){
        
        super()
       
        this.state = {
            
            
            lines:props.list['unico'],
            tipos:['Sobre a produção live action','Sobre a pós','Sobre a produção','Sobre a receita','Sobre o valor total menos impostos','Valor fixo']
           
        }

        
    }

    

    render(props) {
        
       
        return (

                <ThemeProvider theme={theme}>
                    <div className="mt10 mb5">
                        <Grid container spacing={0} className="bgcolor_8 pd30 br5">
                        <Grid item xs={11} className="mini-titulo"><div className="mt10">Comissões</div></Grid>
                        
                        </Grid>
                    </div>

                    
                    {this.props.list['unico'].map((lines,objectId2)=>(

                        <Grid key={objectId2} container spacing={0} className="bgcolor_6 br5 mb1">
                            
                            <Grid item xs={7}><div className="ff-ro bgcolor_7">{lines.cargo}</div></Grid>
                            <Grid item xs={3}><div className="ff-ro bgcolor_8">{this.state.tipos[lines.tipo]}</div></Grid>
                            <Grid item xs={1}><div className="bgcolor_6">{lines.tipo<5 ? 
                            
                            <div>

                                {this.props.comissao_edit === lines.id ? <TextField autoFocus={true} variant="outlined" fullWidth={true} placeholder={lines.percentual+'%'} onBlur={(e)=>this.props.blurFieldComissao(e,objectId2)} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#444'}}}/> : <div onClick={(e)=>this.props.defineComissaoEdit(lines.id)} className="ff bgcolor_6">{lines.percentual+'%'}</div>}
                            </div>
                            
                            
                            : ''}</div></Grid>
                            <Grid item xs={1} className="bgcolor_7">
                                <div className="ff-ro b">
                                {lines.tipo === '0' ?
                                
                                <CurrencyFormat value={this.props.live_action*lines.percentual/100} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />

                                :


                                
                                <div>
                                    
                                        <div>
                                            {lines.tipo === '1' ?<CurrencyFormat value={this.props.post*lines.percentual/100} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /> :''}
                                            {lines.tipo === '2' ?<CurrencyFormat value={this.props.total_producao*lines.percentual/100} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /> :''}
                                            {lines.tipo === '3' ?<CurrencyFormat value={this.props.total_fee*lines.percentual/100} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /> :''}
                                            {lines.tipo === '4' ?<CurrencyFormat value={(this.props.total_producao+this.props.total_fee)/(1-(lines.percentual/100))-(this.props.total_producao+this.props.total_fee)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /> :''}
                                            {lines.tipo === '5' ?<div>{this.props.comissao_edit === lines.id ? <TextField autoFocus={true} variant="outlined" fullWidth={true} placeholder={lines.percentual} onBlur={(e)=>this.props.blurFieldComissao(e,objectId2)} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#444'}}}/> : <CurrencyFormat onClick={(e)=>this.props.defineComissaoEdit(lines.id)} value={lines.percentual} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div> :''}
                                        </div>

                                
                                
                                </div>

                                }
                                </div>
                                </Grid>
                        </Grid>


                    ))}


                    <div className="mb5">
                        <Grid container spacing={0} className="bgcolor_8 mt5 br5">
                        <Grid item xs={11}><div className="mt5 pd15"><b>Sub-total</b></div></Grid>
                        <Grid item xs={1}><div className="pd20 r b"><CurrencyFormat value={this.props.valor_bloco} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                        </Grid>
                    </div>
                    

                
                </ThemeProvider>
            
        )
    }
}
