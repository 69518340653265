import React, { Component, } from "react";
import Grid from '@material-ui/core/Grid';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import './style.css';
import Meta from "../../components/meta";
import {Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, TextField, withStyles,Button,Zoom,Tooltip, Switch, IconButton } from "@material-ui/core";
import { ArrowBack, ArrowForward, Dvr, GetApp, Lock, LockOpen, NavigateBefore, NavigateNext, Payment, SearchRounded,Today } from "@material-ui/icons";
import CurrencyFormat from "react-currency-format";
import Janela from './janela';
import Lista from './lista';
import ListaFluxo from './listaFluxo';
import ListaProjetos from './listaProjeto';
import ListaComissoes from './listaComissoes';
import { AutoComp, AutoCompSelect, MyDatePicker, MySelect } from "../../components/textField";
import { DateRange } from 'react-date-range';
import { format } from 'date-fns'; 
import { zonedTimeToUtc } from 'date-fns-tz'
import { Redirect } from "react-router";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { HourglassTop } from "@mui/icons-material";

const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  overrides: {
      MuiTooltip: {
          tooltip: {
            fontSize: "11px",
            backgroundColor:'#666',
            whiteSpace: 'pre-line'
      }},
      
    }

}
  
)





const StyledMenuItem = withStyles((theme) => ({
  root: {
      '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      width: '15vw',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
          
          
      },
      
      },
  },
  }))(MenuItem);


const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
  ];


  
  const date = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  const today = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  const startFluxo = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  
  date.setDate(date.getDate() + 7);

 
  
export default class Cashflow extends Component {

  
  state = {
    token: localStorage.getItem("tokens").replace('"','').replace('"',''),
    empresa_id: localStorage['empresa_id'],
    old_empresa_id: localStorage['empresa_id'],
    usuario_id: localStorage['usuario_id'],
    permission: localStorage['permission'],
    page:0,
    mes_inicial:'2000,1,1',
    optionUsers: [],
    meses:meses,
    receitasAno: [],
    despesasAno: [],
    fluxoDias: [],
    fluxoEntradas: [],
    fluxoSaidas: [],
    fluxoResultado: [],
    startFluxo:startFluxo,
    reportProjeto:100,
    contaExcel:0,
    dataExcel:0,
    todasEmpresas:0,
    grBar: [],
    grRec: [],
    check:[],
    receitaTT:[],
    despesaTT:[],
    range:1,
    janelaOpen:false,
    fechado:false,
    mes_fechado:false,
    statusHoras:0,
    status:[{"id":"0","value":"0","label":"Validar"},{"id":"1","value":"1","label":"Aguardando"},{"id":"2","value":"2","label":"Realizado"},{"id":"3","value":"3","label":"Verificado"},{"id":"4","value":"4","label":"Pix Imediato"},{"id":"5","value":"5","label":"Nota de débito"}],
    statusRec:[{"id":"0","value":"0","label":"Validar"},{"id":"1","value":"1","label":"Faturado"},{"id":"2","value":"2","label":"Realizado"},{"id":"3","value":"3","label":"Verificado"},{"id":"4","value":"4","label":"Pix Imediato"},{"id":"5","value":"5","label":"Nota de débito"}],
    statusRedux:[{"value":"0","label":"Validar"},{"id":"3","value":"3","label":"Verificado"},{"value":"2","label":"Realizado"}],
    statusCom:[{"value":"0","label":"Aberto"},{"value":"2","label":"Pago"}],
    reportMenu:[{"value":"4","label":"Conta bancária"},{"value":"5","label":"Projetos"},{"value":"6","label":"Unidades"},{"value":"7","label":"Comissões de projetos"},{"value":"9","label":"Exportar para Excel"},{"value":"10","label":"Verbas"},,{"value":"11","label":"Folha de pagamento"}],
    abertoFechado:[{"value":0,'label':'Aberto'},{"value":2,'label':'Fechado'}],
    selectDataXLS:[{'value':0,'label':'Data de Vencimento'},{'value':1,'label':'Data de Competência'},{'value':2,'label':'Data de Emissão'},{'value':3,'label':'Data de Chegada NF'}],
    selectedAbertoFechado:0,
    poId:'n',
    colorStatus:['bgcolor_5s','bgcolor_2'],
    filterStatus:-1,
    filterFornecedor:-1,
    filterPlanoContas:-1,
    statusComissao:0,
    userComissao:0,
    all:0,
    bankToken:0,
    salarios:null,
    empresas_salarios:null,
    transferData: (today),
    selectionRange:({
      startDate: today,
      endDate: date,
      key: 'selection',
    }),
    classes: ({
      root: {
        flexGrow: 0,
      
      },
      paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        padding: 0,
        margin: -2,
    
      },
      control: {
        padding: 10,
        marginTop: 5,
        background: "#fc3"
      }
      
    })
    
  }
 

  
  async componentDidMount(){

      
    this.setPage();
    //this.token();
    this.getContasBancarias();
    this.getFechado();

  }


    // ARRAY COMPARE

    compare = ( a, b ) => {
      if ( a.label < b.label ){
        return -1;
      }
      if ( a.label > b.label ){
        return 1;
      }
      return 0;
    }
  

    // TOLOAD

    toLoad = () => {
        
      this.setState({
        loadPerc: 2,
        loading:true,
        intervalLoad: setInterval(this.Loader, 200)
      })
    }
    

    endLoad = () => {
      
      this.setState({
        loadPerc: 100,
        loading:false,
        intervalLoad:false,
      })
    }

    Loader = () => {
      if(this.state.loadPerc<100){
          this.setState({
              loadPerc:this.state.loadPerc*1.4,
          })
        }
    }


  // SET PAGE

  setPage = async () => {

    const thisPage = '/cashflow';
    localStorage.setItem("page",thisPage);
    api.get('update/setPage.php?page='+thisPage+'&token='+this.state.token);
    

    this.cashflowHoje();
    this.getProjetos();
    this.getPlanoContas();
    this.getListUnidades();
    this.getUserComissoes();
    this.getCashflowBars();
    this.grupo();
  }


  // GRUPO

  grupo = () => {

    api.get('get/get.php?sheet=empresa&id='+this.state.empresa_id+'&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
       
          this.setState({
            grupo: result.data
            
          })

          this.empresasGrupo()
          
      }});
  }


  // EMPRESAS DO GRUPO

  empresasGrupo = () => {

    this.toLoad()

    let grupo = this.state.grupo[0]['grupo'];

   
    api.get('get/getSelect.php?sheet=empresa&id='+grupo+'&col_id=grupo&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
       
          this.setState({
            listEmpresasGrupo: result.data
          })

          
          this.endLoad()
          
      }});


  }


  // MUDA EMPRESA

  mudaEmpresa = (w) => {
    this.setState({
      
      old_empresa_id:this.state.empresa_id,
      empresa_id:w
      
    })

    setTimeout(() => {

      this.getProjetos();
      this.getPlanoContas();
      this.getListUnidades();
      this.getContasBancarias();
      this.getFechado();

    }, 10);
    

  }


  // LISTA USUARIOS

  getUserComissoes = async () => {

    this.toLoad()
    api.get('get/getSelect.php?sheet=users&id=0&col_id=tipo&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
       
          this.setState({
            listUsers: result.data
          })

          this.endLoad()
          
      }});

  }


    // LISTA DAS CONTAS BANCÁRIAS

    getContasBancarias = async () => {

      this.toLoad()

      api.get('get/parcerias.php?id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {


          this.setState({
            
            conta_santa:result.data[0]['conta_santa'],
            conta_liquor:result.data[0]['conta_liquor'],
            conta_sam:result.data[0]['conta_sam'],
            conta_filial:result.data[0]['conta_filial'],
          
          })


      api.get('get/getSelect.php?sheet=banco&id='+this.state.empresa_id+'&col_id=empresa_id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result2 => {
        
        if (result2.status === 200) {
          
          let listaContas = result2.data;


          if(this.state.conta_santa/1===1){
            listaContas.push({id: "2", value: "2", label: "Conta Itaú Santa"});
          }

          if(this.state.conta_liquor/1===1){
            listaContas.push({id: "15", value: "15", label: "Conta Itaú Liquor"});
          }

          if(this.state.conta_sam/1===1){
            listaContas.push({id: "27", value: "27", label: "Conta Itaú SAM"});
          }

          
          if(this.state.conta_filial/1===1 && this.state.empresa_id/1!==1){
            listaContas.push({id: "47", value: "47", label: "Conta Itaú Filial"});
          }
          
          listaContas.push({id: "45", value: "45", label: "Transfeera SANTA"});
          listaContas.push({id: "66", value: "66", label: "Transfeera SAM"});
          listaContas.push({id: "30", value: "30", label: "Conta Simples"});
          listaContas.push({id: "68", value: "68", label: "Cartão Scale"});

          
          listaContas = this.sortDataBy(listaContas,'label')

            this.setState({
              
              listaContas: listaContas,
              contaBancaria: result2.data[0]['value']
            })

           
            this.endLoad()
            this.calendarHome();

        }});

      }});
        
  
    }

    // SORT ARRAY 

    sortDataBy = (data, byKey) => {
      let sortedData;
      if(byKey == 'label'){
        sortedData = data.sort(function(a,b){
          let x = a.label.toLowerCase();
          let y = b.label.toLowerCase();
          if(x>y){return 1;}
          if(x<y){return -1;}
          return 0;
        });
      }
      return sortedData;
    }

    // GET FECHADO

    getFechado = () => {

      api.get('get/get.php?sheet=empresa&id='+this.state.empresa_id+'&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {

        let mes_fechado = result.data[0].fechado.split('-')[1]
        let ano_fechado = result.data[0].fechado.split('-')[0]
        let mes_atual = today.getMonth()+1;

        if(mes_fechado>=mes_atual){

          let fechado = false;
          if(ano_fechado/1===new Date().getFullYear()){
            fechado = true
          }

          this.setState({
            mes_fechado:result.data[0].fechado.split('-')[1],
            fechado:fechado
          })
        }else{
          this.setState({
            mes_fechado:result.data[0].fechado.split('-')[1],
            fechado:false
          })
        }

        setTimeout(() => {

          let mes_inicial = '2000,1,1';

          if(this.state.mes_fechado/1>new Date().getMonth()+1){
            
            mes_inicial = new Date().getFullYear()-1+','+this.state.mes_fechado+',1';
          }else{
          
            mes_inicial = (new Date().getFullYear())+','+this.state.mes_fechado+',1';
          }
          
          this.setState({
            mes_inicial:mes_inicial
          })
          
        }, 0);
       
      })

    }

    // CHANGE CONTA

    changeConta = (e) => {

      this.setState({
        contaBancaria:e
      })

      setTimeout(() => {
        this.salvaJanela()
      }, 10);


    }

    // ABERTO FECHADO

    changeAbertoFechado = (e) => {

      this.setState({
        selectedAbertoFechado:e
      })

      setTimeout(() => {
        this.getVerbas()
      }, 10);

    }

     // CHANGE CONTA FLUXO

     changeContaFluxo = (e) => {

      this.setState({
        contaBancaria:e
      })

      if(this.state.page===0){
      setTimeout(() => {
        this.calendarHome()
      }, 10);
      }

      if(this.state.page===1 || this.state.page===2){
        setTimeout(() => {
        this.cashflowLista(this.state.page-1)
      }, 10);
      }


    }


    // CHANGE PROJETOS

    changeProjetos = (e) => {

      this.setState({
        reportProjeto:e
      })

      setTimeout(() => {
        this.salvaJanela()
      }, 10);


    }

    // CHANGE UNIDADE

    changeUnidade = (e) => {

      this.setState({
        unidade:e
      })

      setTimeout(() => {
        this.salvaJanela()
      }, 10);


    }


        // CHANGE USER COMISSAO

        changeUserComissao = (e) => {

          this.setState({
            userComissao:e
          })
    
          setTimeout(() => {
            this.salvaJanela()
          }, 10);
    
    
        }


        // CHANGE COMISSÃO STATUS

        changeStatusComissao = (e) => {
          
          this.setState({
            statusComissao:e
          })
    
          setTimeout(() => {
            this.salvaJanela()
          }, 10);
    
    
        }



        // CHANGE USER HORAS

        changeUserHoras = (e) => {

          this.setState({
            userHoras:e
          })
    
          setTimeout(() => {
            this.salvaJanela()
          }, 10);
    
    
        }


        // CHANGE HORAS STATUS

        changeStatusHoras = (e) => {
          
          this.setState({
            statusHoras:e
          })
    
          setTimeout(() => {
            this.salvaJanela()
          }, 10);
    
    
        }


        // CHANGE HORAS STATUS SAVE

        changeStatusHorasSave = (e) => {
          
          this.setState({
            statusHorasSave:e
          })
    
          setTimeout(() => {
            this.salvaJanela()
          }, 10);
    
    
        }
    

    // RECEITAS E DESPESAS DE HOJE

    getCashflowBars = () => {

      
      api.get('get/fluxoCashflowBars.php?empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
    
        if(result.status===200){
          
            let high=0;
            let grBar=[];
            let grRec=[];
            
            let receita = result.data.entradas;
            let despesa = result.data.saidas;

            for(let i=0;i<receita.length;i++){
              
                if(receita[i]>high){
                  
                  high=receita[i];
                }
            }

            for(let i=0;i<despesa.length;i++){
              if(despesa[i]>high){
                
                high=despesa[i];
              }
          }
          
          for(let i=0;i<despesa.length;i++){
            if(receita[i]>despesa[i]){
              grBar[i]="linear-gradient(0deg, #A456FF "+despesa[i]/high*100+"%, #333 "+despesa[i]/high*100+"%)"
            }else{
              grBar[i]="linear-gradient(0deg, #777 "+despesa[i]/high*100+"%, #333 "+despesa[i]/high*100+"%)"
            }
          }

          for(let i=0;i<receita.length;i++){
            grRec[i]=(receita[i]/high*-300)-50+'px';
          }

          this.setState({
            high:high,
            grBar:grBar,
            grRec:grRec,
            receitaTT:receita,
            despesaTT:despesa,
          })


          
        }})
    }


  // CALENDAR HOME


  calendarHome = () => {

    this.toLoad()

        api.get('get/fluxoCashflow.php?conta_id='+this.state.contaBancaria+'&start='+format(this.state.startFluxo,'yyyy-MM-dd')+'&r='+Math.random(10000)).then(result => {
            
          if(result.status===200){
            
            this.setState({
              fluxoDias: result.data.dias,
              fluxoEntradas: result.data.entradas,
              fluxoSaidas: result.data.saidas,
              fluxoResultado: result.data.resultado,
              fluxoSaldo: result.data.saldo,
              
        })
        this.endLoad()
  }})

}


// MOVE CALENDARIO


moveCalendario = (w) =>{

  
  if(w===0){
    this.state.startFluxo.setDate(this.state.startFluxo.getDate() - 10)
  }else{
    this.state.startFluxo.setDate(this.state.startFluxo.getDate() + 10)
  }

  this.calendarHome()
}


  // RECEITAS E DESPESAS DE HOJE

  cashflowHoje = () => {

   api.get('get/cashflowHoje.php?empresa_id='+this.state.empresa_id+'&conta='+this.state.contaBancaria+'&r='+Math.random(10000)).then(result => {
  
      if(result.status===200){
 
        this.setState({
          receitasHoje: result.data.receitas,
          despesasHoje: result.data.despesas
        })

        
      }})
  }


  //  RECEITAS/DESPESAS FULL

  cashflowLista = (w) => {
    
    this.toLoad();

    api.get('get/cashflow.php?tipo='+w+'&conta='+this.state.contaBancaria+'&cashflow_status='+this.state.filterStatus+'&fornecedor='+this.state.filterFornecedor+'&plano_conta='+this.state.filterPlanoContas+'&start='+format(this.state.selectionRange.startDate,'yyyy-MM-dd')+'&end='+format(this.state.selectionRange.endDate,'yyyy-MM-dd')+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
  
      if(result.status===200){
        
        this.setState({

          cashflow: result.data,
          
        })

        this.endLoad()
      }})


    
  }

  // BAIXAR CNAB

  baixarCnab = () => {

    let check = this.state.check;
    let checkId = [];
    for(let i=0;i<check.length;i++){
      if(check[i]===1){
        checkId.push(i);
      }
    }

   window.open('https://treatments.run/landscape/insert/cnab.php?empresa_id='+this.state.empresa_id+'&conta_id='+this.state.contaBancaria+'&po='+checkId.join(','))

  }


  //  REPORT BANCO

  reportContaBancaria = () => {
    
    this.toLoad()

    api.get('get/cashflowReport.php?tipo=4&conta='+this.state.contaBancaria+'&start='+format(this.state.selectionRange.startDate,'yyyy-MM-dd')+'&end='+format(this.state.selectionRange.endDate,'yyyy-MM-dd')+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
  
      if(result.status===200){

            
        this.setState({

          cashflowReportConta: result.data.linhas,
          cashflowReportSaldo: result.data.saldo,
          
        })

        this.endLoad()
      }})


    
  }


  //  REPORT UNIDADE

  reportUnidade = () => {
    this.toLoad()
    api.get('get/cashflowReport.php?tipo=6&unidade='+this.state.unidade+'&start='+format(this.state.selectionRange.startDate,'yyyy-MM-dd')+'&end='+format(this.state.selectionRange.endDate,'yyyy-MM-dd')+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
  
      if(result.status===200){

        
        this.setState({

          cashflowReportUnidade: result.data.linhas,
          
        })

        this.endLoad()
      }})


    
  }


  //  REPORT COMISSÃO

  reportComissao = () => {
  
   this.toLoad()
   if(this.state.userComissao>0){
   api.get('get/cashflowReport.php?tipo=7&user='+this.state.userComissao+'&status='+this.state.statusComissao+'&start='+format(this.state.selectionRange.startDate,'yyyy-MM-dd')+'&end='+format(this.state.selectionRange.endDate,'yyyy-MM-dd')+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
  
      if(result.status===200){

        
        this.setState({

          cashflowReportComissao: result.data.linhas,
          cashflowReportComissaoCost: result.data.cost,
          
        })

        this.endLoad()
      }})
    }


    
  }


  //  REPORT HORAS

  reportHoras = () => {
  
    
    this.toLoad()
    api.get('get/getReportHorasCashflow.php?status='+this.state.statusHoras+'&usuario_id='+this.state.userHoras+'&start='+format(this.state.selectionRange.startDate,'yyyy-MM-dd')+'&end='+format(this.state.selectionRange.endDate,'yyyy-MM-dd')+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
         

          this.setState({
            reportHoras: result.data.horas
          
          })
         
          this.endLoad()
          
      }});
 
 
     
   }


 


  //  REPORT PROJETOS

  reportProjetos = () => {
    
  this.toLoad()


    api.get('get/cashflowReport.php?tipo=5&projeto='+this.state.reportProjeto+'&start=2000-01-01&end=2050-01-01&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
  
      if(result.status===200){

       
        this.setState({

          cashflowReportProjeto: result.data.linhas,
          cashflowReportProjetoImpostos: result.data.impostos,
          cashflowReportProjetoEntradas: result.data.entradas
          
        })

        this.endLoad()
      }})


    
  }


   // LISTA DE PROJETOS

   getProjetos = async () => {
    this.toLoad()
    api.get('get/getSelect.php?sheet=projeto&closed=0&id=2&col_id=projeto_status&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
          let projetos = result.data

          projetos.push({id:1,value:1,label:'###_Limbo'})
          
          for(let i=0;i<projetos.length;i++){

            projetos[i]['label'] = projetos[i]['label'].replace('  ',' ');

          }

          
          this.setState({listProjetos: projetos})
         
          this.endLoad()
          
      }});

  }


  // LISTA DE PLANO DE CONTAS

  getPlanoContas = async () => {
    this.toLoad()
   
    api.get('get/getSelect.php?sheet=plano_conta&id=0&col_id=active&empresa_id=0&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          
          this.setState({listPlanoContas: result.data})
         
          this.endLoad()
          
      }});

  }


    // LISTA UNIDADES

    getListUnidades = async () => {
      
      this.toLoad()
      api.get('get/getSelect.php?sheet=empresa&id=1&col_id=landscape_ops&empresa_id=no&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {
            
          
          let listUnidades = result.data;

         
            listUnidades.sort(this.compare)
            this.setState({
              listUnidades1: listUnidades,
              listUnidades2: listUnidades,
              unidade: result.data[0]['value']
            })
            this.endLoad()

          }

      })


      api.get('get/getSelect.php?sheet=unidades&id=0&col_id=active&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {
            
          
          let listUnidades = result.data;

         
            listUnidades.sort(this.compare)
            this.setState({
              listUnidades: listUnidades
            })
            this.endLoad()

          }

      })

          
       
    }


    buscaPOEnter = (e) =>{
      if(e.keyCode === 13 && e.target.value>0){
        this.buscaPO(e)
      }

    }
  buscaPO = (e) => {
    if(e.target.value>0){
      this.setState({
        poId:e.target.value,
        janelaOpen:true,
        
      })
    }
  }
   

  // FECHA JANELA

  fechaJanela = () => {

    this.setState({
      empresa_id:this.state.old_empresa_id,
      janelaOpen:false,
      poId:'n',
    })
  }

  // ABRE JANELA

  abreJanela = (w) => {

    this.fechaMenu()

    this.setState({
      poId:w,
      janelaOpen:true,
    })
  }


  // SALVA JANELA

  salvaJanela = (w) => {
    this.fechaJanela();
    this.cashflowHoje();
    this.getProjetos();

    if(this.state.page===1){
      this.cashflowLista(0)
    }

    if(this.state.page===2){
        this.cashflowLista(1)
    }

    if(this.state.page===4){
      this.reportContaBancaria()
    }

    if(this.state.page===5){
      this.reportProjetos()
    }

    if(this.state.page===6){
      this.reportUnidade()
    }

    if(this.state.page===7){
      this.reportComissao()
    }


    if(this.state.page===8){
      this.reportHoras()
    }

  }


  // ABRE JANELA TRANSFER

  abreJanelaTransfer = (w) => {

    this.fechaMenu()

    this.setState({
      poId:w,
      transferenciaOpen:true,
      
    })
  }



  // FECHA DATA

fechaDialog = () =>{
  this.setState({
    dataOpen:false,
    statusOpen:false,
    statusComissoesOpen:false,
    statusHorasOpen:false,
    transferenciaOpen:false
  })
}

// ABRE DATA GRUPO

abrirDataGrupo = () => {

  this.setState({
    dataOpen:true
  })
}

//  SETA DATA GRUPO

changeDataGrupo = (e) =>{

  this.setState({
    dataGrupo:e
  })
}



// SALVA DATA GRUPO

salvaDataGrupo = () => {

  if(this.state.dataGrupo!==0){


    let check = this.state.check;
    let checkId = [];
    for(let i=0;i<check.length;i++){
      if(check[i]===1){
        checkId.push(i);
      }
    }

    api.get('update/updateBatch.php?sheet=cashflow&col=dt_vencimento&value='+format(this.state.dataGrupo,'yyyy-MM-dd')+'&id='+checkId.join()+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        this.setState({
          dataOpen:false,
          check:[],
          dataGrupo:0
        })
  
        this.toCheck(0)
        this.salvaJanela();
  
      }})

  }
}




// ABRE STATUS GRUPO

abrirStatusGrupo = () => {

  this.setState({
    statusOpen:true
  })
}

//  SETA STATUS GRUPO

changeStatusGrupo = (e) =>{

let ok_set = true;
 let data_ini = this.formatDate(this.state.selectionRange.startDate).split('/')
 if(data_ini[2]>today.getFullYear()){
  ok_set = true;
 }else{
  if(data_ini[1]<this.state.mes_fechado && data_ini[0]===new Date().getFullYear()){
    ok_set = false;
  }
 }

 if(ok_set){
    this.setState({
      statusGrupo:e
    })
  }else{
    alert('Data de busca menor que o mês fechado atual.')
    this.setState({
      statusOpen:false
    })
  }
}


// SALVA STATUS GRUPO

salvaStatusGrupo = () => {

  if(this.state.statusGrupo!==0){


    let check = this.state.check;
    let checkId = [];
    for(let i=0;i<check.length;i++){
      if(check[i]===1){
        checkId.push(i);
      }
    }

    api.get('update/updateBatch.php?sheet=cashflow&col=cashflow_status&value='+this.state.statusGrupo+'&id='+checkId.join()+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        this.setState({
          statusOpen:false,
          check:[],
          statusGrupo:0
        })
  
        this.toCheck(0)
        this.salvaJanela();
  
      }})

  }
}

// ABRE STATUS COMISSOES

abrirStatusComissoes = (w) => {

  this.setState({
    statusComissoesOpen:true,
    comissaoAtiva:w
  })
}

//  SETA STATUS COMISSOES

changeStatusCom = (e) =>{

  this.setState({
    statusComissoes:e
  })
}


// ABRE STATUS HORAS

abrirStatusHoras = (w) => {

  this.setState({
    statusHorasOpen:true,
    horasAtiva:w
  })
}

//  SETA STATUS COMISSOES

changeStatusCom = (e) =>{

  this.setState({
    statusHoras:e
  })
}



// SALVA STATUS GRUPO

salvaStatusComissoes = () => {

  api.get('update/update.php?sheet=comissao_budget&col=comissao_status&value='+this.state.statusComissoes+'&id='+this.state.comissaoAtiva+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        this.setState({
          statusComissoesOpen:false,
          statusComissoesGrupo:0
        })
  
        
        this.salvaJanela();
  
      }})

  
}


// SALVA STATUS HORAS

salvaStatusHoras = () => {

  api.get('update/update.php?sheet=horas&col=horas_status&value='+this.state.statusHorasSave+'&id='+this.state.horasAtiva+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        this.setState({
          statusHorasOpen:false
        })
  
        
        this.salvaJanela();
  
      }})

  
}



  // CHECK

  toCheck = (w,all,lista) => {

  
    let check = this.state.check;
    let cashflow = [];
    let checkAll = this.state.all;

    if(all===1){

      if(lista !== this.state.lista){
        checkAll=0;
      }

      
      check=[];
      
        if(lista===0){
           cashflow =this.state.cashflow;
          
        }
      if(lista===1){
         cashflow =this.state.receitasHoje;
      }
      if(lista===2){
         cashflow =this.state.despesasHoje;
      }

      if(checkAll===0){
        for(let i=0;i<cashflow.length;i++){
          check[cashflow[i]['id']]=1;
        }

        all=1;
      }else{
        for(let i=0;i<cashflow.length;i++){
          check[cashflow[i]['id']]=0;
        }
        all=0;
      }
    }
    
      if(all===undefined){
      if(check[w]){
        check[w]= 1-check[w];
      }else{
        check[w]=1;
      }
    }
    this.setState({
      check:check,
      all:all,
      lista:lista

    })
  }


      // ABRE MENU PRESET

      abreMenu = (event) => {


        this.setState({
            anchorEl: event.currentTarget
        })
        
        
      };


      // ABRE MENU DATA

      abreMenuData = (event) => {


        this.setState({
            anchorElData: event.currentTarget
        })
        
        
      };

    
      // FECHA MENU PRESET

    fechaMenu = () => {
        
        this.setState({
            anchorEl: null,
            anchorElData: null,
            anchorElReport: null
            
        })

        if(this.state.page===10){
          setTimeout(() => {
            this.getVerbas()
          }, 0);
          
        }
      };



      // FECHAR MES

      fecharMes = () =>{
        if(!this.state.fechado){
          
          let mesfechado = (this.state.mes_fechado/1+1);

          if(mesfechado<10){
            mesfechado = '0'+mesfechado;
          }
          
          if(mesfechado>12){
            mesfechado='01'
          }

          let mes = today.getFullYear()+'-'+mesfechado+'-01';

          if(mesfechado>new Date().getMonth()+1){
            mes = (today.getFullYear()-1)+'-'+mesfechado+'-01';
          }

          api.get('update/fecharAbrirMes.php?mes='+mes+'&id='+this.state.empresa_id+'&w=0').then(result => {
            this.getFechado()
          })
          
        }
      }

      // ABRIR MES

      abrirMes = () =>{
        
        let mesfechado = (this.state.mes_fechado/1-1);

        
        if(mesfechado<10){
          mesfechado = '0'+mesfechado;
        }
        
        if(mesfechado==='00'){
          mesfechado='12'
        }

       
        let mes = today.getFullYear()+'-'+mesfechado+'-01';
          
        if(mesfechado==='12'){
          mes = (today.getFullYear()-1)+'-'+mesfechado+'-01';
        }
        
          api.get('update/fecharAbrirMes.php?mes='+mes+'&id='+this.state.empresa_id+'&w=1').then(result => {
            this.getFechado()
          })
        
      }


      // ABRE REPORT DATA

      abreReport = (event) => {


        this.setState({
            anchorElReport: event.currentTarget
        })
        
        
      };

      // NAV CASHFLOW

      navCashflow = (w) =>{
        
        this.setState({
          cashflow:[],
          filterPlanoContas:-1,
          filterFornecedor:-1,
          filterStatus:-1,
          page:w/1,
          all:0
        })

        setTimeout(() => {
          this.salvaJanela()
          
        }, 10);
        
        this.fechaMenu();
        if(w/1===11){
          if(this.state.permission<2){
            this.getSalarios()
          }else{
            alert('Você não tem acesso a esta área')
          }
        }

        if(w/1===10){
          this.getVerbas()
        }


        
      }

      // GET VERBAS

      getVerbas = () =>{

        api.get('get/getVerbas.php?usuario_id='+this.state.usuario_id+'&status='+this.state.selectedAbertoFechado+'&in='+this.formatDate2(this.state.selectionRange.startDate)+'&out='+this.formatDate2(this.state.selectionRange.endDate)+'&id=0&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {
      
            this.setState({
      
              listVerbas:result.data.verbas,
              verbaPermission:result.data.cargo
      
            })
      
           
            this.endLoad()
          }})

      }

      // GET VERBAS

      getSalarios = () =>{

        api.get('get/getSalaries.php?r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {
      
            this.setState({
      
              
              empresas_salarios:result.data.empresas,
              salarios:result.data.salarios
      
            })
      
           
            this.endLoad()
          }})

      }


      // ALTERA RANGE

      alteraRange = (e) => {
        
          let range = 1-this.state.range;
        
          
          this.setState({
              selectionRange:({
                startDate: e.selection.startDate,
                endDate: e.selection.endDate,
                key: 'selection',
              }),
              
              range:range
            })
        
            if(range===1){

              setTimeout(() => {
                
                this.salvaJanela()

              }, 0);
              
              this.fechaMenu();
            }

             
              
      }

      // CHANGE FILTER

      changeFilter = (e,w) => {

        if(w===1){
          this.setState({
            filterFornecedor:e,
          })
        }
        if(w===2){
          this.setState({
            filterPlanoContas:e,
          })
        }
        if(w===3){
          this.setState({
            filterStatus:e,
          })
        }

        setTimeout(() => {
          if(this.state.page===1){
            this.setState({
              cashflow:[],
            })
            this.cashflowLista(0)
          }
      
          if(this.state.page===2){
            this.setState({
              cashflow:[],
            })
              this.cashflowLista(1)
          }
        }, 0);

        
      }

      // CLEAR FORNECEDOR

      clearFornecedor = (e) => {

        if(e===""){

          this.setState({
            
            filterFornecedor:-1
            
          })

          setTimeout(() => {
           
            this.salvaJanela()

          }, 0);

        }

      }


      // CLEAR FORNECEDOR

      clearProjetos = (e) => {

        if(e===""){

          this.setState({
            
            reportProjeto:100
            
          })

          setTimeout(() => {
           
            this.salvaJanela()
            
          }, 0);

        }

      }

      // FORMATE DATE

      formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    }

    // FORMATE DATE

    formatDate2 = (date) => {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
  }

    // MUDA INFOS TRANSFER

    changeJanelaTransfer = (e,w) => {
      if(w===0){
        this.setState({
          transferUnidade1:e
        })
      }
      if(w===1){
        this.setState({
          transferUnidade2:e
        })
      }
      if(w===2){
        let valor = e;
        valor = valor.split(',').join('');
        this.setState({

          transferValor:valor
        })
      }
      if(w===3){
        this.setState({
          transferObs:e
        })
      }

      if(w===4){
        this.setState({
          transferData:this.formatDate2(e)
        })
      }

    }

   // ENVIA TRANSFERENCIA

   enviaTransferencia = () => {

    if(this.state.transferUnidade1 && this.state.transferUnidade2 && this.state.transferValor && this.state.transferObs && this.state.transferData){
      
      this.toLoad()
      api.get('insert/transferencia.php?usuario='+this.state.usuario_id+'&unidade1='+this.state.transferUnidade1+'&unidade2='+this.state.transferUnidade2+'&valor='+this.state.transferValor+'&observacao='+this.state.transferObs+'&data='+this.state.transferData+'&r='+Math.random(10000)).then(result => {
          
        if (result.status === 200) {
          
          this.setState({
            transferenciaOpen:false
          })
    
          
          this.salvaJanela();
    
        }})

      }

   }

   // MUDA CONTA REPORT EXCEL 

   changeContaExcel = (e) => {
     this.setState({
       contaExcel:e
     })
   }

   // MUDA DATA REPORT EXCEL 

   changeDataExcel = (e) => {
    this.setState({
      dataExcel:e
    })
  }

   // DOWNLOAD EXCEL

   downloadExcel = () => {

    let empresa = this.state.empresa_id;
    if(this.state.todasEmpresas===1){
      empresa=0;
    }
   
     window.open('https://treatments.run/landscape/get/xls_fluxo.php?empresa_id='+empresa+'&dia1='+this.formatDate2(this.state.selectionRange.startDate)+'&dia2='+this.formatDate2(this.state.selectionRange.endDate)+'&conta_id='+this.state.contaExcel+'&data='+this.state.dataExcel+'&r='+Math.random(10000))
   }

    // DOWNLOAD EXCEL VERBAS

    downloadExcelVerbas = () => {
      
        window.open('https://treatments.run/landscape/get/xls_verbas.php?status='+this.state.selectedAbertoFechado+'&dia1='+this.formatDate2(this.state.selectionRange.startDate)+'&dia2='+this.formatDate2(this.state.selectionRange.endDate)+'&r='+Math.random(10000))
      }
    
    // DOWNLOAD EXCEL RECIBOS

    downloadExcelRecibos = () => {
      window.open('https://treatments.run/landscape/get/xls_recibos.php?status='+this.state.selectedAbertoFechado+'&dia1='+this.formatDate2(this.state.selectionRange.startDate)+'&dia2='+this.formatDate2(this.state.selectionRange.endDate)+'&r='+Math.random(10000))
    }

   // TODAS EMPRESAS

   changeEmpresas = () => {
   
      this.setState({
        todasEmpresas:1-this.state.todasEmpresas
      })

      
   }
    // VOID

    void = () => {

    }


  render(){

    const classes = this.state.classes;

     

  return (

    
    <ThemeProvider theme={theme}>
      

      {this.state.permission > 1 ? <Redirect to='/login'/> : ''}
      
<Meta title="Cashflow"/>
      <div className={classes.root}>
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo">
          
          <Grid container spacing={3}>
            
            <Grid item xs={3}><div className="titulo" onClick={()=>this.navCashflow(0)}>Cashflow</div></Grid>
            <Grid item xs={2}><div className="bt large bgcolor_1" onClick={(e)=>this.abreMenu(e)}>Novo</div></Grid>
            <Grid item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs={4}><div className={this.state.page===1 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navCashflow(1)}>Receitas</div></Grid>
              <Grid item xs={4}><div className={this.state.page===2 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navCashflow(2)}>Despesas</div></Grid>
              <Grid item xs={4}><div className={this.state.page===3 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={(e)=>this.abreReport(e)}>Report</div></Grid>
              
              </Grid>
              </Grid>
              <Grid item xs={2}>{this.state.contaBancaria/1>0 ? <MySelect list={this.state.listaContas ? this.state.listaContas : []} initValue={this.state.contaBancaria/1} label="Conta bancária" change={(e)=>this.changeContaFluxo(e)}/>:''}</Grid>
         
             
             <Grid item xs={10}></Grid>
          </Grid>
          {this.state.page===0 ? 
          <div>
          <div className="wp100 mt20">

            <Grid container spacing={0}>
            <Grid item xs={1} onClick={()=>this.moveCalendario(0)} className="bgcolor_6 tc cl br5">
              <NavigateBefore className="mt90" fontSize="large"/>
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={0}>
                {this.state.fluxoDias.map((dias,objectId)=>(
                  <Grid key={objectId} item xs><div className="bgcolor_7 color_0 celula pd20 tc eli">{dias}</div></Grid>
                  ))}
              </Grid>

              <Grid container spacing={0}>
                {this.state.fluxoEntradas.map((entradas,objectId)=>(
                  <Grid key={objectId} item xs><div className="bgcolor_8 br5 color_0 celula pd20 tc eli">{<CurrencyFormat value={entradas} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                  ))}
              </Grid>

              <Grid container spacing={0}>
                {this.state.fluxoSaidas.map((saidas,objectId)=>(
                  <Grid key={objectId} item xs><div className="bgcolor_9 br5 celula color_0 pd20 tc eli">{<CurrencyFormat value={saidas*-1} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                  ))}
              </Grid>

              <Grid container spacing={0}>
                {this.state.fluxoResultado.map((resultado,objectId)=>(
                  <Grid key={objectId} item xs><div className={(resultado+this.state.fluxoSaldo)>0 ? "bgcolor_3 celula color_0 pd20 tc b eli" : "bgcolor_5 celula color_0 pd20 tc b"}>{<CurrencyFormat value={resultado+this.state.fluxoSaldo} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                  ))}
              </Grid>
              </Grid>
              <Grid item xs={1} onClick={()=>this.moveCalendario(1)} className="bgcolor_6 tc cl br5">
              
                <NavigateNext className="mt90" fontSize="large"/>
              </Grid>
            </Grid>

          </div>
          
            

      <hr/>
      

      <Grid container spacing={1}>
      
      {this.state.meses.map((bar,objectId)=>(
        
        <Grid item xs={1}>


          
          <div className="bar bgcolor_6 br5" style={{backgroundImage: this.state.grBar[objectId]}}></div>
          <Tooltip title={"Receitas: R$ "+this.state.receitaTT[objectId]+'\nDespesas: R$ '+this.state.despesaTT[objectId]+'\nResultado: R$ '+Math.floor(this.state.receitaTT[objectId]-this.state.despesaTT[objectId])} placement="bottom" arrow TransitionComponent={Zoom}><div className="bar-mes mt20 mb20">{bar}</div></Tooltip>
          <div className="bar-line bgcolor_2" style={{marginTop: this.state.grRec[objectId]}}></div>
          
        </Grid>

      ))}
      
      

      </Grid>

        <hr/>
      <div className="sub-titulo mb20">Receitas de hoje</div>
      <Lista lista={1} abrirDataGrupo={()=>this.abrirDataGrupo()} abrirStatusGrupo={()=>this.abrirStatusGrupo()} abreJanela={(w)=>this.abreJanela(w)} infos={this.state.receitasHoje ? this.state.receitasHoje : ''}  check={this.state.check ? this.state.check : ''} toCheck={(w,all,lista)=>this.toCheck(w,all,lista)}  />

      <hr/>
      <div className="sub-titulo mb20">Despesas de hoje</div>

      <Lista lista={2} abrirDataGrupo={()=>this.abrirDataGrupo()} abrirStatusGrupo={()=>this.abrirStatusGrupo()} abreJanela={(w)=>this.abreJanela(w)} infos={this.state.despesasHoje ? this.state.despesasHoje : ''}  check={this.state.check ? this.state.check : ''} toCheck={(w,all,lista)=>this.toCheck(w,all,lista)}  />

      </div>
      :''}




        {this.state.page===1 ? 

          <div>

            
          
           
            <div className="mt30">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className="sub-titulo mb40">Receitas</div>
              </Grid>
              <Grid item xs={2}></Grid>
              
              <Grid item xs={2}>
              {this.state.usuario_id/1===3147 || this.state.usuario_id/1===23154 || this.state.usuario_id/1===62 ? 
              <Button
                  size="large"
                    onClick={(e)=>this.fecharMes()}
                    variant="contained"
                    fullWidth={true}
                    color={this.state.fechado ? "primary" : "secondary"}
                    style={{
                      height:"50px",
                    }}
                    
                    startIcon={this.state.fechado ? <Lock /> : <LockOpen/>}
                  >
                    {this.state.meses[this.state.mes_fechado-1]}
                  </Button>
                  :''
                  }
              </Grid>
              <Grid item xs={1}>
              {this.state.usuario_id/1===3147 || this.state.usuario_id/1===23154 || this.state.usuario_id/1===62 ? 
              <Button
                  size="large"
                    onClick={(e)=>this.abrirMes()}
                    variant="contained"
                    fullWidth={true}
                    color={this.state.fechado ? "primary" : "secondary"}
                    style={{
                      height:"50px",
                    }}
                    
                    startIcon={<LockOpen/>}
                  >
                    
                  </Button>
                  :''
                  }

              </Grid>
              <Grid item xs={2}>
                

                      <TextField autoComplete="off" variant="outlined" label="Busca PO" fullWidth={true}  placeholder={"PO"} onKeyDown={(e)=>this.buscaPOEnter(e)} onBlur={(e)=>this.buscaPO(e)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <SearchRounded/>
                          </InputAdornment>
                      ),
                    }}

                    />
                  
                
              </Grid>

              <Grid item xs={2}>
                  <Button
                  size="large"
                    onClick={(e)=>this.abreMenuData(e)}
                    variant="contained"
                    fullWidth={true}
                    style={{
                      backgroundColor: "#A456FF",
                      color:"white",
                      height:"50px",
                      

                  }}
                    
                    startIcon={<Today />}
                  >
                    {this.formatDate(this.state.selectionRange.startDate) + ' --- '+this.formatDate(this.state.selectionRange.endDate)}
                  </Button>
              </Grid>
            </Grid>
            </div>

            <Grid container spacing={0} className="bgcolor_7 br5 mb1">
                    <Grid item xs={2}>
                    <AutoComp empresa_id={this.state.empresa_id} sheet="users" tipo="fornecedor" label="Fornecedor" blur={(e)=>this.clearFornecedor(e)} change={(e)=>this.changeFilter(e,1)}/>
           
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                    <AutoCompSelect notSave={true} list={this.state.listPlanoContas} label={"Plano de contas"} blur={()=>this.void()} change={(e)=>this.changeFilter(e,2)}/>
           
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={1}>
                    <AutoCompSelect notSave={true} list={this.state.statusRec} label={"Status"} blur={()=>this.void()} change={(e)=>this.changeFilter(e,3)}/>
           
                    </Grid>
                </Grid>

            <Lista lista={0} tipo={this.state.page/1}  abrirDataGrupo={()=>this.abrirDataGrupo()} abrirStatusGrupo={()=>this.abrirStatusGrupo()} abreJanela={(w)=>this.abreJanela(w)} infos={this.state.cashflow ? this.state.cashflow : ''}  check={this.state.check ? this.state.check : ''} toCheck={(w,all,lista)=>this.toCheck(w,all,lista)}  />


          </div>

          : ''}


          {this.state.page===2 ? 

          <div>

            
          <Menu
                        
              anchorEl={this.state.anchorElData}
              keepMounted
              
              open={Boolean(this.state.anchorElData)}
              onClose={()=>this.fechaMenu()}
              >
                
                        <DateRange
                        
                        ranges={[this.state.selectionRange]}
                        onChange={(e)=>this.alteraRange(e)}
                        moveRangeOnFirstSelection={false}
                        rangeColors={['#A456FF']}
                        />
                  

            </Menu>
          
            <div className="mt30">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className="sub-titulo mb40">Despesas</div>
              </Grid>
              <Grid item xs={2}></Grid>
              
              <Grid item xs={2}>
              {this.state.usuario_id/1===3147 || this.state.usuario_id/1===23154 || this.state.usuario_id/1===62 ? 
              <Button
                  size="large"
                    onClick={(e)=>this.fecharMes()}
                    variant="contained"
                    fullWidth={true}
                    color={this.state.fechado ? "primary" : "secondary"}
                    style={{
                      height:"50px",
                    }}
                    
                    startIcon={this.state.fechado ? <Lock /> : <LockOpen/>}
                  >
                    {this.state.meses[this.state.mes_fechado-1]}
                  </Button>
                  :''
                  }
              </Grid>
              <Grid item xs={1}>
              {this.state.usuario_id/1===3147 || this.state.usuario_id/1===23154 || this.state.usuario_id/1===62 ? 
              <Button
                  size="large"
                    onClick={(e)=>this.abrirMes()}
                    variant="contained"
                    fullWidth={true}
                    color={this.state.fechado ? "primary" : "secondary"}
                    style={{
                      height:"50px",
                    }}
                    
                    startIcon={<LockOpen/>}
                  >
                    
                  </Button>
                  :''
                  }

              </Grid>
              <Grid item xs={2}><TextField autoComplete="off" variant="outlined" label="Busca PO" fullWidth={true}  placeholder={"PO"} onKeyDown={(e)=>this.buscaPOEnter(e)} onBlur={(e)=>this.buscaPO(e)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <SearchRounded/>
                          </InputAdornment>
                      ),
                    }}

                    /></Grid>
              <Grid item xs={2}>
                  <Button
                  size="large"
                    onClick={(e)=>this.abreMenuData(e)}
                    variant="contained"
                    fullWidth={true}
                    style={{
                      backgroundColor: "#A456FF",
                      color:"white",
                      height:"50px",
                      

                  }}
                    
                    startIcon={<Today />}
                  >
                    {this.formatDate(this.state.selectionRange.startDate) + ' --- '+this.formatDate(this.state.selectionRange.endDate)}
                  </Button>
              </Grid>
            </Grid>
            </div>

            <Grid container spacing={0} className="bgcolor_7 br5 mb1">
                    <Grid item xs={2}>
                    <AutoComp empresa_id={this.state.empresa_id} sheet="users" tipo="fornecedor" label="Fornecedor" blur={()=>this.void()} change={(e)=>this.changeFilter(e,1)}/>
          
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                    <AutoCompSelect notSave={true} list={this.state.listPlanoContas} label={"Plano de contas"} blur={()=>this.void()} change={(e)=>this.changeFilter(e,2)}/>
          
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                    <AutoCompSelect notSave={true} list={this.state.status} label={"Status"} blur={()=>this.void()} change={(e)=>this.changeFilter(e,3)}/>
                    </Grid>
                </Grid>

            <Lista lista={0} filterStatus={this.state.filterStatus} tipo={this.state.page/1} baixarCnab={()=>this.baixarCnab()} abrirDataGrupo={()=>this.abrirDataGrupo()} abrirStatusGrupo={()=>this.abrirStatusGrupo()} abreJanela={(w)=>this.abreJanela(w)} infos={this.state.cashflow ? this.state.cashflow : ''}  check={this.state.check ? this.state.check : ''} toCheck={(w,all,lista)=>this.toCheck(w,all,lista)}  />


          </div>

          : ''}


        {this.state.page===4 ?
        <div>
        <div className="mt30">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className="sub-titulo mt10 mb20">Report</div>
              </Grid>
              <Grid item xs={2}><div className="sub-titulo mt10 mb20">Conta bancária</div></Grid>
              <Grid item xs={3}>
                  <MySelect list={this.state.listaContas ? this.state.listaContas : []} initValue={this.state.contaBancaria} label="Conta bancária" change={(e)=>this.changeConta(e)}/>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                  <Button
                  size="large"
                    onClick={(e)=>this.abreMenuData(e)}
                    variant="contained"
                    fullWidth={true}
                    style={{
                      backgroundColor: "#A456FF",
                      color:"white",
                      height:"50px",
                      

                  }}
                    
                    startIcon={<Today />}
                  >
                    {this.formatDate(this.state.selectionRange.startDate) + ' --- '+this.formatDate(this.state.selectionRange.endDate)}
                  </Button>
              </Grid>
            </Grid>
            </div>

          <ListaFluxo abrirDataGrupo={()=>this.abrirDataGrupo()} abrirStatusGrupo={()=>this.abrirStatusGrupo()} abreJanela={(w)=>this.abreJanela(w)} infos={this.state.cashflowReportConta ? this.state.cashflowReportConta : ''} saldo={this.state.cashflowReportSaldo ? this.state.cashflowReportSaldo : ''}/>

          </div>

      : ''}



{this.state.page===5 ?
        <div>
        <div className="mt30">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className="sub-titulo mt10 mb20">Projetos</div>
              </Grid>
              
              <Grid item xs={3}>
              <AutoComp empresa_id={this.state.empresa_id} sheet="projeto" tipo="projeto" label="Selecione o projeto" blur={(e)=>this.clearProjetos(e)} change={(e)=>this.changeProjetos(e)}/>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                  <Button
                  size="large"
                    onClick={(e)=>this.abreMenuData(e)}
                    variant="contained"
                    fullWidth={true}
                    style={{
                      backgroundColor: "#A456FF",
                      color:"white",
                      height:"50px",
                      
                  }}
                    
                    startIcon={<Today />}
                  >
                    {this.formatDate(this.state.selectionRange.startDate) + ' --- '+this.formatDate(this.state.selectionRange.endDate)}
                  </Button>
              </Grid>
            </Grid>
            </div>

          <ListaProjetos hide_impostos={true} abrirDataGrupo={()=>this.abrirDataGrupo()} abrirStatusGrupo={()=>this.abrirStatusGrupo()} abreJanela={(w)=>this.abreJanela(w)} impostos={this.state.cashflowReportProjetoImpostos ? this.state.cashflowReportProjetoImpostos : ''} entradas={this.state.cashflowReportProjetoEntradas ? this.state.cashflowReportProjetoEntradas : ''}  infos={this.state.cashflowReportProjeto ? this.state.cashflowReportProjeto : '' } out={0}/>

          </div>

        : ''}



        {this.state.page===6 ?
        <div>
        <div className="mt30">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className="sub-titulo mt10 mb20">Unidades</div>
              </Grid>
              
              <Grid item xs={2}>
                  <MySelect list={this.state.listUnidades ? this.state.listUnidades : []} initValue={this.state.unidade} label="Unidades" change={(e)=>this.changeUnidade(e)}/>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={2}>
                  <Button
                  size="large"
                    onClick={(e)=>this.abreMenuData(e)}
                    variant="contained"
                    fullWidth={true}
                    style={{
                      backgroundColor: "#A456FF",
                      color:"white",
                      height:"50px",
                      

                  }}
                    
                    startIcon={<Today />}
                  >
                    {this.formatDate(this.state.selectionRange.startDate) + ' --- '+this.formatDate(this.state.selectionRange.endDate)}
                  </Button>
              </Grid>
            </Grid>
            </div>

          <ListaFluxo abrirDataGrupo={()=>this.abrirDataGrupo()} abrirStatusGrupo={()=>this.abrirStatusGrupo()} abreJanela={(w)=>this.abreJanela(w)} infos={this.state.cashflowReportUnidade ? this.state.cashflowReportUnidade : ''}/>

          </div>

        : ''}



{this.state.page===7 ?
        <div>
        <div className="mt30">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className="sub-titulo mt10 mb20">Comissões de projetos</div>
              </Grid>
              
              <Grid item xs={2}>
                 
                  <MySelect list={this.state.listUsers ? this.state.listUsers : []} initValue={this.state.userComissao} label="Recurso" change={(e)=>this.changeUserComissao(e)}/>
    
              </Grid>

              <Grid item xs={2}>
              
              <MySelect list={this.state.statusCom ? this.state.statusCom : []} initValue={this.state.statusComissao} label="Status" change={(e)=>this.changeStatusComissao(e)}/>
    
                  </Grid>

              <Grid item xs={3}></Grid>
              <Grid item xs={2}>
                  <Button
                  size="large"
                    onClick={(e)=>this.abreMenuData(e)}
                    variant="contained"
                    fullWidth={true}
                    style={{
                      backgroundColor: "#A456FF",
                      color:"white",
                      height:"50px",
                      

                  }}
                    
                    startIcon={<Today />}
                  >
                    {this.formatDate(this.state.selectionRange.startDate) + ' --- '+this.formatDate(this.state.selectionRange.endDate)}
                  </Button>
              </Grid>
            </Grid>
            </div>

          <ListaComissoes abrirDataGrupo={()=>this.abrirDataGrupo()} abrirStatusComissoes={(w)=>this.abrirStatusComissoes(w)} abreJanela={(w)=>this.abreJanela(w)} infos={this.state.cashflowReportComissao ? this.state.cashflowReportComissao : ''} cost={this.state.cashflowReportComissaoCost ? this.state.cashflowReportComissaoCost : ''}/>

          </div>

        : ''}




{this.state.page===8 ?
        <div>
        <div className="mt30">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className="sub-titulo mt10 mb20">Recurso</div>
              </Grid>
              <Grid item xs={2}><div className="sub-titulo mt10 mb20">Horas extras</div></Grid>
              <Grid item xs={2}>
                 
                  <MySelect list={this.state.listUsers ? this.state.listUsers : []} initValue={this.state.userComissao} label="Recurso" change={(e)=>this.changeUserHoras(e)}/>
    
              </Grid>

              <Grid item xs={2}>
              
              <MySelect list={this.state.statusCom ? this.state.statusCom : []} initValue={this.state.statusComissao} label="Status" change={(e)=>this.changeStatusHoras(e)}/>
    
                  </Grid>

              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                  <Button
                  size="large"
                    onClick={(e)=>this.abreMenuData(e)}
                    variant="contained"
                    fullWidth={true}
                    style={{
                      backgroundColor: "#A456FF",
                      color:"white",
                      height:"50px",
                      

                  }}
                    
                    startIcon={<Today />}
                  >
                    {this.formatDate(this.state.selectionRange.startDate) + ' --- '+this.formatDate(this.state.selectionRange.endDate)}
                  </Button>
              </Grid>
            </Grid>
            </div>

          
            {this.state.reportHoras ? this.state.reportHoras.map((lines,objectId)=>(
                            <Grid key={objectId} container spacing={0} className="bgcolor_6 br5 mb1 cl" onClick={(w)=>this.abrirStatusHoras(lines.id)}>
                                <Grid item xs={3}>
                                <div className="pd20 eli">{lines.projeto}</div>
                                </Grid>
                                <Grid item xs={1} className="tc" ><div className="bgcolor_7 pd20 eli">{lines.horas}</div></Grid>
                                <Grid item xs={2}><div className={lines.valor<0 ? "bgcolor_6 color_5 pd20 tc" : "bgcolor_6 pd20  tc"} ><CurrencyFormat value={lines.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                <Grid item xs={2} className="tc" ><div className="bgcolor_7 pd20">{this.formatDate(lines.dia)}</div></Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={1}><div className="mt5 pd20 tr" ><div className={"bar-status br5 r "+(this.state.colorStatus[lines.horas_status/1])}></div></div></Grid>
                             </Grid>
                    )):''}

{this.state.reportHoras ?
                <div>
                    
                    <Grid container spacing={0} className="bgcolor_1 br5 mb1">
                        <Grid item xs={3}><div className="pd20">Total</div></Grid>
                        <Grid item xs={1}><div className="pd20 b tc">{this.state.reportHoras ? this.state.reportHoras.reduce((a,v) =>  a = a + (v.horas/1) , 0 ) : 0}</div></Grid>
                       
                        <Grid item xs={2}><div className="pd20 b tc"><CurrencyFormat value={this.state.reportHoras ? this.state.reportHoras.reduce((a,v) =>  a = a + (v.valor/1) , 0 ) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </div>
: ''}

          </div>

        : ''}




{this.state.page===9 ?
        <div>
        <div className="mt30">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className="sub-titulo mt10 mb20">Exportar para excel</div>
              </Grid>
              
              <Grid item xs={2}>
                 
                  <MySelect list={this.state.listaContas ? this.state.listaContas : []} initValue={0} label="Conta bancária" change={(e)=>this.changeContaExcel(e)}/>
    
              </Grid>

              

                  <Grid item xs={2}>
                 
                    <MySelect list={this.state.selectDataXLS} initValue={0} label="Data" change={(e)=>this.changeDataExcel(e)}/>
      
                </Grid>
                
              <Grid item xs={1}>
                <div className="mt5 flex">
                  <Switch checked={this.state.todasEmpresas} onChange={()=>this.changeEmpresas()} />
                  <div className="mt10">Todas as empresas</div>  
                </div>
              
              </Grid>
              <Grid item xs={2}>
              
              
              <Button
                  size="large"
                    onClick={()=>this.downloadExcel()}
                    variant="contained"
                    fullWidth={true}
                    color="secondary"
                    style={{
                      align: "left",
                      height:"50px",
                      justifyContent: "flex-start"

                  }}
                    
                    startIcon={<GetApp />}
                  > Download </Button>


              </Grid>

              <Grid item xs={2}>
                  <Button
                  size="large"
                    onClick={(e)=>this.abreMenuData(e)}
                    variant="contained"
                    fullWidth={true}
                    style={{
                      backgroundColor: "#A456FF",
                      color:"white",
                      height:"50px",
                      

                  }}
                    
                    startIcon={<Today />}
                  >
                    {this.formatDate(this.state.selectionRange.startDate) + ' --- '+this.formatDate(this.state.selectionRange.endDate)}
                  </Button>
              </Grid>
            </Grid>
            </div>

          <ListaComissoes abrirDataGrupo={()=>this.abrirDataGrupo()} abrirStatusComissoes={(w)=>this.abrirStatusComissoes(w)} abreJanela={(w)=>this.abreJanela(w)} infos={this.state.cashflowReportComissao ? this.state.cashflowReportComissao : ''}/>

          </div>

        : ''}


{this.state.page===10 ?
        <div>
        <div className="mt30">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className="sub-titulo mt10 mb20">Verbas</div>
              </Grid>
              
              <Grid item xs={3}>
              <MySelect list={this.state.abertoFechado} initValue={0} label="Status da verba" change={(e)=>this.changeAbertoFechado(e)}/>
              </Grid>
              <Grid item xs={2}>
              <Button
                  size="large"
                    onClick={()=>this.downloadExcelVerbas()}
                    variant="contained"
                    fullWidth={true}
                    color="secondary"
                    style={{
                      align: "left",
                      height:"50px",
                      justifyContent: "flex-start"

                  }}
                    
                    startIcon={<GetApp />}
                  > XLS Verbas </Button>
              </Grid>
              <Grid item xs={2}>
              <Button
                  size="large"
                    onClick={()=>this.downloadExcelRecibos()}
                    variant="contained"
                    fullWidth={true}
                    color="secondary"
                    style={{
                      align: "left",
                      height:"50px",
                      justifyContent: "flex-start"

                  }}
                    
                    startIcon={<GetApp />}
                  > XLS Recibos </Button>
              </Grid>
              <Grid item xs={2}>
                  <Button
                  size="large"
                    onClick={(e)=>this.abreMenuData(e)}
                    variant="contained"
                    fullWidth={true}
                    style={{
                      backgroundColor: "#A456FF",
                      color:"white",
                      height:"50px",
                      
                  }}
                    
                    startIcon={<Today />}
                  >
                    {this.formatDate(this.state.selectionRange.startDate) + ' --- '+this.formatDate(this.state.selectionRange.endDate)}
                  </Button>
              </Grid>
            </Grid>
            </div>

            {this.state.listVerbas ? this.state.listVerbas.map((verba,objectId)=>(
                  
                  <div className="line-budget mb5 wp100">
                      <Grid key={objectId} container spacing={1}>

                            <Grid item xs={2} className="bgcolor_7 tc"><div className="ff-ro">{verba.empresa}</div></Grid>
                            <Grid item xs={3} className="bgcolor_8 tc"><div className="ff-ro">{verba.plano}</div></Grid>
                            <Grid item xs={2} className="bgcolor_7 tc"><div className="ff-ro">{<CurrencyFormat value={verba.valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                            <Grid item xs={2} className="bgcolor_8 tc"><div className="ff-ro">{verba.nome}</div></Grid>
                            <Grid item xs={1} className="bgcolor_7 tc"><div className="ff-ro" >{this.formatDate(verba.dt)}</div></Grid>
                            <Grid item xs={1} className="bgcolor_8 tc"><div className="ff-ro">{verba.status}</div></Grid>
                            {verba.link? 
                            <Grid item xs={1} className="bgcolor_7 tc">{verba.link ? <IconButton target="blank" href={"/verbas/"+verba.link}><Payment/></IconButton> : <IconButton target="blank" href={"/prestacao/"+verba.id+'_'+verba.projeto_id}><Dvr/></IconButton> } {this.state.permission/1<2 ? <Tooltip title={verba.status==='Em aberto' ? "Travar verba" : "Liberar"} placement="top" arrow TransitionComponent={Zoom}><IconButton target="blank" color="secundary">{verba.status==='Em aberto' ? <LockOpen onClick={()=>this.travarVerba(verba.id)}/> : <Lock onClick={()=>this.liberarVerba(verba.id)}/>}</IconButton></Tooltip>: ''}</Grid>
                          :
                            <Grid item xs={1} className="bgcolor_7 tc">{verba.access/1===1 ? <IconButton target="blank"><HourglassTop/></IconButton> : <IconButton target="blank" href={"/prestacao/"+verba.id+'_'+verba.projeto_id}><Dvr/></IconButton> } {this.state.verbaPermission/1===4 ? <Tooltip title="Liberar verba" placement="top" arrow TransitionComponent={Zoom}><IconButton target="blank" onClick={()=>this.liberarVerba(verba.id)} color="secundary"><Lock/></IconButton></Tooltip> : ''}</Grid>
                          }
                            </Grid>
                  </div>

                )):''}

          </div>

        : ''}


        {this.state.page===11 ?
            <div>
                <div className="mt30">
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <div className="sub-titulo mt10 mb20">Folha de pagamento</div>
                      </Grid>
                    </Grid>
                </div>

                {this.state.empresas_salarios ? this.state.empresas_salarios.map((empresas,objectId)=>(
                  
                  <div className="line-budget mb5 wp100">
                    <div className="mini-titulo mt20 mb20">{empresas.nome}</div>

                    
                    {this.state.salarios[objectId].map((salarios,o)=>(
                       <div className="line-budget mb5 wp100">
                        <Grid key={objectId} container spacing={1}>
                            <Grid item xs={6} className="bgcolor_7"><div className="ff-ro">{salarios.nome}</div></Grid>
                            <Grid item xs={1} className="bgcolor_8"><div className="ff-ro">Gatilho</div></Grid>
                            <Grid item xs={2} className="bgcolor_7 tc"><div className="ff-ro">{<CurrencyFormat value={salarios.variavel}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                            <Grid item xs={1} className="bgcolor_8"><div className="ff-ro">Salário</div></Grid>
                            <Grid item xs={2} className="bgcolor_7 tc"><div className="ff-ro">{<CurrencyFormat value={salarios.salario}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                        </Grid>
                      </div>

                      ))}
                            
                  </div>

                )):''}


            </div>
        :''}

      </div>
    
      
   
      </div>

     










      <Dialog
                open={this.state.dataOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              
              >
                <DialogTitle>{"Alterar Data em grupo"}</DialogTitle>
                <DialogContent>

                    <MyDatePicker  
                    minDate={this.state.mes_fechado ? this.state.mes_inicial : '2020,0,1'}
                    onChange={(e)=>this.changeDataGrupo(e)} view={["year", "month","date"]} format="dd/MM/yyyy"  label="Data combinada"/>
                  
                </DialogContent>
                <DialogActions>
                  <div className="bt w10 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  <div className="bt w10 bgcolor_1" onClick={()=>this.salvaDataGrupo()} color="secondary">
                    Definir
                  </div>
                </DialogActions>
      </Dialog>



      <Dialog
                open={this.state.statusOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              
              >
                <DialogTitle>{"Alterar Status em grupo"}</DialogTitle>
                <DialogContent>

                <MySelect list={this.state.statusRedux} label="Status" change={(e)=>this.changeStatusGrupo(e)}/>
                  
                </DialogContent>
                <DialogActions>
                  <div className="bt w10 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  <div className="bt w10 bgcolor_1" onClick={()=>this.salvaStatusGrupo()} color="secondary">
                    Definir
                  </div>
                </DialogActions>
      </Dialog>


      <Dialog
                open={this.state.statusComissoesOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              
              >
                <DialogTitle>{"Alterar Status da Comissão"}</DialogTitle>
                <DialogContent>

                <MySelect list={this.state.statusCom} label="Status" change={(e)=>this.changeStatusCom(e)}/>
                  
                </DialogContent>
                <DialogActions>
                  <div className="bt w10 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  <div className="bt w10 bgcolor_1" onClick={()=>this.salvaStatusComissoes()} color="secondary">
                    Definir
                  </div>
                </DialogActions>
      </Dialog>

      <Dialog
                open={this.state.statusHorasOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              
              >
                <DialogTitle>{"Alterar Status das Horas"}</DialogTitle>
                <DialogContent>

                <MySelect list={this.state.statusCom} label="Status" change={(e)=>this.changeStatusHorasSave(e)}/>
                  
                </DialogContent>
                <DialogActions>
                  <div className="bt w10 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  <div className="bt w10 bgcolor_1" onClick={()=>this.salvaStatusHoras()} color="secondary">
                    Definir
                  </div>
                </DialogActions>
      </Dialog>


      <Dialog
                open={this.state.transferenciaOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              
              >
                <DialogTitle>{"Transferência entre unidades"}</DialogTitle>
                <DialogContent>
                <div className="mb10">
                <MySelect list={this.state.listUnidades1} label="Unidade Paga" change={(e)=>this.changeJanelaTransfer(e,0)}/>
                </div>
                <div className="mb10">
                <MySelect list={this.state.listUnidades2} label="Unidade Recebe" change={(e)=>this.changeJanelaTransfer(e,1)}/>
                </div>
                <div className="mb10">
                <CurrencyTextField
                  
                    fullWidth={true}
                    label="Valor"
                    currencySymbol="R$"
                    outputformat="number"
                    onBlur={e => {
                      this.changeJanelaTransfer(e.target.value,2)
                    }}
                    defaultValue={0}
                    variant="outlined"
                  />
                  </div>
                <div className="mb10">
                  <MyDatePicker onChange={(e)=>this.changeJanelaTransfer(e,4)} view={["year", "month","date"]} format="dd/MM/yyyy"  label="Data da transferencia"/>
                  
                </div>

                <div className="mb10">
                <TextField
                            autoComplete="off"
                            fullWidth={true}
                            label="Observação"
                            onBlur={e => {
                            this.changeJanelaTransfer(e.target.value,3)
                            }}
                        
                            defaultValue={''}
                            variant="outlined"
                        />
                </div>

                </DialogContent>
                <DialogActions>
                  <div className="bt w10 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  <div className="bt w10 bgcolor_1" onClick={()=>this.enviaTransferencia()} color="secondary">
                    Salvar
                  </div>
                </DialogActions>
      </Dialog>


      {this.state.poId!=='n' ? <Janela empresa_id={this.state.empresa_id} mes_fechado={this.state.mes_fechado} janelaOpen={this.state.janelaOpen} mudaEmpresa={(w)=>this.mudaEmpresa(w)} grupo={this.state.listEmpresasGrupo} fechaJanela={()=>this.fechaJanela()} salvaJanela={(w)=>this.salvaJanela(w)} contas={this.state.listaContas} projetos={this.state.listProjetos} plano_contas={this.state.listPlanoContas} unidades={this.state.listUnidades} po={this.state.poId}/> : ''}
    
      <Menu
                        
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        open={Boolean(this.state.anchorEl)}
                        onClose={()=>this.fechaMenu()}
                        >
                            <StyledMenuItem><ListItemText primary="Selecione" /></StyledMenuItem>
                            <StyledMenuItem onClick={()=>this.abreJanela('r')}><ListItemIcon><ArrowBack fontSize="small" /></ListItemIcon><ListItemText primary="Nova receita" /></StyledMenuItem>
                            <StyledMenuItem onClick={()=>this.abreJanela('d')}><ListItemIcon><ArrowForward fontSize="small" /></ListItemIcon><ListItemText primary="Nova despesa" /></StyledMenuItem>
                            <StyledMenuItem onClick={()=>this.abreJanelaTransfer()}><ListItemIcon><ArrowForward fontSize="small" /></ListItemIcon><ListItemText primary="Transferencia entre unidades" /></StyledMenuItem>

      </Menu>

      <Menu
                        
                        anchorEl={this.state.anchorElData}
                        keepMounted
                       
                        open={Boolean(this.state.anchorElData)}
                        onClose={()=>this.fechaMenu()}
                        >
                          
                                 <DateRange
                                 
                                 ranges={[this.state.selectionRange]}
                                 onChange={(e)=>this.alteraRange(e)}
                                 moveRangeOnFirstSelection={false}
                                 rangeColors={['#A456FF']}
                                 />
                          
         
      </Menu>

      <Menu
                        
                        anchorEl={this.state.anchorElReport}
                        keepMounted
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        open={Boolean(this.state.anchorElReport)}
                        onClose={()=>this.fechaMenu()}
                        >
                            <StyledMenuItem onClick={()=>this.abreJanela('r')}><ListItemText primary="Selecione" /></StyledMenuItem>
                            
                            {this.state.reportMenu ? this.state.reportMenu.map((report,objectId)=>(
                              
                              <StyledMenuItem key={objectId} onClick={()=>this.navCashflow(report.value)}><ListItemText primary={report.label} /></StyledMenuItem>
                            
                            )):'' }
                            

      </Menu>

      


    </ThemeProvider>
  )
}

}
  
 