import React, { Component} from "react";
import Grid from '@material-ui/core/Grid';
import {AutoComp, MySelect} from '../../components/textField';
import TextField from '@mui/material/TextField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import './style.css';
import Meta from "../../components/meta";
import { Button } from "@material-ui/core";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import axios from "axios";
import { BarLoader } from "react-spinners";
import CurrencyFormat from "react-currency-format";


const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  overrides: {
      MuiTooltip: {
          tooltip: {
            fontSize: "11px",
            backgroundColor:'#666'
      }},
      MuiIconButton: {
        root: {
            color:'white',
          '&:hover': {
            color: '#4aedc4',
          }
        }
      }
    }

}
  
)
  


export default class Viewsheet extends Component {


  state = {
    empresa_id: localStorage['empresa_id'],
    usuario_id: localStorage['usuario_id'],
    nome: localStorage['nome'],
    acessoNegado:true,
    motivos:[{"value":"110","label":"Taxi/Uber"},{"value":"111","label":"Estacionamento"},{"value":"112","label":"Restaurantes"},{"value":"113","label":"Viagens"},{"value":"114","label":"Presentes"},{"value":"34","label":"Estrutura"},{"value":"49","label":"Softwares"}],
    colorStatus:['bgcolor_5s','bgcolor_2'],
    novoValor:false,
    valorAdicional:0,
    valorTotal:0,
    imgCount:[]
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  // MOUTH
  async componentDidMount(){

    if(this.state.usuario_id){

      this.setState({

        userComissao:this.state.usuario_id,
        nome:this.state.nome,
        acessoNegado:false,

      })
      setTimeout(() => {
        this.reportReembolso()
      }, 10);
      
    }
  }

  //  SALVER REEMBOLSO

  enviarReembolso = () => {
    
    this.toLoad()
    if(this.state.plano_conta && this.state.observacao){
     api.get('insert/reembolso.php?plano_conta='+this.state.plano_conta+'&usuario_id='+this.state.userComissao+'&empresa_id='+this.state.empresa_id+'&valor='+this.state.valorTotal+'&observacao='+this.state.observacao+'&projeto_id='+this.state.projeto_id+'&img='+this.state.img+'&r='+Math.random(10000)).then(result => {
   
       if(result.status===200){
      
        
        window.location.reload();
 
         this.endLoad()
       }})
    }else{
      alert('Você precisa preencher todos os campos!')
    }
 
     
   }

//  REPORT REEMBOLSO

reportReembolso = () => {
  
    this.toLoad()
    
     api.get('get/cashflowReport.php?tipo=8&user='+this.state.userComissao+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
   
       if(result.status===200){
 
         this.setState({
 
           cashflowReportComissao: result.data.linhas,
           
         })
 
         this.endLoad()
       }})
 
 
     
   }


// UPDATE ACESSO 

setEmailAcesso = (e) => {
  
    this.setState({emailAcesso:e.target.value})
  }

// UPDATE ACESSO 

setSenhaAcesso = (e) => {
  
    this.setState({senhaAcesso:e.target.value})
  }
  

// ACESSAR

acessar = () => {

  api.get('get/auth.php?email='+this.state.emailAcesso+'&senha='+this.state.senhaAcesso+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
       
        if(result.data.split('|')[0]){
            if(result.data[0]['projeto_id']===this.state.id){
                this.setState({
                    acessoNegado:false,
                    empresa_id:result.data.split('|')[0],
                    nome:result.data.split('|')[6],
                    userComissao: result.data.split('|')[3]
                })

                this.reportReembolso();
            }
        }

      }})


}

// FOTO

fileSelect = (event) => {

  
    if(!this.state.img){

      
    // PRIMEIRA IMAGEM
    this.setState({ uploading: true }); 

    const fd = new FormData();
    fd.append('image',event.target.files[0],event.target.files[0].name)
    axios.post('https://treatments.run/landscape/upload.php?id=0&sheet=no&r='+Math.random(10000),fd).then(res => {
    
    this.fileInput = false
        this.setState({ 
            uploading: false,
            img: res.data,
            valorTotal:this.state.valor,
            imgCount:[]
            }); 

            
    })
  
  
  }else{

    // OUTRAS

    if(this.state.novoValor){

     
        
      

    this.setState({ uploading: true }); 

    const fd = new FormData();
    fd.append('image',event.target.files[0],event.target.files[0].name)
    axios.post('https://treatments.run/landscape/upload.php?id=0&sheet=no&r='+Math.random(10000),fd).then(res => {
    
      let imgCount = this.state.imgCount
      imgCount.push(1)

        this.setState({ 
            uploading: false,
            img: this.state.img+','+res.data,
            imgCount:imgCount,
            valorTotal:this.state.valorTotal/1+this.state.valorAdicional/1
            }); 

            
    })

    

    }else{
      alert('O valor acima precisa ser preenchido!')
    }

  }
}


      // FORMATE DATE

      formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    }


    // UPDATE ACESSO 

    upReembolso = (e,w) => {
  
        if(w===0){
            this.setState({valor:e})
        }

        if(w===1){
            this.setState({projeto_id:e})
        }

        if(w===2){
            this.setState({plano_conta:e})
        }
        if(w===3){
            this.setState({observacao:e})
        }
    
    }


    // ADICIONAL

    addReembolso = (e) => {
      this.setState({
        valorAdicional:e,
        
      })
      if(e){
        this.setState({
          novoValor:true,
        })
      }
    }

    

// VOID

void = () => {}

  render(){

  return (

    
    <ThemeProvider theme={theme}>
       
      <Meta title="Reembolso"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo">
      <Grid container spacing={3}>
      <Grid item xs={12}><div className="titulo">Reembolsos</div></Grid>
      <Grid item xs={12}><div className="sub-titulo">{this.state.nome}</div></Grid>
      <input type="file" name="the_file" onChange={this.fileSelect} style={{display:'none'}} ref={fileInput => this.fileInput = fileInput}></input>
      <Grid item xs={12}>
      <CurrencyTextField
                    fullWidth={true}
                    
                    label="Valor"
                    currencySymbol="R$"
                    outputformat="number"
                    onBlur={e => {
                      this.upReembolso(e.target.value,0)
                    }}
                   
                    defaultValue={0}
                    variant="outlined"
                  />

        </Grid>
      <Grid item xs={12}>
        <AutoComp empresa_id={this.state.empresa_id} sheet="projeto" tipo="projeto_aberto" label="Busque pelo projeto" blur={()=>this.void()} change={(e)=>this.upReembolso(e,1)}/>
      </Grid>
      
        <Grid item xs={12}>
            <MySelect list={this.state.motivos} initValue={0} label="Motivo" change={(e)=>this.upReembolso(e,2)}/>
        </Grid>
        <Grid item xs={12}>
            <TextField variant="outlined" fullWidth={true}  label="Descrição" onBlur={(e)=>this.upReembolso(e.target.value,3)} ></TextField>
        </Grid>

        <Grid item xs={12}>
          {!this.state.img ?
        <Button
                  size="large"
                    onClick={()=>this.fileInput.click()}
                    variant="contained"
                    fullWidth={true}
                    color="secondary"
                    style={{
                      align: "left",
                      height:"50px",
                      

                  }}
                    
                    startIcon={this.state.uploading ? 
                        <BarLoader
                            
                        size={30}
                        color={"white"}
                        loading={this.state.uploading}
                        />
                        : <PhotoCamera/>}
                  > Tirar foto da nota </Button>
                  :
                  
                  <div className="p10 sub-titulo tc w100">Upload concluído!</div>}

        </Grid>

        <div className="p10 micro-titulo tc w100">
          {this.state.imgCount.map((img,obj) => (
            <div className="mt5" key={obj}>Nova NF adicionada!</div>
          ))}
        </div>

        <Grid iten xs={12}>

                  {this.state.img ? 

                  <div className="mt10 mb10">
                    <CurrencyTextField
                    fullWidth={true}
                    label="Valor"
                    currencySymbol="R$"
                    outputformat="number"
                    onBlur={e => {
                      this.addReembolso(e.target.value)
                    }}
                   
                    defaultValue={0}
                    variant="outlined"
                  /></div>
                  :''  
                  
                }
                

                  {this.state.img ? 
                  <Button
                        size="large"
                        onClick={()=>this.fileInput.click()}
                        variant="contained"
                        fullWidth={true}
                        color="secondary"
                        style={{
                          align: "left",
                          height:"50px",
                      

                  }}><PhotoCamera/> {this.state.uploading ? 
                    <BarLoader
                        
                    size={30}
                    color={"white"}
                    loading={true}
                    /> : 'Adicionar NF complementar'
                    
                    }
                    </Button> 
                  : ''}

                 
        </Grid>

        <Grid item xs={12}>
            <div className="bt large bgcolor_1" onClick={()=>this.enviarReembolso()} color="secondary">
                Enviar {<CurrencyFormat value={this.state.valorTotal} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />} para reembolsos
            </div>
        </Grid>
            
             
          </Grid>
          

          <div className="mt10 mb5">
                  <Grid container spacing={0} className={"bgcolor_8 mt20 br5"}>
                      
                  </Grid>
          </div>


          <Grid item xs={12}><div className="titulo mt50 mb30">Em aberto</div></Grid>
          
          <div>
        {this.state.cashflowReportComissao ? this.state.cashflowReportComissao.map((lines,objectId) => (
          <Grid key={objectId} container spacing={0} className="bgcolor_6 br5 mb1 cl">
                                <Grid item xs={3}>
                                <div className="pd20 eli">{lines.nome}</div>
                                </Grid>
                                <Grid item xs={1}><div className={lines.valor<0 ? "bgcolor_7 color_5 pd20 tc" : "bgcolor_7 pd20 tc"} ><CurrencyFormat value={lines.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                <Grid item xs={1} className="tc" ><div className="bgcolor_6 pd20">{this.formatDate(lines.dt_competencia)}</div></Grid>
                                <Grid item xs={2} className="tc" ><div className="bgcolor_7 pd20 eli">{lines.plano_conta}</div></Grid>
                                <Grid item xs={4}><div className="bgcolor_6 pd20 eli" >{lines.comentario}</div></Grid>
                                <Grid item xs={1}><div className="mt5 pd20 tr" ><div className={"bar-status br5 r "+(this.state.colorStatus[lines.comissao_status/1])}></div></div></Grid>
                             </Grid>

        )):''}
          </div>

        

          </div>
          <div>
            {this.state.acessoNegado===true ? 
          <div className="full bgcolor_6">
            <div className="center w80">
              
              <Grid container spacing={1}>
                    
                    <Grid item xs={12}>
                      <TextField variant="outlined" fullWidth={true}  label="E-mail" onBlur={(e)=>this.setEmailAcesso(e)} ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField variant="outlined" type="password" fullWidth={true}  label="Senha" onBlur={(e)=>this.setSenhaAcesso(e)} ></TextField>
                    </Grid>

                    <Grid item xs={12}>
                    <div className="bt large bgcolor_1" onClick={()=>this.acessar()} color="secondary">
                    Entrar
                  </div>
                  </Grid>

              </Grid>
             </div>
          </div>
          : ''}
</div>

    </ThemeProvider>
  )


}
}
  