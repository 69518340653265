import { Grid, ThemeProvider, createTheme, TextField, IconButton } from '@material-ui/core'
import React, { Component } from 'react'
import {deepPurple,teal} from '@material-ui/core/colors';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { AutoComp, MySelect, MyDatePicker } from '../../components/textField';
import { Delete } from "@material-ui/icons";
import CurrencyFormat from "react-currency-format";
import api from '../../services/api';


const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },
   
    })
    

export default class Bloco extends Component {
    
    constructor(props){
        
        super()

    }


    state = {
        origem:[
            {
                value:0,
                label:'Agência'
            },
            {
                value:1,
                label:'Direção'
            },
            {
                value:2,
                label:'Direção de fotografia'
            },
            {
                value:3,
                label:'Direção de arte'
            },
            {
                value:4,
                label:'Produção'
            },
            {
                value:5,
                label:'Motivos maiores'
            },
            
        ],
        status:[
            {
                value:0,
                label:'Aguardando OK'
            },
            {
                value:1,
                label:'Aprovado pelo Landscape'
            },
            {
                value:2,
                label:'Aprovado pelo Produtor Executivo'
            },
            {
                value:3,
                label:'Aprovado pelo Diretor'
            },
            {
                value:4,
                label:'Acomodado no budget'
            },
            {
                value:5,
                label:'Pago pelo cliente'
            },
            {
                value:6,
                label:'Reprovado'
            }
        ],
        track:[],
        trackToday:0,
        impactoPrevisto:0,
        impactoAprovado:0,
        sent:false
        
    }

    componentDidMount(){

        this.upImpacto()

        console.log(this.props.track)
        let track = JSON.parse(this.props.track);
        
        var today = new Date();

        today.setHours(today.getHours() - 3);

        today = today.toISOString().split('T')[0];

        let trackToday = false;

        if(today === this.props.report_date){
            trackToday = true;
        }

        this.setState({
            track:track,
            trackToday:trackToday
        })

        
       

    }

    changeTrack=(e,w,o)=>{
        let track = this.state.track;
     
        if(w==='valor'){
            e = e.split(',').join('')
            e = Number(e)
        }

        if(w==='data'){
            e=this.formatDate(e)
        }

        track[o][w] = e
       
        this.upImpacto()

        this.setState({
            track:track
            
        })


        api.get('update/upProjectTrack.php?id='+this.props.id+'&tipo=0&track='+JSON.stringify(track)+'&r='+Math.random(10000))

    }


    // FORMAT DATE

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    
    addNewTrack = () => {

        let track = this.state.track;
        track.push({
                data:new Date().toISOString().split('T')[0],
                item:'',
                origem:0,
                valor:0,
                status:0,
                delete:1
        })

        this.setState({
            track:track
        })

        api.get('update/upProjectTrack.php?id='+this.props.id+'&tipo=0&track='+JSON.stringify(track)+'&r='+Math.random(10000))

    }

    upImpacto = () => {
        let track = this.state.track
      
        let impactoPrevisto = track.reduce((a,v)=> a = a+v.valor/1,0)

        let impactoAprovado = 0;

        for(let i=0;i<track.length;i++){
           
            if(track[i].status===1 || track[i].status===2 || track[i].status===3){
                impactoAprovado += track[i].valor
            }
        }
        
        this.setState({
            impactoPrevisto:impactoPrevisto,
            impactoAprovado:impactoAprovado
        })
    }

    void=()=>{

    }

    showContrato = () => {

        let orcadoMostrar = 1-this.state.orcadoMostrar;

        if(orcadoMostrar===1){
            
            api.get('get/get.php?sheet=budget&id='+this.props.id+'&col_id=id&empresa_id=no&r='+Math.random(10000)).then(res=>{

                let contract = JSON.parse(res.data[0].ce_infos);
               
                this.setState({
                    orcadoMostrar:orcadoMostrar,
                    contract:contract
                })
            }
            )
            
        }
        
    }

    sendTrack = () => {

        let track = this.state.track;

        for(let i=0;i<track.length;i++){
            track[i].delete=0
        }

        this.setState({
            sent:true
        })

            api.get('update/upProjectTrack.php?id='+this.props.id+'&tipo=1&previsto='+this.state.impactoPrevisto+'&aprovado='+this.state.impactoAprovado+'&track='+JSON.stringify(track)+'&r='+Math.random(10000)).then(res => {

                if (res.status === 200) {
                    this.setState({
                        trackToday:true,
                        track:track
                    })
                }

            }
            
            )

    }

    deleteTrack = (o) => {

        let track = this.state.track;

        track.splice(o,1);

        api.get('update/upProjectTrack.php?id='+this.props.id+'&tipo=0&track='+JSON.stringify(track)+'&r='+Math.random(10000))

        this.setState({
            track:track
        })
    }
    
    render(props) {
        

        return (

                <ThemeProvider theme={theme}>
                  
                        <div className='mt10'></div>
                        <Grid container spacing={1} >

                        <Grid item xs={2} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Data</div></Grid>
                        <Grid item xs={3} className="bgcolor_6 br5"><div className="mt5 pd15 br5">Item</div></Grid>
                        <Grid item xs={2} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Origem</div></Grid>
                        <Grid item xs={2} className="bgcolor_6 br5">
                            <div className='f'>
                                <div className="mt5 pd15 w30 br5">Impacto Previsto</div>
                                <div className='mt5 pd15 br5 tr b'><CurrencyFormat value={this.state.impactoPrevisto} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />   </div> 
                            </div>
                        </Grid>
                        <Grid item xs={3} className="bgcolor_7 br5">
                            <div className='f'>
                                <div className="mt5 pd15 w30 br5">Impacto Aprovado</div>
                                <div className='mt5 pd15 br5 tr b'><CurrencyFormat value={this.state.impactoAprovado} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />   </div> 
                            </div>
                        </Grid>

                        </Grid>
                        <div className='mt10'></div>
                        {this.state.track ? this.state.track.map((track,objectId)=>(
                        <Grid container spacing={1}>

                        <Grid item xs={2}>
                                <MyDatePicker 
                                    onChange={(e)=>this.changeTrack(e,'data',objectId)} 
                                    view={["year", "month","date"]}  
                                    format="dd/MM/yyyy" 
                                    startDate={track.data}
                                />
                        </Grid>
                        <Grid item xs={3}>
                                    <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeTrack(e.target.value,'item',objectId)
                                            }}
                                            onChange={e => {
                                            this.changeTrack(e.target.value,'item',objectId)
                                            }}
                                            value={track.item}
                                            variant="outlined"
                                    />
                        </Grid>
                        <Grid item xs={2}>
                                        <MySelect
                                            fullWidth={true}
                                            list={this.state.origem}
                                            placeholder=''
                                            change={(e)=>this.changeTrack(e,'origem',objectId)}
                                            initValue={track.origem}
                                            variant="outlined"
                                        />
                        </Grid>

                        <Grid item xs={2}>
                                        <CurrencyTextField
                                            fullWidth={true}
                                            
                                            currencySymbol="R$"
                                            outputformat="number"
                                            onBlur={e => {
                                            this.changeTrack(e.target.value,'valor',objectId)
                                            }}
                                        
                                            defaultValue={track.valor}
                                            variant="outlined"
                                        />
                        </Grid>

                        <Grid item xs={3}>
                            <div className='flex'>
                                        <MySelect
                                            fullWidth={true}
                                            list={this.state.status}
                                            placeholder=''
                                            change={(e)=>this.changeTrack(e,'status',objectId)}
                                            initValue={track.status}
                                            variant="outlined"
                                        />
                                        {track.delete===1 ? 
                                         <div className='' onClick={()=>this.deleteTrack(objectId)}><IconButton><Delete/></IconButton></div>
                                        :''
                                        }
                                         </div>
                        </Grid>

                      


                       </Grid>

                        )):''}

                        <div className={"bt bgcolor_2 mt10"} onClick={()=>this.addNewTrack()}>Adicionar novo item</div>
                        {this.state.trackToday ? 
                            <div className={"bt bgcolor_6 mt10"} onClick={()=>this.sendTrack()}>
                            
                            <div>
                                    {this.state.sent ? 
                                        <div>Enviado</div>
                                        :
                                        <div>Atualizar relatório de hoje</div>
                                    }
                                </div>
                            </div>
                        : 
                            <div className={"bt bgcolor_1 mt10"} onClick={()=>this.sendTrack()}>
                                <div>
                                    {this.state.sent ? 
                                        <div>Enviado</div>
                                        :
                                        <div>Enviar relatório de hoje</div>
                                    }
                                </div>
                            </div>
                        }
                        
                </ThemeProvider>
            
        )
    }
}
