import React, { Component} from "react";

import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Meta from "../../components/meta";
import {Grid} from "@material-ui/core";
import { zonedTimeToUtc } from 'date-fns-tz'
import {MySelect } from "../../components/textField";
import CurrencyFormat from "react-currency-format";
import './style.css';






const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
  }
 

}
  
)

const meses = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
  ];

  const date = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  
  let datas = [];

  for(let i=2015;i<=date.getFullYear();i++){
    for(let m=1;m<=12;m++){
      let mm = 0;
      if(m<10){
        mm = '0'+m;
      }else{
        mm=m;
      }
      if(date.getFullYear()!==i){
        datas.push(i+'-'+mm);
      }else{
        if(date.getMonth()>=m){
          datas.push(i+'-'+mm);
        }
      }

    }
  }

  datas = datas.reverse();


export default class Diretores extends Component {


  state = {
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    uid: localStorage['usuario_id'],
    loading:true,
    loadPerc:0
    
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  async componentDidMount(){
    
    this.loadDiretoresSelect()

  }


  // DROPDOWN DIRETORES

  loadDiretoresSelect = () => {
   
   
      if(this.state.permission/1===0){

          api.get('get/getDiretoresCache.php?r='+Math.random(10000)).then(result => {
            
            if (result.status === 200) {
                
                this.setState({
                  listDiretoresSelect:result.data,
                  uid:result.data[0].value
                })
                
            }})
      }else{

        this.setState({
          listDiretoresSelect:[{'id':this.state.usuario_id,'value':this.state.usuario_id,'label':'Acompanhe seus cachês'}],
          uid:this.state.usuario_id
        })
        

      }

      setTimeout(() => {
        this.getDiretores();
      }, 10);

  }


  // DIRETORES PNL

  getDiretores = async () => {
    
    if(this.state.uid){
    this.toLoad()
    
   api.get('get/getCaches.php?usuario_id='+this.state.uid+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
         
          this.setState({
            caches:result.data.caches,
            recebidos:result.data.recebidos,
            pagarMes:result.data.pagames,
            nome:result.data.nome,
            variavel:result.data.variavel,
            acordo:result.data.acordo,
          })
          this.endLoad()
          
      }});
    }
     
  }

     // FORMAT DATE

     formatDate = (date) => {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()
          
          ;
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [day, month, year].join('/');
  }


  // SELECIONA DIRETOR

  changeDiretor = (e) => {
    this.setState({
      uid:e
    })

    setTimeout(() => {
      this.getDiretores()
    }, 10);
    
  }
  render(){


  return (

   
    <ThemeProvider theme={theme}>
       
       
<Meta title="Cachês dos diretores"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        

        
      <div className="conteudo">
        <Grid container spacing={3}>
                
        <Grid item xs={6}><div className="titulo">{this.state.nome ? this.state.nome : ''}</div></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}><MySelect list={this.state.listDiretoresSelect ? this.state.listDiretoresSelect : []} initValue={''} label="Diretor" change={(e)=>this.changeDiretor(e)}/></Grid>
                
        </Grid>
        <div className="mt30 mb20"></div>





        {this.state.recebidos ? datas.map((datas,objectId)=>(
        <div>
        {this.state.recebidos[datas] ? 
        <div className="mb30">
          
        <Grid container spacing={3}>

        <Grid item xs={12}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                <div className="sub-titulo mb20">{"Cachês "+meses[datas.split('-')[1]-1]+" "+datas.split('-')[0]}</div>
                </Grid>
                </Grid>

                <Grid container spacing={0}>
                
            
                <Grid item xs={3} className="pd15 bgcolor_10">Total de cachês válidos</Grid>
                <Grid item xs={6} className="pd15 bgcolor_10"></Grid>
                <Grid item xs={3} className="pd15 bgcolor_10"><CurrencyFormat value={this.state.caches[datas]  ? this.state.caches[datas].reduce((a,v) =>  a = a + (v.valor/1) , 0 ) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></Grid>
            

           </Grid> 
           <div className="mb10"></div>
                {this.state.caches[datas] ? this.state.caches[datas].map((cache,objectId2) =>(
                  
                    
                <Grid container spacing={0} className="mb1">
                  <Grid item xs={3} className={cache.status/1===2 ? "pd15 bgcolor_6" : "pd15 bgcolor_5"}>{this.formatDate(cache.dia)}</Grid>
                  <Grid item xs={6} className="pd15 bgcolor_7">{cache.projeto}</Grid>
                  <Grid item xs={3} className="pd15 bgcolor_6"><CurrencyFormat value={cache.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></Grid>
                </Grid>
                
                
                )) : ''}

           
            

        </Grid>

        


        
      
        </Grid>

        
        <div className="mb10"></div>
       

        <Grid container spacing={0}>
                    
                    
                    <Grid item xs={3} className="pd15 bgcolor_2">Resultado do mês</Grid>
                    <Grid item xs={6} className="pd15 bgcolor_2">Variável: <CurrencyFormat value={this.state.variavel} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></Grid>
                    {this.state.acordo/1===1 ?
                      <Grid item xs={3} className="pd15 bgcolor_2 tr"><CurrencyFormat value={(this.state.variavel-(this.state.caches[datas]  ? this.state.caches[datas].reduce((a,v) =>  a = a + (v.valor/1) , 0 ) : 0))>0 ? this.state.variavel-(this.state.caches[datas]  ? this.state.caches[datas].reduce((a,v) =>  a = a + (v.valor/1) , 0 ) : 0) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></Grid>
                    :
                      <Grid item xs={3} className="pd15 bgcolor_2 tr b"><CurrencyFormat value={this.state.pagarMes[datas][0].valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></Grid>
                    }

            </Grid> 

        
        </div>
         : ''}
        </div>
        )) : ''}
          </div>

          
        
          

    </ThemeProvider>
  )


}
}