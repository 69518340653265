import React, { Component} from "react";

import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Meta from "../../components/meta";
import {Grid, Tooltip,TextField, Zoom, IconButton } from "@material-ui/core";
import { zonedTimeToUtc } from 'date-fns-tz'
import { Lock, LockOpen, InsertDriveFile } from "@material-ui/icons";
import { MyDatePicker } from "../../components/textField";
import LineGraph from "./line";
import Lista from '../cashflow/lista_pnl';
import CurrencyFormat from "react-currency-format";
import './style.css';
import { Redirect } from "react-router";
import Switch from '@material-ui/core/Switch';





const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
  }
 

}
  
)

const meses = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
  ];


  const data = [{'id':'Plano',"color": "#91c2b5",'data':[]},{'id':'Realizado',"color": "#A456FF",'data':[]}];

  const data_lucro = [{'id':'Plano',"color": "#91c2b5",'data':[]},{'id':'Realizado',"color": "#A456FF",'data':[]}];

  for(let i=0;i<meses.length;i++){
   
    data[0]['data'].push({'x':meses[i]})
    data[1]['data'].push({'x':meses[i]})
    data_lucro[0]['data'].push({'x':meses[i]})
    data_lucro[1]['data'].push({'x':meses[i]})

  }



  const today = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  
  

export default class Report extends Component {


  state = {
    token: localStorage.getItem("tokens").replace('"','').replace('"',''),
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    loading:true,
    loadPerc:0,
    page:1,
    diretores_out:0,
    showDiretores:false,
    ano:today.getFullYear(),
    mes:today.getFullYear()+'-'+(today.getMonth()+1),
    light:['','','','','','','','','','','','','',''],
    showProjetos:false,
    nomeSessao:"Plano Anual",
    check:[],
    poId:'n',
    whereAt:0,
    realizado:false
    
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  async componentDidMount(){
   
    if(today.getMonth()===0){
      
      this.setState({
        mes:this.state.ano+'-01',
       
      })
    }
    // LIGHT
    let cardlight=[];

    for(let i=0;i<14;i++){
      cardlight[i]='';
    }

    
    
    this.setState({
      light:cardlight
    })
    
    setTimeout(() => {
      this.setState({ loading: true });
      this.loadPNL(0);
      this.getDiretores();
      this.getListUnidades();
      this.setPage()
    }, 10);
   
    
  }


  // ULTIMA PÁGINA VISITADA

setPage = async () => {

  
  api.get('update/setPage.php?page=/report&token='+this.state.token);

}

  // DIRETORES PNL

  getDiretores = async () => {
      
    this.toLoad()
    api.get('get/pnl_anual_diretores.php?ano='+this.state.ano+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
        
          this.setState({
            diretores_out:result.data
          })
          this.endLoad()
          
      }});
     
  }


  // INSERIR NOVO DIRETORES

  novosDiretores = async () => {
      
    this.toLoad()
    api.get('insert/pnl_anual_diretores.php?ano='+this.state.ano+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
        
        
          this.setState({
            diretores_out:0
          })
          this.endLoad()
          
      }});
     
  }

  // LISTA UNIDADES

  getListUnidades = async () => {
      
    this.toLoad()
    api.get('get/getSelect.php?sheet=unidades&id=0&col_id=active&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({
            listUnidades: result.data,
            unidade: result.data[0]['value']
          })
          this.endLoad()
          
      }});
     
  }

  // PNL ANUAL


  loadPNL = async (tipo) => {
    
    this.toLoad()
    let linkPHP = 'pnl_anual.php';
    if(this.state.realizado){
          linkPHP = 'pnl_anual_realizado_budget.php';
    }

    api.get('get/'+linkPHP+'?ano='+this.state.ano+'&empresa_id='+this.state.empresa_id+'&mes='+this.state.mes+'&tipo='+tipo+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
        
        // IMPOSTOS
        let impostos = [];
        for(let i=0;i<result.data.total_meses.length;i++){
          impostos[i] = Math.floor(result.data.total_meses[i]/1*result.data.imposto/1)
        }


        // RECEITA

        let receita = [];
        let receita_per = [];
        let transferencias = result.data.colunas['Transferencias'];

        for(let i=0;i<result.data.total_meses.length;i++){
          //receita[i] = result.data.total_meses[i]-impostos[i]-transferencias[i] - result.data.colunas['Custos Diretos'][i];
          receita[i] = result.data.total_meses[i]-impostos[i]- result.data.colunas['Custos Diretos'][i];
          if(result.data.total_meses[i]>0){
            receita_per[i] = Math.floor(receita[i]/result.data.total_meses[i]*100);
          }else{
            receita_per[i]=0;
          }
        }

        // LUCRO
        let custos_diretos=result.data.colunas['Custos Diretos'];
        let custos_operacionais = result.data.colunas['Custos operacionais'];
        let socios = result.data.colunas['Socios'];
        let people = result.data.colunas['People'];
        let overhead = result.data.colunas['Overhead'];
        
        let lucro = [];
        let lucro_per = [];
        let custos_diretos_per = [];

        for(let i=0;i<result.data.total_meses.length;i++){
          lucro[i] = receita[i]-custos_operacionais[i]-people[i]-overhead[i];
          if(result.data.total_meses[i]>0){
            lucro_per[i] = Math.floor(lucro[i]/result.data.total_meses[i]*100);
          }else{
            lucro_per[i] = 0;
          }
        }

        // CUSTOS DIRETOS

        for(let i=0;i<result.data.total_meses.length;i++){
          custos_diretos_per[i] = Math.floor(custos_diretos[i]/result.data.total_meses[i]*100);
        }

        // GRAPH

        for(let i=0;i<result.data.total_meses.length;i++){
          data[1]['data'][i]['y']=result.data.total_meses[i];
          data_lucro[1]['data'][i]['y']=lucro[i];
        }


       


        if(tipo===0){

          // GRAPH

          for(let i=0;i<result.data.total_meses.length;i++){
            data[0]['data'][i]['y']=result.data.total_meses[i];
            data_lucro[0]['data'][i]['y']=lucro[i];
          }
          
          let o_diretores = result.data.diretores;
          o_diretores.push({'usuario_id':0,'nome':'Sem Diretor','meses':[0,0,0,0,0,0,0,0,0,0,0,0]})
          this.setState({
            o_diretores:result.data.diretores,
            o_total_meses:result.data.total_meses,
            o_impostoOp:result.data.imposto,
            o_custos_diretos:custos_diretos,
            o_custos_diretos_per:custos_diretos_per,
            o_custos_operacionais:custos_operacionais,
            o_people:people,
            o_overhead:overhead,
            o_transferencias:transferencias,
            o_receita:receita,
            o_receita_per:receita_per,
            o_impostos:impostos,
            o_lucro:lucro,
            o_socios:socios,
            o_lucro_per:lucro_per
          })

        }

        this.setState({
          diretores:result.data.diretores,
          total_meses:result.data.total_meses,
          soma_meses:result.data.soma_meses,
          impostoOp:result.data.imposto,
          custos_diretos:custos_diretos,
          custos_diretos_per:custos_diretos_per,
          custos_operacionais:custos_operacionais,
          people:people,
          receita:receita,
          overhead:overhead,
          transferencias:transferencias,
          receita_per:receita_per,
          impostos:impostos,
          lucro:lucro,
          socios:socios,
          lucro_per:lucro_per
        })
          
        

          this.endLoad()
          
      }});
    
  }


  // NAV REPORT

  navReport = (w) => {

    this.setState({
      whereAt:w,
      list_operacionais:false,
      list_people:false,
    })

    if(w===1){
      this.loadPNL(0);
      let cardlight=[];

      for(let i=0;i<12;i++){
        cardlight[i]='';
      }

      this.setState({
        light:cardlight,
        showProjetos:false,
        nomeSessao:"Plano Anual"
      })
    }

    if(w===2){
      this.loadPNL(1);

      let cardlight=[];

      for(let i=0;i<12;i++){
        cardlight[i]='';
      }

      cardlight[this.state.mes.split('-')[1]-1] = 'card-mes';

      for(let i=this.state.mes.split('-')[1];i<12;i++){
        cardlight[i]='card-light';
      }
      this.setState({
        light:cardlight,
        showProjetos:false,
        nomeSessao:"Last Report"
      })
    }

    if(w===3){
      this.loadPNL(2);

      let cardlight=[];

      for(let i=0;i<12;i++){
        cardlight[i]='';
      }
      cardlight[this.state.mes.split('-')[1]-1] = 'card-mes';

      for(let i=this.state.mes.split('-')[1];i<12;i++){
        cardlight[i]='card-light';
      }
      this.setState({
        light:cardlight,
        showProjetos:false,
        nomeSessao:"LR + Projetado"
      })
    }


    if(w===4){
      this.loadPNL(3);

      let cardlight=[];

      for(let i=0;i<12;i++){
        cardlight[i]='';
      }

      cardlight[this.state.mes.split('-')[1]-1] = 'card-mes';

      for(let i=today.getMonth();i<12;i++){
        cardlight[i]='card-light';
      }
      this.setState({
        light:cardlight,
        showProjetos:false,
        nomeSessao:"LR + Ajustado"
      })
    }

    if(w===5){

      this.toLoad()
      
      let mes_pnl = this.state.mes.split('-')[1];
      if(mes_pnl/1<10){
        mes_pnl='0'+mes_pnl/1;
      }
      api.get('get/pnlProjetos.php?ano='+this.state.ano+'&empresa_id='+this.state.empresa_id+'&mes='+mes_pnl+'&r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {
          this.setState({
            showProjetos:true,
            projetos:result.data.budgets,
            nomeSessao:"Projetos"
          })
          this.endLoad()
        }})

      
    }
    
    this.setState({
      page:w,
      edit:false
    })


  }

  

// CLEAR

clear = (e,d,m) => {

  let diretores = this.state.diretores;
  let clean = diretores[d]['meses'][m]

    diretores[d]['meses'][m] = '';

    this.setState({
      clean:clean,
      diretores:diretores
    })
}


  // UP

  up = (e,d,m) => {

    let diretores = this.state.diretores;
    diretores[d]['meses'][m] = e.target.value;

   
    
    this.setState({
      diretores:diretores
    })

  }


  // SAVE

  save = (e,d,m) => {


    if(e.target.value!==''){

      let diretores = this.state.diretores;

      let total_meses = this.state.total_meses;

      let impostos = this.state.impostos;

      this.toLoad()
      api.get('update/upPNL.php?ano='+this.state.ano+'&usuario_id='+diretores[d]['usuario_id']+'&mes='+(m+1)+'&valor='+e.target.value+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {
            this.loadPNL(0)
            
            
        }});
      
    
     
      for(let m=0;m<total_meses.length;m++){
        let novo_total_mes = 0;
      for(let i=0;i<diretores.length;i++){
        novo_total_mes+=diretores[i]['meses'][m]/1;

      }
      
      total_meses[m]=novo_total_mes;
   
      impostos[m]=novo_total_mes*this.state.impostoOp;
    }



    this.setState({
      total_meses:total_meses,
      impostos:impostos
    })


    

    }else{
      let diretores = this.state.diretores;
      diretores[d]['meses'][m] = this.state.clean;

      this.setState({
        diretores:diretores
      })
    }
    

  }


  // CLEAR COLUNAS

clearColunas = (e,d,c) => {

  let clear = e.target.value;

  if(c==='Custos Diretos'){
  let colunas = this.state.custos_diretos;
  colunas[d]='';
  this.setState({
    custos_diretos:colunas,
    clear:clear
  })
  }

  if(c==='Custos Operacionais'){
    let colunas = this.state.custos_operacionais;
    colunas[d]='';
    this.setState({
      custos_operacionais:colunas,
      clear:clear
    })
  }

  if(c==='People'){
    let colunas = this.state.people;
    colunas[d]='';
    this.setState({
      people:colunas,
      clear:clear
    })
  }

  


}


  // UP COLUNAS

  upColunas = (e,d,c) => {

    
    if(c==='Custos Diretos'){
      let colunas = this.state.custos_diretos;
      colunas[d]=e.target.value;
      this.setState({
        custos_diretos:colunas,
      })
      }
    
      if(c==='Custos Operacionais'){
        let colunas = this.state.custos_operacionais;
        colunas[d]=e.target.value;
        this.setState({
          custos_operacionais:colunas,
         
        })
      }
    
      if(c==='Overhead'){
        let colunas = this.state.overhead;
        colunas[d]=e.target.value;
        this.setState({
          overhead:colunas,
          
        })
      }

      if(c==='People'){
        let colunas = this.state.people;
        colunas[d]=e.target.value;
        this.setState({
          people:colunas,
          
        })
      }
    
      
    
  }



  // SAVE COLUNAS

  saveColunas = (e,d,c) => {

    let tipo=0;
    let diretores = this.state.diretores;

    let total_meses = this.state.total_meses;

    let impostos = this.state.impostos;
    let colunas = []
    if(c==='Custos Diretos'){
       colunas = this.state.custos_diretos;
       tipo=0;
    }
  
    if(c==='Custos Operacionais'){
       colunas = this.state.custos_operacionais;
       tipo=1;
    }
  
    if(c==='Overhead'){
      colunas = this.state.overhead;
      tipo=2;
   }
  
    if(c==='People'){
       colunas = this.state.people;
       tipo=3;
    }

  if(e.target.value!==''){

      

      this.toLoad()
      // UPDATE COLUNA TRABALHAR
      api.get('update/upPNLColuna.php?ano='+this.state.ano+'&tipo='+tipo+'&mes='+(d+1)+'&valor='+e.target.value+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {
          
          this.loadPNL(0)
            
        }});
      
    
     
      for(let m=0;m<total_meses.length;m++){
        let novo_total_mes = 0;
      for(let i=0;i<diretores.length;i++){
        novo_total_mes+=diretores[i]['meses'][m]/1;

      }
      
      total_meses[m]=novo_total_mes;
   
      impostos[m]=novo_total_mes*this.state.impostoOp;
    }



    this.setState({
      total_meses:total_meses,
      impostos:impostos
    })


    

    }else{

     
      colunas[d] = this.state.clean;

      this.setState({
        colunas:colunas
      })
    }
  }


  // SHOW DIR

  showDir = () => {
    if(this.state.showDiretores){
      this.setState({
        showDiretores:false
      })
    }else{
      this.setState({
        showDiretores:true
      })
    }
  }


  // CHANGE DATE

  changeData = (e) =>{


    if(this.formatDate(e).split('-')[0]/1<2023){


    if(this.state.selDate){
      
      this.setState({
        selDate:false,
        mes:this.formatDate(e),
        ano:this.formatDate(e).split('-')[0]
      })
      
      setTimeout(() => {
        
        this.navReport(this.state.whereAt);
  
      }, 10);
      


    }else{
      this.setState({
        selDate:true
      })
    }
  }

  if(this.formatDate(e).split('-')[0]/1===2023){
    window.location.href='/ebitda';
  }
  if(this.formatDate(e).split('-')[0]/1>2023){
      <Redirect to='/fpna'/> 
      window.location.href='/fpna';
  }

  }

  // FORMAT DATE

formatDate = (date) => {
  
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, ''].join('-');
}

// OPEN PROJETO

openProjeto = (w) => {
  window.open('xray/'+w+'_'+this.state.empresa_id);
}

// OPEN EDIT

openEdit = () => {
  this.setState({
    edit:true
  })
}


// SHOW LISTA

showLista = (w) => {


  this.toLoad()

  
      api.get('get/cashflowPNL.php?tipo='+w+'&empresa_id='+this.state.empresa_id+'&mes='+this.state.mes+'&r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {


          if(w===0){
            this.setState({
              list_operacionais:result.data,
              list_people:false,
            })
          }

          if(w===1){
            this.setState({
              list_people:result.data,
              list_operacionais:false,
            })
          }

          

          this.endLoad()
        }})

        
}


// FECHA JANELA

fechaJanela = () => {

  this.setState({
    janelaOpen:false,
    poId:'n',
  })
}

// ABRE JANELA

abreJanela = (w) => {


  this.setState({
    poId:w,
    janelaOpen:true,
  })
}


// SALVA JANELA

salvaJanela = (w) => {
  this.fechaJanela();

}


// PDF

openPDF = () => {
 window.open('reportPDF/'+this.state.ano+'_'+this.state.mes.split('-')[1]+'_'+this.state.empresa_id)
}

// REALIZADO

changeRealizado = (e) => {

    
    this.setState({
      realizado:e.target.checked,
      page:2,
    })

    setTimeout(() => {
      this.loadPNL(1)
    }, 10);
    
}

// VOID
void = () => {

}
  render(){


  return (

   
    <ThemeProvider theme={theme}>
       
       
<Meta title="PNL Report"/>
{(this.state.permission/1) > 0 ? <Redirect to='/wrong'/> : ''}
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo">
        <Grid container spacing={3}>
                
                <Grid item xs={3}><div className="titulo">PNL Report</div></Grid>
                <Grid item xs={6}>
                {this.state.realizado===false ?
                  <Grid container spacing={1}>
                    
                    <Grid item xs><div className={this.state.page===1 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navReport(1)}>Plano Anual</div></Grid>
                    <Grid item xs><div className={this.state.page===2 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navReport(2)}>Last Report</div></Grid>
                    <Grid item xs><div className={this.state.page===3 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navReport(3)}>LR + Projetado</div></Grid>
                    <Grid item xs><div className={this.state.page===4 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navReport(4)}>LR + Ajustado</div></Grid>
                    <Grid item xs><div className={this.state.page===5 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navReport(5)}>Projetos</div></Grid>
                  </Grid>

                  :
                  
                  <Grid container spacing={1}>
                    
                    <Grid item xs><div className={this.state.page===2 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navReport(2)}>Last Report</div></Grid>
                    <Grid item xs><div className={this.state.page===5 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navReport(5)}>Projetos</div></Grid>
                  </Grid>
                  
                  }
              </Grid>

        
        
        <Grid item xs={2}>

        <MyDatePicker onChange={(e)=>this.changeData(e)} view={["year", "month"]}  format="MMM/yyyy" label="Busque pelo mês" startDate={''}/>

        </Grid>
        <Grid item xs={1} className="tr">
          <IconButton>{this.state.edit ?  <LockOpen/> :<Lock onClick={()=>this.openEdit()}/>}</IconButton>
          <IconButton><InsertDriveFile onClick={()=>this.openPDF()}/></IconButton>
          
          </Grid>

          <Grid item xs={3} className="mt50"><div className="sub-titulo mt50">{this.state.nomeSessao}</div></Grid>  
          <Grid item xs={6} className="mt50">
            <div className="mt45 flex">
                <div className="sub-titulo mt5">{meses[this.state.mes.split('-')[1]/1-1] + ' ' + this.state.ano}</div>
                <Switch checked={this.state.realizado} onChange={(e) => this.changeRealizado(e)} name="Realizado" id='0' /> <div className="mt15">Realizado</div>
            </div>
          </Grid> 
          
          <Grid item xs={3} className="">{this.state.diretores_out>0 ? <div onClick={()=>this.novosDiretores()} className="bt large bgcolor_5 mt50">Inserir novos diretores</div> : ''}</Grid> 
          
          

          
        </Grid>
        
        <div className="mt30"></div>
        {!this.state.showProjetos ? 
          <div>
          <Grid container spacing={0}>
            {meses.map((mes,objectID)=>(
          <Grid  key={objectID}  item xs><div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>{mes}</div></Grid>
          ))}

          <Grid item xs><div className="tc bgcolor_1 label-report">{this.state.mes.split('-')[0]}</div></Grid>
        
          </Grid>
          
          <div className="mt20 mb10">Faturamento</div>
          <Grid container spacing={0}>
            {this.state.total_meses ? this.state.total_meses.map((mes,objectID)=>(
          <Tooltip title={'R$ ' +Math.floor(this.state.o_total_meses[objectID])+' | '+ Math.floor(mes/this.state.o_total_meses[objectID]*100)+'% | SUM R$ '+Math.floor(this.state.soma_meses[objectID]) } placement="top" arrow TransitionComponent={Zoom}><Grid key={objectID} onClick={()=>this.showDir()} item xs><div className={objectID%2===0 ? "tc bgcolor_3d label-report "+this.state.light[objectID] : "tc bgcolor_3 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={mes ? mes : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div></Grid></Tooltip>
          )):''}

          <Grid item xs><div className="tc bgcolor_1 label-report">{this.state.total_meses ? <CurrencyFormat value={Math.floor(this.state.total_meses.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        
          </Grid>

          {this.state.diretores && this.state.showDiretores ? this.state.diretores.map((diretor,objectID)=>(
            <div key={objectID} >
          <div className="mt20 mb5">{diretor.nome}</div>
          <Grid container spacing={0}>
            {meses.map((mes,objectID2)=>(
              
              <Tooltip title={'R$ ' +Math.floor(this.state.o_diretores[objectID]['meses'][objectID2])+' | '+ Math.floor(diretor.meses[objectID2]/this.state.o_diretores[objectID]['meses'][objectID2]*100)+'%'} placement="top" arrow TransitionComponent={Zoom}><Grid  key={objectID2}  item xs><div>{this.state.edit ? <TextField variant="outlined" value={diretor.meses[objectID2]} onChange={(e)=>this.up(e,objectID,objectID2)} onFocus={(e)=>this.clear(e,objectID,objectID2)} onBlur={(e)=>this.save(e,objectID,objectID2)} fullWidth={true} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> :<div className={objectID2%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID2] : "tc bgcolor_10 label-report "+this.state.light[objectID2]}>{<CurrencyFormat value={diretor.meses[objectID2]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div>}</div></Grid></Tooltip>
            ))}

            <Grid item xs><div className={this.state.edit ? "tc bgcolor_1 label-report-linha" : "tc bgcolor_1 label-report"}>{diretor.meses ? <CurrencyFormat value={Math.floor(diretor.meses.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        
          </Grid>
          </div>
          )) : ''}


        <Grid container spacing={1}>
        <Grid item xs>
        <div className="nano-titulo mt10 mb10">Impostos</div>
        </Grid>
        </Grid>
        <Grid container spacing={0}>
          
          {this.state.impostos ? this.state.impostos.map((imposto,objectID)=>(
          <Grid item xs><div className={objectID%2===0 ? "tc bgcolor_7 label-report-linha "+this.state.light[objectID] : "tc bgcolor_10 label-report-linha "+this.state.light[objectID]}>{<CurrencyFormat value={imposto ? imposto : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div></Grid>
          )) : 0}

        <Grid item xs><div className="tc bgcolor_6 label-report-linha">{this.state.impostos ? <CurrencyFormat value={Math.floor(this.state.impostos.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        </Grid>

        <Grid container spacing={1}>
        <Grid item xs>
        <div className="nano-titulo mt10 mb10">Custos Diretos</div>
        </Grid>
        </Grid>
        <Grid container spacing={0}>
          
          {this.state.custos_diretos ? this.state.custos_diretos.map((custos,objectID)=>(
          <Tooltip title={'R$ ' +Math.floor(this.state.o_custos_diretos[objectID])+' | '+ Math.floor(custos/this.state.o_custos_diretos[objectID]*100)+'%'} placement="top" arrow TransitionComponent={Zoom}><Grid  key={objectID}  item xs><div>{this.state.edit ? <TextField variant="outlined" value={custos} onChange={(e)=>this.upColunas(e,objectID,'Custos Diretos')} onFocus={(e)=>this.clearColunas(e,objectID,'Custos Diretos')} onBlur={(e)=>this.saveColunas(e,objectID,'Custos Diretos')} fullWidth={true} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={custos ? custos : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div> }</div><div className={"pd10 br5 mt1 nano-titulo "+this.state.light[objectID]}>{this.state.custos_diretos_per[objectID] ? this.state.custos_diretos_per[objectID]+'%' : '0%'}</div></Grid></Tooltip>
          )) : 0}

        <Grid item xs><div className={this.state.edit ? "tc bgcolor_6 label-report-linha" : "tc bgcolor_6 label-report"}>{this.state.custos_diretos ? <CurrencyFormat value={Math.floor(this.state.custos_diretos.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
        <div className="pd10 br5 mt1 nano-titulo b">{this.state.custos_diretos ? Math.floor(this.state.custos_diretos.reduce((a,b) => a = a+b/1,0)/Math.floor(this.state.total_meses.reduce((a,b) => a = a+b/1,0))*100)+'%' : '0%'}</div></Grid>
        </Grid>
            



        <div className="mt20 mb10">Receita</div>
        <Grid container spacing={0}>
          {this.state.receita ? this.state.receita.map((receita,objectID)=>(
        <Tooltip title={'R$ ' +Math.floor(this.state.o_receita[objectID])+' | '+ Math.floor(receita/this.state.o_receita[objectID]*100)+'%'} placement="top" arrow TransitionComponent={Zoom}><Grid item xs><div className={objectID%2===0 ? "tc bgcolor_3d label-report "+this.state.light[objectID] : "tc bgcolor_3 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={receita ? receita : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div><div className={"pd10 br5 mt1 nano-titulo "+this.state.light[objectID]}>{this.state.receita_per[objectID]+'%'}</div></Grid></Tooltip>
        )) : ''}

        <Grid item xs><div className="tc bgcolor_1 label-report">{this.state.receita ? <CurrencyFormat value={Math.floor(this.state.receita.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
        <div className="pd10 br5 mt1 nano-titulo b">{this.state.custos_diretos ? Math.floor(this.state.receita.reduce((a,b) => a = a+b/1,0)/Math.floor(this.state.total_meses.reduce((a,b) => a = a+b/1,0))*100)+'%' : '0%'}</div>
        </Grid>
       
        </Grid>


        <Grid container spacing={1}>
        <Grid item xs>
        <div className="nano-titulo mt10 mb10">Custos Operacionais</div>
        </Grid>
        </Grid>
        <Grid container spacing={0} className={this.state.page>1 ? "cl" : ''} onClick={this.state.page>1 ? ()=>this.showLista(0) : ()=>this.void()}>
          
          {this.state.custos_operacionais ? this.state.custos_operacionais.map((custos,objectID)=>(
          <Tooltip title={'R$ ' +Math.floor(this.state.o_custos_operacionais[objectID])+' | '+ Math.floor(custos/this.state.o_custos_operacionais[objectID]*100)+'%'} placement="top" arrow TransitionComponent={Zoom}><Grid  key={objectID}  item xs><div>{this.state.edit ? <TextField variant="outlined" value={custos} onChange={(e)=>this.upColunas(e,objectID,'Custos Operacionais')} onFocus={(e)=>this.clearColunas(e,objectID,'Custos Operacionais')} onBlur={(e)=>this.saveColunas(e,objectID,'Custos Operacionais')} fullWidth={true} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={custos ? custos : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div> }</div></Grid></Tooltip>
          )) : ''}

        <Grid item xs><div className={this.state.edit ? "tc bgcolor_6 label-report-linha" : "tc bgcolor_6 label-report"}>{this.state.custos_operacionais ? <CurrencyFormat value={Math.floor(this.state.custos_operacionais.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        </Grid>

        <Lista lista={0} tipo={1} abreJanela={(w)=>this.abreJanela(w)} infos={this.state.list_operacionais ? this.state.list_operacionais : ''} />

        <Grid container spacing={1}>
          <Grid item xs>
          <div className="nano-titulo mt10 mb10">People</div>
          </Grid>
          </Grid>
          <Grid container spacing={0} className={this.state.page>1 ? "cl" : ''} onClick={this.state.page>1 ? ()=>this.showLista(1) : ()=>this.void()}>
            
            {this.state.people ? this.state.people.map((custos,objectID)=>(
            <Tooltip title={'R$ ' +Math.floor(this.state.o_people[objectID])+' | '+ Math.floor(custos/this.state.o_people[objectID]*100)+'%'} placement="top" arrow TransitionComponent={Zoom}><Grid  key={objectID}  item xs><div>{this.state.edit ?  <TextField variant="outlined" value={custos} onChange={(e)=>this.upColunas(e,objectID,'People')} onFocus={(e)=>this.clearColunas(e,objectID,'People')} onBlur={(e)=>this.saveColunas(e,objectID,'People')} fullWidth={true} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={custos ? custos : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div>}</div></Grid></Tooltip>
            )) : ''}

          <Grid item xs><div className={this.state.edit ? "tc bgcolor_6 label-report-linha" : "tc bgcolor_6 label-report"}>{this.state.people ? <CurrencyFormat value={Math.floor(this.state.people.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        </Grid>

        <Lista lista={0} tipo={1} abreJanela={(w)=>this.abreJanela(w)} infos={this.state.list_people ? this.state.list_people : ''} />

        <Grid container spacing={1}>
          <Grid item xs>
          <div className="nano-titulo mt10 mb10">Shared Costs</div>
          </Grid>
          </Grid>
          <Grid container spacing={0}>
            
            {this.state.overhead ? this.state.overhead.map((custos,objectID)=>(
            <Tooltip title={'R$ ' +Math.floor(this.state.o_overhead[objectID])+' | '+ Math.floor(custos/this.state.overhead[objectID]*100)+'%'} placement="top" arrow TransitionComponent={Zoom}><Grid  key={objectID}  item xs><div>{this.state.edit ?  <TextField variant="outlined" value={custos} onChange={(e)=>this.upColunas(e,objectID,'Overhead')} onFocus={(e)=>this.clearColunas(e,objectID,'Overhead')} onBlur={(e)=>this.saveColunas(e,objectID,'Overhead')} fullWidth={true} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={custos ? custos : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div>}</div></Grid></Tooltip>
            )) : ''}

          <Grid item xs><div className={this.state.edit ? "tc bgcolor_6 label-report-linha" : "tc bgcolor_6 label-report"}>{this.state.overhead ? <CurrencyFormat value={Math.floor(this.state.overhead.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        </Grid>


       

        <div className="mt20 mb10">Lucro</div>
        <Grid container spacing={0}>
          {this.state.lucro ? this.state.lucro.map((lucro,objectID)=>(
        <Tooltip title={'R$ ' +Math.floor(lucro)+' | '+ Math.floor(lucro/this.state.o_lucro[objectID]*100)+'%'} placement="top" arrow TransitionComponent={Zoom}><Grid item xs><div className={objectID%2===0 ? "tc bgcolor_3d label-report "+this.state.light[objectID] : "tc bgcolor_3 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={lucro ? lucro : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div><div className={"br5 mt1 pd10 nano-titulo "+this.state.light[objectID]}>{this.state.lucro_per[objectID]+'%'}</div></Grid></Tooltip>
        )):''}

        <Grid item xs><div className="tc bgcolor_1 label-report">{this.state.lucro ? <CurrencyFormat value={Math.floor(this.state.lucro.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
          
          <div className="pd10 br5 mt1 nano-titulo b">{this.state.lucro ? Math.floor(this.state.lucro.reduce((a,b) => a = a+b/1,0)/Math.floor(this.state.total_meses.reduce((a,b) => a = a+b/1,0))*100)+'%' : '0%'}</div>
        
        </Grid>
       
        </Grid>

        <div className="mt20 mb10">Sócios</div>
        <Grid container spacing={0}>
          {this.state.socios ? this.state.socios.map((socios,objectID)=>(
        <Grid item xs><div className={objectID%2===0 ? "tc bgcolor_10 label-report "+this.state.light[objectID] : "tc bgcolor_7 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={socios ? socios : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div></Grid>
        )):''}

        <Grid item xs><div className="tc bgcolor_1 label-report">{this.state.socios ? <CurrencyFormat value={Math.floor(this.state.socios.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
       
        </Grid>
       
        </div>
      :''}


      {this.state.showProjetos ? 
      
      <div>
      <Grid container spacing={0} className="ml10 mt20 mb20">
      
          <Grid item xs={2} className="b">Projeto</Grid>
          <Grid item xs={2} className="b">Faturamento</Grid>
          <Grid item xs={2} className="b">Custos Orçados</Grid>
          <Grid item xs={2} className="b">Custos Realizados</Grid>
          <Grid item xs={1} className="b">%</Grid>
          <Grid item xs={2} className="b">Receita</Grid>
          <Grid item xs={1} className="b">%</Grid>
      
      </Grid>
      {this.state.projetos ? this.state.projetos.map((budgets,objectId)=>(
      <Grid container spacing={0} onClick={(w)=>this.openProjeto(budgets.id)}className="bgcolor_6 mt1 cl">
          <Grid item xs={2}  className="pd20 bgcolor_8"><div className="wp100 eli pd2">{budgets.nome}</div></Grid>
          <Grid item xs={2} className="pd20 bgcolor_7"><CurrencyFormat value={Math.floor(budgets.valor)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></Grid>
          <Grid item xs={2} className="pd20 bgcolor_8"><CurrencyFormat value={Math.floor(budgets.orcado)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></Grid>
          <Grid item xs={2} className="pd20 bgcolor_7"><CurrencyFormat value={Math.floor(budgets.realizado)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></Grid>
          <Grid item xs={1} className={budgets.realizado>0 && Math.floor((budgets.realizado)/(budgets.orcado)*100)>100 ?  "pd20 bgcolor_5" : "pd20 bgcolor_8"}>{budgets.realizado>0 ? Math.floor((budgets.realizado)/(budgets.orcado)*100)+'%' : 0}</Grid>
          <Grid item xs={2} className="pd20 bgcolor_8"><CurrencyFormat value={budgets.receita} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></Grid>
          <Grid item xs={1} className="pd20 bgcolor_7">{budgets.valor>0 ? Math.floor(budgets.receita/budgets.valor*100)+'%':0}</Grid>
    
       
       
      </Grid>
        
        )) :''}
      

      </div>
      
      :''}
      
      {!this.state.loading ? 
      <div>
      <div className="mini-titulo mt50">Faturamento</div>
      <div className="line-graph mt30"><LineGraph data={data}/></div>

      <div className="mini-titulo mt50">Lucro</div>
      <div className="line-graph mt30"><LineGraph data={data_lucro}/></div>
      </div>
    :''}
      


      
       


        </div>
          
 
    </ThemeProvider>
  )


}
}