import axios from 'axios';

const api = axios.create(
    { baseURL: 'https://treatments.run/landscape/'}
    //{ baseURL: 'https://treatments.run/landscape_dev/'}
    //{ baseURL: 'http://localhost:3050/src/php'}

    
);


export default api;