import { Grid } from "@material-ui/core";
import React,{Component} from "react";
import { ReactComponent as LogoLandscape } from '../../svg/logo.svg'
import './style.css';

export default class Header extends Component{
    constructor(props){
        super();
        this.state ={
            // vars
        }
        
    }
    componentDidMount(){
        
           
              
            
    }


    render(){
        return(

            <div className="conteudo footer">
                <Grid container spacing={3}>
                
                    <Grid item lg={1}><LogoLandscape className="logo-landscape footer"/></Grid>
                    <Grid item xs={1} lg={9}></Grid>
                    

                </Grid>
            </div>
             )
    
        }
    }

