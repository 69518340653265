import React, { Component} from "react";

import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Meta from "../../components/meta";
import { Button, Grid,Menu } from "@material-ui/core";
import { DateRange } from 'react-date-range';
import { zonedTimeToUtc } from 'date-fns-tz'
import { Today } from "@material-ui/icons";
import { MyDatePicker, MySelect } from "../../components/textField";
import { format } from 'date-fns'; 
import CurrencyFormat from "react-currency-format";


const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
 

}
  
)

  const date = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  const today = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  
  
  date.setDate(date.getDate() + 7);

export default class Report extends Component {


  state = {
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    loading:true,
    loadPerc:0,
    range:1,
    colorStatus:['bgcolor_5s','bgcolor_4','bgcolor_2'],
    horas:[
      {'value':0,'label':0},
      {'value':1,'label':1},
      {'value':2,'label':2},
      {'value':3,'label':3},
      {'value':4,'label':4},
      {'value':5,'label':5},
      {'value':6,'label':6}
      

  ],
  extrasHoras:0,
  extrasProjeto:0,
  extrasDia:0,
    selectionRange:({
      startDate: today,
      endDate: date,
      key: 'selection',
    }),
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  async componentDidMount(){
    
    
    this.setState({ loading: true });
    this.setPage();
    this.loadProjetos();
    this.loadValorHora();
    
  }



  // LISTA DE ORCAMENTOS


  loadProjetos = async () => {
    
    this.toLoad()
    api.get('get/getSelect.php?closed=0&sheet=projeto&id=2&col_id=projeto_status&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
        let projetos = result.data;

                      for(let i=0;i<projetos.length;i++){

                        projetos[i]['label'] = projetos[i]['label'].replace('  ',' ');

                      }

          this.setState({listProjetos: projetos})
         
          this.endLoad()
          
      }});
    
  }




  // VALOR HORA

  loadValorHora = async () => {
    
    
    api.get('get/get.php?id='+this.state.usuario_id+'&sheet=users&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
         
          this.setState({valorHora: result.data[0]['salario']})
         
          this.loadReport()
          
      }});
    
  }



  // VALOR HORA

  loadReport = async () => {
    
  api.get('get/getReportHoras.php?empresa_id='+this.state.empresa_id+'&usuario_id='+this.state.usuario_id+'&start='+format(this.state.selectionRange.startDate,'yyyy-MM-dd')+'&end='+format(this.state.selectionRange.endDate,'yyyy-MM-dd')+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
         

          this.setState({
            reportHoras: result.data.abertas,
            reportHorasPagas: result.data.pagas,
          
          })
         
          this.endLoad()
          
      }});
    
  }


  // FORMATE DATE

  formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
}

// FECHA MENU PRESET

fechaMenu = () => {
        
  this.setState({
      
      anchorElData: null,
      
      
  })

  this.loadReport()
};


    
// ULTIMA PÁGINA VISITADA

setPage = async () => {

  const thisPage = window.location.pathname.replace('`','/').replace('`','/').replace('`','/').replace('`','/');
  
  api.get('update/setPage.php?page='+thisPage+'&token='+this.state.token);


}



// ABRE MENU DATA

abreMenuData = (event) => {


  this.setState({
      anchorElData: event.currentTarget
  })
  
  
};


// ALTERA RANGE

alteraRange = (e) => {
        
  let range = 1-this.state.range;

  this.setState({
      selectionRange:({
        startDate: e.selection.startDate,
        endDate: e.selection.endDate,
        key: 'selection',
      }),
      
      range:range
    })

    if(range===1){
      setTimeout(() => {
        this.fechaMenu();
      }, 10);
     
    }

     
      
}

changeExtras = (e,w) => {

  if(w===0){
    this.setState({
      extrasHoras:e,
    })
  }

  if(w===1){
    this.setState({
      extrasProjeto:e,
    })
  }

  if(w===2){
    this.setState({
      extrasDia:e,
    })
  }
}


addExtras = () => {

  if(this.state.extrasHoras!==0 && this.state.extrasProjeto!==0 && this.state.extrasDia!==0){
    
    let valor = this.state.valorHora/160 * this.state.extrasHoras * 1.8;

    let extras = [{'usuario_id':this.state.usuario_id,'empresa_id':this.state.empresa_id,'valor':valor,'horas':this.state.extrasHoras,'projeto_id':this.state.extrasProjeto,'dia':format(this.state.extrasDia,'yyyy-MM-dd')}]

    api.post('insert/insertJson.php?&r='+Math.random(10000),JSON.stringify({ 
      dados: extras,
      sheet: 'horas',
      
    
    })).then(result => {
          
     
      if (result.status === 200) {
        
       this.loadReport();
      
      }})
    


  }else{
    alert('Os 3 campos precisam ser preenchidos!')
  }
}
  render(){


  return (

   
    <ThemeProvider theme={theme}>
       
       
<Meta title="Horas extras"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo">
        <Grid container spacing={3}>
                
                <Grid item xs={4}><div className="titulo">Report Horas</div></Grid>

        
       
        
        <Grid item xs={1}>
          <MySelect list={this.state.horas ? this.state.horas : []} initValue={0} label="Horas extras" change={(e)=>this.changeExtras(e,0)}/>
        </Grid>   
        <Grid item xs={2}>
          <MySelect list={this.state.listProjetos ? this.state.listProjetos : []} initValue={0} label="Projeto" change={(e)=>this.changeExtras(e,1)}/>
        </Grid>   
        <Grid item xs={2}>

        <MyDatePicker onChange={(e)=>this.changeExtras(e,2)} view={["year", "month","date"]} format="dd/MM/yyyy"  label="Data realizada"/>
        </Grid>

        <Grid item xs={1}>
        <div className="bt bgcolor_2 large" onClick={()=>this.addExtras()}>Adicionar</div>
               
       
        </Grid>
        <Grid item xs={2}>
       
       <Button
               size="large"
               onClick={(e)=>this.abreMenuData(e)}
               variant="contained"
               fullWidth={true}
               style={{
                 backgroundColor: "#A456FF",
                 color:"white",
                 height:"50px",
             }}
               
               startIcon={<Today />}
             >
               {this.formatDate(this.state.selectionRange.startDate) + ' --- '+this.formatDate(this.state.selectionRange.endDate)}
         </Button>
   </Grid>


        </Grid>

       
      <div className="mt50">
      <div className="mt30 mb30 mini-titulo">Horas Abertas</div>
                    {this.state.reportHoras ? this.state.reportHoras.map((lines,objectId)=>(
                            <Grid key={objectId} container spacing={0} className="bgcolor_6 br5 mb1">
                                <Grid item xs={3}>
                                <div className="pd20 eli">{lines.projeto}</div>
                                </Grid>
                                <Grid item xs={1} className="tc" ><div className="bgcolor_7 pd20 eli">{lines.horas}</div></Grid>
                                <Grid item xs={2}><div className={lines.valor<0 ? "bgcolor_6 color_5 pd20 tc" : "bgcolor_6 pd20  tc"} ><CurrencyFormat value={lines.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                <Grid item xs={2} className="tc" ><div className="bgcolor_7 pd20">{this.formatDate(lines.dia)}</div></Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={1}><div className="mt5 pd20 tr" ><div className={"bar-status br5 r "+(this.state.colorStatus[lines.horas_status/1])}></div></div></Grid>
                             </Grid>
                    )):''}

{this.state.reportHoras ?
                <div>
                    
                    <Grid container spacing={0} className="bgcolor_1 br5 mb1">
                        <Grid item xs={3}><div className="pd20">Total</div></Grid>
                        <Grid item xs={1}><div className="pd20 b tc">{this.state.reportHoras ? this.state.reportHoras.reduce((a,v) =>  a = a + (v.horas/1) , 0 ) : 0}</div></Grid>
                       
                        <Grid item xs={2}><div className="pd20 b tc"><CurrencyFormat value={this.state.reportHoras ? this.state.reportHoras.reduce((a,v) =>  a = a + (v.valor/1) , 0 ) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </div>
                :''}


<div className="mt50 mb30 mini-titulo">{'Horas Pagas | ' + this.formatDate(this.state.selectionRange.startDate) + ' até '+this.formatDate(this.state.selectionRange.endDate)}</div>

{this.state.reportHorasPagas ? this.state.reportHorasPagas.map((lines,objectId)=>(
                            <Grid key={objectId} container spacing={0} className="bgcolor_6 br5 mb1">
                                <Grid item xs={3}>
                                <div className="pd20 eli">{lines.projeto}</div>
                                </Grid>
                                <Grid item xs={1} className="tc" ><div className="bgcolor_7 pd20 eli">{lines.horas}</div></Grid>
                                <Grid item xs={2}><div className={lines.valor<0 ? "bgcolor_6 color_5 pd20 tc" : "bgcolor_6 pd20  tc"} ><CurrencyFormat value={lines.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                <Grid item xs={2} className="tc" ><div className="bgcolor_7 pd20">{this.formatDate(lines.dia)}</div></Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={1}><div className="mt5 pd20 tr" ><div className={"bar-status br5 r "+(this.state.colorStatus[lines.horas_status/1])}></div></div></Grid>
                             </Grid>
                    )):''}

{this.state.reportHorasPagas ?
                <div>
                    
                    <Grid container spacing={0} className="bgcolor_1 br5 mb1">
                        <Grid item xs={3}><div className="pd20">Total</div></Grid>
                        <Grid item xs={1}><div className="pd20 b tc">{this.state.reportHorasPagas ? this.state.reportHorasPagas.reduce((a,v) =>  a = a + (v.horas/1) , 0 ) : 0}</div></Grid>
                       
                        <Grid item xs={2}><div className="pd20 b tc"><CurrencyFormat value={this.state.reportHorasPagas ? this.state.reportHorasPagas.reduce((a,v) =>  a = a + (v.valor/1) , 0 ) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </div>
                :''}


</div>
          </div>
   
          <Menu
                        
                        anchorEl={this.state.anchorElData}
                        keepMounted
                        open={Boolean(this.state.anchorElData)}
                        onClose={()=>this.fechaMenu()}
                        >
                          
                          <DateRange
                          
                          ranges={[this.state.selectionRange]}
                          onChange={(e)=>this.alteraRange(e)}
                          moveRangeOnFirstSelection={false}
                          rangeColors={['#A456FF']}
                          />
                          
          </Menu>
          

    </ThemeProvider>
  )


}
}