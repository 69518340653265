import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import {AutoComp, MySelect} from '../../components/textField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import ListFreelancers from './listFreelancers';
import ListClientes from './listClientes';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ListUsers from './listUsers';
import './style.css';
import Meta from "../../components/meta";
import TextField from '@mui/material/TextField';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import InputMask from 'react-input-mask';
import { Search } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import axios from "axios";


const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },

}
  
)






export default class Budget extends Component {

  

  state = {
    token: localStorage.getItem("tokens"),
    usuario_id: localStorage['usuario_id'],
    empresa_id: localStorage['empresa_id'],
    permissao: localStorage['permission'],
    loading:true,
    loadPerc:0,
    listSkill:[],
    listUsers:[],
    listFreelancers:[],
    listClientes:[],
    fornecedor:[],
    display:localStorage['usuario_id'],
    optionUsers: [],
    selected:0,
    newUser:['','','',3,0,'',0,'',''],
    permissoes: [{"value":"0","label":"Admin"},{"value":"1","label":"Full"},{"value":"2","label":"Budget"},{"value":"3","label":"Artist"}],
    cargos:[{"value":"0","label":"Diretor"},{"value":"1","label":"Produtor Executivo"},{"value":"2","label":"Atendimento"},{"value":"3","label":"Coordenador"},{"value":"4","label":"Financeiro"},{"value":"5","label":"Artista"}],
    overhead: [{"value":"0","label":"Overhead"},{"value":"1","label":"Horas Vendáveis"}], 
    openFreela:false,
    internacional:false,
    openCliente:false,
    openUser:false,
    new_cnpj_freela:false,
    cpfOrPJ:true,
    classes: ({
      root: {
        flexGrow: 0,
      
        
      },
      paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        padding: 0,
        margin: -2,
    
       
      },
      control: {
        padding: 10,
        marginTop: 5,
        background: "#fc3"
      }
    })
    
  }
 

  
  
  async componentDidMount(){
    
    this.setPage()
    this.getSkills()
    this.getUsers()
    this.getUnidades()

  }

  // LISTA DOS SKILLS

  getSkills = async () => {
    this.toLoad()
    
    api.get('get/getSelect.php?sheet=skill&id='+this.state.empresa_id+'&col_id=empresa_id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({listSkill: result.data})
         
          this.endLoad()
          
      }});

  }


  // LISTA DOS USERS

  getUsers = async () => {
    this.toLoad()
  
    api.get('get/users.php?tipo=0&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({listUsers: result.data})
         
          this.endLoad()
          
      }});

  }


    // LISTA DAS UNIDADES

    getUnidades = async () => {
      this.toLoad()
      
      api.get('get/getSelect.php?sheet=unidades&id='+this.state.empresa_id+'&empresa_id='+this.state.empresa_id+'&col_id=empresa_id&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {
            
            this.setState({listUnidades: result.data})
           
            this.getUsers()
        }});
  
    }


  
// ULTIMA PÁGINA VISITADA

  setPage = async () => {

    const thisPage = window.location.pathname.replace('`','/').replace('`','/').replace('`','/').replace('`','/');
    localStorage.setItem("page",thisPage);
    api.get('update/setPage.php?page='+thisPage+'&token='+this.state.token);

  }

  
  // TOLOAD

      toLoad = () => {
        
        this.setState({
          loadPerc: 2,
          loading:true,
          intervalLoad: setInterval(this.Loader, 200)
        })
      }
      

      endLoad = () => {
        
        this.setState({
          loadPerc: 100,
          loading:false,
          intervalLoad:false,
        })
      }

      Loader = () => {
        if(this.state.loadPerc<100){
            this.setState({
                loadPerc:this.state.loadPerc*1.4,
            })
          }
      }
  

      
      // SELECTED

      handleChange = (event) => {
       
        this.setState({
          selected:(event.target.value)
      })
        
      }

        // DISPLAY LIST

    showList = (e) => {
      
   
        this.setState({
          display:e
        })
    }
  
    changeCNPJFreelancers = (e) => {
    
      this.toLoad()
      api.get('get/get.php?sheet=users&id='+e+'&col_id=id&empresa_id=no&r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {
            
            this.setState({listFreelancers: result.data})
            
            this.endLoad()
            
        }});

    }


    changeCNPJClientes = (e) => {
      
      this.toLoad()

      api.get('get/get.php?sheet=users&id='+e+'&col_id=id&empresa_id=no&r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {
            
            this.setState({listClientes: result.data})
            
            this.endLoad()
            
        }});

    }


    changeSkill = (e) => {
      
      this.toLoad()
      
      api.get('get/get.php?sheet=users&id='+e+'&col_id=skill&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {
            
            this.setState({listFreelancers: result.data})
          
            this.endLoad()
            
        }});
        
      

    }

    // UPDATE FIELDS

    upNewFields = (e,w) =>{
      
      let check=0;
      
      if(w===1){
        let email1 = e.split('@');
        let email2 = e.split('.');
       
        if(email1.length<2 || email2.length<2){
          alert('E-mail inválido.');
          check=1;
        }
        
      }

      if(w===5){
        e = e.replace(',','')/1;

      }

      if(w===9){
        e = e.replace(/\D/g, "");

      }

      
      if(check===0){
        let newUser = this.state.newUser;

        newUser[w] = e;

        this.setState({
            newUser:newUser
        })
      }

    }



    // DIALOG

 

  handleClose = () => {
      
      this.setState({
        internacional:false,
        new_cnpj_freela:false,
        cpfOrPJ:true,
        openFreela: false,
        openCliente: false,
        openUser: false,
        newUser:['','','',3,0,'',0,'']
      });
     
      this.endLoad()

    };
  
  handleConfima = (w) => {
    
    let newUser = this.state.newUser;
    let check=0;

    if(w===0){
      if(newUser[0]==="" || newUser[1]==="" || newUser[2]==="" || newUser[5]===""){
        alert('Todos os campos precisam ser preenchidos.');
        check=1;
      }
    }

    if(w===1){
      
      if(newUser[1]==="" || newUser[8]==="" || newUser[9]==="" || newUser[0]===""){
        alert('Todos os campos precisam ser preenchidos.');
        check=1;
      }




    }

    if(w===2){
      
      if(newUser[1]==="" || newUser[8]==="" || newUser[0]===""){
        alert('Todos os campos precisam ser preenchidos.');
        check=1;
      }
    }


    if(check===0){
      this.saveUser(w)
    }
    
    };

    openDialog = (w) =>{

      let newUser = this.state.newUser;

      if(w===0){
              
        this.setState({
          openUser:true,
          newUser:newUser
        })
      }
      if(w===1){
        this.setState({
          openFreela:true,
          newUser:newUser
        })
      }
      if(w===2){
        this.setState({
          openCliente:true,
          newUser:newUser
        })
      }



    }


    saveUser = (w) => {
    
      this.toLoad();
      api.get('insert/newUser.php?dados='+(this.state.newUser).join(',')+'&sheet=users&id='+w+'&col_id=tipo&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {
           
            if(result.data!=='erro'){
            if(w===0){
              this.setState({
                openUser:true,
                display:result.data
              })
              setTimeout(() => {
                this.getUsers();
              }, 10);
            }
            if(w===1){
              this.setState({
                openFreela:true,
                display:result.data
              })
              setTimeout(() => {
                this.changeCNPJFreelancers(result.data);
              }, 10);
              
            }
            if(w===2){

              this.setState({
                openCliente:true,
                display:result.data
              })
              setTimeout(() => {
                this.changeCNPJClientes(result.data);
              }, 10);
              
            }

            this.handleClose()
          }else{
            alert('CNPJ já existente.')
          }
            
            
        }});

        
    }



// SETAR CNPJ RECEITA


setaCNPJBusca = (e) => {

  let cnpj = e.target.value.replace(/\D/g, "")
  this.setState({
    cnpjBusca:cnpj
  })

}

// SETAR CPF 


setaCPFBusca = (e) => {

  let cpf = e.target.value.replace(/\D/g, "")
  this.setState({
    cpfBusca:cpf
  })

}

// BUSCAR CNPJ RECEITA

buscarCNPJReceita = () => {
  this.toLoad()
  let fornecedor = [{"id":"0","empresa_id":this.state.empresa_id,"permissao":"5","unidade_id":"0","foto":"","nome":"","email":"","senha":"","url":"","contrato":"0","overhead":"0","cargo":"5","bonus":"","tipo":"1","cpf":"","cnpj":"","salario":"0","razao_social":"","inscricao_estadual":"","fone":"","endereco":"","cidade":"","drt":"","aniversario":"","banco":"","agencia":"","conta_corrente":"","confirmado":"0","skill":"0","page":"","token":"","active":"0"}]

  axios.get('https://publica.cnpj.ws/cnpj/'+this.state.cnpjBusca).then(result => {
        
  if (result.status === 200) {

    let resultCNPJ = result.data;

    fornecedor = [{"id":"0","empresa_id":this.state.empresa_id,"permissao":"5","unidade_id":"0","foto":"","nome":resultCNPJ.razao_social,"email":resultCNPJ.estabelecimento.email,"senha":"","url":"","contrato":"0","overhead":"0","cargo":"5","bonus":"","tipo":"1","cpf":"","cnpj":resultCNPJ.estabelecimento.cnpj,"salario":"0","razao_social":resultCNPJ.razao_social,"inscricao_estadual":"","fone":'',"endereco":"","cidade":"","drt":"","aniversario":"","banco":"","agencia":"","conta_corrente":"","confirmado":"0","skill":"0","page":"","token":"","active":"0"}]
    
    api.get('get/get.php?sheet=users&id='+this.state.cnpjBusca+'&col_id=cnpj&empresa_id=no&r='+Math.random(10000)).then(result2 => {
        
      if (result2.status === 200) {


        if(result2.data.length===0){
          
          this.setState({
            fornecedor:fornecedor,
            new_cnpj_freela:true
          })

          this.endLoad()
        

        
      }else{
        alert('CNPJ já cadastrado. Busque novamente.')
       
        this.endLoad()
      }

      }})


      
  }else{
    alert('CNPJ Inválido!')
  }})  

}


// BUSCAR CPF RECEITA

buscarCPFReceita = () => {
  this.toLoad()
  let fornecedor = [{"id":"0","empresa_id":this.state.empresa_id,"permissao":"5","unidade_id":"0","foto":"","nome":"","email":"","senha":"","url":"","contrato":"0","overhead":"0","cargo":"5","bonus":"","tipo":"1","cpf":this.state.cpfBusca,"cnpj":"","salario":"0","razao_social":"","inscricao_estadual":"","fone":"","endereco":"","cidade":"","drt":"","aniversario":"","banco":"","agencia":"","conta_corrente":"","confirmado":"0","skill":"0","page":"","token":"","active":"0"}]

  api.get('get/get.php?sheet=users&id='+this.state.cpfBusca+'&col_id=cpf&empresa_id=no&r='+Math.random(10000)).then(result2 => {
        
    if (result2.status === 200) {

      if(result2.data.length===0){
        
        this.setState({
          fornecedor:fornecedor,
          new_cnpj_freela:true
        })

        this.endLoad()
      

      
    }else{
      alert('CPF já cadastrado. Busque novamente.')
     
      this.endLoad()
    }

    }})

}


// BUSCAR CPF RECEITA

pularInternacional = () => {
  this.toLoad()
  let fornecedor = [{"id":"0","empresa_id":this.state.empresa_id,"permissao":"5","unidade_id":"0","foto":"","nome":"","email":"","senha":"","url":"","contrato":"0","overhead":"0","cargo":"5","bonus":"","tipo":"1","cpf":"","cnpj":"","salario":"0","razao_social":"","inscricao_estadual":"","fone":"","endereco":"","cidade":"","drt":"","aniversario":"","banco":"","agencia":"","conta_corrente":"","confirmado":"0","skill":"0","page":"","token":"","active":"0",'internacional':'1'}]

        
        this.setState({
          fornecedor:fornecedor,
          new_cnpj_freela:true,
          internacional:true
        })

}




  // ALTERA DADOS FORNECEDOR

  dadosFornecedor = (e,w) =>{
    
    if(e.target.value!==""){
      let fornecedor = this.state.fornecedor;
      fornecedor[0][w]=e.target.value;


      if(w==='cnpj' || w==='fone' || w==='cpf'){
        fornecedor[0][w] = fornecedor[0][w].replace(/\D/g, "");
      }

    
      this.setState({
        fornecedor:fornecedor,
      
      })

      }
    }



// CADASTRAR


cadastraFornecedor = () => {

  this.toLoad()

  let fornecedor = this.state.fornecedor;

    if(fornecedor[0]['cnpj']!=='' && fornecedor[0]['razao_social']!=='' && fornecedor[0]['email']!=='' && fornecedor[0]['fone']/1>0){
    
      api.get('insert/insert.php?id=1&sheet=users&col_id=tipo&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        
        fornecedor[0]['id'] = result.data;
        
        api.post('update/updateRow.php?r='+Math.random(10000),JSON.stringify({ 
      
          sheet:'users',
          dados: this.state.fornecedor
        
        })).then(result => {

        this.setState({
          openFreela:false
        })

        })

        this.endLoad()

          }})
        

    }else{
      alert('Você precisa preencher Razão Social, CNPJ, E-mail e Celular!')
    }

}


// CADASTRAR


cadastraFornecedorInternacional = () => {

  this.toLoad()

  let fornecedor = this.state.fornecedor;

  api.get('insert/insert.php?id=1&sheet=users&col_id=tipo&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      
      fornecedor[0]['id'] = result.data;
      
      api.post('update/updateRow.php?r='+Math.random(10000),JSON.stringify({ 
    
        sheet:'users',
        dados: this.state.fornecedor
      
      })).then(result => {

      this.setState({
        openFreela:false
      })

      })

      this.endLoad()

  }})


}

// TIPO CLIENTE

clienteTipo = (w) => {
  this.setState({
    cpfOrPJ:false,
    tipoCliente:w
  })
}

// SETA TIPO CADASTRO

setCadastro = (w) => {

  this.setState({
    whatCadastro:w,
    cpfOrPJ:false
    
  })
}
 
  void = () => {}
  no = () => {
    alert('Você não tem permissõo. Peça ajuda para seu superior.')
  }

  render(props){

    const classes = this.state.classes;

   
  return (

    
    <ThemeProvider theme={theme}>
       
<Meta title="Users"/>
      <div className={classes.root}>
          
          <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
          
          <div className="conteudo">
          <Grid container spacing={3}>
          
            <Grid item xs={3}><div className="titulo">Fornecedores</div></Grid>
              
              <Grid item xs={4}>
              
                { /* <AutoCompSelect notSave={true} list={this.state.listSkill} label="Busque pelo Skill" blur={()=>this.void()} change={(e)=>this.changeSkill(e)}/> */ }
              
              </Grid>


              <Grid item xs={4}>
              
                <AutoComp empresa_id={this.state.empresa_id} sheet="users" tipo="freelancer" label="Busque pelo nome ou CNPJ" blur={()=>this.void()} change={(e)=>this.changeCNPJFreelancers(e)}/>
              
              </Grid>
              
              <Grid item xs={1}>
                <div className="bt large bgcolor_1" onClick={this.state.permissao/1<3 ? ()=>this.openDialog(1) : ()=>this.no()}>Novo</div>
              </Grid>
          </Grid>
            <div className="mb20"></div>

            {this.state.listFreelancers.map((freela,objectID) => (

              <div>

                  <div key={objectID} onClick={()=>this.showList(freela.id)} className={this.state.permissao/1<3 ? "bgcolor_6 listLine cl" : "bgcolor_6 listLine"}>
                  <Grid container spacing={3}>
                   
                      <Grid item xs={3}><div className="listItem">{freela.nome}</div></Grid>
                      <Grid item xs={3}><div className="listItem">{freela.razao_social}</div></Grid>
                      <Grid item xs={3}><div className="listItem">{freela.cnpj}</div></Grid>
                      <Grid item xs={3}><div className="listItem">{freela.email}</div></Grid>
                      
                  </Grid>
                </div>

                
                {this.state.display===freela.id ? <ListFreelancers 
            id={freela.id} 
            /> : ""}

            </div>

            ))}
            
            
            <hr/>

            <Grid container spacing={3}>
                <Grid item xs={7}><div className="titulo">Clientes</div></Grid>
                <Grid item xs={4}><AutoComp empresa_id={this.state.empresa_id} sheet="users" tipo="cliente" label="Busque pelo nome ou CNPJ" blur={()=>this.void()} change={(e)=>this.changeCNPJClientes(e)}/>
                </Grid>
                <Grid item xs={1}>
                <div className="bt large bgcolor_1" onClick={this.state.permissao/1<3 ? ()=>this.openDialog(2) : ()=>this.no()}>Novo</div>
              </Grid>
                
            </Grid>

     

          {this.state.listClientes.map((clients,objectID)=>( 
          <div>
            <div key={objectID} onClick={()=>this.showList(clients.id)} className={"bgcolor_6 listLine cl"}>
              
                <Grid container spacing={3}>
                
                    
                    <Grid item xs={3}><div className="listItem">{clients.nome}</div></Grid>
                    <Grid item xs={3}><div className="listItem">{clients.email}</div></Grid>
                    <Grid item xs={3}><div className="listItem">{clients.fone}</div></Grid>
                    <Grid item xs={3}><div className="icone"><ExpandMoreIcon/></div></Grid>
                    
                </Grid>

            </div>
            
            {this.state.display===clients.id ? <ListClientes 
            id={clients.id} 
            /> : ""}
            
            </div>
            ))}

            <hr/>
          <Grid container spacing={3}>
              <Grid item xs={7}><div className="titulo">Users</div></Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={1}>
                <div className="bt large bgcolor_1" onClick={this.state.permissao/1<3 ? ()=>this.openDialog(0) : ()=>this.no()}>Novo</div>
              </Grid>
          </Grid>
          <div className="mb20"></div>


 {this.state.listUsers.map((users,objectID)=>( 
          <div>
            <div key={objectID} onClick={this.state.permissao/1<3 ? ()=>this.showList(users.id) : ()=>this.void()} className={this.state.permissao/1<3 ? "bgcolor_6 listLine cl" : "bgcolor_6 listLine"}>
              
                <Grid container spacing={3}>
                
                    <Grid item xs={1}><div className="img_user_micro" style={users.foto==="" ? {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'}  : {backgroundImage: 'url(https://www.landscape.to/img/upload/'+users.foto+')'}}></div></Grid>
                    <Grid item xs={4}><div className="listItem">{users.nome}</div></Grid>
                    <Grid item xs={3}><div className="listItem">{users.email}</div></Grid>
                    <Grid item xs={3}><div className="listItem">{users.fone}</div></Grid>
                    <Grid item xs={1}><div className="icone"><ExpandMoreIcon/></div></Grid>
                    
                </Grid>

            </div>
            
            {this.state.display===users.id ? <ListUsers 
            id={users.id} 
            /> : ""}
            
            </div>
            ))}
         
        </div>

      </div>
    

      <Dialog
        open={this.state.openFreela}
        onClose={()=>this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
      >
        <div className="pd30">
        <DialogContent>
          <div className="mini-titulo mb30">Adicionar um novo freelancer</div>
          
          
        

            
        {this.state.new_cnpj_freela ? this.state.fornecedor.map((fornecedor,objectId) => (
          <Grid container spacing={3}>
            
            <Grid item xs={4}>
                  <TextField autoFocus={true} color="secondary" autoComplete="off" variant="outlined" label="Nome" fullWidth={true}  defaultValue={fornecedor.nome} placeholder={"Nome"} onBlur={(e)=>this.dadosFornecedor(e,'nome')}/>
                </Grid>

                
                <Grid item xs={4}>
                  <TextField variant="outlined" label="Razão Social" fullWidth={true}  defaultValue={fornecedor.razao_social} placeholder="Razão Social" onBlur={(e)=>this.dadosFornecedor(e,'razao_social')}/>
                </Grid>
                
                

               

                    <Grid item xs={4}>
                    {!this.state.internacional ? 
                    <InputMask
                          mask="99.999.999/9999-99"
                          disabled={false}
                          maskChar=" "
                          defaultValue={fornecedor.cnpj} 
                          onBlur={(e)=>this.dadosFornecedor(e,'cnpj')}
                          >
                          {() =>   <TextField variant="outlined" label="CNPJ" kind="cnpj" fullWidth={true}  placeholder={"CNPJ"} />}
                      </InputMask>
                    :''}
                    </Grid>
                    
                

                
                
                <Grid item xs={4}>
                  <TextField variant="outlined" label="E-mail" fullWidth={true}  defaultValue={fornecedor.email} placeholder={"E-Mail"} onBlur={(e)=>this.dadosFornecedor(e,'email')}/>
                </Grid>
               
               
                
                <Grid item xs={4}>
                {!this.state.internacional ? 
                <InputMask
                      mask="999.999.999-99"
                      disabled={false}
                      maskChar=" "
                      defaultValue={fornecedor.cpf} 
                      onBlur={(e)=>this.dadosFornecedor(e,'cpf')}
                      >
                      {() =>   <TextField variant="outlined" label="CPF" fullWidth={true}  placeholder={"CPF"} />}
                  </InputMask>
                  :''
                  
                }
                  </Grid>
                  
                 
                  
                    
                  <Grid item xs={4}>
                
                  {!this.state.internacional ? 
                   <TextField variant="outlined" label="Celular" fullWidth={true}  defaultValue={fornecedor.fone} placeholder="Celular" InputProps={this.state.fornecedorNovo === 2 ? {readOnly: true} : {}} onBlur={(e)=>this.dadosFornecedor(e,'fone')}/>
                   :''}
                  </Grid>
                  

                 
                
                <Grid item xs={4}>
                  {
                  !this.state.internacional ? 
                  <TextField variant="outlined" label="Banco" fullWidth={true}  defaultValue={fornecedor.banco} placeholder="Banco" onBlur={(e)=>this.dadosFornecedor(e,'banco')}/>
                  :''}
                  </Grid>

               
                

                
                <Grid item xs={4}>
                {
                  !this.state.internacional ? 
                  <TextField variant="outlined" label="Agência" fullWidth={true}  defaultValue={fornecedor.agencia} placeholder="Agência" onBlur={(e)=>this.dadosFornecedor(e,'agencia')}/>
                  :''}
                </Grid>
                
                
                <Grid item xs={4}>

                {
                !this.state.internacional ? 
                  <TextField variant="outlined" label="Conta Corrente" fullWidth={true}  defaultValue={fornecedor.conta_corrente} placeholder="Conta Corrente" onBlur={(e)=>this.dadosFornecedor(e,'conta_corrente')}/>
                  :''}
                  </Grid>
               
                <Grid item xs={4}>
                  <TextField variant="outlined" label="Endereço" fullWidth={true}  defaultValue={fornecedor.endereco} placeholder="Endereço" onBlur={(e)=>this.dadosFornecedor(e,'endereco')}/>
                </Grid>

                
                <Grid item xs={4}>
                {!this.state.internacional ? 
                  <TextField variant="outlined" label="Cidade" fullWidth={true}  defaultValue={fornecedor.cidade} placeholder="Cidade" onBlur={(e)=>this.dadosFornecedor(e,'cidade')}/>
                :
                <TextField variant="outlined" label="Pais" fullWidth={true}  defaultValue={fornecedor.pais} placeholder="Cidade" onBlur={(e)=>this.dadosFornecedor(e,'pais')}/>
                }
                  </Grid>

                  <Grid item xs={4}>
                  {!this.state.internacional ? 
                    <TextField variant="outlined" label="DRT" fullWidth={true}  defaultValue={fornecedor.drt} placeholder="DRT" InputProps={this.state.fornecedorNovo === 2 ? {readOnly: true} : {}} onBlur={(e)=>this.dadosFornecedor(e,'drt')}/>
                    :''}
                  </Grid>
                  
          </Grid>
            ))
          :
          <Grid container spacing={3}>
            {this.state.cpfOrPJ ? 


            <Grid item xs={12} className="tr">
                    
                    <Grid container spacing={3}>
                      <Grid item xs={4} className="tr">
                          <div className="bt bgcolor_1 dialog wp100 mt30" disableElevation onClick={()=>this.setCadastro('cnpj')} color="secondary">
                            CNPJ
                          </div>
                      </Grid>
                      <Grid item xs={4} className="tr">
                          <div className="bt bgcolor_1 dialog wp100 mt30" disableElevation onClick={()=>this.setCadastro('cpf')} color="secondary">
                            CPF
                          </div>
                      </Grid>
                      <Grid item xs={4} className="tr">
                          <div className="bt bgcolor_1 dialog wp100 mt30" disableElevation onClick={()=>this.pularInternacional('internacional')} color="secondary">
                            Internacional
                          </div>
                      </Grid>
                    </Grid>
              </Grid>
              :

              <Grid item xs={11}>
                <div>
                {
                this.state.whatCadastro==='cnpj' ? 
                      

                      <Grid container spacing={3}>

                         <Grid item xs={11} className="tr">
                            <TextField fullWidth={true} label="Digite o CNPJ para buscar" onBlur={(e)=>this.setaCNPJBusca(e)}/>
                        </Grid>
                            <Grid item xs={1} className="tr">
                          
                            <IconButton size="large" onClick={()=>this.buscarCNPJReceita()}><Search fontSize="large"/></IconButton>
                          </Grid>
                      </Grid>

                      : ''}

                </div>
                <div>
                        {
                        this.state.whatCadastro==='cpf' ? 
                      
                      <Grid container spacing={3}>
                         <Grid item xs={11} className="tr">
                            <TextField fullWidth={true} label="Digite o CPF" onBlur={(e)=>this.setaCPFBusca(e)}/>
                        </Grid>
                            <Grid item xs={1} className="tr">
                          
                            <IconButton size="large" onClick={()=>this.buscarCPFReceita()}><Search fontSize="large"/></IconButton>
                          </Grid>
                      </Grid>
                      :''
                      }
                      
                </div>

                <div>
                        {
                        this.state.whatCadastro==='internacional' ? 
                      
                      <Grid container spacing={3}>
                         <Grid item xs={11} className="tr">
                            <TextField fullWidth={true} label="Digite o CPF" onBlur={(e)=>this.setaCPFBusca(e)}/>
                        </Grid>
                            <Grid item xs={1} className="tr">
                          
                            <IconButton size="large" onClick={()=>this.buscarCPFReceita()}><Search fontSize="large"/></IconButton>
                          </Grid>
                      </Grid>
                      :''
                      }
                      
                </div>


              </Grid>
            
            }
                    

                  

          </Grid>
          
            
          }
          {this.state.new_cnpj_freela ? 
          <div className="bt bgcolor_1 dialog w10 mt30" disableElevation onClick={this.state.internacional ? ()=>this.cadastraFornecedorInternacional() : ()=>this.cadastraFornecedor()} color="secondary">
              Criar
            </div>
            :''
          }
          {this.state.new_cnpj_freela ? 
          <div className="bt bgcolor_5 w10 mt30" disableElevation onClick={()=>this.handleClose()} color="primary" autoFocus>
            Cancelar
          </div>
          :''}
          
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        open={this.state.openCliente}
        onClose={()=>this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
      >
        <div className="pd30">
        <DialogContent>
          <div className="mini-titulo mb30">Adicionar um novo cliente</div>


            {this.state.cpfOrPJ ? 
            <Grid item xs={12} className="tr">
                    
            <Grid container spacing={3}>
              <Grid item xs={6} className="tr">
                  <div className="bt bgcolor_1 dialog wp100 mt30" disableElevation onClick={()=>this.clienteTipo(0)} color="secondary">
                    Nacional
                  </div>
              </Grid>
              
              <Grid item xs={6} className="tr">
                  <div className="bt bgcolor_1 dialog wp100 mt30" disableElevation onClick={()=>this.clienteTipo(1)} color="secondary">
                    Internacional
                  </div>
              </Grid>
            </Grid>
          </Grid>
          :
          <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField autoFocus={true} variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,0)} fullWidth={true} placeholder={'Nome'}/>  
                </Grid>
                <Grid item xs={6}>
                    <TextField variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,8)} fullWidth={true} placeholder={'Razão social'}/>  
                </Grid>
                <Grid item xs={6}>
                    <TextField autoFocus={true} variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,15)} fullWidth={true} placeholder={'Endereço'}/>  
                </Grid>
                <Grid item xs={6}>
                    <TextField variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,14)} fullWidth={true} placeholder={'Inscrição Estadual'}/>  
                </Grid>
                <Grid item xs={6}>
                    <TextField variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,15)} fullWidth={true} placeholder={'Cidade'}/>  
                </Grid>
                <Grid item xs={6}>
                  {this.state.tipoCliente===0 ? 
                    <TextField variant="outlined" type='number' onBlur={(e)=>this.upNewFields(e.target.value,9)} fullWidth={true} placeholder={'CNPJ'}/>  
                    :
                    <TextField autoFocus={true} variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,13)} fullWidth={true} placeholder={'País'}/>  
                    }
                </Grid>
                <Grid item xs={6}>
                    <TextField variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,1)} fullWidth={true} placeholder={'E-mail'}/>  
                </Grid>

          </Grid>

          }

          {!this.state.cpfOrPJ ? 
          <div>
                
                <div className="bt bgcolor_1 dialog w10 mt30" disableElevation onClick={()=>this.handleConfima(2)} color="secondary">
                    Criar
                  </div>
                <div className="bt bgcolor_5 w10 mt30" disableElevation onClick={()=>this.handleClose()} color="primary" autoFocus>
                  Cancelar
                </div>
                
          </div>
          :''}
          
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        open={this.state.openUser}
        onClose={()=>this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
      >
        <div className="pd30">
        <DialogContent>
          <div className="mini-titulo mb30">Adicionar um novo usuário</div>

          <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField autoFocus={true} variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,0)} fullWidth={true} placeholder={'Nome'}/>  
                </Grid>
                <Grid item xs={6}>
                    <TextField variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,1)} fullWidth={true} placeholder={'E-mail'}/>  
                </Grid>
                <Grid item xs={6}>
                    <TextField variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,2)} fullWidth={true} placeholder={'Senha'}/>  
                </Grid>
                <Grid item xs={6}>
                    <MySelect list={this.state.permissoes} initValue={3} label="Permissão" change={(e)=>this.upNewFields(e,3)}/>
                </Grid>
                <Grid item xs={6}>
                    <MySelect list={this.state.cargos} initValue={0} label="Cargo" change={(e)=>this.upNewFields(e,4)}/>                </Grid>
                <Grid item xs={6}>

                  <CurrencyTextField variant="outlined" onBlur={(e)=>this.upNewFields(e.target.value,5)} fullWidth={true} placeholder={'Salário'} currencySymbol="R$"
        outputformat="number"/>  


        
                </Grid>
                <Grid item xs={6}>
                  <MySelect list={this.state.overhead} initValue={0} label="Overhead" change={(e)=>this.upNewFields(e,6)}/> 
                </Grid>
                <Grid item xs={6}>
                    <MySelect list={this.state.listUnidades} initValue={0} label="Unidades" change={(e)=>this.upNewFields(e,7)}/>
                </Grid>
                

          </Grid>
        
        
        <div className="bt bgcolor_1 w10 dialog mt20" disableElevation onClick={()=>this.handleConfima(0)} color="secondary">
            Criar
          </div>
          <div className="bt bgcolor_5 w10 mt20" disableElevation onClick={()=>this.handleClose()} color="primary" autoFocus>
            Cancelar
          </div>
          
          </DialogContent>
          </div>
      </Dialog>


      


    </ThemeProvider>

  )
}

}