import React, { Component } from 'react'

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Meta from '../../components/meta'
import {Grid } from '@material-ui/core'
import { deepPurple, teal } from '@material-ui/core/colors'
import api from '../../services/api'
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import { MySelect } from '../../components/textField';

const styles = {
    textInput: {
      marginRight: '10px',
      color: '#ffffff',
      // #F3C677
    },
    textInputInput: {
        color: '#ffffff',
      // #F3C677
    },
  };

  
  const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
              fontSize: "11px",
              backgroundColor:'#666'
        }},
        MuiIconButton: {
          root: {
              color:'white',
            '&:hover': {
              color: '#4aedc4',
            }
          }
        }
      }
    }
  )

  const listaEmpresas = [
    {
      label:'Selecione',
      value:0
    },
    {
      label:'Transfeera Santa',
      value:1
    },
    {
      label:'Transfeera SAM',
      value:2
    },
    {
      label:'Transfeera Wolfwolf',
      value:3
    },
  ]




export default class index extends Component {

    state = {
        user_id:localStorage['usuario_id'],
        cnpj:null,
        pix:[],
        verbas:[],
        comissoes:[],
        token:null,
        saldo:0,
        showJanela:false,
        transfeera_client_id:process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SANTA,
        transfeera_secret:process.env.REACT_APP_TRANSFEERA_SECRET_SANTA
    }

    

    isValidEmail(email) {
        // regular expression to match email format
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        // test the string against the regex pattern and return true if it matches
        return emailRegex.test(email);
      }

    reorderArray(arr, key) {
    // Sort the array based on the value of the key
    arr.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    
    return arr;
    }
    
    changeCNPJ(w){

      let client_id = 0
      let secret = 0
      if(w===1){
      
        
        client_id = process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SANTA
        secret = process.env.REACT_APP_TRANSFEERA_SECRET_SANTA
        this.getPIXReport('07842589000114')
      }

      if(w===2){
       
        client_id = process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SAM
        secret = process.env.REACT_APP_TRANSFEERA_SECRET_SAM
        this.getPIXReport('17765426000139')
      }

      if(w===3){
       
        client_id = process.env.REACT_APP_TRANSFEERA_CLIENT_ID_WOLF
        secret = process.env.REACT_APP_TRANSFEERA_SECRET_WOLF
        this.getPIXReport('35101686000191')
      }

      this.setState({
        transfeera_client_id:client_id,
        transfeera_secret:secret
      })
      

    }
    getPIXReport = (cnpj) =>{
       
         api.get('get/getPIXReport.php?cnpj='+cnpj+'&r='+Math.random(10000)).then(result => {
              
          if (result.status === 200) {
           
                this.setState({
                    pix:result.data.fornecedores,
                    verbas:result.data.verbas,
                    comissoes:result.data.comissoes
                })

                this.tokenPix()
         

            }})
      }


    // FORMAT DATE

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    }


  // TOKEN PIX

  tokenPix = (w,dt) => {

    axios({
      method: 'post',
      url: 'https://login-api.transfeera.com/authorization', 
      params: {
              "grant_type": "client_credentials",
              "client_id": this.state.transfeera_client_id,
              "client_secret": this.state.transfeera_secret
      },
      data: {
              "grant_type": "client_credentials",
              "client_id": this.state.transfeera_client_id,
              "client_secret": this.state.transfeera_secret
      }
      }).then((response) => {
        
            // handle success
            
            this.setState({
                token:response.data.access_token
            })

            setTimeout(() => {
              this.getSaldo()
            }, 1000);

          
            
      }).catch(function (error) {
        console.log(error)
            
      })

      
    }

    // GET SALDO

    getSaldo(){

      axios({
        method: 'get',
        url: 'https://api.transfeera.com/statement/balance', 
        headers: {
          "Authorization": 'Bearer '+this.state.token,
          "User-Agent": "Santa Transmedia (giovanna@sant.at)",
          "Content-Type": "application/json"
        },
        }).then((response) => {
              
              this.setState({
                  saldo:response.data.value
              })
  
        }).catch(function (error) {
              console.log(error)
              
        })


    }

    abreJanela(id){
      this.setState({
        showJanela:true,
        idJanela:id
      })
    }



  render() {
    return (
        <ThemeProvider theme={theme}>
       
        <Meta title="PIX Report"/>

        <div className="conteudo">

            <Grid container spacing={0}>
                <Grid item xs={3}><div className='titulo mb20'>PIX Report</div></Grid>
                
                <Grid item xs={3}>
                  <MySelect list={listaEmpresas} initValue={0} label="Conta bancária" change={(e)=>this.changeCNPJ(e)}/>
                </Grid>

                <Grid item xs={3}>
                  <div className='mb5 tr'>Saldo Transfeera</div>
                  <div className='sub-titulo tr'>
                    <CurrencyFormat value={this.state.saldo} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />
                  </div>
                </Grid>
                
                <Grid item xs={3}>
                  <div className='mb5 tr'>Total de POs</div>
                  <div className='sub-titulo tr'>
                    <CurrencyFormat value={this.state.pix.reduce((a,v) =>  a = a + (v.valor/1) , 0 )+this.state.verbas.reduce((a,v) =>  a = a + (v.valor/1) , 0 )+this.state.comissoes.reduce((a,v) =>  a = a + (v.valor/1) , 0 )} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />
                  </div>
                </Grid>

            </Grid>
            <div className='w-full mt30'>

            <div className='sub-titulo mt20 mb20'>Verbas</div>
            <div className='mini-titulo mb20'><CurrencyFormat value={this.state.verbas.reduce((a,v) =>  a = a + (v.valor/1) , 0 )} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
            {this.state.verbas.map((pix,obj)=>(

                <div>
                    <Grid key={obj} container spacing={0} className="bgcolor_6 br5 mb1 cl">
                                <Grid item xs={1}><div className="bgcolor_7 pd20 tc" onClick={(w)=>this.abreJanela(pix.id)}>{pix.id}</div></Grid>
                                <Grid item xs={5}>
                                <div onClick={(w)=>this.abreJanela(pix.id)} className="pd20 eli">{pix.projeto}</div>
                                </Grid>
                                <Grid item xs={1}><div className="bgcolor_7 pd20 tc" onClick={(w)=>this.abreJanela(pix.id)}><CurrencyFormat value={pix.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                <Grid item xs={2} className="tc" onClick={(w)=>this.abreJanela(pix.id,pix.empresa_id)}><div className="bgcolor_6 pd20">{this.formatDate(pix.dt_vencimento)}</div></Grid>
                                <Grid item xs={3} className="tc" onClick={(w)=>this.abreJanela(pix.id)}><div className="bgcolor_7 pd20 eli">{pix.plano_conta}</div></Grid>
                                
                    </Grid>

                    
                </div>
            ))}

                    <Grid container spacing={0} className="bgcolor_6 br5 mb1 cl">
                      <Grid item xs={6}><div className='pd20'>Total</div></Grid>
                      <Grid item xs={1}><div className='pd20 b'>{<CurrencyFormat value={this.state.verbas.reduce((a,v) =>  a = a + (v.valor/1) , 0 )} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                      <Grid item xs={5}><div className='pd20'></div></Grid>
                    </Grid>

           
            </div>

            <div className='w-full mt30'>

            <div className='sub-titulo mt20 mb20'>Fornecedores</div>
            <div className='mini-titulo mb20'><CurrencyFormat value={this.state.pix.reduce((a,v) =>  a = a + (v.valor/1) , 0 )} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
            {this.state.pix.map((pix,obj)=>(

                <div>
                    <Grid key={obj} container spacing={0} className="bgcolor_6 br5 mb1 cl">
                                <Grid item xs={1}><div className="bgcolor_7 pd20 tc" onClick={(w)=>this.abreJanela(pix.id)}>{pix.id}</div></Grid>
                                <Grid item xs={5}>
                                <div onClick={(w)=>this.abreJanela(pix.id)} className="pd20 eli">{pix.projeto}</div>
                                </Grid>
                                <Grid item xs={1}><div className="bgcolor_7 pd20 tc" onClick={(w)=>this.abreJanela(pix.id)}><CurrencyFormat value={pix.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                <Grid item xs={2} className="tc" onClick={(w)=>this.abreJanela(pix.id,pix.empresa_id)}><div className="bgcolor_6 pd20">{this.formatDate(pix.dt_vencimento)}</div></Grid>
                                <Grid item xs={3} className="tc" onClick={(w)=>this.abreJanela(pix.id)}><div className="bgcolor_7 pd20 eli">{pix.plano_conta}</div></Grid>
                                
                    </Grid>

                    
                </div>
            ))}

                    <Grid container spacing={0} className="bgcolor_6 br5 mb1 cl">
                      <Grid item xs={6}><div className='pd20'>Total</div></Grid>
                      <Grid item xs={1}><div className='pd20 b'>{<CurrencyFormat value={this.state.pix.reduce((a,v) =>  a = a + (v.valor/1) , 0 )} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                      <Grid item xs={5}><div className='pd20'></div></Grid>
                    </Grid>

           
            </div>

            


            <div className='w-full mt30'>

            <div className='sub-titulo mt20 mb20'>Comissões</div>
            <div className='mini-titulo mb20'><CurrencyFormat value={this.state.comissoes.reduce((a,v) =>  a = a + (v.valor/1) , 0 )} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
            {this.state.comissoes.map((pix,obj)=>(

                <div>
                    <Grid key={obj} container spacing={0} className="bgcolor_6 br5 mb1 cl">
                                <Grid item xs={1}><div className="bgcolor_7 pd20 tc" onClick={(w)=>this.abreJanela(pix.id)}>{pix.id}</div></Grid>
                                <Grid item xs={5}>
                                <div onClick={(w)=>this.abreJanela(pix.id)} className="pd20 eli">{pix.projeto}</div>
                                </Grid>
                                <Grid item xs={1}><div className="bgcolor_7 pd20 tc" onClick={(w)=>this.abreJanela(pix.id)}><CurrencyFormat value={pix.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                <Grid item xs={2} className="tc" onClick={(w)=>this.abreJanela(pix.id,pix.empresa_id)}><div className="bgcolor_6 pd20">{this.formatDate(pix.dt_vencimento)}</div></Grid>
                                <Grid item xs={3} className="tc" onClick={(w)=>this.abreJanela(pix.id)}><div className="bgcolor_7 pd20 eli">{pix.plano_conta}</div></Grid>
                                
                    </Grid>

                    
                </div>
            ))}

                    <Grid container spacing={0} className="bgcolor_6 br5 mb1 cl">
                      <Grid item xs={6}><div className='pd20'>Total</div></Grid>
                      <Grid item xs={1}><div className='pd20 b'>{<CurrencyFormat value={this.state.comissoes.reduce((a,v) =>  a = a + (v.valor/1) , 0 )} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                      <Grid item xs={5}><div className='pd20'></div></Grid>
                    </Grid>

           
            </div>


            


        </div>


        


        </ThemeProvider>
    )
  }
}
