import React,{Component} from 'react'
import Grid from '@material-ui/core/Grid';
import CurrencyFormat from 'react-currency-format';
import ProgressBar from '../../components/progress';


export default class Listas extends Component {

    constructor(props){
        super();
        this.state = {

            empresa_id: localStorage['empresa_id'],
            permission: localStorage['permission'],
            loading:true,
            loadPerc:0,
            colorStatus:['bgcolor_5s','bgcolor_4','bgcolor_2']
            

        }
    }
   

    // LOADING

    toLoad = () => {
        this.setState({
        loadPerc: 2,
        loading:true,
        intervalLoad: setInterval(this.Loader, 200)
        })
    }
    

    endLoad = () => {  
        this.setState({
        loadPerc: 100,
        loading:false,
        intervalLoad:false,
        })
    }

    Loader = () =>{
        if(this.state.loadPerc<100){
            this.setState({
                loadPerc:this.state.loadPerc*1.4,
            })
        }
    }


    // FORMAT DATE

    // FORMAT DATE

formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [day, month, year].join('/');
  }


    
 toCheck = () => {
    this.toLoad();
 }


render(props){
return (
        

        <div>       
                    <Grid container spacing={0}>
                        <div className="w85">
                            <Grid item xs={12}>
                                <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
                            </Grid>
                        </div>
                    </Grid>

                    
                    {this.props.infos ? this.props.infos.map((lines,objectId)=>(
                            <Grid key={objectId} container spacing={0} className="bgcolor_6 br5 mb1 cl">
                                <Grid item xs={3}>
                                    <Grid container spacing={0}>
                                    <Grid item xs={2}><div className="pd20">{lines.id}</div></Grid>
                                    <Grid item xs={10}><div className="pd20 eli">{lines.razao_social}</div></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}><div className="bgcolor_7 pd20 tc"><CurrencyFormat value={this.props.lista===2 ||  this.props.tipo===2 ? lines.valor*-1 : lines.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                <Grid item xs={1} className="tc"><div className="bgcolor_6 pd20">{this.formatDate(lines.dt_vencimento)}</div></Grid>
                                <Grid item xs={3} className="tc"><div className="bgcolor_7 pd20 eli">{lines.plano_conta.length>2 ? lines.plano_conta : '-'}</div></Grid>
                                
                                <Grid item xs={4}><div className="bgcolor_6 pd20 eli">{lines.comentario.length>2 ? lines.comentario : '-'}</div></Grid>
                                
                                
                            </Grid>
                    )):''}

            {this.props.infos ?
                <Grid container spacing={0} className="bgcolor_1 br5 mb1">
                    <Grid item xs={2}><div className="pd5"></div></Grid>
                    <Grid item xs={1}><div className="pd20 b tc"><CurrencyFormat value={this.props.infos ? this.props.infos.reduce((a,v) =>  a = a + (v.valor/1) , 0 ) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.props.lista===2 ||  this.props.tipo===2 ? '-R$':'R$'} /></div></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            :''}
        </div>

        
    )
    
}
}
