import React, { Component} from "react";
import Grid from '@material-ui/core/Grid';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';



const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  overrides: {
      MuiTooltip: {
          tooltip: {
            fontSize: "11px",
            backgroundColor:'#666'
      }},
      MuiIconButton: {
        root: {
            color:'white',
          '&:hover': {
            color: '#4aedc4',
          }
        }
      }
    }

}
  
)
  


export default class People extends Component {


  
 



  render(){

  return (

    
    <ThemeProvider theme={theme}>
       
       <div className="wp100 pd10 cl bgcolor_6 mb1" onClick={(id)=>this.props.openMsn(this.props.id)}>
       <Grid container spacing={0}>
       <Grid item xs={3}>
       <div className="img_user_micro login msn" style={this.props.foto ? {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+this.props.foto+')'} : {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} }></div>
       </Grid>
       <Grid item xs={9}><div className="list-msn">{this.props.nome}</div></Grid>
      </Grid>
      </div>
    {this.props.idMsn === this.props.id ? 
      <div className="wp100 pd10 bgcolor_1 mb1">
        {this.props.superior ? this.props.superior.map((superior,objectId2)=>(
          <div key={objectId2}>
            <div onClick={(id)=>this.props.openMsn(superior.id)} className="pd10 w100 tc bgcolor_7 br5 cl">{superior.nome}</div>
            <div className="arrow-down"></div>
          </div>
        )) : ''}
        <div className="mt20 mb20"><div className="img_user_large" style={this.props.foto==="" ?  {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} : {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+this.props.foto+')'} }></div></div>
        <div className="b w100 tc">{this.props.nome}</div>
        <div className="pd10 w100 tc">{this.props.empresa}</div>
        <div className="pd10 w100 tc">{this.props.nome_cargo}</div>
        <div className="pd10 w100 tc">{this.props.email}</div>
        <div className="pd5 w100 tc">{this.props.fone}</div>

      </div>
    :''}
    </ThemeProvider>
  )


}
}
  