import { Grid, ThemeProvider, createTheme, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import {deepPurple,teal} from '@material-ui/core/colors';
import CurrencyFormat from 'react-currency-format';
import Switch from '@material-ui/core/Switch';

const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },
   
    })
    


  
let valor_bloco_total = 0;
export default class Bloco extends Component {
    
    
    state = {
        
        permission: localStorage['permission'],
        valor_bloco:0
    }

    componentDidUpdate(){
        let lines_api = this.props.list['unico'];
        let valor_bloco = 0;
        for(let i=0;i<lines_api.length;i++){

            valor_bloco += Math.floor(lines_api[i].o_diaria*lines_api[i].o_qt*this.props.valor_total/100/50)*50;
        }

        
        valor_bloco_total=valor_bloco

        
    }
    
    render(props) {
        
       
        return (

                <ThemeProvider theme={theme}>
                  
                  <div className="mt10 mb5">
                                <Grid container spacing={0} className="bgcolor_2 pd30 br5">
                                    
                                <Grid item xs={9} className="mini-titulo"><div className="mt10">LandscapeAPI</div></Grid>

                                        <Grid item xs={3}>
                                            {this.props.api_id/1===0 ?
                                            <div>
                                           
                                            </div>
                                             : 
                                        
                                        <div className="tr mini-titulo mt10">
                                            
                                            {<CurrencyFormat value={this.props.valor_api} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/>}
                                            
                                            </div>
                                        
                                        }
                                        
                                        </Grid>
                                    </Grid>
                               </div>
                                
                    
                    {this.props.list['unico'].map((lines,objectId2)=>(

                        <Grid key={objectId2} container spacing={0} className="bgcolor_7 br5 mb1">
                            {this.props.empresa_id/1 !== 4 ?
                            <Grid item xs={10}><div className="l"><Switch checked={lines.o_qt/1} onChange={(e) => this.props.api_switch(e,objectId2,lines.id)} name="liquor" id={0} /></div><div className="l pd5 mt10">{lines.nome}</div></Grid>
                            : 
                            <Grid item xs={10}><div className="l pd10">{lines.nome}</div></Grid>
                            }
                            <Grid item xs={1}>
                                <div>
                               
                                        {this.props.api_edit === objectId2+'a' && this.props.api_permission/1===1 ? <TextField autoFocus={true} variant="outlined" fullWidth={true} placeholder={lines.o_diaria} onBlur={(e,w,id)=>this.props.blurFieldApi(e,objectId2,lines.id)} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#444'}}}/> : <div onClick={(e)=>this.props.defineApiEdit(objectId2+'a')} className="bgcolor_6 ff">{<CurrencyFormat value={lines.o_diaria} displayType={'text'}  suffix={'%'}/>}</div>}

                              </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="b">
                               
                                       <div className="bgcolor_7 ff"><CurrencyFormat value={Math.floor(this.props.valor_total*lines.o_qt*lines.o_diaria/100/50)*50} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div>

                              </div>
                            </Grid>
                        </Grid>


                    ))}    
                                        
                        
                    
                    

                    <div className="mb5">
                        <Grid container spacing={0} className="bgcolor_6 mt5 br5">
                        <Grid item xs={11}><div className="mt5 pd15"><b>Sub-total</b></div></Grid>
                        <Grid item xs={1}><div className="pd20 r b"> <CurrencyFormat value={valor_bloco_total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                        </Grid>
                    </div>
                    

                
                </ThemeProvider>
            
        )
    }
}
