import React, { Component } from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import {createTheme,MuiThemeProvider } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';

const ProgressTrack = styled.div`
    width: 100%;
    height: 32px;
    background: #3A3A3A;
    border-radius: 5px;
    margin-bottom:2px;
`;


const ProgressLoad = styled.div`
    width: ${props => props.perc}%;
    height: 32px;
    background: ${props => props.color};
    color:#333;
    transition-duration: 6s, 1s;
    transition-property: width, opacity;
    padding: 10px;
    border-radius: 5px;
`;

const theme = createTheme (
    {
     
    overrides: {
        MuiTooltip: {
            tooltip: {
              fontSize: "11px",
              backgroundColor:'#666'
        }},
        
      }
  
  }
    
  )

export default class BarReport extends Component{
    

    constructor(props){
        super();
        this.state = {
            
        }

        
    }

    render(){
        return(
            <div>
                <MuiThemeProvider theme={theme}>
                    <div></div>
                    <ProgressTrack>
                        <Tooltip title={this.props.valor} placement="top" arrow TransitionComponent={Zoom}><ProgressLoad perc={this.props.perc} color={this.props.color}>{this.props.agencia}</ProgressLoad></Tooltip>
                    </ProgressTrack>
                </MuiThemeProvider>
            </div>

             )    
    
        }

    }
    