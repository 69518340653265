import React, { Component} from "react";
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Meta from "../../components/meta";
import {Grid, Tooltip,TextField, Zoom, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { zonedTimeToUtc } from 'date-fns-tz'
import { Lock, LockOpen, InsertDriveFile, Camera, PhotoCamera } from "@material-ui/icons";
import { MyDatePicker, MySelect } from "../../components/textField";
import CurrencyFormat from "react-currency-format";
import './style.css';
import { Redirect } from "react-router";
import { Download } from "@mui/icons-material";

const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
  }

}
  
)

const meses = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
  ];


  const data = [{'id':'Plano',"color": "#91c2b5",'data':[]},{'id':'Realizado',"color": "#A456FF",'data':[]}];

  const data_lucro = [{'id':'Plano',"color": "#91c2b5",'data':[]},{'id':'Realizado',"color": "#A456FF",'data':[]}];

  for(let i=0;i<meses.length;i++){
   
    data[0]['data'].push({'x':meses[i]})
    data[1]['data'].push({'x':meses[i]})
    data_lucro[0]['data'].push({'x':meses[i]})
    data_lucro[1]['data'].push({'x':meses[i]})

  }


  const today = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');

  export default class Report extends Component {

    state = {

      token: localStorage.getItem("tokens").replace('"','').replace('"',''),
      empresa_id: localStorage['empresa_id'],
      permission: localStorage['permission'],
      usuario_id: localStorage['usuario_id'],
      loading:true,
      page:0,
      ops:0,
      edit:false,
      fieldActive:0,
      objActive:0,
      campoActive:0,
      valorCampo:0,
      showEdit:false,
      nomeSessao:'Budget',
      imposto_direto:null,
      imposto_renda:null,
      ano:today.getFullYear(),
      mes:today.getFullYear()+'-'+(today.getMonth()+1),
      mesNr:today.getMonth(),
      light:['','','','','','','','','','','','','',''],
      infos:[],
      infosBack:[],
      faturamento_interno:[],
      faturamento_interno_venda:[],
      colorStatus:['bgcolor_4','bgcolor_5','bgcolor_2','bgcolor_2','bgcolor_2'],
      optReport:[{"id":"0","value":"0","label":"Unidade"},{"id":"1","value":"1","label":"Pilar"}],
      lastMore:-1,
      take:false,
    }
  


  // LOADING

  toLoad = () => {

    let optReport = this.state.optReport;

    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  async componentDidMount(){
   
    if(today.getMonth()===0){
      
      this.setState({
        mes:this.state.ano+'-01',
       
      })
    }

    let optReport = this.state.optReport;
    if(this.state.usuario_id===4342 || this.state.usuario_id===129 || this.state.permission===0){
        optReport.push({"id":"1","value":"1","label":"Pilar"})
    }
    if(this.state.empresa_id/1===13){
        optReport.push({"id":"2","value":"2","label":"Partner Ops"})
        optReport.push({"id":"3","value":"3","label":"All Ops"})
    }
    
    
    
    setTimeout(() => {
        this.setState({ 
            loading: true,
            optReport:optReport 
        });
      this.loadPNL(0);
      this.setPage()
    }, 10);
   
    
  }


  // ULTIMA PÁGINA VISITADA

setPage = async () => {
    
  api.get('update/setPage.php?page=/fpna&token='+this.state.token);

}


  // EXPORT XLS

 // EXPORT XLS

 exportarXLS = ()  =>{
   
    window.open('https://treatments.run/landscape/get/fpna.php?empresa_id='+this.state.empresa_id+'&ano='+this.state.ano+'&mes='+this.state.mes+'&xls=1&ops='+this.state.ops+'&r='+Math.random(10000))

  }

  // PNL ANUAL

  loadPNL = async (tipo) => {
    
    this.toLoad()

    api.get('get/fpna.php?empresa_id='+this.state.empresa_id+'&ano='+this.state.ano+'&mes='+this.state.mes+'&xls=0&ops='+this.state.ops+'&r='+Math.random(10000)).then(res => {
        
        this.setState({
            infos:res.data
            
        })
        setTimeout(() => {
            this.montaPNL(tipo)
            this.endLoad()
        }, 10);
        
    })

    
    
    
    
  }

  // MONTA PNL
  montaPNL = (tipo) => {

    let data = this.state.infos;

    let infosTemp = []

    let imposto = data['Imposto'];

   
    let imposto_direto = 0;
    let imposto_renda = 0;
    if(imposto<18){
        imposto_direto = 9;
        imposto_renda = 34.4;
    }else{
        imposto_direto = 9;
        imposto_renda = 0;
    }

    if(this.state.empresa_id/1===13){
        imposto_direto = 9;
    }

    let receita_budget = [];
    let receita = [];
    let receita_budget_key = [];
    let renda = [];
    let lucro_liquido = [];
    let lucro_liquido_budget = [];

    let ebitda = [];
    let ebitda_budget = [];

    let socios = [];
    let socios_budget = [];

    let landscape = [];
    let landscape_budget = [];

    let keyflagCx = [];

    let cardlight=[];

    let plano_anual = data['Plano Anual'];

    for(let i=0;i<12;i++){
        keyflagCx[i]=0
    }

    
    for(let i=0;i<plano_anual.Faturamento.length;i++){
        
        receita_budget[i] = plano_anual.Faturamento[i].valor/1+plano_anual.Faturamento_Interno[i].valor/1-Math.floor(plano_anual.Faturamento[i].valor*imposto_direto/100)-plano_anual.Custos[i].valor/1;
        
        if(data.KeyFlagcx/1===1){
            keyflagCx[i] = Math.floor(receita_budget[i]*0.1);
        }else{
            keyflagCx[i]=0;
        }

        ebitda_budget[i] = receita_budget[i] - plano_anual['Despesas'][i].valor - plano_anual['People'][i].valor - plano_anual['Shared'][i].valor - keyflagCx[i];

        renda[i] = ebitda_budget[i]*imposto_renda/100;

        lucro_liquido_budget[i] = ebitda_budget[i] - renda[i];

        socios_budget[i] = lucro_liquido_budget[i] * data.Socios[i]/100;

        landscape_budget[i] = lucro_liquido_budget[i] - socios_budget[i];

    }


    if(tipo===0){

        infosTemp = data['Plano Anual'];
       

        this.setState({
            faturamento:infosTemp['Faturamento'],
            faturamento_interno:infosTemp['Faturamento_Interno'],
            faturamento_interno_venda:infosTemp['Faturamento_Interno'],
            custos:infosTemp['Custos'],
            people:infosTemp['People'],
            despesas:infosTemp['Despesas'],
            shared:infosTemp['Shared'],
            key:infosTemp['Key_Venda'],
            plano_anual:plano_anual,
            imposto_direto:imposto_direto,
            imposto_renda:imposto_renda,
            receita:receita_budget,
            receita_budget:receita_budget,
            ebitda:ebitda_budget,
            ebitda_budget:ebitda_budget,
            lucro_liquido:lucro_liquido_budget,
            lucro_liquido_budget:lucro_liquido_budget,
            renda:renda,
            socios:socios_budget,
            landscape:landscape_budget,
            light:[],
            listFaturamento:[],
            listFaturamentoInterno:[],
            listCustos:[],
            listPeople:[],
            listDespesas:[],
            listShared:[],
            keyflagCx:keyflagCx
            
            
            

        })

    }else{

        
        // LIGHT

        for(let i=0;i<=this.state.mesNr;i++){
            if(this.state.mesNr===i){
                cardlight[i]='card-mes';
            }else{
                cardlight[i]='';
            }
        
        }

        for(let i=this.state.mesNr+1;i<14;i++){
                cardlight[i]='card-light';
        }
    }


    if(tipo===1){

        infosTemp = data;

        let shared = infosTemp['Shared']
        let sharedZero = []

        receita = [];
        keyflagCx = [];

        for(let i=0;i<12;i++){
            keyflagCx[i]=0
            sharedZero[i] = {valor:0}
        }

        for(let i=0;i<=this.state.mesNr;i++){
           
            receita[i] = infosTemp.Faturamento[i].valor/1+infosTemp['Faturamento Interno Venda'][i].valor/1-Math.floor(infosTemp.Faturamento[i].valor*imposto_direto/100)-infosTemp.Custos[i].valor/1;
           
            
            if(data.KeyFlagcx/1===1){
                keyflagCx[i] = Math.floor(receita[i]*0.1);
            }else{
                keyflagCx[i]=0;
            }

            if(this.state.empresa_id/1===13 && this.state.ops>1){
                keyflagCx[i] = Math.floor(receita[i]*0.07);
            }

             // ZERAR SHARED CASO SEJA VENDAS LANDSCAPE INTERNO

            
            if(this.state.empresa_id/1===13 && this.state.ops/1===2){

                shared = sharedZero;
            }

            
            ebitda[i] = receita[i] - infosTemp['Despesas'][i].valor - infosTemp['People'][i].valor - shared[i].valor -keyflagCx[i]+infosTemp['Key_Venda'][i];
            renda[i] = ebitda[i]*imposto_renda/100;
            if(renda[i]<0 && this.state.empresa_id/1!==13){
                renda[i]=0;
            }
            lucro_liquido[i] = ebitda[i] - renda[i];
            socios[i] = lucro_liquido[i] * data.Socios[i]/100;
            landscape[i] = lucro_liquido[i] - socios[i];
        }


       
        for(let i=this.state.mesNr+1;i<this.state.faturamento.length;i++){
            receita[i] = 0;
            ebitda[i] = 0;
            renda[i] = 0;
            lucro_liquido[i] = 0;
            socios[i] = 0;
            landscape[i] = 0;
            infosTemp['Faturamento'][i]={valor:0};
            infosTemp['Faturamento Interno Venda'][i]={valor:0};
            infosTemp['Custos'][i]={valor:0};
            infosTemp['People'][i]={valor:0};
            infosTemp['Despesas'][i]={valor:0};
            shared[i]={valor:0};
            infosTemp['Key_Venda'][i]=0;
            infosTemp['Key_Entrega'][i]=0;
        }

       
        
        

        this.setState({

            faturamento:infosTemp['Faturamento'],
            faturamento_interno:infosTemp['Faturamento Interno'],
            faturamento_interno_venda:infosTemp['Faturamento Interno Venda'],
            custos:infosTemp['Custos'],
            people:infosTemp['People'],
            despesas:infosTemp['Despesas'],
            shared:shared,
            key:infosTemp['Key_Venda'],
            receita:receita,
            ebitda:ebitda,
            lucro_liquido:lucro_liquido,
            renda:renda,
            socios:socios,
            landscape:landscape,
            light:cardlight,
            keyflagCx:keyflagCx

        })
        
    }

    if(tipo==2){

        infosTemp = data;

        let shared = infosTemp['Shared'];

        receita = [];

        for(let i=0;i<12;i++){
            keyflagCx[i]=0
        }
        
        for(let i=0;i<infosTemp.Faturamento.length;i++){
            
            receita[i] = infosTemp.Delivery[i].valor/1+infosTemp['Faturamento Interno'][i].valor/1-Math.floor(infosTemp.Delivery[i].valor*imposto_direto/100)-infosTemp['Custos Delivery'][i].valor/1;
            
            if(data.KeyFlagcx/1===1){
                keyflagCx[i] = Math.floor(receita[i]*0.1);
            }else{
                keyflagCx[i]=0;
            }

            if(this.state.empresa_id/1===13 && this.state.ops>1){
                keyflagCx[i] = Math.floor(receita[i]*0.07);
            }
            
            ebitda[i] = receita[i] - infosTemp['Despesas'][i].valor - infosTemp['People'][i].valor - infosTemp['Shared'][i].valor-keyflagCx[i]+infosTemp['Key_Entrega'][i];
            renda[i] = ebitda[i]*imposto_renda/100;
            if(renda[i]<0 && this.state.empresa_id/1!==13){
                renda[i]=0;
            }
            lucro_liquido[i] = ebitda[i] - renda[i];
            socios[i] = lucro_liquido[i] * data.Socios[i]/100;
            landscape[i] = lucro_liquido[i] - socios[i];
        }

        this.setState({
            faturamento:infosTemp['Delivery'],
            faturamento_interno:infosTemp['Faturamento Interno'],
            custos:infosTemp['Custos Delivery'],
            people:infosTemp['People'],
            despesas:infosTemp['Despesas'],
            shared:shared,
            key:infosTemp['Key_Entrega'],
            receita:receita,
            ebitda:ebitda,
            lucro_liquido:lucro_liquido,
            renda:renda,
            socios:socios,
            landscape:landscape,
            light:cardlight,
            keyflagCx:keyflagCx

        })
        
    }


  }


  // NAV REPORT

  navReport = (w) => {

    this.cleanMore()

    if(w===0){
        
        this.setState({
            nomeSessao:"Budget",
            page:w,
        })
        setTimeout(() => {
            this.montaPNL(0);
        }, 10);
    }

    if(w===1){
        
        this.setState({
            nomeSessao:"Sold",
            page:w,
      })
      setTimeout(() => {
        this.montaPNL(1);
      }, 10);
    }

    if(w===2){
       
        this.setState({
            nomeSessao:"Delivered",
            page:w,
        })
        setTimeout(() => {
            this.montaPNL(2);
          }, 10);
    }
    

  }

  // OPEN EDIT

  openEdit = () =>{
    if(this.state.permission/1===0){
            if(this.state.edit){
                this.setState({
                    edit:false
            })
        }else{
            
                this.setState({
                    edit:true
                })
            
        }
    }
  }
  // EDIT FIELD DIALOG

  editField = (id,campo,obj) => {

    if(this.state.edit){
        this.setState({
            fieldActive:id,
            objActive:obj,
            campoActive:campo,
            showEdit:true,
            valorCampo:this.state.plano_anual[campo][obj]['valor']
        })
    }
  }

  // EDIT FIELD

  upValorCampo = (e) =>{
        this.setState({
            valorCampo:e.target.value
        })
  }

  // SALVA FIELD

  saveCampo = () => {

    api.get('update/update.php?sheet=pnl_ano&id='+this.state.fieldActive+'&col=valor&value='+this.state.valorCampo).then(res => {
        let plano_anual = this.state.plano_anual;
        plano_anual[this.state.campoActive][this.state.objActive]['valor'] = this.state.valorCampo;
    
        this.setState({
            plano_anual:plano_anual,
            showEdit:false
        })
    })
    
  }

  // FECHA DIALOG

  fechaDialog = () =>{
    this.setState({
        showEdit:false
    })
  }
  // SHOW MORE

  showMore = (w) =>{

    let listFaturamento = [];
    let listFaturamentoInterno = [];
    let listCustos = [];
    let listDespesas = [];
    let listPeople = [];
    let listShared = [];
    let tipo = 0;

    if(w===this.state.lastMore){

        this.setState({
            listFaturamento:listFaturamento,
            listFaturamentoInterno:listFaturamentoInterno,
            listCustos:listCustos,
            listDespesas:listDespesas,
            listPeople:listPeople,
            listShared:listShared,
            lastMore:-1
        })

        }else{

            
        if(w===0){
            // FATURAMENTO

            tipo = 0;
            if(this.state.page===2){
                tipo=2
            }       

            api.get('get/fpnaList.php?empresa_id='+this.state.empresa_id+'&ano='+this.state.ano+'&mes='+this.state.mesNr+'&tipo='+tipo+'&ops='+this.state.ops+'&r='+Math.random(10000)).then(res => {

                listFaturamento = res.data;

                this.setState({
                    listFaturamento:listFaturamento,
                    listFaturamentoInterno:listFaturamentoInterno,
                    listCustos:listCustos,
                    listDespesas:listDespesas,
                    listPeople:listPeople,
                    listShared:listShared,
                    lastMore:w
                })

            })
        }


        if(w===1){
            // FATURAMENTO INTERNO

            tipo = 1;

            api.get('get/fpnaList.php?empresa_id='+this.state.empresa_id+'&ano='+this.state.ano+'&mes='+this.state.mesNr+'&tipo='+tipo+'&ops='+this.state.ops+'&r='+Math.random(10000)).then(res => {

                listFaturamentoInterno = res.data;

                this.setState({
                    listFaturamento:listFaturamento,
                    listFaturamentoInterno:listFaturamentoInterno,
                    listCustos:listCustos,
                    listDespesas:listDespesas,
                    listPeople:listPeople,
                    listShared:listShared,
                    lastMore:w
                })

            })
        }

        if(w===7){
            // FATURAMENTO INTERNO VENDAS

            tipo = 7;

            api.get('get/fpnaList.php?empresa_id='+this.state.empresa_id+'&ano='+this.state.ano+'&mes='+this.state.mesNr+'&tipo='+tipo+'&ops='+this.state.ops+'&r='+Math.random(10000)).then(res => {

                listFaturamentoInterno = res.data;

                this.setState({
                    listFaturamento:listFaturamento,
                    listFaturamentoInterno:listFaturamentoInterno,
                    listCustos:listCustos,
                    listDespesas:listDespesas,
                    listPeople:listPeople,
                    listShared:listShared,
                    lastMore:w
                })

            })
        }


        if(w===2){
            // CUSTOS

            tipo = 3;

            if(this.state.page===2){
                tipo=32;
            }     

            api.get('get/fpnaList.php?empresa_id='+this.state.empresa_id+'&ano='+this.state.ano+'&mes='+this.state.mesNr+'&tipo='+tipo+'&ops='+this.state.ops+'&r='+Math.random(10000)).then(res => {

                listCustos = res.data;

                this.setState({
                    listFaturamento:listFaturamento,
                    listFaturamentoInterno:listFaturamentoInterno,
                    listCustos:listCustos,
                    listDespesas:listDespesas,
                    listPeople:listPeople,
                    listShared:listShared,
                    lastMore:w
                })

            })
        }
        if(w===3){
            // DESPESAS

            tipo = 4;

            api.get('get/fpnaList.php?empresa_id='+this.state.empresa_id+'&ano='+this.state.ano+'&mes='+this.state.mesNr+'&tipo='+tipo+'&ops='+this.state.ops+'&r='+Math.random(10000)).then(res => {

                listDespesas = res.data;

                this.setState({
                    listFaturamento:listFaturamento,
                    listFaturamentoInterno:listFaturamentoInterno,
                    listCustos:listCustos,
                    listDespesas:listDespesas,
                    listPeople:listPeople,
                    listShared:listShared,
                    lastMore:w
                })

            })
        }
        if(w===4){
            // PEOPLE

            tipo = 5;

            api.get('get/fpnaList.php?empresa_id='+this.state.empresa_id+'&ano='+this.state.ano+'&mes='+this.state.mesNr+'&tipo='+tipo+'&ops='+this.state.ops+'&r='+Math.random(10000)).then(res => {

                listPeople = res.data;

                
                this.setState({
                    listFaturamento:listFaturamento,
                    listFaturamentoInterno:listFaturamentoInterno,
                    listCustos:listCustos,
                    listDespesas:listDespesas,
                    listPeople:listPeople,
                    listShared:listShared,
                    lastMore:w
                })

            })
        }
        if(w===5){
            // SHARED

            tipo = 6;

            api.get('get/fpnaList.php?empresa_id='+this.state.empresa_id+'&ano='+this.state.ano+'&mes='+this.state.mesNr+'&tipo='+tipo+'&ops='+this.state.ops+'&r='+Math.random(10000)).then(res => {

                listShared = res.data;

                this.setState({
                    listFaturamento:listFaturamento,
                    listFaturamentoInterno:listFaturamentoInterno,
                    listCustos:listCustos,
                    listDespesas:listDespesas,
                    listPeople:listPeople,
                    listShared:listShared,
                    lastMore:w
                })

            })
        }
    }

    
  }


  // CHANGE OPS

  changeOps = (e) => {
    
    this.setState({
        ops:e
    })
    setTimeout(() => {
        this.loadPNL(this.state.page)
    }, 10);
  }

  // CHANGE DATE

  changeData = (e) =>{

    if(this.state.selDate){


    this.cleanMore()

    
    if(this.formatDate(e).split('-')[0]/1>2023){
      this.setState({
        selDate:false,
        mes:this.formatDate(e),
        mesNr:this.formatDate(e).split('-')[1]/1-1,
        ano:this.formatDate(e).split('-')[0],
        infos:this.state.infosBack
      })

      setTimeout(() => {
        this.loadPNL(this.state.page)
      }, 0);
    }
    
    if(this.formatDate(e).split('-')[0]/1===2023){
       window.location.href='/ebitda';
        
    }
    if(this.formatDate(e).split('-')[0]/1<2023){
        window.location.href='/report';
    }

    }else{
        this.setState({
          selDate:true
        })
      }
      
    
  }

  // FORMAT DATE

formatDate = (date) => {
  
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, ''].join('-');
}

// PDF

openPDF = () => {
 window.open('reportPDF/'+this.state.ano+'_'+this.state.mes.split('-')[1]+'_'+this.state.empresa_id)
}


// CLEAN MORE

cleanMore = () => {
    let listFaturamento = [];
    let listFaturamentoInterno = [];
    let listCustos = [];
    let listDespesas = [];
    let listPeople = [];
    let listShared = [];


    this.setState({
        listFaturamento:listFaturamento,
        listFaturamentoInterno:listFaturamentoInterno,
        listCustos:listCustos,
        listDespesas:listDespesas,
        listPeople:listPeople,
        listShared:listShared,
        lastMore:-1
    
    })
}

    // UPDATE FOTO

    updatePhoto = () => {
        this.toLoad()
        api.get('insert/fpna_photo_mes.php?ano='+this.state.ano+'&mes='+this.state.mes+'&empresa_id='+this.state.empresa_id).then(res=>{
            this.setState({
                take:true
            })

            this.loadPNL(1);
        })
    }
    // VOID
    void = () => {

    }
  render(){


  return (

   
    <ThemeProvider theme={theme}>
       
<Meta title="PNL Report"/>
{(this.state.permission/1) > 0 ? <Redirect to='/wrong'/> : ''}
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo">
        <Grid container spacing={3}>
                
                <Grid item xs={3}><div className="titulo">PNL Report</div></Grid>
                <Grid item xs={9}>
               
                  
                  <Grid container spacing={1}>
                    <Grid item xs><div className={this.state.page===0 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navReport(0)}>Budget</div></Grid>
                    <Grid item xs><div className={this.state.page===1 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navReport(1)}>Sold</div></Grid>
                    <Grid item xs><div className={this.state.page===2 ? "bt large bgcolor_2" : "bt large bgcolor_8"} onClick={()=>this.navReport(2)}>Delivered</div></Grid>
                    <Grid item xs><MyDatePicker onChange={(e)=>this.changeData(e)} view={["year", "month"]} format="MMM/yyyy" label="Busque pelo mês" startDate={''}/></Grid>
                    <Grid item xs><MySelect list={this.state.optReport} initValue={0} label="Nível Report" change={(e)=>this.changeOps(e)}/></Grid>
                    <Grid item xs>
                        <Grid container justify="flex-end">
                        <Tooltip title="Update foto mensal" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.updatePhoto()}>{this.state.take ?  <Camera/> :<PhotoCamera/>}</IconButton></Tooltip>
                        <Tooltip title="Editar plano anual" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.openEdit()}>{this.state.edit ?  <LockOpen/> :<Lock/>}</IconButton></Tooltip>
                        <Tooltip title="Relatório detalhado" placement="top" arrow TransitionComponent={Zoom}><IconButton><InsertDriveFile onClick={()=>this.openPDF()}/></IconButton></Tooltip>
                        <Tooltip title="Baixar XLS" placement="top" arrow TransitionComponent={Zoom}><IconButton><Download onClick={()=>this.exportarXLS()}/></IconButton></Tooltip>
                        </Grid>
                    </Grid>
                  </Grid>
                  
                  
              </Grid>

       
       
         
        </Grid>
        
        <div className="mt30"></div>
        
       
        <Grid container spacing={0}>
            {meses.map((mes,objectID)=>(
                <Grid  key={objectID}  item xs><div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>{mes}</div></Grid>
            ))}

            <Grid item xs><div className="tc bgcolor_1 label-report">{this.state.mes.split('-')[0]}</div></Grid>
        
        </Grid>

{this.state.loading ? '' : 
        <div>
        <div className="mt20 mb10">Faturamento</div>
          <Grid container spacing={0}>
            {this.state.faturamento.length>0 ? this.state.faturamento.map((mes,objectID)=>(
                <Tooltip title={'R$ ' +Math.floor(this.state.plano_anual.Faturamento[objectID].valor)+' | '+ Math.floor(mes.valor/this.state.plano_anual.Faturamento[objectID].valor*100)+'%' } placement="top" arrow TransitionComponent={Zoom}>
                    <Grid key={objectID} onClick={this.state.page===0 ? ()=>this.editField(mes.id,'Faturamento',objectID) : ()=>this.showMore(0)} item xs>
                        <div className={objectID%2===0 ? "tc bgcolor_3d label-report "+this.state.light[objectID] : "tc bgcolor_3 label-report "+this.state.light[objectID]}>
                       
                                <CurrencyFormat value={this.state.light[objectID]!=='card-light' ? mes.valor : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />
                        
                        </div>
                    </Grid>
                </Tooltip>
                )):''}

                <Grid item xs><div className="tc bgcolor_1 label-report">{this.state.faturamento ? <CurrencyFormat value={Math.floor(this.state.faturamento.reduce((a,b) => a = a+b.valor/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        
          </Grid>

          <div>
            {this.state.faturamento.length>0 ? this.state.listFaturamento.map((projeto,objectID)=>(
                    <div className="mb1 bgcolor_7 mb1" key={objectID}>
                
                    <Grid container spacing={0}>
                        <Grid item xs={1}>
                            <div className="label-report pd10">{projeto.id}</div>
                        </Grid>
                        
                        <Grid item xs={4}>
                            <div className="bgcolor_6 label-report pd10 eli">{projeto.projeto}</div>
                        </Grid>
                        <Grid item xs={7}>
                            <div className="bgcolor_6 label-report pd10 tr">{projeto.valor}</div>
                        </Grid>
                    </Grid>
                    </div>
                ))
                :''
            }
          </div>

            
          <div className="mt20 mb10">Faturamento interno</div>
          <Grid container spacing={0}>


          {this.state.faturamento_interno.length>0 && this.state.page===0 ? this.state.faturamento_interno.map((mes,objectID)=>(
                <Tooltip title={'R$ ' +Math.floor(this.state.plano_anual.Faturamento_Interno[objectID].valor)+' | '+ Math.floor((mes.valor/1)/this.state.plano_anual.Faturamento_Interno[objectID].valor*100) } placement="top" arrow TransitionComponent={Zoom}>
                    <Grid key={objectID} onClick={this.state.page===0 ? ()=>this.editField(mes.id,'Faturamento_Interno',objectID) : ()=>this.showMore(1)} item xs>
                    <div className={objectID%2===0 ? "tc bgcolor_3d label-report-linha "+this.state.light[objectID] : "tc bgcolor_3 label-report-linha "+this.state.light[objectID]}>
                        <CurrencyFormat value={this.state.light[objectID]!=='card-light' ? (mes.valor/1) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />
                    </div>
                    </Grid>
                </Tooltip>
            )):''}
            {this.state.page===0 && this.state.faturamento_interno.length>0? 
                <Grid item xs><div className="tc bgcolor_1 label-report-linha">{this.state.faturamento_interno.length>0 ? <CurrencyFormat value={Math.floor(this.state.faturamento_interno.reduce((a,b) => a = a+b.valor/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
            :
            ''
            }


            
          {this.state.page===1 && this.state.faturamento_interno_venda.length>0  ? this.state.faturamento_interno_venda.map((mes,objectID)=>(
                <Tooltip title={'R$ ' +Math.floor(this.state.plano_anual.Faturamento_Interno[objectID].valor)+' | '+ Math.floor((mes.valor/1)/this.state.plano_anual.Faturamento_Interno[objectID].valor*100) } placement="top" arrow TransitionComponent={Zoom}>
                    <Grid key={objectID} onClick={this.state.page===0 ? ()=>this.editField(mes.id,'faturamento_interno',objectID) : ()=>this.showMore(7)} item xs>
                    <div className={objectID%2===0 ? "tc bgcolor_3d label-report-linha "+this.state.light[objectID] : "tc bgcolor_3 label-report-linha "+this.state.light[objectID]}>
                        <CurrencyFormat value={this.state.light[objectID]!=='card-light' ? (mes.valor/1) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />
                    </div>
                    </Grid>
                </Tooltip>
            )):''}
            
            {this.state.page===1 && this.state.faturamento_interno_venda.length>0 ? 
                <Grid item xs><div className="tc bgcolor_1 label-report-linha">{this.state.faturamento_interno_venda.length>0 ? <CurrencyFormat value={Math.floor(this.state.faturamento_interno_venda.reduce((a,b) => a = a+b.valor/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
            :
            ''
            }


            {this.state.faturamento_interno.length>0 && this.state.page===2 ? this.state.faturamento_interno.map((mes,objectID)=>(
                <Tooltip title={'R$ ' +Math.floor(this.state.plano_anual.Faturamento_Interno[objectID].valor)+' | '+ Math.floor((mes.valor/1)/this.state.plano_anual.Faturamento_Interno[objectID].valor*100) } placement="top" arrow TransitionComponent={Zoom}>
                    <Grid key={objectID} onClick={this.state.page===0 ? ()=>this.editField(mes.id,'Faturamento_Interno',objectID) : ()=>this.showMore(1)} item xs>
                    <div className={objectID%2===0 ? "tc bgcolor_3d label-report-linha "+this.state.light[objectID] : "tc bgcolor_3 label-report-linha "+this.state.light[objectID]}>
                        <CurrencyFormat value={this.state.light[objectID]!=='card-light' ? (mes.valor/1) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />
                    </div>
                    </Grid>
                </Tooltip>
            )):''}
            {this.state.page===2 && this.state.faturamento_interno.length>0 ? 
                <Grid item xs><div className="tc bgcolor_1 label-report-linha">{this.state.faturamento_interno.length>0 ? <CurrencyFormat value={Math.floor(this.state.faturamento_interno.reduce((a,b) => a = a+b.valor/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
            :
            ''
            }
          </Grid>

          <div>
            {this.state.listFaturamentoInterno ? this.state.listFaturamentoInterno.map((projeto,objectID)=>(
                        <div className="mb1 bgcolor_7 mb1" key={objectID}>
                    
                        <Grid container spacing={0}>
                            <Grid item xs={1}>
                                <div className="label-report pd10">{projeto.id}</div>
                            </Grid>
                            
                            <Grid item xs={3}>
                                <div className="bgcolor_6 label-report pd10 eli">{projeto.projeto}</div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="bgcolor_6 label-report pd10 eli">{projeto.empresa}</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="bgcolor_6 label-report pd10 tr"><CurrencyFormat value={projeto.valor_original} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} /></div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="mt5s tr pd20 mt5"><div className={"bar-status br5 r "+(this.state.colorStatus[projeto.cashflow_status/1])}></div></div>
                            </Grid>
                        </Grid>
                        </div>
                    ))
                    :''
                }
          </div>


         
          {this.state.key.length>0 && this.state.page>0 && this.state.empresa_id/1===13 && this.state.ops>1 ?
            <div className="mt20 mb10">Repasse Key</div>
          :
          ''}
          <Grid container spacing={0}>

            

          {this.state.key.length>0 && this.state.page>0 && this.state.empresa_id/1===13 && this.state.ops>1 ? this.state.key.map((mes,objectID)=>(
                
                    <Grid key={objectID}item xs>
                    <div className={objectID%2===0 ? "tc bgcolor_3d label-report-linha "+this.state.light[objectID] : "tc bgcolor_3 label-report-linha "+this.state.light[objectID]}>
                        <CurrencyFormat value={this.state.light[objectID]!=='card-light' ? (mes/1) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />
                    </div>
                    </Grid>
            )):''}

            {this.state.key.length>0 && this.state.page>0 && this.state.empresa_id/1===13 && this.state.ops>1 ? 
                <Grid item xs><div className="tc bgcolor_1 label-report-linha">{this.state.key.length>0 ? <CurrencyFormat value={Math.floor(this.state.keyflagCx.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
            :
            ''
            }

            </Grid>

         

          <Grid container spacing={1}>
            <Grid item xs>
            <div className="nano-titulo mt20 mb10">Impostos Diretos</div>
            </Grid>
            </Grid>
            <Grid container spacing={0}>
            
            {this.state.imposto_direto>-1 && this.state.faturamento ? this.state.faturamento.map((imposto,objectID)=>(
            <Grid item xs><div className={objectID%2===0 ? "tc bgcolor_7 label-report-linha "+this.state.light[objectID] : "tc bgcolor_10 label-report-linha "+this.state.light[objectID]}>{<CurrencyFormat value={imposto.valor ? imposto.valor*this.state.imposto_direto/100 : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div></Grid>
            )) : 0}

            <Grid item xs><div className="tc bgcolor_6 label-report-linha">{this.state.impostos ? <CurrencyFormat value={Math.floor(this.state.impostos.reduce((a,b) => a = a+b.valor/1,0)*this.state.imposto_direto/100)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        </Grid>


        <Grid container spacing={1}>
            <Grid item xs>
            <div className="nano-titulo mt10 mb10">Custos Líquido</div>
            </Grid>
            </Grid>
            <Grid container spacing={0}>
            
            {this.state.custos.length>0 ? this.state.custos.map((custos,objectID)=>(
            <Tooltip title={Math.floor(custos.valor/this.state.faturamento[objectID].valor*100)+'% | '+Math.floor(this.state.plano_anual.Custos[objectID].valor/this.state.plano_anual.Faturamento[objectID].valor*100)+'%'} placement="top" arrow TransitionComponent={Zoom}>
                <Grid  key={objectID}  item xs>
                    <div onClick={this.state.page===0 ? ()=>this.editField(custos.id,'Custos',objectID) : ()=>this.showMore(2)}>
                        <div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>
                            <CurrencyFormat value={custos.valor ? custos.valor : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />
                        </div>
                        </div>
                            <div className={"pd10 br5 mt1 nano-titulo color_7 "+this.state.light[objectID]}>{this.state.custos[objectID].valor ? Math.floor(this.state.custos[objectID].valor/this.state.faturamento[objectID].valor*100)+'%' : '0%'}
                        </div>
                </Grid>
            </Tooltip>
            )) : 0}

            <Grid item xs><div className={"tc bgcolor_6 label-report"}>{this.state.custos ? <CurrencyFormat value={Math.floor(this.state.custos.reduce((a,b) => a = a+b.valor/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
            <div className="pd10 br5 mt1 nano-titulo color_7 b">{this.state.custos ? Math.floor(this.state.custos.reduce((a,b) => a = a+b.valor/1,0)/Math.floor(this.state.faturamento.reduce((a,b) => a = a+b.valor/1,0))*100)+'%' : '0%'}</div></Grid>
        </Grid>

            

            {this.state.listCustos ? this.state.listCustos.map((cost,objProj)=>(

                 
                        
                    <Grid container spacing={0} key={objProj} className="bgcolor_7 br5 mb1 cl">
                        <Grid item xs={1}>
                        <div className="label-report mt5">{cost.id}</div>
                        </Grid>
                        
                        <Grid item xs={3}>
                        <div className="label-report mt5 eli">{cost.projeto}</div>
                        </Grid>

                        <Grid item xs={2}>
                        <div className="label-report eli">{cost.plano_conta}</div>
                        </Grid>

                        <Grid item xs={3}>
                        <div className="label-report eli">{cost.pj_id}</div>
                        </Grid>
                        
                        <Grid item xs={2}>
                        <div className="tr label-report eli"><CurrencyFormat value={cost.valor_original} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} /></div>
                        </Grid>
                        <Grid item xs={1}>
                        <div className="mt5s tr pd20 mt5"><div className={"bar-status br5 r "+(this.state.colorStatus[cost.cashflow_status/1])}></div></div>
                        </Grid>
                    </Grid>
                    
                    ))
                    :
            ''}
          

        <div className="mt10 mb10">Receita</div>
          <Grid container spacing={0}>
            {this.state.receita.length>0 ? this.state.receita.map((mes,objectID)=>(
                <Tooltip title={'R$ ' +Math.floor(this.state.receita_budget[objectID])+' | '+ Math.floor(mes/this.state.receita_budget[objectID]*100)+'%' } placement="top" arrow TransitionComponent={Zoom}>
                    <Grid key={objectID} item xs><div className={objectID%2===0 ? "tc bgcolor_3d label-report "+this.state.light[objectID] : "tc bgcolor_3 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={mes ? mes : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div>
                        <div className={"pd10 br5 mt1 nano-titulo color_7 "+this.state.light[objectID]}>{this.state.receita[objectID] ? Math.floor(this.state.receita[objectID]/this.state.faturamento[objectID].valor*100)+'%' : '0%'}</div>
                    </Grid>
                </Tooltip>
                )):''}

                <Grid item xs><div className="tc bgcolor_1 label-report">{this.state.receita ? <CurrencyFormat value={Math.floor(this.state.receita.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
                    <div className="pd10 br5 mt1 nano-titulo color_7 b">{this.state.receita ? Math.floor(this.state.receita.reduce((a,b) => a = a+b/1,0)/(this.state.faturamento.reduce((a,b) => a = a+b.valor/1,0)+this.state.faturamento_interno.reduce((a,b) => a = a+b.valor/1,0))*100)+'%' : '0%'}</div>
                </Grid>
        
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs>
            <div className="nano-titulo mt5 mb10">Despesas Operacionais</div>
            </Grid>
            </Grid>
            <Grid container spacing={0}>
            
            {this.state.despesas.length>0 ? this.state.despesas.map((despesas,objectID)=>(
            <Tooltip title={'R$ ' +Math.floor(this.state.plano_anual.Despesas[objectID].valor)+' | '+ Math.floor(despesas.valor/this.state.plano_anual.Despesas[objectID].valor*100)+'%'} placement="top" arrow TransitionComponent={Zoom}>
                <Grid  key={objectID}  item xs><div onClick={this.state.page===0 ? ()=>this.editField(despesas.id,'Despesas',objectID) : ()=>this.showMore(3)}>
                    
                    <div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>
                        <CurrencyFormat value={despesas.valor ? despesas.valor : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />
                    </div> 
                        
                    </div>
            <div className={"pd10 br5 mt1 nano-titulo color_7 "+this.state.light[objectID]}>{this.state.despesas[objectID].valor ? Math.floor(this.state.despesas[objectID].valor/this.state.faturamento[objectID].valor*100)+'%' : '0%'}</div>
            </Grid></Tooltip>
            )) : 0}

            <Grid item xs><div className={"tc bgcolor_6 label-report"}>{this.state.despesas ? <CurrencyFormat value={Math.floor(this.state.despesas.reduce((a,b) => a = a+b.valor/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
            <div className="pd10 br5 mt1 nano-titulo color_7 b">{this.state.despesas ? Math.floor(this.state.despesas.reduce((a,b) => a = a+b.valor/1,0)/Math.floor(this.state.faturamento.reduce((a,b) => a = a+b.valor/1,0))*100)+'%' : '0%'}</div></Grid>
        </Grid>

        {this.state.listDespesas ? this.state.listDespesas.map((cost,objProj)=>(

               
            <Grid container spacing={0} key={objProj} className="bgcolor_7 br5 mb1 cl">
                <Grid item xs={1}>
                <div className="label-report pd10">{cost.id}</div>
                </Grid>
                
                <Grid item xs={4}>
                <div className="bgcolor_6 label-report pd10 eli">{cost.plano_conta}</div>
                </Grid>

                <Grid item xs={4}>
                <div className="label-report pd10 eli">{cost.pj_id}</div>
                </Grid>
                
                <Grid item xs={2}>
                <div className="tr bgcolor_6 label-report pd10"><CurrencyFormat value={cost.valor_original} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} /></div>
                </Grid>
                <Grid item xs={1}>
                <div className="mt5 pd20 tr"><div className={"bar-status br5 r "+(this.state.colorStatus[cost.cashflow_status/1])}></div></div>
                </Grid>
            </Grid>
            
            ))
            :
            ''}

        <Grid container spacing={1}>
            <Grid item xs>
            <div className="nano-titulo mt5 mb10">People</div>
            </Grid>
            </Grid>
            <Grid container spacing={0}>
            
            {this.state.people.length>0 ? this.state.people.map((people,objectID)=>(
            <Tooltip title={'R$ ' +Math.floor(this.state.plano_anual.People[objectID].valor)+' | '+ Math.floor(people.valor/this.state.plano_anual.People[objectID].valor*100)+'%'} placement="top" arrow TransitionComponent={Zoom}>
                <Grid  key={objectID}  item xs>
                    <div onClick={this.state.page===0 ? ()=>this.editField(people.id,'People',objectID) : ()=>this.showMore(4)}>
                
                        <div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>
                            <CurrencyFormat value={people.valor ? people.valor : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />
                        </div> 
                        
                    </div>

            <div className={"pd10 br5 mt1 nano-titulo color_7 "+this.state.light[objectID]}>{this.state.people[objectID].valor ? Math.floor(this.state.people[objectID].valor/this.state.faturamento[objectID].valor*100)+'%' : '0%'}</div>
            </Grid></Tooltip>
            )) : 0}

            <Grid item xs><div className={"tc bgcolor_6 label-report"}>{this.state.people ? <CurrencyFormat value={Math.floor(this.state.people.reduce((a,b) => a = a+b.valor/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
            <div className="pd10 br5 mt1 nano-titulo color_7 b">{this.state.people ? Math.floor(this.state.people.reduce((a,b) => a = a+b.valor/1,0)/Math.floor(this.state.faturamento.reduce((a,b) => a = a+b.valor/1,0))*100)+'%' : '0%'}</div></Grid>
        </Grid>

        {this.state.listPeople ? this.state.listPeople.map((cost,objProj)=>(
    
                <Grid container spacing={0} key={objProj} className="bgcolor_7 br5 mb1 cl">
                    <Grid item xs={1}>
                    <div className="label-report pd10">{cost.id}</div>
                    </Grid>
                    
                    <Grid item xs={4}>
                    <div className="label-report pd10 eli">{cost.plano_conta}</div>
                    </Grid>

                    <Grid item xs={4}>
                    <div className="label-report pd10 eli">{cost.pj_id}</div>
                    </Grid>
                    
                    <Grid item xs={2}>
                    <div className="tr label-report pd10"><CurrencyFormat value={cost.valor_original} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} /></div>
                    </Grid>
                    <Grid item xs={1}>
                    <div className="mt5 pd20 tr"><div className={"bar-status br5 r "+(this.state.colorStatus[cost.cashflow_status/1])}></div></div>
                    </Grid>
                </Grid>
                ))
                :
                ''}

        <Grid container spacing={1}>
            <Grid item xs>
            <div className="nano-titulo mt5 mb10">Shared</div>
            </Grid>
            </Grid>
            <Grid container spacing={0}>
            
            {this.state.shared.length>0 ? this.state.shared.map((shared,objectID)=>(
            <Tooltip title={'R$ ' +Math.floor(this.state.plano_anual.Shared[objectID].valor)+' | '+ Math.floor(shared.valor/this.state.plano_anual.Shared[objectID].valor*100)+'%'} placement="top" arrow TransitionComponent={Zoom}>
                <Grid  key={objectID}  item xs>
                    <div onClick={this.state.page===0 ? ()=>this.editField(shared.id,'Shared',objectID) : ()=>this.showMore(5)}>
                       
                        <div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>
                            <CurrencyFormat value={shared.valor ? shared.valor : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />
                        </div> 
                            
                    </div>
            <div className={"pd10 br5 mt1 nano-titulo color_7 "+this.state.light[objectID]}>{this.state.shared[objectID].valor ? Math.floor(this.state.shared[objectID].valor/this.state.faturamento[objectID].valor*100)+'%' : '0%'}</div>
            </Grid></Tooltip>
            )) : 0}

            <Grid item xs><div className={"tc bgcolor_6 label-report"}>{this.state.shared ? <CurrencyFormat value={Math.floor(this.state.shared.reduce((a,b) => a = a+b.valor/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
            <div className="pd10 br5 mt1 nano-titulo color_7 b">{this.state.shared ? Math.floor(this.state.shared.reduce((a,b) => a = a+b.valor/1,0)/Math.floor(this.state.faturamento.reduce((a,b) => a = a+b.valor/1,0))*100)+'%' : '0%'}</div></Grid>
        </Grid>


        {this.state.listShared ? this.state.listShared.map((cost,objProj)=>(

            <Grid container spacing={0} key={objProj} className="bgcolor_7 br5 mb1 cl">
                <Grid item xs={1}>
                <div className="label-report pd10">{cost.id}</div>
                </Grid>
                
                <Grid item xs={8}>
                <div className="label-report pd10 eli">{cost.comentario}</div>
                </Grid>
                
                <Grid item xs={2}>
                <div className="tr label-report pd10"><CurrencyFormat value={cost.valor_original} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} /></div>
                </Grid>
                <Grid item xs={1}>
                <div className="mt5 pd20 tr"><div className={"bar-status br5 r "+(this.state.colorStatus[cost.cashflow_status/1])}></div></div>
                </Grid>
            </Grid>
            ))
            :
            ''}

            {this.state.infos.KeyFlagcx>0 || (this.state.empresa_id/1===13 && this.state.ops>1) ?
            <Grid container spacing={1}>
                <Grid item xs>
                    <div className="nano-titulo mt5 mb10">Key FlagCX</div>
                </Grid>
            </Grid>
            :''}

            {this.state.infos.KeyFlagcx>0 || (this.state.empresa_id/1===13 && this.state.ops>1) ?
            <Grid container spacing={0}>
            
            {this.state.keyflagCx.length>0 ? this.state.keyflagCx.map((key,objectID)=>(
           
                <Grid  key={objectID}  item xs>
                    <div>
                       
                        <div className={objectID%2===0 ? "tc bgcolor_7 label-report "+this.state.light[objectID] : "tc bgcolor_10 label-report "+this.state.light[objectID]}>
                            <CurrencyFormat value={key ? key : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />
                        </div> 
                            
                    </div>
            <div className={"pd10 br5 mt1 nano-titulo color_7 "+this.state.light[objectID]}>{this.state.receita[objectID] ? Math.ceil(key/this.state.receita[objectID]*100)+'% Receita' : '0%'}</div>
            </Grid>
            )) : 0}

            <Grid item xs><div className={"tc bgcolor_6 label-report"}>{this.state.keyflagCx ? <CurrencyFormat value={Math.floor(this.state.keyflagCx.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
            <div className="pd10 br5 mt1 nano-titulo color_7 b">{this.state.keyflagCx ? Math.floor(this.state.keyflagCx.reduce((a,b) => a = a+b/1,0)/Math.floor(this.state.receita.reduce((a,b) => a = a+b/1,0))*100)+'% Receita' : '0%'}</div></Grid>
        </Grid>
        :''}



        <div className="mt10 mb10">EBITDA</div>
          <Grid container spacing={0}>
            {this.state.ebitda.length>0 ? this.state.ebitda.map((mes,objectID)=>(
                <Tooltip title={'R$ ' +Math.floor(this.state.ebitda_budget[objectID])+' | '+ Math.floor(mes/this.state.ebitda[objectID]*100)+'%' } placement="top" arrow TransitionComponent={Zoom}>
                    <Grid key={objectID} item xs><div className={objectID%2===0 ? "tc bgcolor_3d label-report "+this.state.light[objectID] : "tc bgcolor_3 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={mes ? mes : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div>
                        <div className={"pd10 br5 mt1 nano-titulo color_7 "+this.state.light[objectID]}>{this.state.ebitda[objectID] ? Math.floor(this.state.ebitda[objectID]/this.state.faturamento[objectID].valor*100)+'%' : '0%'}</div>
                    </Grid>
                </Tooltip>
                )):''}

                <Grid item xs><div className="tc bgcolor_1 label-report">{this.state.ebitda ? <CurrencyFormat value={Math.floor(this.state.ebitda.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
                    <div className="pd10 br5 mt1 nano-titulo color_7 b">{this.state.ebitda ? Math.floor(this.state.ebitda.reduce((a,b) => a = a+b/1,0)/(this.state.faturamento.reduce((a,b) => a = a+b.valor/1,0)+this.state.faturamento_interno.reduce((a,b) => a = a+b.valor/1,0))*100)+'%' : '0%'}</div>
                </Grid>
        
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs>
            <div className="nano-titulo mt10 mb10">Imposto de Renda</div>
            </Grid>
            </Grid>
            <Grid container spacing={0}>
            
            {this.state.renda.length>0 ? this.state.renda.map((renda,objectID)=>(
            <Grid item xs><div className={objectID%2===0 ? "tc bgcolor_7 label-report-linha "+this.state.light[objectID] : "tc bgcolor_10 label-report-linha "+this.state.light[objectID]}>{<CurrencyFormat value={renda ? renda : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div></Grid>
            )) : 0}

            <Grid item xs><div className="tc bgcolor_6 label-report-linha">{this.state.renda ? <CurrencyFormat value={Math.floor(this.state.renda.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        </Grid>

        <div className="mt20 mb10">Lucro Líquido</div>
          <Grid container spacing={0}>
            {this.state.lucro_liquido ? this.state.lucro_liquido.map((mes,objectID)=>(
                <Tooltip title={'R$ ' +Math.floor(this.state.lucro_liquido_budget[objectID])+' | '+ Math.floor(mes/this.state.lucro_liquido[objectID]*100)+'%' } placement="top" arrow TransitionComponent={Zoom}>
                    <Grid key={objectID} onClick={()=>this.showDir()} item xs><div className={objectID%2===0 ? "tc bgcolor_3d label-report "+this.state.light[objectID] : "tc bgcolor_3 label-report "+this.state.light[objectID]}>{<CurrencyFormat value={mes ? mes : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div>
                        <div className={"pd10 br5 mt1 nano-titulo color_7 "+this.state.light[objectID]}>{this.state.lucro_liquido[objectID] ? Math.floor(this.state.lucro_liquido[objectID]/this.state.faturamento[objectID].valor*100)+'%' : '0%'}</div>
                    </Grid>
                </Tooltip>
                )):''}

                <Grid item xs><div className="tc bgcolor_1 label-report">{this.state.lucro_liquido ? <CurrencyFormat value={Math.floor(this.state.lucro_liquido.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div>
                    <div className="pd10 br5 mt1 nano-titulo color_7 b">{this.state.lucro_liquido ? Math.floor(this.state.lucro_liquido.reduce((a,b) => a = a+b/1,0)/(this.state.faturamento.reduce((a,b) => a = a+b.valor/1,0)+this.state.faturamento_interno.reduce((a,b) => a = a+b.valor/1,0))*100)+'%' : '0%'}</div>
                </Grid>
        
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs>
            <div className="nano-titulo mt10 mb10">Sócios Operacionais</div>
            </Grid>
            </Grid>
            <Grid container spacing={0}>
            
            {this.state.socios.length>0 ? this.state.socios.map((socios,objectID)=>(
            <Grid item xs><div className={objectID%2===0 ? "tc bgcolor_7 label-report-linha "+this.state.light[objectID] : "tc bgcolor_10 label-report-linha "+this.state.light[objectID]}>{<CurrencyFormat value={socios ? socios : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div></Grid>
            )) : 0}

            <Grid item xs><div className="tc bgcolor_6 label-report-linha">{this.state.socios ? <CurrencyFormat value={Math.floor(this.state.socios.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        </Grid>

        <Grid container spacing={1}>
            <Grid item xs>
            <div className="nano-titulo mt10 mb10">Landscape</div>
            </Grid>
            </Grid>
            <Grid container spacing={0}>
            
            {this.state.landscape.length>0 ? this.state.landscape.map((landscape,objectID)=>(
            <Grid item xs><div className={objectID%2===0 ? "tc bgcolor_7 label-report-linha "+this.state.light[objectID] : "tc bgcolor_10 label-report-linha "+this.state.light[objectID]}>{<CurrencyFormat value={landscape ? landscape : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />}</div></Grid>
            )) : 0}

            <Grid item xs><div className="tc bgcolor_6 label-report-linha">{this.state.landscape ? <CurrencyFormat value={Math.floor(this.state.landscape.reduce((a,b) => a = a+b/1,0))} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={''} />:0}</div></Grid>
        </Grid>


        </div>

            }



        </div>
            
          
        <Dialog
                open={this.state.showEdit}
                onClose={()=>this.fechaDialog()}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>{"Setar Valor"}</DialogTitle>
                <DialogContent>
                    
                    <TextField variant="outlined" value={this.state.valorCampo} onChange={(e)=>this.upValorCampo(e)} onBlur={(e)=>this.saveCampo(e)} fullWidth={true}/>
                    <div className="mb20 w50 h1 bgcolor_5"></div>
                </DialogContent>
               
                <DialogActions>
                
                 
                  <div className="bt w100 bgcolor_1" onClick={()=>this.fechaDialog()} color="secondary">
                    Salvar
                  </div>
                  
                  
                </DialogActions>
                
        </Dialog>

    </ThemeProvider>
  )


}
}