import React, { Component} from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@mui/material/TextField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import './style.css';
import Meta from "../../components/meta";
import {Tooltip, Zoom } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { Cake, Favorite, FavoriteBorder } from "@material-ui/icons";
import Linkify from 'react-linkify';
import Delete from "@material-ui/icons/Delete";
import People from './people'
import ReactPlayer from 'react-player';

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noreferrer">
    {text}
  </a>
);


const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'red !important',
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#555", color: 'white'
  },
  overrides: {
      MuiTooltip: {
          tooltip: {
            fontSize: "11px",
            backgroundColor:'#666'
      }},
     
      MuiIconButton: {
        root: {
            color:'white',
          '&:hover': {
            color: '#4aedc4',
          }
        }
      }
    }

}
  
)


  


export default class Vitra extends Component {


  state = {
    empresa_id: localStorage['empresa_id'],
    usuario_id: localStorage['usuario_id'],
    foto: localStorage['foto'],
    nome: localStorage['nome'],
    comentario:[[]],
    comentario_aniver:[[]],
    idMsn:0,
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  // MOUNT
  async componentDidMount(){

    this.loadPosts();
    this.loadPeople();

  }


// POST

loadPosts = () => {
  
  this.toLoad()
   api.get('get/getBlog.php?empresa_id='+this.state.empresa_id+'&usuario_id='+this.state.usuario_id+'&r='+Math.random(10000)).then(result => {
 
     if(result.status===200){
    
        this.setState({
          posts:result.data.posts,
          comentarios:result.data.comentario,
          aniversario:result.data.aniversario,
          comentarios_aniver:result.data.comentario_aniver
        })
       

       this.endLoad()
     }})



}

// PEOPLE

loadPeople = () => {
  
  this.toLoad()
   api.get('get/getPeople.php?r='+Math.random(10000)).then(result => {
 
     if(result.status===200){
    
        this.setState({
          people:result.data,
          
        })
       

       this.endLoad()
     }})



}

      // FORMATE DATE

      formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    }

// VOID

void = () => {}


// POST CHANGE

postLinha = (e) => {

  this.setState({
    post:e
  })
}


// COMENTARIO CHANGE

comentarioLinha = (e,w) => {
 
  let comentario = this.state.comentario;
  comentario[w]['text'] = e.target.value;

  this.setState({
    comentario:comentario
  })
}

// COMENTARIO POST

postaComentario = (e,w,id) => {

  if(e.keyCode === 13){
    
    api.get('insert/comentario.php?post_id='+id+'&nome='+this.state.nome+'&foto='+this.state.foto+'&comentario='+e.target.value+'&r='+Math.random(10000)).then(result => {
 
      if(result.status===200){

        let comentario = this.state.comentario;
        comentario[w]['text'] = '';

          this.setState({
            comentario:comentario
          })
 
          this.loadPosts();
      }})


    
  }
}



// COMENTARIO CHANGE

comentarioLinhaAniver = (e,w) => {
 
  let comentario = this.state.comentario_aniver;
  comentario[w]['text'] = e.target.value;

  this.setState({
    comentario_aniver:comentario
  })
}

// COMENTARIO POST

postaComentarioAniver = (e,w,id) => {

  if(e.keyCode === 13){
    
    api.get('insert/comentario.php?post_id='+id+'&usuario_id='+this.state.usuario_id+'&nome='+this.state.nome+'&foto='+this.state.foto+'&comentario='+e.target.value+'&r='+Math.random(10000)).then(result => {
 
      if(result.status===200){

        let comentario = this.state.comentario_aniver;
        comentario[w]['text'] = '';

          this.setState({
            comentario_aniver:comentario
          })
 
          this.loadPosts();
      }})


    
  }
}




// POST

postBlog = (e) => {
  
  if(e.keyCode === 13){
    
    if(this.state.post){
    this.toLoad()
        api.get('get/meta.php?post='+this.state.post+'&usuario_id='+this.state.usuario_id+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
   
       if(result.status===200){
 
        this.setState({
          post:''
        })
         
        this.loadPosts();
       }})
      }


  }
}

// OPEN LINK


openLink = (w) => {
  window.open(w)
}
    // FORMAT DATE

    formatDate = (date) => {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear(),
          hour = d.getHours()
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [day, month, year].join('/') + ' - '+hour+'h';
  }



  // LIKE

  like = (w,m,id) => {
    if(m>0){

      api.get('update/like.php?w=0&id='+w+'&usuario_id='+this.state.usuario_id+'&r='+Math.random(10000)).then(result => {
   
        if(result.status===200){

          let posts = this.state.posts;

          posts[id]['meu_like']=0;

          this.setState({
            posts: posts
          })
        
        }})

    }else{

      api.get('update/like.php?w=1&id='+w+'&usuario_id='+this.state.usuario_id+'&r='+Math.random(10000)).then(result => {
   
        if(result.status===200){
            
          let posts = this.state.posts;

          posts[id]['meu_like']=1;

          this.setState({
            posts: posts
          })
        
        }})

    }
  }

  // DELETE POST

  deletePost = (w) => {

    api.get('delete/delete.php?sheet=blog&id='+w+'&r='+Math.random(10000)).then(result => {
   
      if(result.status===200){
          
        this.loadPosts()
      
      }})

  

  }

  // OPEN MSN

  openMsn = (id) => {
    this.toLoad()
    api.get('get/getSuperior.php?id='+id+'&r='+Math.random(10000)).then(result => {
   
      if(result.status===200){
        
        this.setState({
          idMsn:id
        })
        this.setState({
          superior: result.data.superiores,
          people_empresa:result.data.empresa
        })
        this.endLoad()
      }})
    
  }

  render(){

  return (

    
    <ThemeProvider theme={theme}>
       
<Meta title="VITRA"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo vitra">
      <Grid container spacing={2}>
      <Grid item xs={12}><div className="titulo">{'Olá, '+this.state.nome }</div></Grid>
      <Grid item xs={12}>
        <TextField autoFocus={true} variant="outlined" value={this.state.post} fullWidth={true} onChange={(e)=>this.postLinha(e.target.value)} onKeyDown={(e)=>this.postBlog(e)} placeholder={'Sharing is caring.'} />
      </Grid>
      </Grid>


      {/* ANIVERSARIO */ }

    {this.state.aniversario ? this.state.aniversario.map((aniver,objectIdA)=>(

<div key={objectIdA} className="post aniver mt30">
      
      <Grid container spacing={2} className="bgcolor_2 br5">
     
      <Grid item xs={1}>
         <Cake style={{ fontSize: 40 }}/>
      </Grid>

        <Grid item xs={11}>
          <div className="font-post-aniver">{"Hoje é aniversário do "+aniver.post+"!"}</div>
          
      </Grid>

     
      </Grid>

        <div className="mt20 wp100">
          <Grid container spacing={2}>
              <Grid item xs={1}>

              <div className="img_user_vitra comment" style={this.state.foto ? {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+this.state.foto+')'} : {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} }></div>
              </Grid>
            
              <Grid item xs={11}>
              <TextField variant="outlined" value={this.state.comentario_aniver[objectIdA] ? this.state.comentario_aniver[objectIdA]['text'] :''} fullWidth={true} onChange={(e)=>this.comentarioLinhaAniver(e,objectIdA)} onKeyDown={(e)=>this.postaComentarioAniver(e,objectIdA,aniver.id)} placeholder={'Comentário..'} InputProps={{style: { marginTop:3,height:0,padding: '18px 0px'}, }}/>
                  
              </Grid>
          
              
          </Grid>
        </div>
        
      
        <div className="mt20 wp100 bloco-comment">
        {this.state.comentarios_aniver[objectIdA] ? this.state.comentarios_aniver[objectIdA].map((com,objectId2)=>(
                     <Grid key={objectId2} container spacing={2}>
                     <Grid item xs={1}>
   
                         <div className="img_user_vitra comment" style={this.state.foto ? {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+com.foto+')'} : {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} }></div>
                         </Grid>
   
                         <Grid item xs={10}>
                           <div className="nome-com b">{com.nome}</div>
                           <div className="text-com">{com.comentario}</div>
                           </Grid>
   
   
                     </Grid>

                  )) : ''}

          
        </div>

     
      

    </div>

    )) : ''}
      


      {this.state.posts ? this.state.posts.map((posts,objectId)=>(


        <div>

        {posts.image ? 
        

              <div className="post lh2 mt30">
              
              <Grid container spacing={2}>
              <Grid item xs={1}><div className="img_user_vitra mt20" style={posts.foto ? {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+posts.foto+')'} : {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} }></div>
              </Grid>
              <Grid item xs={10}>
                <div className="nome-post">{posts.nome}</div>
                <div className="data-post">{this.formatDate(posts.reg_data)}</div>
              </Grid>
              {posts.usuario_id===this.state.usuario_id ? 
              <Grid item xs={1}><IconButton onClick={(w)=>this.deletePost(posts.id)}><Delete/></IconButton></Grid>
              :''}
              {posts.post!==posts.url ? 
              <Grid item xs={12}>
                {posts.post.indexOf('Select VITRA ISSUE')>=0 ? 
                    
                    <Grid item xs={12} className="mini-titulo">
                      <div className="header-vitra"><Linkify componentDecorator={componentDecorator}>{posts.post}</Linkify></div>
                      </Grid>
                    : 
              <Linkify componentDecorator={componentDecorator}>{posts.post}</Linkify>
              }

              </Grid>
              :''}

                
                <Grid item xs={12}>
                  
                  {
                  posts.image.indexOf('mp4')===-1 ? 

                  <div className="photo-post" onClick={(w)=>this.openLink(posts.url)} style={{backgroundImage:'url('+posts.image+')'}}></div>

                  :
                  
                  <div className="photo-post">
                    <ReactPlayer
                    width="640"
                    height="360"
                    playing
                    muted
                    loop
                    url={posts.image}>
                    </ReactPlayer>
                  </div>
                  }
                  
                  
                  
                  
                  </Grid>
                
                  {posts.image.indexOf('mp4')===-1 ? 
                    <div className="bgcolor_6 base-video">
                    
                    <Grid item xs={12} className="mini-titulo">{posts.title}</Grid>

                    
                      
                      <Grid item xs={12} className="post-desc">{posts.desc}</Grid>
                    
                  </div>
                  :
                  ''}
                  
              </Grid>

            

                <div className={posts.image.indexOf('mp4')===-1 ? "mt20 wp100" : 'mt60 wp100'}>
                  <Grid container spacing={2}>
                      <Grid item xs={1}>

                      <div className="img_user_vitra comment" style={this.state.foto ? {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+this.state.foto+')'} : {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} }></div>
                      </Grid>
                    
                      <Grid item xs={10}><TextField variant="outlined" value={this.state.comentario[objectId] ? this.state.comentario[objectId]['text']:''} fullWidth={true} onChange={(e)=>this.comentarioLinha(e,objectId)} onKeyDown={(e)=>this.postaComentario(e,objectId,posts.id)} placeholder={'Comentário..'} InputProps={{style: { marginTop:3,height:0,padding: '18px 0px'}, }}/></Grid>
                  
                      <Grid item xs={1}>
                      <Tooltip title={posts.likes ? posts.likes+" likes" : '0 like'} placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.like(posts.id,posts.meu_like,objectId)}>{posts.meu_like/1>0 ? <Favorite color="secondary"/> : <FavoriteBorder/>}</IconButton></Tooltip>
                      </Grid>
                  </Grid>
                </div>
                
              
                <div className="mt20 wp100 bloco-comment">


                  {this.state.comentarios[objectId] ? this.state.comentarios[objectId].map((com,objectId2)=>(
                     <Grid container spacing={2}>
                     <Grid item xs={1}>
   
                         <div className="img_user_vitra comment" style={this.state.foto ? {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+com.foto+')'} : {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} }></div>
                         </Grid>
   
                         <Grid item xs={10}>
                           <div className="nome-com b">{com.nome}</div>
                           <div className="text-com"><Linkify componentDecorator={componentDecorator}>{com.comentario}</Linkify></div>
                           </Grid>
   
   
                     </Grid>

                  )) : ''}
                  



                  
                </div>

            
              

            </div>

        
        
        : 
        
        
        <div className="post mt30">
      
        <Grid container spacing={2}>
        {posts.post.indexOf('mp4')===-1 ? 
          <Grid item xs={1}>
          
            <div className="img_user_vitra" style={posts.foto ? {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+posts.foto+')'} : {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} }></div>
          
          </Grid>
              :''
        }
              {posts.post.indexOf('mp4')>=0 ? 
              <Grid item xs={12}>
             
                <Grid item xs={12}><div className="video-post" onClick={(w)=>this.openLink(posts.url)}>
                  <video width="800px" height="480px" loop="true" autoplay="autoplay" no-controls muted>
                    <source src="https://treatments.run/landscape/img/teste.mp4" type="video/mp4" />
                    
                  </video>
                  </div></Grid>
                  </Grid>
                :''}
               {posts.post.indexOf('mp4')===-1 ? 
              <Grid item xs={10}>
               
                  <div className="nome-post">{posts.nome}</div>
                
                <div className="data-post">{this.formatDate(posts.reg_data)}</div>
              </Grid>
              :''}

              {posts.usuario_id===this.state.usuario_id ? 
              <Grid item xs={1}><IconButton onClick={(w)=>this.deletePost(posts.id)}><Delete/></IconButton></Grid>
              :''}

        {posts.post.indexOf('mp4')===-1 ? 
          <Grid item xs={11} className="font-post"><Linkify componentDecorator={componentDecorator}>{posts.post}</Linkify></Grid>
        :''}

        </Grid>

          <div className="mt20 wp100">
            <Grid container spacing={2}>
                <Grid item xs={1}>

                <div className="img_user_vitra comment" style={this.state.foto ? {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+this.state.foto+')'} : {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} }></div>
                </Grid>
              
                <Grid item xs={10}><TextField variant="outlined" value={this.state.comentario[objectId] ? <Linkify componentDecorator={componentDecorator}>{this.state.comentario[objectId]['text']}</Linkify> :''} fullWidth={true} onChange={(e)=>this.comentarioLinha(e,objectId)} onKeyDown={(e)=>this.postaComentario(e,objectId,posts.id)} placeholder={'Comentário..'} InputProps={{style: { marginTop:3,height:0,padding: '18px 0px'}, }}/></Grid>
                  
                <Grid item xs={1}>
                <Tooltip title={posts.likes ? posts.likes+" likes" : '0 like'} placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.like(posts.id,posts.meu_like,objectId)}>{posts.meu_like/1>0 ? <Favorite color="secondary"/> : <FavoriteBorder/>}</IconButton></Tooltip>
                </Grid>
            </Grid>
          </div>
          
        
          <div className="mt20 wp100 bloco-comment">
            
                  {this.state.comentarios[objectId] ? this.state.comentarios[objectId].map((com,objectId2)=>(
                     <Grid container spacing={2}>
                     <Grid item xs={1}>
   
                         <div className="img_user_vitra comment" style={this.state.foto ? {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+com.foto+')'} : {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} }></div>
                         </Grid>
   
                         <Grid item xs={10}>
                           <div className="nome-com b">{com.nome}</div>
                           <div className="text-com"><Linkify componentDecorator={componentDecorator}>{com.comentario}</Linkify></div>
                           </Grid>
                      
   
                     </Grid>

                  )) : ''}


            
          </div>

       
        

      </div>
      
      }

        </div>
      

      )) : ''}

      


   
      
<div className="lat-msn w20">
  {this.state.people ? this.state.people.map((people,objectId)=>(
  <People id={people.id} idMsn={this.state.idMsn} openMsn={(id)=>this.openMsn(id)} nome={people.nome} nome_cargo={people.nome_cargo} fone={people.fone} email={people.email} foto={people.foto} superior={this.state.superior} empresa={this.state.people_empresa}/>
  )):''}
</div>
      
      
</div>



    </ThemeProvider>
  )


}
}
  