import React, { Component} from "react";
import Grid from '@material-ui/core/Grid';
import {MySelect} from '../../components/textField';
import TextField from '@mui/material/TextField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import './style.css';
import Meta from "../../components/meta";
import { Button } from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import axios from "axios";
import { BarLoader } from "react-spinners";
import CurrencyFormat from "react-currency-format";
import InputMask from 'react-input-mask';


const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  overrides: {
      MuiTooltip: {
          tooltip: {
            fontSize: "11px",
            backgroundColor:'#666'
      }},
      MuiIconButton: {
        root: {
            color:'white',
          '&:hover': {
            color: '#4aedc4',
          }
        }
      }
    }

}
  
)
  


export default class Viewsheet extends Component {


  state = {
    projeto_id: this.props.match.params.id,
    valorTeste:[{'value':80,'label':'São Paulo'},{'value':60,'label':'Outra Localidade'}],
    chaves:[{'value':-1,'label':'Selecione'},{'value':0,'label':'E-mail'},{'value':1,'label':'CPF'},{'value':2,'label':'Celular'}],
    showRecibo:false,
    assinado:false,
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  // MOUTH
  async componentDidMount(){

    api.get('get/get.php?id='+this.state.projeto_id+'&col_id=id&sheet=projeto&empresa_id=no&r='+Math.random(10000)).then(result => {
   
        if(result.status===200){
  
          let projeto = result.data[0];

          this.setState({
            projeto:projeto.id+' '+projeto.cliente+' '+projeto.nome,
            empresa_id:projeto.empresa_id
          })
          
          api.get('get/get.php?id='+projeto.empresa_id+'&col_id=id&sheet=empresa&empresa_id=no&r='+Math.random(10000)).then(result2 => {
   
            if(result.status===200){
              this.setState({
                nome_empresa:result2.data[0].nome
              })

            }})
          }})
 
        

  }

  //  SALVAR CACHE TESTE

  enviarCacheTeste = () => {

    if(this.state.nome && this.state.cpf && this.state.valor && this.state.drt && this.state.pix && this.state.img && this.state.email && this.state.celular){
    
    this.toLoad()
     api.get('insert/cacheteste.php?nome='+this.state.nome+'&cpf='+this.state.cpf+'&empresa_id='+this.state.empresa_id+'&email='+this.state.email+'&valor='+this.state.valor+'&projeto_id='+this.state.projeto_id+'&img='+this.state.img+'&drt='+this.state.drt+'&pix='+this.state.pix+'&celular='+this.state.celular+'&tipo='+this.state.tipo+'&r='+Math.random(10000)).then(result => {
   
       if(result.status===200){

        if(result.data!=='ERRO'){
         this.setState({
          showRecibo:true
         })
 
         this.endLoad()
        }else{
          alert('Talento já registrado para este projeto!')
        }
       }})

      }else{
        alert('Você precisa preencher todos os campos e subir a imagem do seu DRT.')
      }
 
 
     
   }


  


// FOTO

fileSelect = (event) => {
    this.setState({ uploading: true }); 

    const fd = new FormData();
    fd.append('image',event.target.files[0],event.target.files[0].name)
    axios.post('https://treatments.run/landscape/upload.php?id=0&sheet=no&r='+Math.random(10000),fd).then(res => {
    
        this.setState({ 
            uploading: false,
            img: res.data
            }); 

            
    })
}


    // UPDATE TESTE 

    upCacheTeste = (e,w) => {
  
        if(w===0){
            this.setState({valor:e})
        }

        if(w===1){
            this.setState({nome:e})
        }

        if(w===2){
            this.setState({cpf:e})
        }
        if(w===3){
            this.setState({tipo:e})
           
            if(e===0){
              this.setState({pix:this.state.email})
            }
            if(e===1){
              this.setState({pix:this.state.cpf})
            }
            if(e===2){
              this.setState({pix:this.state.celular})
            }
            
        }
        if(w===4){
          
            this.setState({pix:e})
        }
        if(w===5){
          this.setState({drt:e})
      }
      if(w===6){
        this.setState({email:e})
      }
      
      if(w===8){
        this.setState({celular:e})
      }
    
    }

    assinar = () => {
      this.setState({
        assinado:true
      })
    }


    assinarRecibo = () => {
      this.setState({
        concluido:true
      })
    }
// VOID

void = () => {}

  render(){

  return (

    
    <ThemeProvider theme={theme}>
       
<Meta title="Reembolso"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo">
            <Grid container spacing={3}>
              
              <Grid item xs={12}><div className="sub-titulo">{this.state.projeto}</div></Grid>
              <input type="file" name="the_file" onChange={this.fileSelect} style={{display:'none'}} ref={fileInput => this.fileInput = fileInput}></input>

              
              <Grid item xs={12}>
                </Grid>
                {!this.state.showRecibo && !this.state.concluido ? 
              <div className="w100">
                <Grid item xs={12}>
                    <MySelect list={this.state.valorTeste} initValue={0} label="Local do teste" change={(e)=>this.upCacheTeste(e,0)}/>
                </Grid><br></br>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth={true}  label="Nome" onBlur={(e)=>this.upCacheTeste(e.target.value,1)} ></TextField>
                </Grid>
                <br></br>
                <Grid item xs={12}>

                <InputMask
                      mask="999.999.999-99"
                      disabled={false}
                      maskChar=" "
                      onBlur={(e)=>this.upCacheTeste(e.target.value,2)}
                      >
                      {() =>   <TextField variant="outlined" fullWidth={true}  label="CPF" ></TextField>}
                  </InputMask>
                    
                </Grid>

                

                <br></br>
                
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth={true}  label="E-mail" onBlur={(e)=>this.upCacheTeste(e.target.value,6)} ></TextField>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth={true}  label="Celular" onBlur={(e)=>this.upCacheTeste(e.target.value,8)} ></TextField>
                </Grid>
                <br></br>

                
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth={true}  label="Número DRT" onBlur={(e)=>this.upCacheTeste(e.target.value,5)} ></TextField>
                </Grid>
                <br></br>

                <div className="micro-titulo mt10">Dados PIX</div>
                <br></br>
                <br></br>
                <Grid item xs={12}>
                    <MySelect list={this.state.chaves} initValue={-1} label="Tipo de Chave Pix" change={(e)=>this.upCacheTeste(e,3)}/>
                </Grid>
                <br></br>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth={true}  placeholder="Chave PIX"  value={this.state.pix}
                    InputProps={{
                      readOnly: true,
                  }}>

  </TextField>
                </Grid>
                <br></br>
                <br></br>
                
                <br></br>
                <Grid item xs={12}>
                <Button
                          size="large"
                            onClick={()=>this.fileInput.click()}
                            variant="contained"
                            fullWidth={true}
                            color="secondary"
                            style={{
                              align: "left",
                              height:"50px",
                              

                          }}
                            
                            startIcon={this.state.uploading ? 
                                <BarLoader
                                    
                                size={30}
                                color={"white"}
                                loading={this.state.uploading}
                                />
                                : <PhotoCamera/>}
                          > {this.state.img ? 'Upload concluído!' : 'Upload DRT'} </Button>

                </Grid>
                <br></br>
                <Grid item xs={12}>
                  {this.state.loading ? 

                      <div className="bt large bgcolor_1" color="primary">
                      Enviando..
                      </div>
                  
                :
                    <div className="bt large bgcolor_1" onClick={()=>this.enviarCacheTeste()} color="secondary">
                        Enviar
                    </div>
                    }
                </Grid>
                    
                </div>  
                :
                
                <Grid container spacing={3}>
                  {!this.state.concluido ? 
                  <Grid item xs={12}>
                    <div className="recibo wp100">
                      <h1>RECIBO</h1><br></br>

                  Eu, <b>{this.state.nome}</b> portador do CPF <b>{this.state.nome}</b> e DRT <b>{this.state.drt}</b> recebi de <b>{this.state.nome_empresa}</b> declaro que recebi, nesta data, a quantia de <b>{<CurrencyFormat value={this.state.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</b>, a título de cachê, pela participação no teste de VT do job <b>{this.state.projeto}</b><br/><br/>

                  {this.state.hoje}
                  {this.state.assinado===false ? 
                  <div onClick={()=>this.assinar()} className="assinar tc">click para assinar</div>
                  :
                  <div className="assinado tc">{this.state.nome}</div>
                  }
                  <div className="w100 tc">{this.state.nome}</div>
                  

                  </div>

                  {this.state.loading ? 

                      <div className="bt large bgcolor_1 mt10" color="primary">
                      Enviando..
                      </div>

                      :
                      <div className="bt large bgcolor_1 mt10" onClick={()=>this.assinarRecibo()} color="secondary">
                        Concluir
                      </div>
                      }
                  </Grid>
                  :
                  <div className="concluido">Concluído! Você receberá seu cachê por PIX em breve.</div>
                  }

                </Grid>
                }

            </Grid>
          
          
</div>

    </ThemeProvider>
  )


}
}
  