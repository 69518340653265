import React, { Component } from "react";
import {Grid,ThemeProvider,createTheme} from '@material-ui/core';
import api from '../../services/api';
import {Helmet} from 'react-helmet';
import Meta from "../../components/meta";
import ProgressBar from '../../components/progress';
import { MySelect } from '../../components/textField';
import { ReactComponent as LogoLandscape } from '../../../src/svg/logo.svg'
import {deepPurple,teal} from '@material-ui/core/colors';
import CurrencyFormat from "react-currency-format";
import { Redirect } from "react-router";
import { ResponsiveBar } from '@nivo/bar'
import ListaProjetos from '../cashflow/listaProjeto';

const theme = createTheme (
{
  palette: {
    type: "dark",
    primary: deepPurple,
    secondary: teal,
  
},axis: {
    ticks: {
        line: {
          stroke: "#555555"
        },
        text: {
          fill: "#888"
        }
      },
  },
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
    
  }
},

)


const nucleos = ['Direção','Produçao','Segurança e testes','Fotografia','Equipamento de Câmera','Luz e Infra','Arte','Locação','Figurino e Make','Produção de Elenco','Elenco','Transporte','Alimentação','Acompanhamento'];


export default class XRay extends Component {


  state = {
   
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    projeto_id:this.props.match.params.id.split('_')[0],
    empresa_id:this.props.match.params.id.split('_')[1],
    nomeEmpresa:'',
    centros:[],
    hide_impostos:false
  }


  async componentDidMount(){
    
    this.loadPdf();
    
  }



  // UTILS

    onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }


  // LOADING

  toLoad = () => {
    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  


  // LOAD PDF

  loadPdf = async () => {

    this.toLoad();

   
    api.get('get/pnl_xray.php?&empresa_id='+this.state.empresa_id+'&id='+this.state.projeto_id+'&r='+Math.random()).then(result => {
        
      if (result.status === 200) {
       
       
        this.setState({
            nomeEmpresa:result.data.nomeEmpresa,
            projetos:result.data.projetos,
            api:result.data.api,
            
        })

        this.reportProjetos();

      }})

    }

    // DOWNLOAD XLS

    downloadExcel = () => {
            window.open('https://treatments.run/landscape/get/xls_xray.php?projeto_id='+this.state.projeto_id+'&r='+Math.random(10000))
      }


    //  REPORT PROJETOS

    reportProjetos = (w) => {
    
      api.get('get/cashflowReport.php?tipo=5&projeto='+this.state.projeto_id+'&plano_conta_id='+w+'&start=2000-01-01&end=2050-01-01&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
    
        
        if(result.status===200){
           
            let linhas = result.data.linhas;
            
            let centros_clean = this.state.centros;

            let hide_impostos = true;

            if(w/1>0){

            
            }else{
                let centros = [];

                for(let i=0;i<linhas.length;i++){
                    centros.push({'label':linhas[i].plano_conta,'value':linhas[i].plano_conta_id});
                    
                }
                    const map = new Map();
                    for (const item of centros) {
                        if(!map.has(item.label)){
                            map.set(item.label, true);    // set any value to Map
                            centros_clean.push({
                                label: item.label,
                                value: item.value
                            });
                        }
                    }
    
                centros_clean.sort()
                hide_impostos = false;
            }

            
          this.setState({
            
            cashflowReportProjeto: result.data.linhas,
            cashflowReportProjetoImpostos: result.data.impostos,
            cashflowReportProjetoEntradas: result.data.entradas,
            centros:centros_clean,
            hide_impostos:hide_impostos,
            out:result.data.entradas_out
            
          })
  
          this.endLoad()
        }})
  
  
      
    }


void = () => {}

  
render() {
    return (
  
      
      <ThemeProvider theme={theme}>
            {this.state.permission > 2 ? <Redirect to='/login'/> : ''}
            {this.state.loading ? <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/> : ''}
            <Meta title={"PNL Report | "+this.state.nomeEmpresa} />
          <div className="conteudo print">
             
          <Helmet>
                      
                    
                      <style>
                        
                         {`
                              body {
                                  
                                  margin:0px;
                                  padding:0px;
                                  line-height:20px;
                                  font-size: 11px;
                                  
                                  
                              }
  
                              .menu{
                                display: none !important;
                            }
                            
                            .footer{
                                display: none !important;
                            }

                            .conteudo.print{
                                padding:0px;
                                
                              }
  .page{
    background:#252525;
    background-size:cover;
    width:100vw;
    
    color:white;
    margin:auto;
    padding:30px;
    padding-bottom:50px;
}

.page2{
    background:#252525;
    background-size:cover;
    width:100vw;
    
    padding:30px;
    padding-bottom:50px;
    margin:auto;
}
  
.barPDF{
    height: 200px;
}

.barPDF2{
    height: 320px;
}

.logo-landscape.pdf2{
    width:80%;
    margin-top:-30px;
    margin-left:0px
}
  
                         `}
                         
                      </style>
                  </Helmet>

                  
                  
                  
                  
                 
                 {this.state.projetos ? this.state.projetos.map((projeto,objectId)=>
                  <div className="page2">

                  <Grid container spacing={0}>
                  <Grid item xs={2}>
                  <LogoLandscape className="logo-landscape pdf2 tl"/>
                  </Grid>
                      <Grid item xs={10}>
                      <div className="mt30 sub-titulo"><div className="l color_1 b">{projeto.id}</div><div className="l ml10">{projeto.cliente}</div><div className="l ml10 color_1">{projeto.nome}</div></div>
                      </Grid>
                      
                  </Grid>

                    <div className="mt50"></div>
                    <Grid container spacing={0}>
                        <Grid item xs={4}><div className="nano-titulo">Valor vendido</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Receita Prevista</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo"></div></Grid>
                    </Grid>
                        
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className="sub-titulo pdf b">
                                <CurrencyFormat value={projeto.valor}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}><div className="sub-titulo pdf">
                            <div className="l b color_5">{Math.floor(projeto.receitaPrevista/projeto.valor*100)+'%'}</div>
                                <div className="l ml10">
                                    <CurrencyFormat value={projeto.receitaPrevista}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            
                            </Grid>
                    </Grid>

                    <div className="mt30"></div>

                    <Grid container spacing={0}>
                        <Grid item xs={4}><div className="nano-titulo">Live action projetado</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Live action realizado</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Live action shift</div></Grid>
                        
                            
                    </Grid>
                        
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <CurrencyFormat value={projeto.projetado_liveaction}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}><div className="sub-titulo pdf">
                                <CurrencyFormat value={projeto.realizado_liveaction}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <div className="l b color_3">{projeto.projetado_liveaction>0 ? Math.floor((projeto.projetado_liveaction-projeto.realizado_liveaction)/projeto.projetado_liveaction*100)+'%' : '0%'}</div>
                                    <div className="l ml10">
                                    <CurrencyFormat value={projeto.projetado_liveaction-projeto.realizado_liveaction}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                                    </div>
                                </div>
                            </Grid>
                    </Grid>


                    <div className="mt30"></div>

                    <Grid container spacing={0}>
                        <Grid item xs={4}><div className="nano-titulo">Post projetado</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Post realizado</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Post shift</div></Grid>
                        
                            
                    </Grid>
                        
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <CurrencyFormat value={projeto.projetado_post}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}><div className="sub-titulo pdf">
                                <CurrencyFormat value={projeto.realizado_post}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <div className="l b color_3">{projeto.projetado_post>0 ? Math.floor((projeto.projetado_post-projeto.realizado_post)/projeto.projetado_post*100)+'%' :'0%'}</div>
                                    <div className="l ml10">
                                    <CurrencyFormat value={projeto.projetado_post-projeto.realizado_post}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                                    </div>
                                </div>
                            </Grid>
                    </Grid>
                        
                    <div className="mt30"></div>
                    
                        
                    
                    {projeto.realizado_liveaction>0 ? 
                    
                  <div className="barPDF2 wp100 mt50">
                            <ResponsiveBar
                                    data={projeto.nucleos}
                                    keys={['previsto','realizado']}
                                    indexBy="nucleo"
                                    margin={{ top: 0, right: 20, bottom: 20, left: 132 }}
                                    
                                    groupMode="grouped"
                                    layout="horizontal"
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={({id, data}) => data[`${id}Color`]}
                                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                    axisTop={0}
                                    padding={0.3}
                                    axisRight={0}
                                    axisBottom={false}
                                    enableGridY={false}
                                    enableLabel={false}
                                    
                                    axisLeft={{
                                        tickSize: 0,
                                        tickPadding: 10,
                                        tickRotation: 0,
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    theme={theme}
                                    legends={[]}
                                    isInteractive={false}
                                    
                                />
                        </div>
                        
                      :''}

{projeto.realizado_liveaction>0 ? <div>
<Grid container spacing={0}>
                        <Grid item xs={6}><div className="nano-titulo">Maior econômia</div></Grid>
                        <Grid item xs={6}><div className="nano-titulo">Maior estouro</div></Grid>
                        
                        
                            
                    </Grid>
                        
                    <Grid container spacing={0}>
                        
                    <Grid item xs={6}>
                            <div className="sub-titulo pdf">
                                <div className="l b color_3">{}
                                <CurrencyFormat value={projeto.difMenosPer}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} /></div>
                                    <div className="l ml10">{nucleos[projeto.difMenos]}</div>
                                </div>
                            </Grid>
                        <Grid item xs={6}><div className="sub-titulo pdf">
                            <div className="l b color_5">
                            <CurrencyFormat value={projeto.difMaisPer}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                                <div className="l ml10">{nucleos[projeto.difMais]}</div>
                            </div>
                        </Grid>

                        
                    </Grid>
                    
                    </div> : ''}

                    
                </div>
        ):''}


        <div className="pd30">

       
                    <Grid container spacing={0} className="mb10">
                        <Grid item xs={5}><div className="mt30 sub-titulo">Report linha a linha</div></Grid>
                        <Grid item xs={2}><div className="mt30 sub-titulo">

                            <MySelect list={this.state.centros ? this.state.centros : []} initValue={0} label="Centro de custos" change={(e)=>this.reportProjetos(e)}/>
                            
                            </div></Grid>

                        <Grid item xs={3}></Grid>
                        <Grid item xs={2}>
                            <div onClick={()=>this.downloadExcel()} className="mt40 bt bgcolor_1">Exportar XLS</div>
                        </Grid>

                        
                        </Grid>
                        
        <ListaProjetos hide_impostos={this.state.hide_impostos} out={this.state.out} abrirDataGrupo={()=>this.void()} abrirStatusGrupo={()=>this.void()} abreJanela={(w)=>this.void(w)} impostos={this.state.cashflowReportProjetoImpostos ? this.state.cashflowReportProjetoImpostos : ''} entradas={this.state.cashflowReportProjetoEntradas ? this.state.cashflowReportProjetoEntradas : ''}  infos={this.state.cashflowReportProjeto ? this.state.cashflowReportProjeto : '' }/>
        


                <div className="mt20"></div>
                    <Grid container spacing={0}>
                        <Grid item xs={6}><div className="mt30 sub-titulo">Custos API</div></Grid>
                    </Grid>


                        {this.state.api ? this.state.api.map((lines,objectId)=>(
                            <Grid key={objectId} container spacing={0} className="bgcolor_6 br5 mb1 cl">
                                <Grid item xs={3}>
                                <div className="pd20 eli">{lines.nome}</div>
                                </Grid>
                                <Grid item xs={1}><div className={lines.o_total<0 ? "bgcolor_7 color_5 pd20 tc" : "bgcolor_7 pd20 tc"}><CurrencyFormat value={lines.o_total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                
                             </Grid>
                    )):''}


                <div className="mt20"></div>
                    <Grid container spacing={0}>
                        <Grid item xs={6}><div className="mt30 sub-titulo">Resultado</div></Grid>
                    </Grid>

                    <Grid container spacing={0} className="bgcolor_6 br5 mb1 cl">
                                <Grid item xs={3}>
                                <div className="pd20 eli">Resultado</div>
                                </Grid>
                                <Grid item xs={1}><div className="bgcolor_7 pd20 tc"><CurrencyFormat value={this.state.cashflowReportProjeto ? this.state.cashflowReportProjeto.reduce((a,v) =>  a = a + (v.valor/1) , 0 )-this.state.cashflowReportProjetoImpostos-this.state.api.reduce((a,v) =>  a = a + (v.o_total/1) , 0 ) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                
                             </Grid>


                </div>
         </div>
            
  


                      


      </ThemeProvider>
    )
  }
  
  }
  
  
  
