import React, { Component } from "react";
import {Grid,ThemeProvider,createTheme} from '@material-ui/core';
import api from '../../services/api';
import {Helmet} from 'react-helmet';
import Meta from "../../components/meta";
import ProgressBar from '../../components/progress';
import { ReactComponent as LogoLandscape } from '../../../src/svg/logo.svg'
import {deepPurple,teal} from '@material-ui/core/colors';
import CurrencyFormat from "react-currency-format";
import { Redirect } from "react-router";
import { ResponsiveBar } from '@nivo/bar'


const theme = createTheme (
{
  palette: {
    type: "dark",
    primary: deepPurple,
    secondary: teal,
  
},axis: {
    ticks: {
        line: {
          stroke: "#555555"
        },
        text: {
          fill: "#888"
        }
      },
  },
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
    
  }
},

)


const meses = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
    ];

const nucleos = ['Direção','Produçao','Segurança e testes','Fotografia','Equipamento de Câmera','Luz e Infra','Arte','Locação','Figurino e Make','Produção de Elenco','Elenco','Transporte','Alimentação','Acompanhamento'];









export default class Office extends Component {


  state = {
   
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    ano:this.props.match.params.id.split('_')[0],
    mes:this.props.match.params.id.split('_')[1],
    empresa_id:this.props.match.params.id.split('_')[2],
    nomeEmpresa:''
  }


  async componentDidMount(){
    
    this.setPage();
    this.loadPdf();
    
  }



  // SALVA PAGINA

  setPage = async () => {

    
    const thisPage = '/budgets';
    api.get('update/setPage.php?id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&page='+thisPage+'&token='+this.state.token).then(result => {
        
      if (result.status === 200) {
       
        if(result.data==='LOGOUT'){
          window.location.href='/wrong';
        }
        
      }})
  }



  // LOADING

  toLoad = () => {
    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  


  // LOAD PDF

  loadPdf = async () => {

    this.toLoad();
   
    
    api.get('get/pnl_pdf.php?&empresa_id='+this.state.empresa_id+'&ano='+this.state.ano+'&mes='+this.state.mes).then(result => {
        
        
      if (result.status === 200) {
       
        this.setState({
            nomeEmpresa:result.data.nomeEmpresa,
            planoVsRealizado:result.data.planoVsRealizado,
            report:result.data.report,
            projetos:result.data.projetos,
            projetosAprovados:result.data.projetosAprovados,
        })

        this.endLoad();

      }})

      

      
  
    }


  
render() {
    return (
  
      
      <ThemeProvider theme={theme}>
            {this.state.permission > 2 ? <Redirect to='/login'/> : ''}
            {this.state.loading ? <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/> : ''}
            <Meta title={"PNL Report | "+this.state.nomeEmpresa + ' - ' +meses[this.state.mes/1-1] + '/' + this.state.ano} />
          <div className="conteudo print">
             
          <Helmet>
                      
                    
                      <style>
                        
                         {`
                              body {
                                  
                                  margin:0px;
                                  padding:0px;
                                  line-height:20px;
                                  font-size: 11px;
                                  
                                  
                              }
  
                              .menu{
                                display: none !important;
                            }
                            
                            .footer{
                                display: none !important;
                            }

                            .conteudo.print{
                                padding:0px;
                                
                              }
  .page{
    background:#252525;
    background-size:cover;
    width:793px;
    height: 1120px;
    color:white;
    margin:auto;
    padding:30px;
}

.page2{
    background:#252525;
    background-size:cover;
    width:793px;
    height: 1120px;
    padding:30px;
    margin:auto;
}
  
.barPDF{
    height: 200px;
}

.barPDF2{
    height: 320px;
}

  
                         `}
                         
                      </style>
                  </Helmet>

                  
                  
                  
                  <div className="page">
                  
                  <Grid container spacing={0}>
                  <Grid item xs={2}>
                      <LogoLandscape className="logo-landscape pdf tl"/>
                      </Grid>
                      <Grid item xs={7}>
                      <div className="mt30 b mini-titulo color_1">{this.state.nomeEmpresa}</div>
                      </Grid>
                      <Grid item xs={3}><div className="mt35 mini-titulo tr">{meses[this.state.mes-1]+' '+this.state.ano}</div></Grid>
                  </Grid>
                        <div className="barPDF wp100">
                            <ResponsiveBar
                                    data={this.state.planoVsRealizado ? this.state.planoVsRealizado : []}
                                    keys={[ 'previsto', 'realizado' ]}
                                    indexBy="mes"
                                    margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
                                    groupMode="grouped"
                                    layout="vertical"
                                    padding={0.3}
                                    axisBottom={{
                                        tickSize: 0,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                       legendOffset: -40
                                    }}
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={({id, data}) => data[`${id}Color`]}
                                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                    axisTop={0}
                                    axisRight={0}
                                    enableGridY={false}
                                    enableLabel={false}
                                    axisLeft={0}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    theme={theme}
                                    legends={[]}
                                    role="application"
                                    ariaLabel="Nivo bar chart demo"
                                    barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
                                    
                                />
                        </div>
                        <hr/>
                        <Grid container spacing={0}>
                        <Grid item xs={4}><div className="nano-titulo">Vendas</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Custos Diretos</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Receita</div></Grid>
                        
                            
                        </Grid>
                        {this.state.report ? 
                        <Grid container spacing={0}>
                        <Grid item xs={4}><div className="sub-titulo pdf b"><CurrencyFormat value={this.state.report.vendasMes}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                        <Grid item xs={4}><div className="sub-titulo pdf"><div className="l b color_5">{this.state.report.perCustos + '%'}</div><div className="l ml10"><CurrencyFormat value={this.state.report.custos}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} /></div></div></Grid>
                        <Grid item xs={4}><div className="sub-titulo pdf"><div className="l b color_3">{this.state.report.perReceita + '%'}</div><div className="l ml10"><CurrencyFormat value={this.state.report.receita}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} /></div></div></Grid>
                        </Grid>
                        :''}

                        <div className="mt30"></div>
                        <Grid container spacing={0}>
                        <Grid item xs={4}><div className="nano-titulo">Pedidos de orçamento</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Projetos aprovados</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Diretores</div></Grid>
                        </Grid>

                        {this.state.report ? 
                        <Grid container spacing={0}>
                        <Grid item xs={4}><div className="sub-titulo pdf">{this.state.report.qtPedidos}</div></Grid>
                        <Grid item xs={4}><div className="sub-titulo pdf">{this.state.report.pedidosAprovados}</div></Grid>
                        <Grid item xs={4}><div className="sub-titulo pdf">{this.state.report.qtDiretores}</div></Grid>
                        </Grid>
                        :''}

                        <hr/>
                        
                        <Grid container spacing={0}>
                            <Grid item xs={4}><div className="nano-titulo b">Projeto</div></Grid>
                            <Grid item xs={2}><div className="nano-titulo b">Diretor</div></Grid>
                            <Grid item xs={2}><div className="nano-titulo b">Valor</div></Grid>
                            <Grid item xs={2}><div className="nano-titulo b">Custos diretos</div></Grid>
                            <Grid item xs={2}><div className="nano-titulo b">Receita</div></Grid>
                            
                            
                        </Grid>
                            
                        {this.state.projetosAprovados ? this.state.projetosAprovados.map((projeto,objectId)=>(
                        <Grid container spacing={0}>
                            <Grid item xs={4}><div className="nano-titulo">{projeto.id+' '+projeto.nome}</div></Grid>
                            <Grid item xs={2}><div className="nano-titulo">{projeto.diretor}</div></Grid>
                            <Grid item xs={2}><div className="nano-titulo"><CurrencyFormat value={projeto.valor}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                            <Grid item xs={2}><div className="nano-titulo"><div className="l wp20 color_5 b">{Math.floor(projeto.custo/projeto.valor*100)+'%'}</div><div className="l"><CurrencyFormat value={projeto.custo}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} /></div></div></Grid>
                            <Grid item xs={2}><div className="nano-titulo"><div className="l wp20 color_3 b">{Math.floor(projeto.receita/projeto.valor*100)+'%'}</div><div className="l"><CurrencyFormat value={projeto.receita}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} /></div></div></Grid>
                            
                           
                            
                        </Grid>
                        ))
                    :''}

                  </div>
                 
                 {this.state.projetos ? this.state.projetos.map((projeto,objectId)=>
                  <div className="page2">

                  <Grid container spacing={0}>
         
                      <Grid item xs={12}>
                      <div className="mt30 sub-titulo pd10"><div className="l color_1 b">{projeto.id}</div><div className="l ml10">{projeto.cliente}</div><div className="l ml10 color_1">{projeto.nome}</div></div>
                      </Grid>
                      
                  </Grid>

                    <div className="mt50"></div>
                    <Grid container spacing={0}>
                        <Grid item xs={4}><div className="nano-titulo">Valor vendido</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Receita Prevista</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Receita Realizada</div></Grid>
                        
                            
                    </Grid>
                        
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className="sub-titulo pdf b">
                                <CurrencyFormat value={projeto.valor}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}><div className="sub-titulo pdf">
                            <div className="l b color_5">{Math.floor(projeto.receitaPrevista/projeto.valor*100)+'%'}</div>
                                <div className="l ml10">
                                    <CurrencyFormat value={projeto.receitaPrevista}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <div className="l b color_3">{Math.floor(projeto.receitaRealizado/projeto.valor*100)+'%'}</div>
                                    <div className="l ml10">
                                    <CurrencyFormat value={projeto.receitaRealizado}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                                    </div>
                                </div>
                            </Grid>
                    </Grid>

                    <div className="mt30"></div>

                    <Grid container spacing={0}>
                        <Grid item xs={4}><div className="nano-titulo">Live action projetado</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Live action realizado</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Live action shift</div></Grid>
                        
                            
                    </Grid>
                        
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <CurrencyFormat value={projeto.projetado_liveaction}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}><div className="sub-titulo pdf">
                                <CurrencyFormat value={projeto.realizado_liveaction}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <div className="l b color_3">{projeto.projetado_liveaction>0 ? Math.floor((projeto.projetado_liveaction-projeto.realizado_liveaction)/projeto.projetado_liveaction*100)+'%' : '0%'}</div>
                                    <div className="l ml10">
                                    <CurrencyFormat value={projeto.projetado_liveaction-projeto.realizado_liveaction}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                                    </div>
                                </div>
                            </Grid>
                    </Grid>


                    <div className="mt30"></div>

                    <Grid container spacing={0}>
                        <Grid item xs={4}><div className="nano-titulo">Post projetado</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Post realizado</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Post shift</div></Grid>
                        
                            
                    </Grid>
                        
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <CurrencyFormat value={projeto.projetado_post}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}><div className="sub-titulo pdf">
                                <CurrencyFormat value={projeto.realizado_post}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <div className="l b color_3">{projeto.projetado_post>0 ? Math.floor((projeto.projetado_post-projeto.realizado_post)/projeto.projetado_post*100)+'%' :'0%'}</div>
                                    <div className="l ml10">
                                    <CurrencyFormat value={projeto.projetado_post-projeto.realizado_post}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                                    </div>
                                </div>
                            </Grid>
                    </Grid>
                        
                    <div className="mt30"></div>
                    <Grid container spacing={0}>
                        <Grid item xs={4}><div className="nano-titulo">Cachê diretor</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Outros cachês</div></Grid>
                        <Grid item xs={4}><div className="nano-titulo">Total cachês</div></Grid>
                        
                            
                    </Grid>
                        
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <CurrencyFormat value={projeto.cache_diretor}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                        </Grid>

                        <Grid item xs={4}><div className="sub-titulo pdf">
                            
                                    <CurrencyFormat value={projeto.outros_caches}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                                </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="sub-titulo pdf">
                                <div className="l b color_3">{Math.floor((projeto.cache_diretor/1+projeto.outros_caches)/projeto.valor*100)+'%'}</div>
                                    <div className="l ml10">
                                    <CurrencyFormat value={Math.floor(projeto.cache_diretor/1+projeto.outros_caches)}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                                    </div>
                                </div>
                            </Grid>
                    </Grid>
                    {projeto.realizado_liveaction>0 ? 
                    
                  <div className="barPDF2 wp100 mt50">
                            <ResponsiveBar
                                    data={projeto.nucleos}
                                    keys={['previsto','realizado']}
                                    indexBy="nucleo"
                                    margin={{ top: 0, right: 20, bottom: 20, left: 132 }}
                                    
                                    groupMode="grouped"
                                    layout="horizontal"
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={({id, data}) => data[`${id}Color`]}
                                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                    axisTop={0}
                                    padding={0.3}
                                    axisRight={0}
                                    axisBottom={false}
                                    enableGridY={false}
                                    enableLabel={false}
                                    
                                    axisLeft={{
                                        tickSize: 0,
                                        tickPadding: 10,
                                        tickRotation: 0,
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    theme={theme}
                                    legends={[]}
                                    isInteractive={false}
                                    
                                />
                        </div>
                        
                      :''}

{projeto.realizado_liveaction>0 ? <div>
<Grid container spacing={0}>
                        <Grid item xs={6}><div className="nano-titulo">Maior econômia</div></Grid>
                        <Grid item xs={6}><div className="nano-titulo">Maior estouro</div></Grid>
                        
                        
                            
                    </Grid>
                        
                    <Grid container spacing={0}>
                        
                    <Grid item xs={6}>
                            <div className="sub-titulo pdf">
                                <div className="l b color_3">{}
                                <CurrencyFormat value={projeto.difMenosPer}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} /></div>
                                    <div className="l ml10">{nucleos[projeto.difMenos]}</div>
                                </div>
                            </Grid>
                        <Grid item xs={6}><div className="sub-titulo pdf">
                            <div className="l b color_5">
                            <CurrencyFormat value={projeto.difMaisPer}  displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'R$'} />
                            </div>
                                <div className="l ml10">{nucleos[projeto.difMais]}</div>
                            </div>
                        </Grid>

                        
                    </Grid>
                    </div> : ''}
                </div>
        ):''}

         </div>
            
  


                      


      </ThemeProvider>
    )
  }
  
  }
  
  
  
