import React, { Component } from "react";
import {Grid,Zoom,ListItemText,Menu, MenuItem} from '@material-ui/core';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import './style.css';
import { Tooltip } from "@material-ui/core";
import { Redirect } from "react-router";
import { withStyles } from "@material-ui/styles";

const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
    MuiIconButton: {
      root: {
          color:'#ddd',
        '&:hover': {
          color: teal[100]
        }
      }
    },
    
  },
  

},

  
)


const colors=["#A456FF","#a7ffeb","#F9D48E","#ef5350","#ec407a","#ab47bc","#7e57c2","#5c6bc0","#42a5f5","#29b6f6","#26c6da","#26a69a","#66bb6a","#9ccc65","#d4e157","#ffee58","#ffca28","#ffa726","#ff7043","#A456F0","#a7f0eb","#F9D48E","#e05350","#ec4f7a","#ab87bc","#7e97c0","#5c4bf0","#43a5f0","#29f6f6","#26c6fa","#2fa69a","#66bf6a","#9ccf65","#d4e1f7","#ffee7f","#ffcaf8","#ffa7f6","#ff70f3"];

const StyledMenuItem = withStyles((theme) => ({
  root: {
      '&:focus': {
      backgroundColor: deepPurple,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: 'white',
      },
      },
  },
  }))(MenuItem);


let nomeMeses=['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];
let nomeSemana=['Seg','Ter','Qua','Qui','Sex','Sab','Dom'];


let dt = new Date();
let year  = dt.getFullYear();
let mes  = dt.getMonth();

export default class Calendar extends Component {

  

  state = {
    token: localStorage.getItem("tokens"),
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    id:25717,
    loading:true,
    diasInfo:[],
    mesesDiasOffIni:[],
    mesesDiasOffFim:[],
    mesesDias:[],
    editText:0,
    projetoIdAtivo:0,
    projetoCompara:[],
    projetosAtivos:[{'id':0,'nome':'Principal','s':1},{'id':1,'nome':'Paralelo','s':0},{'id':2,'nome':'Extra','s':0}],
    //eventos:[{'2021-07-10':[{'id':1,'nome':'Offline','cor':'#452345'},{'id':33,'nome':'Kick-off','cor':'#4f5ff2'}],'2021-07-15':[{'id':6,'nome':'Offline','cor':'#452345'},{'id':9,'nome':'Kick-off','cor':'#4f5ff2'}]}],
    projetoAtivoColor:1,
    tipoEvento:0,
    nomeEvento:'',
    editandoEvento:false,
    dv:''
  }
 

  

  // LOADING

  toLoad = () => {
    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }


  


  async componentDidMount(){
    
    
    this.calendarInfo();
    
    this.setPage();
    
    

  }


  // MONTA CALENDARIO
  calendar = () => {
        
    
        // DAYS IN MONTH
        
        let nrDays = [];
        let firstDay = [];
        let mesesDias = [];
        let mesesDiasOffIni = [];
        let mesesDiasOffFim = [];
        let totalCasas = 35;
        let mesAtivos = [];
        let yearStart=1;
        let iniMes = -1;
        
        
        for(let a=0;a<3;a++){
            nrDays.push([]);
            firstDay.push([]);
        for(let i=0;i<12;i++){
            
            nrDays[a].push(new Date((year+a-1),(i+1), 0).getDate());
            firstDay[a].push(new Date((year+a-1),i, 0).getDay());

            

            if(yearStart===a){
              
              if(mes===i || (mes+1)===i || (mes+2)===i){
                mesAtivos.push(2);
              }else{
                mesAtivos.push(0);
                
              }

              
              
            }else{

                  mesAtivos.push(0);
                
            }

        }

    }


    let iniMesOpen = 0;
   
    for(let i=0;i<mesAtivos.length;i++){

      if(mesAtivos[i]===2){
        iniMesOpen=i;
        break
      }
    }

    mesAtivos[iniMesOpen+1]=2;
    mesAtivos[iniMesOpen+2]=2;
    

    for(let i=0;i<mesAtivos.length;i++){
      
      if(mesAtivos[i]===2){
        if(iniMes===-1){
        iniMes = i;
        }
        
      }
    }
    
    
    for(let ma=(iniMes-2);ma<(iniMes+5);ma++){
      if(mesAtivos[ma]!==2){
        mesAtivos[ma]=1;
      }
    }

   
  
    for(let i=0;i<mesAtivos.length;i++){

      mesesDias.push([]);
      mesesDiasOffIni.push([]);
      mesesDiasOffFim.push([]);

      
     
      if(mesAtivos[i]>0){
        
        // DIAS VAZIOS INICIO
        
        for(let s=0;s<firstDay[Math.floor(i/12)][i-(Math.floor(i/12)*12)];s++){
          mesesDiasOffIni[i].push(1);
        }
          
       

        if(mesesDiasOffIni[i].length>=5){
          totalCasas=42;
        }else{
          totalCasas=35;
        }


        // DIAS MES
        for(let a=0;a<nrDays[Math.floor(i/12)][i-(Math.floor(i/12)*12)];a++){
          if((mesesDias[i].length+mesesDiasOffIni[i].length)<totalCasas){
            mesesDias[i].push(year+Math.floor(i/12)-1);
          }
      }

      

      // DIAS VAZIOS FINAL
      for( let c=0;(mesesDias[i].length+mesesDiasOffIni[i].length+mesesDiasOffFim[i].length)<totalCasas;c++){
        mesesDiasOffFim[i].push(1);
      }
  }

    
    
    
  }
  
  this.setState({
    nrDays:nrDays,
    firstDay:firstDay,
    mesesDias:mesesDias,
    mesesDiasOffIni:mesesDiasOffIni,
    mesesDiasOffFim:mesesDiasOffFim,
    
})

  
  if(!this.state.mesAtivos){
    setTimeout(() => {
      this.setState({
        mesAtivos:mesAtivos
      })
    }, 0);
  }
  
}

  

  // SALVA PAGINA

  setPage = async () => {

    
    const thisPage = '/budgets';
    localStorage.setItem("page",thisPage);
    api.get('update/setPage.php?id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&page='+thisPage+'&token='+this.state.token).then(result => {
        
      if (result.status === 200) {
       
        if(result.data==='LOGOUT'){
          window.location.href='/wrong';
        }
        
      }})
  }

  // PEGA INFOS CALENDARIO

  calendarInfo = () => {
    
    this.toLoad();
    api.get('get/calendar_coordenacao.php?r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
      
          let projetoCompara = result.data.compara;
          projetoCompara[0]['s']=1;
          
          this.setState({

            tipos:result.data.tipos,
            eventos:result.data.linhas,
            projetoCompara:result.data.compara
          })

          

          this.calendar()
          this.endLoad();
          
  }})
  }


  // CLICA PARA EDITAR O NOME

  editNomeVersao = () => {

    this.setState({
      editNome:1,
    })

   }

   // EDITA O NOME DA VERS�O

   setNomeVersao = (e) => {

    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+e.target.value+'&id='+this.state.id+'&sheet=budget&col=nome&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

            if(e.target.value!==""){
              this.setState({
                editNome:0,
                versao: e.target.value
              })
            }else{
              this.setState({
                editNome:0
              })
            }
      }
    })
   }




   // PULA CALENDARIO

   writeCalendar = (e,d) => {
     
      let diasInfo = this.state.diasInfo;
      diasInfo[d] = e;
      this.setState({
        diasInfo: diasInfo,
    })
    
   }

   // EDIT TEXTO CALENDARIO

   editTextField = (w) => {
     
    this.setState({
        editText: w,
    })

  }


  // NOME DIA

  nomeDia = (m,s) => {

    m+=1;
    if(m<10){
      m='0'+m;
    }

    s = s-(Math.floor(s/7)*7);
    return nomeSemana[s]+', '+m;
  }



  //  MONTA DATA

  montaData = (y,m,d) => {

    if(m<12){
      y--;
    }

    m = (m-(Math.floor(m/12)*12)+1);
    if(m<10){
      m='0'+m;
    }
    d = (d+1);

    if(d<10){
      d='0'+d;
    }

    
    return (y+'-'+m+'-'+d);
    
  }


    // SHOW MES

    showMes = (w) => {

      this.toLoad()
      let mesAtivos = this.state.mesAtivos;

      if(mesAtivos[w]===1){
        mesAtivos[w]=2;
      }else{
        mesAtivos[w]=1;
      }

      
      this.setState({
        mesAtivos:mesAtivos
      })
      this.endLoad()
    }


    // DRAG START
    dragStart = (e,w) => {
   
     
      const te = e.target;
      
      this.setState({
        te:te,
        w:w
      })
  
    }


  // DRAG OVER
  dragOver = (e) => {

    if(e.target.attributes.class){
      if(e.target.attributes.class.value==="dia-semana" || e.target.attributes.class.value==="base-eventos"){

      e.target.parentNode.style.background='#555';
    }
    }
   
    e.stopPropagation();
    e.preventDefault();
  }

  // DRAG END
  dragEnd = (e) => {
  
    e.stopPropagation();
    e.preventDefault();
  
  }


    // DROP
    onDrop = (e) => {
      
      //this.toLoad();
      e.preventDefault();
      e.target.parentNode.style.background='';

      let from_id = this.state.te.id;
      let to_data = (e.target.id);
      
      if(to_data.split('-').length===3){
        
        api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+to_data+'&id='+from_id+'&sheet=calendar&col=dia&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {

            this.toLoad();
 
              api.get('get/calendar_coordenacao.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
                  
                if (result.status === 200) {

                    this.setState({
                      
                      eventos:result.data.linhas

                    })

                    this.calendar()
                    this.endLoad();
                    
            }})
  
          }})
     
    }
    }




  // DRAG LEAVE
  dragLeave = (e) => {
    
    if(e.target.attributes.class){
      if(e.target.attributes.class.value==="dia-semana" || e.target.attributes.class.value==="base-eventos"){
        e.target.parentNode.style.background='';
       
      }
    }

    e.stopPropagation();
    e.preventDefault();

  }


  //FORMAT DATE

  formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
  

// TAB CHANGE

tabChange = (e,w) => {
  if(e.key==='Tab'){
    e.preventDefault();
    //w = w.split('-');
    let nd = new Date(w);
    nd.setDate(nd.getDate()+2);

    this.setState({
      editText: this.formatDate(nd)+'-0',
  })
  }
}

// ABRE MENU PROJETOS
comparaMenu = (e) => {
  this.setState({
    anchorEl:e.currentTarget,
    
  })
}

// FECHA MENU COMPARA

fechaMenuCompara = () => {

  let selecionaProjeto = this.state.projetoCompara;
  let id = this.state.id;

  for(let i=0;i<selecionaProjeto.length;i++){

    if(selecionaProjeto[i]['s']===1){
    id+=','+selecionaProjeto[i]['id'];
    }
    
  }

  this.toLoad();
 
    api.get('get/calendar_coordenacao.php?id='+id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        
        


          this.setState({
            
            eventos:result.data.linhas

          })

          

          this.calendar()
          this.endLoad();
          
  }})



  this.setState({
    anchorEl:null,

  })
}

// FECHA MENu EVENTOS

fechaMenu = () => {

  this.setState({
    anchorElNew:null,
    dv:''
  })
}


// FECHA MENU EDITA

fechaMenuEdit = () => {

  this.setState({
    anchorElEdit:null,
    editandoEvento:null
    
  })
}


// SELECIONA PROJETO

selecionaProjeto = (w) => {
  let projetoCompara = this.state.projetoCompara;
  if(projetoCompara[w]['s']===1){
    projetoCompara[w]['s']=0;
  }else{
    projetoCompara[w]['s']=1;
  }

  this.setState({
    projetoCompara:projetoCompara
  })
}

// SELECIONA PROJETO EVENTO

selecionaProjetoEvento = (w) => {
  let projetoCompara = this.state.projetoCompara;

  for(let i=0;i<projetoCompara.length;i++){
    projetoCompara[i]['s']=0;
  }
  
  projetoCompara[w]['s']=1;

  let c = projetoCompara[w]['id']%38;
  this.setState({
    projetoCompara:projetoCompara,
    projetoAtivoColor:c,
    projetoIdAtivo:projetoCompara[w]['id']
  })
}



// ABRE NOVO EVENTO
novoEvento = (e) => {

  let ct = e.currentTarget

  setTimeout(() => {
    
    if(!this.state.editandoEvento){
      
      this.setState({
        anchorElNew:ct,
        dataMenu:e.target.id
      })
      
    }

  }, 1);
 
}


// NOME EVENTO

setNomeEvento = (e) => {

    this.setState({
      nomeEvento:e.target.value
    })
  
}

// SALVA EVENTO

salvaEvento = (w) =>{

  this.toLoad();
  
  let nomeEvento='';
  if(w>0){
    nomeEvento = this.state.tipos[w-1];
  }

  
  if(nomeEvento!==''){
     api.get('insert/newCalendarCoordenacao.php?projeto_id='+this.state.projetoIdAtivo+'&dia='+this.state.dataMenu+'&tipo='+w+'&texto='+nomeEvento+'&r='+Math.random(10000)).then(result => {
            
        if (result.status === 200) {

    api.get('get/calendar_coordenacao.php?r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        
        
          this.setState({
            eventos:result.data.linhas,
            anchorElNew:null

          })

          

          this.calendar()
          this.endLoad();
          
  }})



        }})
     
    }
    
}

// EDITA EVENTO

editaEvento = (e,nome,cor) => {


  this.setState({
    nomeEvento:nome,
    editandoEvento:true,
    anchorElEdit:e.currentTarget,
    eventoId:e.target.id,
    texto:'texto'+cor
  })

}


// UPDATE EVENTO

upEvento = (w,tipo) => {

 
  this.toLoad();
  
  let nomeEvento='';
  if(w>0){
    nomeEvento = this.state.tipos[w-1];
  }else{
    nomeEvento = this.state.nomeEvento;
  }

  if(nomeEvento!==''){

  
        
    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+nomeEvento+'&id='+this.state.eventoId+'&sheet=calendar&col='+this.state.texto+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {


        api.get('get/calendar_coordenacao.php?&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {
            
            
              this.setState({
                
                eventos:result.data.linhas,
                anchorElEdit:null,
                nomeEvento:'',
                editandoEvento:false
    
              })
    
              
    
              this.calendar()
              this.endLoad();
            }})
      }})


  }


}


// DELETAR

deletar = () =>{

  api.get('delete/delete.php?sheet=calendar&id='+this.state.eventoId+'&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {

      this.setState({
              
      
        anchorElEdit:null,
        nomeEvento:''

      })


      api.get('get/calendar_coordenacao.php?r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {
          
          
            this.setState({
              
              eventos:result.data.linhas
              
  
            })
  
            
  
            this.calendar()
            this.endLoad();
          }})
  


    }})
}

// SALVA EVENTO ENTER

salvaEventoEnter = (e) => {

  if(e.key==='Enter'){
   
    if(e.target.value!==''){

      this.setState({
        nomeEvento:e.target.value
      })
      setTimeout(() => {
        this.salvaEvento(0)
      }, 10);
    }
    
  }
}

// VOID

void = () => {
  
}
  render(){




  return (

    
    <ThemeProvider theme={theme}>

          {this.state.permission > 3 ? <Redirect to='/login'/> : ''}

          <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
          
          <div className="conteudo">
          <Grid item xs={4}><div className="titulo mb20">Agenda Coordenação</div></Grid>
          

          
          {this.state.mesAtivos ? this.state.mesAtivos.map((mes,objectId) =>(
          
          <div key={objectId} >

          {mes>0 ? 
            
          <Grid container spacing={0}>
            <div className="mt5 wp100">

            <Grid container className='br5 bgcolor_1 pd20'>
              <Grid item xs={11}><div onClick={(w)=>this.showMes(objectId)} className="mini-titulo mt15">{nomeMeses[objectId-(Math.floor(objectId/12)*12)]} {year+(Math.floor(objectId/12)-1)}</div></Grid>
              <Grid item xs={1} className="tr"></Grid>
            </Grid>

            </div>

            {mes===1 ? '' : 
          
            <Grid container spacing={0}>

          {this.state.mesesDiasOffIni[objectId].map((dias,objectIdOffini)=>(
              <Grid key={objectIdOffini} item xs className={"box-calendar-off br5"}><div className="dia-semana"></div></Grid>
          ))}
              
          {this.state.mesesDias[objectId].map((dias,objectId2)=>(
            
            
          <Grid id={this.montaData(dias,objectId,objectId2)} onDragOver={(e)=>this.dragOver(e)} onDragEnd={(e)=>this.dragEnd(e)} onDrop={(e)=>this.onDrop(e)} onDragLeave={(e)=>this.dragLeave(e)} item xs className={this.state.diasInfo[this.montaData(dias,objectId,objectId2)] ? "box-calendar box2 br15 cor"+this.state.diasInfo[this.montaData(dias,objectId,objectId2)] : "box-calendar box2 br15"  }>

            
                    <div className="dia-semana">{this.nomeDia(objectId2,(objectId2+this.state.mesesDiasOffIni[objectId].length))}</div>
                    <div id={this.montaData(dias,objectId,objectId2)} onClick={(e)=>this.novoEvento(e)} className="base-eventos">

                    {this.state.eventos[this.montaData(dias,objectId,objectId2)] ? this.state.eventos[this.montaData(dias,objectId,objectId2)].map((evento,objectId3)=>(
                    
                    <Tooltip title={evento.projeto} placement="top" arrow TransitionComponent={Zoom}><Grid id={evento.id} onClick={(e)=>this.editaEvento(e,evento.nome,evento.cor)}  onDragStart={(e)=>this.dragStart(e,objectId3)} xs draggable={true} key={objectId3} style={{backgroundColor:colors[evento.projeto_id%38]}} className="evento br5">{evento.nome}</Grid></Tooltip>
                    
                    )) : ''}
                    </div>
                    
                    

          </Grid>
         
         

          ))}

          {this.state.mesesDiasOffFim[objectId].map((dias,objectIdOffFim)=>(
              <Grid key={objectIdOffFim} item xs className={"box-calendar-off br5 "}><div className="dia-semana"></div></Grid>
          ))}

      
          </Grid>
        }
          </Grid>

          : ''}
          </div>

          )) : ''}

          
          
          </div>
          <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={()=>this.fechaMenuCompara()}
                        >
                                <StyledMenuItem><ListItemText primary="" /></StyledMenuItem>
                                {this.state.projetoCompara ? this.state.projetoCompara.map((projeto,objectId)=>(
                                  <StyledMenuItem onClick={()=>this.selecionaProjeto(objectId)}><div style={{backgroundColor:colors[projeto.id%38]}} className="color-box br5">{projeto.s===1 ? <div className="br5 box-marker"></div> : ''}</div><ListItemText primary={projeto.nome} /></StyledMenuItem>
                                )):''}
          </Menu>


          <Menu
                        id="simple-menu-new"
                        anchorEl={this.state.anchorElNew}
                        keepMounted
                        open={Boolean(this.state.anchorElNew)}
                        onClose={()=>this.fechaMenu()}
                        >
                          
                                
                                {this.state.projetoCompara ? this.state.projetoCompara.map((projeto,objectId)=>(
                                  <div className="celula-tipo-cal" onClick={()=>this.selecionaProjetoEvento(objectId)}><div style={{backgroundColor:colors[projeto.id%38]}} className="color-box br5 l">{projeto.s===1 ? <div className="br0 box-marker l"></div> : ''}</div><ListItemText primary={projeto.nome} /></div>
                                )):''}
                                <div className="divider-evento"></div>
                                {this.state.tipos ? this.state.tipos.map((tipos,objectId)=>(
                                  <div key={objectId} onClick={()=>this.salvaEvento(objectId+1)} style={{backgroundColor:colors[this.state.projetoAtivoColor]}} className="evento cl br5">{tipos}</div>
                                )):''}
                                
          </Menu>


          <Menu
                        id="simple-menu-new"
                        anchorEl={this.state.anchorElEdit}
                        keepMounted
                        open={Boolean(this.state.anchorElEdit)}
                        onClose={()=>this.fechaMenuEdit()}
                        >
                          <div className="mini-titulo pd20">{this.state.nomeEvento}</div>
                                
                                {this.state.projetoCompara ? this.state.projetoCompara.map((projeto,objectId)=>(
                                  <StyledMenuItem onClick={()=>this.selecionaProjetoEvento(objectId)}><div style={{backgroundColor:colors[projeto.id%38]}} className="color-box br5">{projeto.s===1 ? <div className="br5 box-marker"></div> : ''}</div><ListItemText primary={projeto.nome} /></StyledMenuItem>
                                )):''}
                                <div className="divider-evento"></div>
                                {this.state.tipos ? this.state.tipos.map((tipos,objectId)=>(
                                  <div key={objectId} onClick={()=>this.upEvento(objectId+1)} style={{backgroundColor:colors[this.state.projetoAtivoColor]}} className="evento cl br5">{tipos}</div>
                                )):''}
                                
                                <div onClick={()=>this.upEvento(0)} className="bt bgcolor_1">Atualizar</div>
                                <div onClick={()=>this.deletar()} className="bt bgcolor_5">Deletar</div>
          </Menu>


         
                       
         
    </ThemeProvider>

    
  )
}

}
  
 