import React,{Component} from 'react'
import Grid from '@material-ui/core/Grid';
import CurrencyFormat from 'react-currency-format';
import ProgressBar from '../../components/progress';


export default class ListaProjetos extends Component {

    constructor(props){
        super();
        this.state = {

            empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
            loading:true,
            loadPerc:0,
            colorStatus:['bgcolor_5s','bgcolor_2']
            

        }
    }
   

    // LOADING

    toLoad = () => {
        this.setState({
        loadPerc: 2,
        loading:true,
        intervalLoad: setInterval(this.Loader, 200)
        })
    }
    

    endLoad = () => {  
        this.setState({
        loadPerc: 100,
        loading:false,
        intervalLoad:false,
        })
    }

    Loader = () =>{
        if(this.state.loadPerc<100){
            this.setState({
                loadPerc:this.state.loadPerc*1.4,
            })
        }
    }


    // FORMAT DATE

    // FORMAT DATE

formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [day, month, year].join('/');
  }





render(props){
return (
        

        <div>       
            
                    <Grid container spacing={0}>
                        <div className="w85">
                            <Grid item xs={12}>
                                <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid container spacing={3}>
            
                        <Grid item xs={3}>{this.props.infos ? <div className="mini-titulo mt20 mb20">PO Lançadas</div> : ''}</Grid>
                        </Grid>
                    {this.props.infos ? this.props.infos.map((lines,objectId)=>(
                            <Grid key={objectId} container spacing={0} className="bgcolor_6 br5 mb1 cl" onClick={(w)=>this.props.abreJanela(lines.id)}>
                                <Grid item xs={3}>
                                <div className="pd20 eli">{lines.nome}</div>
                                </Grid>
                                <Grid item xs={1}><div className={lines.valor<0 ? "bgcolor_7 color_5 pd20 tc" : "bgcolor_7 pd20 tc"} ><CurrencyFormat value={lines.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                <Grid item xs={1} className="tc" ><div className="bgcolor_6 pd20">{this.formatDate(lines.dt_vencimento)}</div></Grid>
                                <Grid item xs={2} className="tc" ><div className="bgcolor_7 pd20 eli">{lines.plano_conta}</div></Grid>
                                <Grid item xs={4}><div className="bgcolor_6 pd20 eli" >{lines.projeto}</div></Grid>
                                <Grid item xs={1}><div className="mt5 pd20 tr" ><div className={"bar-status br5 r "+(this.state.colorStatus[lines.comissao_status/1])}></div></div></Grid>
                             </Grid>
                    )):''}

                    {this.props.infos ?
                                    <div>
                                        
                                        <Grid container spacing={0} className="bgcolor_1 br5 mb1">
                                            <Grid item xs={3}><div className="pd20">Total</div></Grid>
                                            <Grid item xs={1}><div className="pd20 b tc"><CurrencyFormat value={this.props.infos ? this.props.infos.reduce((a,v) =>  a = a + (v.valor/1) , 0 ) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={6}></Grid>
                                            <Grid item xs={1}></Grid>
                                        </Grid>
                                    </div>
                                    :''}


                        <Grid container spacing={3}>
                            
                            <Grid item xs={3}>{this.props.cost ?  <div className="mini-titulo mt20 mb20">Comissões sem PO</div> : ''}</Grid>
                            </Grid>
                        {this.props.cost ? this.props.cost.map((lines,objectId)=>(
                                <Grid key={objectId} container spacing={0} className="bgcolor_6 br5 mb1">
                                    <Grid item xs={3}>
                                    <div className="pd20 eli">{lines.nome}</div>
                                    </Grid>
                                    <Grid item xs={1}><div className={lines.valor<0 ? "bgcolor_7 color_5 pd20 tc" : "bgcolor_7 pd20 tc"} ><CurrencyFormat value={lines.o_total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                    <Grid item xs={1} className="tc" ><div className="bgcolor_6 pd20">{this.formatDate(lines.reg_date)}</div></Grid>
                                    <Grid item xs={2} className="tc" ><div className="bgcolor_7 pd20 eli">{lines.cargo}</div></Grid>
                                    <Grid item xs={4}><div className="bgcolor_6 pd20 eli" >{lines.projeto}</div></Grid>
                                    <Grid item xs={1}><div className="mt5 pd20 tr" ><div className={"bar-status br5 r "+(this.state.colorStatus[lines.comissao_status/1])}></div></div></Grid>
                                </Grid>
                        )):''}

                        {this.props.cost ?
                        <div>
                            
                            <Grid container spacing={0} className="bgcolor_1 br5 mb1">
                                <Grid item xs={3}><div className="pd20">Total</div></Grid>
                                <Grid item xs={1}><div className="pd20 b tc"><CurrencyFormat value={this.props.cost ? this.props.cost.reduce((a,v) =>  a = a + (v.o_total/1) , 0 ) : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>
                        </div>
                        :''}
        </div>

        
    )
    
}
}
