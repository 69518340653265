import React from 'react'

function Board(props) {
    const drop = e => {
        
        e.preventDefault();
        const card_id = e.dataTransfer.getData('card_id');
        const card = document.getElementById(card_id);
        card.style.display = 'none';
        
        //e.target.appendChild(card);
        e.target.style.background="rgba(166,166,166,0.05)";
        
        props.update(props.id,card_id)
    }

    const dragOver = e =>{
        if(e.target.attributes.class && e.target.attributes.class.value==="board"){
            e.target.style.background=props.color;
        }
        e.preventDefault();

    }

    const dragLeave = e =>{
        
        if(e.target.attributes.class && e.target.attributes.class.value==="board"){
            e.target.style.background="rgba(166,166,166,0.05)";
        }
    
        e.preventDefault();

    }

    return (
        <div id={'board-'+props.id}
        onDrop={drop}
        onDragOver={dragOver}
        onDragLeave={dragLeave}
        >
        { props.children }
        </div>
    )
}

export default Board
