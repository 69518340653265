import {ThemeProvider, createTheme, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React, { Component } from 'react'
import {deepPurple,teal} from '@material-ui/core/colors';
import { Person } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
              fontSize: "11px",
              backgroundColor:'#666'
        }},
   
    }
})
    

const StyledMenuItem = withStyles((theme) => ({
    root: {
        
    },
    }))(MenuItem);

  

export default class Bloco extends Component {
    
    constructor(props){
        super()
        this.state = {
            anchorEl:false
        }
    }

    // ABRE MENU PRESET

    abreMenu = (event) => {


        this.setState({
            anchorEl: event.currentTarget
        })
        
        
      };
    
    // FECHA MENU

    fechaMenu = (id) => {
        
        this.setState({
            anchorEl: null
        })
        };
    
    
    swapLogin = (empresa_id,empresa_nome) => {
        if(empresa_id==='logout'){
            this.props.logOut();
        }else{
            this.props.swapLogin(empresa_id,empresa_nome)
        }
        this.setState({
            anchorEl: null
        })
        };

    
    render(props) {
        
       
        return (

                <ThemeProvider theme={theme}>
                    
                    <div onClick={(e)=>this.abreMenu(e)} className="img_user_micro login mt20" style={this.props.logins[0] ? {backgroundImage: 'url(https://treatments.run/landscape/img/upload/'+this.props.logins[0]['foto']+')'} : {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} }></div>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={()=>this.fechaMenu()}
                        >
                            
                        {this.props.logins.map((login,objectId)=>(
                                <StyledMenuItem key={objectId} onClick={(nome,email)=>this.swapLogin(login.empresa_id,login.empresa)}><ListItemIcon><Person fontSize="small" /></ListItemIcon><ListItemText primary={login.empresa} /></StyledMenuItem>
                       ))}
                                <StyledMenuItem onClick={()=>this.swapLogin('logout')}>Logout</StyledMenuItem>
                        </Menu>
                
                </ThemeProvider>
            
        )
    }
}
