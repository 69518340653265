import React,{Component} from 'react';
import Axios from 'axios';
import { IconButton} from "@material-ui/core";
import PhotoCamera from '@material-ui/icons//PhotoCamera';
import BarLoader from "react-spinners/BarLoader";
import './style.css';

class Imgupload extends Component {
    constructor(props){
        super();

        this.state = { 
            // Initially, no file is selected 
            uploading: false,
            
            img: 'https://treatments.run/landscape/img/upload/'+props.foto,
        }; 
    }


    fileSelect = (event) => {
        this.setState({ uploading: true }); 

        const fd = new FormData();
        fd.append('image',event.target.files[0],event.target.files[0].name)
        Axios.post('https://treatments.run/landscape/upload.php?id='+this.props.id+'&sheet='+this.props.sheet+'&r='+Math.random(10000),fd).then(res => {
        
            this.setState({ 
                uploading: false,
                img: 'https://treatments.run/landscape/img/upload/'+res.data,
                }); 

                
        })
    }
  

    render(){

        return (

            <div>
            <div className="mt20"><div className="img_user_large" style={this.props.foto==="" ?  {backgroundImage: 'url(https://www.landscape.to/img/semfoto.jpg)'} : {backgroundImage: 'url('+this.state.img+')'} }></div></div>
                <div className="wp100 f mt20">
                    <div className="ma">
                        
                        <IconButton variant="contained" color="secondary" onClick={()=>this.fileInput.click()}>{this.state.uploading ? 
                        <BarLoader
                            
                        size={30}
                        color={"#33ab9f"}
                        loading={this.state.uploading}
                        />
                        : <PhotoCamera/>}</IconButton>
                        <input type="file" name="the_file" onChange={this.fileSelect} style={{display:'none'}} ref={fileInput => this.fileInput = fileInput}></input>
                    </div>
                </div>
            </div>

        )
    }
}

export default Imgupload;