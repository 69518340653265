import React, { Component } from "react";
import {Grid,ThemeProvider,createTheme,Tooltip,Zoom,Menu, MenuItem, ListItemIcon, ListItemText} from '@material-ui/core';
import api from '../../services/api';
import {Helmet} from 'react-helmet';
import Meta from "../../components/meta";
import ProgressBar from '../../components/progress';
import { withStyles } from "@material-ui/styles";
import { Add, Close } from "@material-ui/icons";
import {deepPurple,teal} from '@material-ui/core/colors';
import CurrencyFormat from "react-currency-format";
import { Redirect } from "react-router";
import ContentEditable from 'react-contenteditable'

const theme = createTheme (
{
  palette: {
    type: "dark",
    primary: deepPurple,
    secondary: teal,
  
},
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
    
  }
}
)


const StyledMenuItem = withStyles((theme) => ({
  root: {
      '&:focus': {
      backgroundColor: deepPurple,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: 'white',
      },
      },
  },
  }))(MenuItem);



export default class Office extends Component {


  state = {
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    id:this.props.match.params.id,
    versoes:'',
    ingles:0,
    vn:1,
    salvo:true,
    salvarCarta:{'id':this.props.match.params.id},
    aproNomes:['Pré-Produção','Produção','Cenografia','Locação/Estúdio','Equipe Técnica','Equipamento','Alimentação/Transporte','Elenco','Passagem e Hospedagem','Pós Produção','Custos COVID','Outros'],
  
  }


  async componentDidMount(){
    
    this.setPage();
    this.budgetInfo(this.state.id);
    this.getEmpresa();
    this.setState({
      ano:new Date().getFullYear()
    })
    
  }



  // SALVA PAGINA

  setPage = async () => {

    
    const thisPage = '/budgets';
    localStorage.setItem("page",thisPage);
    api.get('update/setPage.php?id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&page='+thisPage+'&token='+this.state.token).then(result => {
        
      if (result.status === 200) {
       
        if(result.data==='LOGOUT'){
          window.location.href='/wrong';
        }
        
      }})


      
  }



  // LOADING

  toLoad = () => {
    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  

  // PEGA INFORMAÇÕES DO BUDGET

  budgetInfo = async (id) => {

    this.toLoad()
      
      api.get('get/office.php?id='+id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

       
        if(result.data.atendimento.length===0){
          result.data.atendimento.push({'nome':''})
          result.data.atendimento.push({'email':''})
        }

        if(result.data.diretor.length===0){
          result.data.diretor.push({'nome':''})
          
        }

        if(result.data.office[0]){
          this.setState({

            projeto_id:result.data.budget[0].projeto_id,
            versao:result.data.budget[0].nome,
            projeto:result.data.projeto[0].nome,
            cliente:result.data.projeto[0].cliente,
            empresa:result.data.empresa[0].nome,
            office:result.data.office[0],
            versoes:result.data.office[0].versoes,
            titulos:result.data.office[0].titulos.split('`'),
            nome:result.data.atendimento[0].nome,
            atendimento:result.data.atendimento[0].nome +' - '+result.data.atendimento[0].email,
            diretor:result.data.diretor[0].nome,
            legal:result.data.legal,
            showweather:result.data.office[0].showweather/1,
            ingles:result.data.office[0].ingles,
            hideMain:result.data.office[0].sem_custo_principal,
            officeLoad:true
          })
        }else{
          
          this.setState({
            office:[{'valores':0,'versoes':''}],
            projeto_id:result.data.budget[0].projeto_id,
            versao:result.data.budget[0].nome,
            projeto:result.data.projeto[0].nome,
            cliente:result.data.projeto[0].cliente,
            empresa:result.data.empresa[0].nome,
            atendimento:result.data.atendimento[0].nome +' - '+result.data.atendimento[0].email,
            diretor:result.data.diretor[0].nome,
            ingles:0,
            hideMain:0,
            showweather:0
            //officeLoad:true
          })
          
        }

          setTimeout(() => {
           
            
          if(!this.state.office['valores']){
            // BUSCA VALORES ORIGINAIS
           
            this.valoresBudget();
            this.getDecupaInfos();

          }else{
            
            this.setState({
              valores:this.state.office['valores'],
              weather: this.state.office['weather'],
              adiantamento1: this.state.office['adiantamento1'],
              adiantamento2: this.state.office['adiantamento2'],
              cancelamento: this.state.office['cancelamento'],
              outros: this.state.office['outros']
            })
            this.endLoad();
          }






          }, 10);
          
          
  

      }})
       

  }



  // GET EMPRESA

  getEmpresa = async() => {

    api.get('/get/get.php?id='+this.state.empresa_id+'&sheet=empresa&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        
        this.setState({
          logo_carta:result.data[0].logo_carta,
          logo_carta_2:result.data[0].logo_carta_2,
          cor_fonte:result.data[0].cor_fonte,
          cor_bg:result.data[0].cor_bg
          
        })
      
      }})

  }


  // FORMAT MONEY

  formatMoney = (w) => {

    w = Math.floor(w/50)*50;
    return 'R$' + w.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }


  // INFOS DECUPA

  getDecupaInfos = () => {

 
    api.get('/get/get.php?id='+this.state.id+'&sheet=decupagem&col_id=budget_id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
       
      
        let salvarCarta = this.state.salvarCarta;
        let office = this.state.office;
        
        salvarCarta['midias'] = result.data[0]['midias'];
        office['midias'] = result.data[0]['midias'];

        salvarCarta['periodo'] = result.data[0]['praca'] + ' - ' + result.data[0]['periodo']  + ' meses';
        office['periodo'] = result.data[0]['praca'] + ' - ' + result.data[0]['periodo'] + ' meses';
       
       
        this.setState({
         
          salvarCarta:salvarCarta,
          office:office,
          salvo:false,
          anchorEl: null
        })
        
    
        this.endLoad();

      }})



  }
  // VALORES BUDGET

  valoresBudget = () => {

    this.setState({
      officeLoad:false
    })
   api.get('/get/get.php?id='+this.state.id+'&sheet=budget&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
       
        let salvarCarta = this.state.salvarCarta;
        let office = this.state.office;
        
        salvarCarta['valores'] = this.formatMoney(result.data[0]['valor']/1);
        salvarCarta['weather'] = this.formatMoney(result.data[0]['valor']*0.4);
        salvarCarta['adiantamento1'] = this.formatMoney(result.data[0]['valor']*0.2*1.153);
        salvarCarta['adiantamento2'] = this.formatMoney(result.data[0]['valor']*0.3*1.153);
        salvarCarta['cancelamento'] = this.formatMoney(result.data[0]['valor']*0.5*1.153);


        office['valores'] = this.formatMoney(result.data[0]['valor']/1);
        office['weather'] = this.formatMoney(result.data[0]['valor']*0.4);
        office['adiantamento1'] = this.formatMoney(result.data[0]['valor']*0.2*1.153);
        office['adiantamento2'] = this.formatMoney(result.data[0]['valor']*0.3*1.153);
        office['cancelamento'] = this.formatMoney(result.data[0]['valor']*0.5*1.153);
       
       
        this.setState({
          valores: this.formatMoney(result.data[0]['valor']/1),
          weather: this.formatMoney(result.data[0]['valor']*0.4),
          adiantamento1: this.formatMoney(result.data[0]['valor']*0.2*1.153),
          adiantamento2: this.formatMoney(result.data[0]['valor']*0.3*1.153),
          cancelamento: this.formatMoney(result.data[0]['valor']*0.5*1.153),
          salvarCarta:salvarCarta,
          office:office,
          salvo:false,
          anchorEl: null
        })


        setTimeout(() => {
          this.setState({
            officeLoad:true,
          })
        }, 10);
        
      
        this.endLoad();

      }})

  }

  // UPDATE TEXT
  updateText = (e,w) => {


    let text = e.target.innerText.replace(/(?:\r\n|\r|\n)/g, '<br/>');;

    for(let i=0;i<5;i++){
    text = text.replace('<span>','');
    text = text.replace('</span>','');
    }
    for(let i=0;i<200;i++){
      text = text.split(i+'"').join(i+'s');
    }

    for(let i=0;i<100;i++){
      text = text.split(i+"'").join(i+'m');
    }


    text = text.split("'").join('"');

    let salvarCarta = this.state.salvarCarta;
    salvarCarta[w] = text;
    
    let office = this.state.office;
    office[w] = text;
    
    

    this.setState({
      adiantamento1:office['adiantamento1'],
      adiantamento2:office['adiantamento2'],
      cancelamento:office['cancelamento'],
      weather:office['weather'],
      outros:office['outros'],
      office:office,
      salvarCarta:salvarCarta,
      salvo:false
    })
    
      
  }

  // SALVAR CARTA

  save = () => {
 

    api.post('update/updateOffice.php?&r='+Math.random(10000),JSON.stringify({ 
      
      sheet:'office',
      dados: [this.state.salvarCarta]
    
    })).then(result => {

          
      if (result.status === 200) {
        
        this.setState({

          salvo:true,
        
        })

      }})


  }
  // UPDATE TITULO

  updateTitulo = (e,w) => {

    let titulos = this.state.titulos;
    
    let text = e.target.innerText;

    for(let i=0;i<300;i+=5){
      text = text.split(i+'"').join(i+'s');
    }

    text = text.split("'").join('"');
 
    titulos[w]=text;


    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+titulos.join('`')+'&id='+this.state.office.id+'&sheet=office&col=titulos&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        this.setState({
          titulos:titulos
        })
      }})


  }
  


    // UPDATE TITULO

    updateTituloCarta = (e) => {

      
      
      let text = e.target.innerText;
  
      text = text.split("'").join('"');
   
      
  
  
      api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+text+'&id='+this.state.office.id+'&sheet=office&col=nome&r='+Math.random(10000)).then(result => {
          
        if (result.status === 200) {
          this.setState({
            projeto:text,
            salvo:false
          })
        }})
  
  
    }


 // ABRE MENU PRESET

 abreMenu = (event) => {


  this.setState({
      anchorEl: event.currentTarget
  })
  
  
};

// FECHA MENU PRESET

fechaMenu = (id) => {
  
  this.setState({
      anchorEl: null
  })
  };



// ADD VERSAO

novaVersao = (e) =>{
  
 

  let novaVersao = this.state.versoes+'<spam class="mt10 mini-titulo">Versão nova</spam><br><spam class="mt5 valor-menor">R$ 0.00</spam><br><br>';

  let salvarCarta = this.state.salvarCarta;
  salvarCarta['versoes'] = e;

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+novaVersao+'&id='+this.state.office.id+'&sheet=office&col=versoes&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      this.setState({
        versoes: novaVersao,
        salvarCarta:salvarCarta,
        anchorEl: null,
        salvo:false
      })
    
    }})


}


// UPDATE VERSAO
  
updateVersao = (e) =>{
  
  
  let text = e.target.innerHTML;
  for(let i=0;i<100;i++){
    text = text.split(i+'"').join(i+'s');
  }
  for(let i=0;i<40;i++){
    text = text.split(i+"'").join(i+'m');
  }


  let salvaVersao = text.split("'").join('"');

  let salvarCarta = this.state.salvarCarta;
  salvarCarta['versoes'] = salvaVersao;

  
  this.setState({
    versoes:salvaVersao,
    salvo:false,
    salvarCarta:salvarCarta
  })

}


// UPDATE WEATHER

upWeather = () =>{
  let w =this.state.showweather;

    if(w/1===0){
      w=1;
    }else{
      w=0;
    }

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+w+'&id='+this.state.office.id+'&sheet=office&col=showweather&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {
      this.setState({
        showweather:w
      })
      this.fechaMenu();
    }})
}


// OUTROS

addLine = () =>{

  let salvarCarta = this.state.salvarCarta;
  let office = this.state.office;

  office['outros'] = ' ';
  salvarCarta['outros'] = ' ';
  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value= &id='+this.state.office.id+'&sheet=office&col=outros&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {
      this.setState({
        salvarCarta:salvarCarta,
        office:office
      })
      this.fechaMenu();
    }})
}



// TITULOS INGLES

titulosIngles = () => {

  let titulos = 'Director`Sales`Deliverables`Media`Licensing period`Exclusivity`Estimate`Weather day`Postponement before PPM`Postponement after PPM`Canceling`Included`Not Included`Observations`Valid untill `Payment issue`Payment condition';

  titulos = titulos.split('`');

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+titulos+'&id='+this.state.office.id+'&sheet=office&col=titulos&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      this.setState({
        titulos: titulos,
        anchorEl: null,
        ingles:true
      })
    
    }})

    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value=1&id='+this.state.office.id+'&sheet=office&col=ingles&r='+Math.random(10000))


}


// ESCONDER PRINCIPAL

esconderPrincipal = () => {
  
  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value=1&id='+this.state.office.id+'&sheet=office&col=sem_custo_principal&r='+Math.random(10000))

  this.setState({
    hideMain:1,
    anchorEl: null,
  })

}

// HIDE SAVE

hide = () => {
  this.setState({
    salvo:true
  })
}


render() {
    return (
  
      
      <ThemeProvider theme={theme}>
            {this.state.permission > 2 ? <Redirect to='/login'/> : ''}
<Meta title={"Office | "+this.state.id + ' - ' +this.state.cliente + ' ' + this.state.projeto} />
          <div className="conteudo print">
             
          <Helmet>
                      
                    
                      <style>
                        
                         {`
                              body {
                                  background: white;
                                  margin:0px;
                                  padding:0px;
                                  line-height:20px;
                                  font-size: 11px;
                              }
  
  .dia-semana{
      width: 13vw;
      padding: 5px;
      font-size: 10px;
  }
  
  .box-calendar{
      width: 13vw;
      border: 1px solid rgb(165, 165, 165);
  }
  
  .box-calendar-off{
      width: 13vw;
      border: 1px solid rgb(172, 172, 172);
  }
  
  .box-calendar-high{
      width: 13vw;
      border: 1px solid #f0f0f0;
  }
  
  .box-calendar:hover{
      border: 1px solid #888;
  }
  .calendar-mes{
      display: flex;
      justify-content: space-between;
      
  }
  
  .box-calendar{
      background-image: -webkit-linear-gradient(top, #acacac, #acacac 18%, transparent 18%, transparent 100%);
  }
  
  .box-calendar.cor0{
      background-image: -webkit-linear-gradient(top, #8f8f8f, #8f8f8f 18%, transparent 18%, transparent 100%);
      border: 1px solid #5f5f5f;
  }
  
  .box-calendar.cor1{
      background-image: -webkit-linear-gradient(top, #A456FF, #A456FF 18%, transparent 18%, transparent 100%);
      border: 1px solid #5f5f5f;
      color: white;
  }
  
  
  .txt-dia{
      font-size: 10px;
      width: 100%;
      height: 40px;
      padding: 5px 5px;
      color: #f0f0f0;
  }
  
  .txt-dia.c2{
      
      color: #444;
  }
  
  .menu{
      display: none !important;
  }
  
  .footer{
      display: none !important;
  }
  
  body{
      background: white !important;
      color: #333 !important;
  }
  
  .print{
      
      
  }
  .mini-titulo{
    font-size: 14px;
    line-height: 16px;
  }

  .sub-titulo{
    font-size: 20px;
    line-height: 30px;
  }
  .titulo{
      font-size: 35px;
      line-height: 35px;
  }
  

  .valor{
    font-size:30px;
    font-weight:800;
  }

  .valor-menor{
    font-size:20px;
    font-weight:800;
  }

  .conteudo.print{
    padding:0px;
    background:gray;
  }

  .bgcolor_10{
    background:black;
  }

  .box-extras{
    
    padding-top:5px;
    padding-bottom:5px;
  }

  .box-extras-assina{
    
    padding-top:5px;
    padding-bottom:5px;
  }

  .legal{
    font-size:8px;
    line-height: 16px;
  }

  .space-legal{
    margin-top:300px;
  }

  .page{
    width:793px;
    height: 1120px;
    padding-top:0px;
    margin:auto;
}

.page-santa{
    width:793px;
    height: 1120px;
    padding-top:0px;
    margin:auto;
    background-image:url('https://treatments.run/landscape/img/Base.png');
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

.page2{
    background:white;
    width:793px;
    height: 1120px;
    padding-top:40px;
    margin:auto;
    color:#224549 !important;
   
}

.footer-carta{
    position: absolute;
    width: 793px;
    height: 31px;
    box-sizing:border-box;
    padding-left:40px;
    padding-right:40px;
    top: 1080px;
    text-transform:capitalize;
    font-family:'Cosmica Bold';   
}

.footer-carta.p2{
  top: 2210px;
}

.footer-carta.p3{
  top: 3330px;
}

.footer-carta.p4{
  top: 4450px;
}

.footer-carta.p5{
  top: 5570px;
}


.date-office{
  float:right;
  font-family:'Cosmica';  
}
  
.legal-font{
  font-size:8px;
  line-height:8px;
  
}

.titulo.print{
  white-space:normal !important;
  overflow:normal !important;
  text-overflow:inherit !important;
}

.logo-produtora{
  width:100%;
  height:125px;
  margin-left:-50px;
}

.logo-sub{
  width:200px;
  height:50px;
  margin-left:40px;
  margin-bottom:-20px;
}

.img-logo-main{
    width: 100%;
    height: 100%;
}

.img-logo-main{
  width:500px;
  height:200px;
}

  
                         `}
                         
                      </style>
                  </Helmet>

                  {this.state.loading ? <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/> : ''}
                  <div>
                  {this.state.salvo ? '' : <div onClick={()=>this.save()} onDoubleClick={()=>this.hide()} className="bt bgcolor_1 bt-save-office">Salvar</div>}

                  {this.state.officeLoad ? 
                  <div className={this.state.empresa_id/1!==1 ? "page" : "page-santa"} style={{color:'#'+this.state.cor_fonte,backgroundColor:'#'+this.state.cor_bg}}>
                 
                    <div className="footer-carta">{this.state.empresa}
                    <div className="date-office">{this.state.ano}</div></div>
                    
                    <Grid container className="pd60">
                    <Grid xs={6}><div className="logo-produtora"><img alt="logo" className="img-logo-main" src={'https://treatments.run/landscape/img/upload/'+this.state.logo_carta}></img></div></Grid>
                    <Grid xs={6}>
                      <Grid xs={12} className="">{'Orc'+this.state.id}</Grid>
                      <Grid xs={12} className="sub-titulo">{this.state.cliente}</Grid>
                      <Grid xs={12} className="titulo print b"><ContentEditable onBlur={(e)=>this.updateTituloCarta(e)} html={this.state.projeto}/></Grid>
                      </Grid>
                      <Grid xs={6} className="mt100">
                        <div className="mini-titulo b" ><ContentEditable onBlur={(e)=>this.updateTitulo(e,0)} html={this.state.titulos[0]}/><div></div></div>
                        <div><ContentEditable onBlur={(e)=>this.updateText(e,'diretor')} html={this.state.office['diretor']}/></div>
                        <div className="mini-titulo b mt20"><ContentEditable onBlur={(e)=>this.updateTitulo(e,1)} html={this.state.titulos[1]}/></div>
                        <div><ContentEditable onBlur={(e)=>this.updateText(e,'atendimento')} html={this.state.office['atendimento']}/></div>
                        
                        <div className="mini-titulo b mt20"><ContentEditable onBlur={(e)=>this.updateTitulo(e,2)} html={this.state.titulos[2]}/></div>
                        <div><ContentEditable onBlur={(e)=>this.updateText(e,'entregas')} html={this.state.office['entregas']}/></div>
                      
                        <div className="mini-titulo b mt20"><ContentEditable onBlur={(e)=>this.updateTitulo(e,4)} html={this.state.titulos[4]}/></div>
                        <div><ContentEditable onBlur={(e)=>this.updateText(e,'periodo')} html={this.state.office['periodo']}/></div>


                      </Grid>

                      <Grid xs={6} className="mt100">
                        
                      <div className="mini-titulo b"><ContentEditable onBlur={(e)=>this.updateTitulo(e,3)} html={this.state.titulos[3]}/></div>
                        <ContentEditable onBlur={(e)=>this.updateText(e,'midias')} html={this.state.office['midias']}/>

                        <Tooltip title="Dois click para abrir o menu" placement="top" arrow TransitionComponent={Zoom}><div className={this.state.hideMain/1===1 ? "mini-titulo mt20" : "mini-titulo b mt20"} onBlur={(e)=>this.updateTitulo(e,6)} onDoubleClick={(e)=>this.abreMenu(e)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[6] : ''}</div></Tooltip>
                        <div className={this.state.hideMain/1===1 ? "valor-menor mt2" : 'mt15 valor b'}><ContentEditable onBlur={(e)=>this.updateText(e,'valores')} html={this.state.office['valores']}/></div>

                        <div className="mt20" onBlur={(e)=>this.updateVersao(e)}><ContentEditable onBlur={(e)=>this.updateVersao(e)} html={this.state.versoes}/></div>
                        
                        {this.state.legal/1===0 ?
                        <div>
                          {this.state.showweather/1===0 ? 
                          
                        <div className="box-extras mt30" style={{borderBottom: '1px solid #'+this.state.cor_fonte}}>
                          <Grid container>
                            <Grid xs={6}><ContentEditable onBlur={(e)=>this.updateTitulo(e,7)} html={this.state.titulos[7]}/></Grid>
                            <Grid xs={6} className="tr"><ContentEditable onBlur={(e)=>this.updateText(e,'weather')} html={this.state.weather}/></Grid>
                          </Grid>
                        </div>
                        :''}

                        <div className="box-extras mt10" style={{borderBottom: '1px solid #'+this.state.cor_fonte}}>
                          <Grid container>
                          <Grid xs={6}><ContentEditable onBlur={(e)=>this.updateTitulo(e,8)} html={this.state.titulos[8]}/></Grid>
                            <Grid xs={6} className="tr"><ContentEditable onBlur={(e)=>this.updateText(e,'adiantamento1')} html={this.state.adiantamento1}/></Grid>
                            
                          </Grid>
                        </div>

                        <div className="box-extras mt10" style={{borderBottom: '1px solid #'+this.state.cor_fonte}}>
                          <Grid container>
                            <Grid xs={6}><ContentEditable onBlur={(e)=>this.updateTitulo(e,9)} html={this.state.titulos[9]}/></Grid>
                            <Grid xs={6} className="tr"><ContentEditable onBlur={(e)=>this.updateText(e,'adiantamento2')} html={this.state.adiantamento2}/></Grid>
                            
                          </Grid>
                        </div>

                        <div className="box-extras mt10" style={{borderBottom: '1px solid #'+this.state.cor_fonte}}>
                          <Grid container>
                          <Grid xs={6}><ContentEditable onBlur={(e)=>this.updateTitulo(e,10)} html={this.state.titulos[10]}/></Grid>
                            <Grid xs={6} className="tr"><ContentEditable onBlur={(e)=>this.updateText(e,'cancelamento')} html={this.state.cancelamento}/></Grid>
                            
                          </Grid>
                        </div>
                        <div className="mt30 b">
                          {this.state.office['outros']!=='' ? <div>{this.state.titulos[0]==='Director' ? 'Extra': 'Outros'}</div> : ''}
                        </div>
                        <div className="mt20">
                        {this.state.office['outros']!=='' ? <ContentEditable onBlur={(e)=>this.updateText(e,'outros')} html={this.state.outros}/> : ''}

                        </div>
                        </div>

                        :''}
                      </Grid>
                  </Grid>

                  
                  </div>
                 
                 :''}

                {this.state.officeLoad ? 
                                  <div className="page2" style={{color:'#'+this.state.cor_bg}}>
                                    <div className="footer-carta p2">{this.state.empresa}
                                    <div className="date-office">{this.state.ano}</div></div>
                                    <Grid container spacing={5}><div/><img alt="logo" className="logo-sub" src={'https://treatments.run/landscape/img/upload/'+this.state.logo_carta_2}></img></Grid>
                                    <Grid container spacing={5} className="pd60">
                                        <Grid xs={6}>
                                          <div className="mini-titulo mb20"><ContentEditable onBlur={(e)=>this.updateTitulo(e,11)} html={this.state.titulos[11]}/></div>
                                          <div className="wp100 incluso"><ContentEditable onBlur={(e)=>this.updateText(e,'incluso')} html={this.state.office['incluso']}/></div>
                                          
                                          
                                        </Grid>
                                        
                                        <Grid xs={6}>
                                          <div className="mini-titulo mb20 ml20"><ContentEditable onBlur={(e)=>this.updateTitulo(e,12)} html={this.state.titulos[12]}/></div>
                                          <div className="wp100 incluso ml20"><ContentEditable onBlur={(e)=>this.updateText(e,'nao_incluso')} html={this.state.office['nao_incluso']}/></div>
                                          
                                        </Grid>

                                    </Grid>
                                  </div>
                                  :''}
                                  
                {this.state.officeLoad ? 
                  <div className="page2" style={{color:'#'+this.state.cor_bg}}>

                      <div className="footer-carta p3">{this.state.empresa}
                      <div className="date-office">{this.state.ano}</div></div>
                      <Grid container spacing={5}><div/><img alt="logo" className="logo-sub" src={'https://treatments.run/landscape/img/upload/'+this.state.logo_carta_2}></img></Grid>
                      <Grid container spacing={5} className="pd60">

                            <Grid xs={12}>
                              <div className="mini-titulo mb20"><ContentEditable onBlur={(e)=>this.updateTitulo(e,13)} html={this.state.titulos[13]}/></div>
                              <div className="wp100 incluso"><ContentEditable onBlur={(e)=>this.updateText(e,'observacoes')} html={this.state.office['observacoes']}/></div>
                              
                              
                            </Grid>
                            
                      </Grid>

                  </div>
                :''}
                

                 



                  {this.state.officeLoad ? 
                  <div className="page2" style={{color:'#'+this.state.cor_bg}}>

                  

                  <div className="footer-carta p5">{this.state.empresa}
                  <div className="date-office">{this.state.ano}</div></div>
                  <Grid container spacing={5}><div/><img alt="logo" className="logo-sub" src={'https://treatments.run/landscape/img/upload/'+this.state.logo_carta_2}></img></Grid>
                  <Grid container spacing={5} className="pd60">

                        <Grid xs={4}>
                        <div className="mini-titulo mt30"><ContentEditable onBlur={(e)=>this.updateTitulo(e,14)} html={this.state.titulos[14]}/></div>
                        <div className="mt5 b"><ContentEditable onBlur={(e)=>this.updateText(e,'validade')} html={this.state.office['validade']}/></div>
                        </Grid>
                        <Grid xs={4}>
                        <div className="mini-titulo mt30 tc"><ContentEditable onBlur={(e)=>this.updateTitulo(e,15)} html={this.state.titulos[15]}/></div>
                        <div className="mt5 b tc"><ContentEditable onBlur={(e)=>this.updateText(e,'faturamento')} html={this.state.office['faturamento']}/></div>
                        
                        </Grid>
                        <Grid xs={4}>
                        <div className="mini-titulo mt30 tr"><ContentEditable onBlur={(e)=>this.updateTitulo(e,16)} html={this.state.titulos[16]}/></div>
                        <div className="mt5 b tr"><ContentEditable onBlur={(e)=>this.updateText(e,'pagamento')} html={this.state.office['pagamento']}/></div>
                        </Grid>
                        <div className="mt100"></div>
                        <Grid xs={4}>
                          <div className="mt50 assinado sm">{this.state.nome}</div>
                          <div className="box-extras-assina" style={{borderTop: '1px solid #333'}}><ContentEditable html={this.state.empresa}/></div>
                          
                        </Grid>
                        <Grid xs={4}>
                          
                        </Grid>
                        <Grid xs={4}>
                          <div className="box-extras-assina mt100 tr" style={{borderTop: '1px solid #333'}}>{this.state.titulos[0]==='Director' ? 'Client' : 'Cliente'}</div>
                          
                        </Grid>
                        
                        
                        <Grid xs={12}>
                        {this.state.legal/1===0 ? 
                        <div>
                          {this.state.cliente.replace(' ','')!=='Unilever' ?
                            <div>

                              {this.state.ingles/1===1 ? 
                              <div>
                              <div className="mt30 micro-titulo space-legal">Legal</div>
                              <div className="mt5 legal">
                              <spam className="b">01. Production Start</spam> - The production will start after the signature of the clients and/or agencys legal representative in accordance to the clauses herein described as well as formal briefing via email. Despite of the approval of production, if any changes may occur on the scope of the project, the requests will be analyzed and if necessary, budget and schedule will be revised. 
                              <br/>
                              <spam className="b">02. Cancelation</spam>  - In case of cancellation of the project after the start and until 1 day before the shooting, the agency and/or client will be responsible for the payment of 50% of the cost of the budget. In case of cancellation afterward the filming day, the agency and/or the client will be responsible for the amount spent, however the minimum charged will correspond to 50% of the total budget. 
                                <br/>
                                <spam className="b">03 . Payment</spam>- The payment order of the project must be done until the pre production meeting with the client. The absence of the payment order will prevent the production to go on. The due date of the payment will be considered as of the the approval of the project, therefore the period spent to issue the billing will be deducted from the realization of the payment. 
                                <br/>
                                <spam className="b">04. Condecine</spam>- The Condecine tax will be calculated according to a table established by Ancine (Agência Nacional do Cinema), over the type of material and media, summed to taxes obligations stipulated by Brazilian government. The production company will not be responsible for any additional tax nor penalties that may occur for any piece placed on non foretold specific media. 
                                <br/>
                                <spam className="b">05. Production term</spam> - The production termination of the job, will be established on written agreement among all parts if not described herein and must be defined by the pre production meeting, on a schedule where all the parts have to attend. Although upon agreement a first schedule is approved, revised versions must be substituted and considered, due to any unpredictable circumstance. 
                                <br/>
                                <spam className="b">06. Weather day / contingency day</spam> - The amount defined for an additional filming day for any cause of "weather day" or "contingency day”, will be billed all together with the second part of the payment of the previously approved budget. Or on its total, when the agreement is made upon single payment, previously billed.
                                
                                </div>
                              </div>

                              : 
                              <div>
                            <div className="mt30 micro-titulo space-legal">Legal</div>
                            <div className="mt20 legal">
                              
                                
                              <spam className="b">01. Início da Produção</spam> - Só daremos início na produção após recebermos a aprovação do presente orçamento assinado pelo representante legal do cliente e/ou da agência, ou ainda mediante pedido de produção formalizado via email. Se após o pedido de produção tivermos alguma mudança de escopo ou roteiro, essa mudança será analisada e, se preciso, orçada à parte e o cronograma revisado.
                              <br/>

                              <spam className="b">02. Cronograma e briefing</spam> - O cronograma será aprovado juntamente com o orçamento. Se houver alteração no prazo de entrega estabelecido, poderão haver custos extras. Se houverem alterações no briefing e/ou na complexidade do trabalho ou atrasos por parte da agência e/ou do cliente, na entrega de materiais, feedbacks e aprovações, poderão ocasionar custos extras e
alterações de cronograma.
                              <br/>

                              <spam className="b">03. Equipe 24h</spam> - Se houver necessidade de equipe 24h ou aos finais de semana, de acordo com a urgência ou exigências da agência e/ou cliente, a solicitação deverá ser feita no momento do pedido de orçamento. Se essa solicitação for feita após o orçamento aprovado, haverão custos extras.
                              <br/>

                              <spam className="b">04. Cancelamento</spam> - Em caso de cancelamento da produção após seu início e até 1 dia antes da captação, a agência ou cliente serão responsáveis pelo pagamento de 50% do custo da produção. Em caso de cancelamento a partir do dia da filmagem, o valor será orçado pela produtora, de acordo com os gastos realizados até o momento, sendo o custo de cancelamento de no mínimo de 50% do valor total do projeto.
                              <br/>
                              <spam className="b">05 . Faturamento</spam> - O faturamento do projeto deve se dar no máximo até a data de reunião de pré-produção com o cliente. Não será possível a realização da filmagem sem o faturamento do projeto. O prazo de vencimento será considerado a partir da aprovação do projeto, devendo ser descontado do prazo de pagamento o período utilizado para a realização do faturamento. A produtora se reserva o direito de bloquear a produção e suas devidas entregas, em qualquer fase do projeto, em caso de falta de faturamento e/ou pagamento.  
                              <br/>
                              <spam className="b">06. Condecine</spam> - A taxa de Condecine será calculada de acordo com a tabela da Ancine, o tipo da peça e as mídias em que será veiculada, acrescida dos impostos da produtora. A produtora não se responsabiliza pelas taxas adicionais e/ou penalidades que possam ocorrer, caso haja alguma veiculação de uma peça ou mídia não prevista, e reserva-se o direito de cobrar do cliente anunciante e/ou agência os valores adicionais referidos. O custo de cópias de veiculação e taxa Condecine não estão inclusos no valor orçado, exceto se estiverem expressamente descritos. Caso esses custos não inclusos se apliquem ou sejam demandados, serão cobrados à parte.
                              <br/>
                              <spam className="b">07. Prazo de produção</spam> - O prazo para realização da produção e entrega da obra, caso não seja especificamente determinado no presente orçamento, será estabelecido de comum acordo entre as partes, devendo estar previamente definido até a reunião de pré-produção através de um cronograma que cada parte integrante deste orçamento, bem como suas eventuais versões posteriores e mais atualizadas. 
                              <br/>
                              <spam className="b">08. Weather day / contingency day</spam> - O valor definido para a diária adicional de filmagem ou gravação por motivo de “weather day" ou "contingency day”, quando necessário, será faturado juntamente com a segunda parcela, se houver. Ou à parte caso a condição de pagamento preveja parcela única, faturada anteriormente a referida diária adicional. 

                            
                            </div>
                            </div>
                            }

                          </div>
                        :
                        
                        
                        <div>
                        <div className="mt30 legal-font space-legal">UNILEVER INTELLECTUAL PROPERTY AND CANCELLATION/POSTPONEMENT:</div><br></br><br></br>
                       <div className="legal-font">
                        If the agency causes the production to be canceled less than 7 working days prior to the scheduled shoot date, shall be liable for and pay the following:<br></br>

                        100% of the production companies actual *expenses incurred prior to notice of cancellation plus 100% of the mark-up on expenses incurred prior to notice of plus 50% of quoted director/photographer and producer fees maximum.<br></br>

                        If the agency causes the production to be postponed less than 7 working days prior to the scheduled shoot date, shall be liable for and pay the following: 100% of the production companies actual *expenses incurred prior to notice of postponement only for those that will not be applied/used/optimized for the rescheduled shooting day plus 100% of the mark-up on expenses incurred prior to notice of postponement.

                        Edit and post-production cancellation costs would only be acceptable if cancellation occurs immediately prior to editing or the post-session not prior to shooting.  <br></br>  

                        The RAP must assess the breakdown cost before final approval by MPL if cancellation is applicable.<br></br>

                        All material produced becomes the property (copyright/usage) of Unilever upon creation of material and approval (without extra costs). Inclusive of post-production project files

                        Unilever may broadcast the material however long or wherever they want (without additional costs). Unilever has the right to re-edit the TVC/edit/DIGITAL ASSET the print image without any additional fees and without the need to inform production company, post-production house, photographer or director (not applicable for Talent/Music).
                        <br></br>
                        <br></br>

                        <b>Post Production:</b><br></br>

                        IP is assigned to Unilever for all working files/wireframes

                        Inclusion of digital uploads for approval and final asset retrieval are included in master quote.<br></br>

                        No additional costs for retrieval of assets and confirmed set rates long term for additional work that may come through from other markets and flexibility of access to suites.<br></br>

                        All hard-drives containing rushes material (e.g raw footage) must be encrypted. All delivery platforms used to transfer rushes material (e.g. raw footage) must have enforced password compliance, defined user access and time limitations to access the links.<br></br>

                       </div>
                        <div className="mt10 legal-font">SANTA TRANSMEDIA TERMS AND CONDITIONS</div><br></br>
                       
                        <div className="legal legal-font">
                        <spam className="b">01. Production Start</spam> - The production will start after the signature of the clients and/or agencys legal representative in accordance to the clauses herein described as well as formal briefing via email. Despite of the approval of production, if any changes may occur on the scope of the project, the requests will be analyzed and if necessary, budget and schedule will be revised.
                        <br/>
                        <spam className="b">02. Payment</spam>  - The payment order of the project must be done until the pre-production meeting with the client. The absence of the payment order will prevent production from proceeding. The due date of the payment will be considered as of the approval of the project. Therefore the period spet to issue the billing will be deducted from the realization of the amount.
                          <br/>
                          <spam className="b">03. Condecine</spam>- The Condecine tax will be calculated according to a table established by Ancine (Agência Nacional do Cinema), over the type of material and media, summed to tax obligations stipulated by the Brazilian government. The production company will not be responsible for any additional tax nor penalties that may occur for any piece placed in specific media not foreseen.
                          <br/>
                          <spam className="b">04. Production term</spam> - The end of the production of the work, will be established by written agreement between all parties, if not described here and must be defined by the pre-production meeting, in a schedule in which all parts must attend. Although by agreement, a first schedule is approved, the revised versions must be replaced and considered due to any unforeseeable circumstances.
                          <br/>
                          <spam className="b">05. Weather day / contingency day</spam> - The amount defined for an additional filming day for any cause of weather day or contingency day, will be billed all together with the second part of the payment of the previously approved budget. Or on its total, when the agreement is made upon a single payment, already billed.
                          
                          </div>
                          
                        </div>
                        
                        
                        }


                        </div> 
                        
                        :
                        
                        ''

                        
                        }
                        </Grid>

                    </Grid>

                  </div>

              :''}
                  </div>
            </div>
  


                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={()=>this.fechaMenu()}
                        >
                                <StyledMenuItem><ListItemText primary="" /></StyledMenuItem>
                                <StyledMenuItem onClick={(w)=>this.titulosIngles()}><ListItemIcon><Add fontSize="small" /></ListItemIcon><ListItemText primary="English version" /></StyledMenuItem>
                                <StyledMenuItem onClick={()=>this.valoresBudget()}><ListItemIcon><Add fontSize="small" /></ListItemIcon><ListItemText primary="Importar valores do budget" /></StyledMenuItem>
                                <StyledMenuItem onClick={(w)=>this.esconderPrincipal()}><ListItemIcon><Add fontSize="small" /></ListItemIcon><ListItemText primary="Esconder versão principal" /></StyledMenuItem>
                                <StyledMenuItem onClick={(w)=>this.novaVersao()}><ListItemIcon><Add fontSize="small" /></ListItemIcon><ListItemText primary="Adicionar versão" /></StyledMenuItem>
                                <StyledMenuItem onClick={(w)=>this.upWeather()}><ListItemIcon><Add fontSize="small" /></ListItemIcon><ListItemText primary="Show/hide Weather" /></StyledMenuItem>
                                <StyledMenuItem onClick={(w)=>this.addLine()}><ListItemIcon><Add fontSize="small" /></ListItemIcon><ListItemText primary="Outros custos" /></StyledMenuItem>
                                <StyledMenuItem onClick={(w)=>this.fechaMenu()}><ListItemIcon><Close fontSize="small" /></ListItemIcon><ListItemText primary="Fechar" /></StyledMenuItem>
                        </Menu>


      </ThemeProvider>
    )
  }
  
  }
  
  
  
