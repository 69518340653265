import { Grid, ThemeProvider, createTheme, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import {deepPurple,teal} from '@material-ui/core/colors';
import { AutoComp, MySelect } from '../../components/textField';
import api from '../../services/api';

const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },
   
    })
    



export default class Bloco extends Component {
    
    constructor(props){
        
        super()

    }


    state = {
        orcadoMostrar:false,
        permission: localStorage['permission'],
        valor_bloco:0,
        qt:[{
                value:1,
                label:1
            },
            {
                value:2,
                label:2
            },
            {
                value:3,
                label:3
            },
            {
                value:4,
                label:4
            },
            {
                value:5,
                label:5
            },
            {
                value:6,
                label:6
            },
            {
                value:7,
                label:7
            },
            {
                value:8,
                label:8
            },
            {
                value:9,
                label:9
            },
            {
                value:10,
                label:10
            },
    ],
    horas:[{
                value:12,
                label:12
            },
            {
                value:13,
                label:13
            },
            {
                value:14,
                label:14
            },
            
        ],
        ce:0,
        cargos:[],
        contract:{}
        
        
    }


    changeContract=(e,w)=>{
     let contract = this.state.contract;
        contract[w] = e
        this.setState({
            contract:contract
        })
    }

    changeContractAuto=(e,w)=>{

        let cargos = this.state.cargos;
        cargos[w]=e;
       

        this.setState({
            cargos:cargos
        })

    }

    void=()=>{

    }

    showContrato = () => {

        let orcadoMostrar = 1-this.state.orcadoMostrar;

        this.setState({
            orcadoMostrar:orcadoMostrar
        })
        
        if(orcadoMostrar===1){
            
            api.get('get/get.php?sheet=budget&id='+this.props.id+'&col_id=id&empresa_id=no&r='+Math.random(10000)).then(res=>{

                let contract = []
                if(res.data[0].ce_infos){
                 contract = JSON.parse(res.data[0].ce_infos);
                }
               
                this.setState({
                    orcadoMostrar:orcadoMostrar,
                    contract:contract
                })
            }
            )
            
        }
        
        
    }

    enviarContrato = () => {

        console.log('CARGOS',this.state.cargos.join(','))

        let contrato = Object.assign({}, this.state.contract);
       
        
        if(Object.keys(contrato).length===18 && this.state.cargos.length===3){

            api.get('update/upEC.php?id='+this.props.id+'&cargos='+this.state.cargos.join(',')+'&usuario_id='+this.props.usuario_id+'&ec='+JSON.stringify(contrato)+'&r='+Math.random(10000)).then(res => {

                if (res.status === 200) {

                    this.setState({
                        ce:1
                    })
                }

            }
            
            )
        }else{
            alert('Você precisa preencher todos os campos!')
        }
        
        

    }
    
    render(props) {
        

        return (

                <ThemeProvider theme={theme}>
                  
                  <div className="mt10 mb5" onClick={()=>this.showContrato()}>
                                <Grid container spacing={0} className="bgcolor_1 pd30 br5">
                                    
                                    <Grid item xs={9} className="mini-titulo">
                                        <div className="mt10">Execution Contract</div>
                                    </Grid>

                                </Grid>
                  </div>

                  {this.state.orcadoMostrar ? 
                  <div>
                    {
                    this.props.ce/1===0 ? 
                        <Grid container spacing={1}>

                        <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Diretor</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <AutoComp empresa_id={this.props.empresa_id} sheet="users" tipo="comissao" label="Diretor" blur={()=>this.void()} change={(e)=>this.changeContractAuto(e,0)}/>
                                    : '' }
                                    
                                    </div></Grid>
                                </Grid>
                                </Grid> 

                                <Grid item xs={4}>
                                    <Grid container spacing={0}>
                                    <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Produtor Executivo</div></Grid>
                                        <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                        {this.state.contract ?
                                            <AutoComp empresa_id={this.props.empresa_id} sheet="users" tipo="comissao" label="Produtor Executivo" blur={()=>this.void()} change={(e)=>this.changeContractAuto(e,1)}/>
                                        : '' }
                                        
                                        </div></Grid>
                                    </Grid>
                                </Grid> 

                                <Grid item xs={4}>
                                    <Grid container spacing={0}>
                                    <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Coordenador</div></Grid>
                                        <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                        {this.state.contract ?
                                        <AutoComp empresa_id={this.props.empresa_id} sheet="users" tipo="comissao" label="Coordenador" blur={()=>this.void()} change={(e)=>this.changeContractAuto(e,2)}/>
                                            : '' }
                                        
                                        </div></Grid>
                                    </Grid>
                                </Grid> 

                            </Grid>
                              :''}

                            <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Diárias</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <MySelect
                                            fullWidth={true}
                                            list={this.state.qt}
                                            placeholder=''
                                            change={(e)=>this.changeContract(e,'diarias_qt')}
                                            initValue={this.state.contract.diarias_qt}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 
                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Horas de set</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <MySelect
                                            fullWidth={true}
                                            list={this.state.horas}
                                            placeholder=''
                                            change={(e)=>this.changeContract(e,'diarias_horas')}
                                            initValue={this.state.contract.diarias_horas}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Cidade(s)</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'cidade')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'cidade')
                                            }}
                                            value={this.state.contract ? this.state.contract.cidade : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Elenco Principal</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'principal')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'principal')
                                            }}
                                            value={this.state.contract ? this.state.contract.principal : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Coadjuvantes</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'coadjuvantes')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'coadjuvantes')
                                            }}
                                            value={this.state.contract ? this.state.contract.coadjuvantes : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Figurantes</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'figurantes')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'figurantes')
                                            }}
                                            value={this.state.contract ? this.state.contract.figurantes : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 


                            

                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Produção</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'producao')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'producao')
                                            }}
                                            value={this.state.contract ? this.state.contract.producao : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">AD</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'ad')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'ad')
                                            }}
                                            value={this.state.contract ? this.state.contract.ad : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            
                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">DOP</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'dop')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'dop')
                                            }}
                                            value={this.state.contract ? this.state.contract.dop : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Arte</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'arte')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'arte')
                                            }}
                                            value={this.state.contract ? this.state.contract.arte : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 


                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Figurino</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'figurino')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'figurino')
                                            }}
                                            value={this.state.contract ? this.state.contract.figurino : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            
                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Casting</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'casting')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'casting')
                                            }}
                                            value={this.state.contract ? this.state.contract.casting : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            

                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Make-up</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'makeup')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'makeup')
                                            }}
                                            value={this.state.contract ? this.state.contract.makeup : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 


                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Câmera</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'camera')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'camera')
                                            }}
                                            value={this.state.contract ? this.state.contract.camera : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            
                            <Grid item xs={4}>
                                <Grid container spacing={0}>
                                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Lentes</div></Grid>
                                    <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'lentes')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'lentes')
                                            }}
                                            value={this.state.contract ? this.state.contract.lentes : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                <Grid item xs={2} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Movimentos Orçados</div></Grid>
                                    <Grid item xs={10} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'movimentos')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'movimentos')
                                            }}
                                            value={this.state.contract ? this.state.contract.movimentos : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                <Grid item xs={2} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Locações</div></Grid>
                                    <Grid item xs={10} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'locacoes')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'locacoes')
                                            }}
                                            value={this.state.contract ? this.state.contract.locacoes : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                <Grid item xs={2} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Arte</div></Grid>
                                    <Grid item xs={10} className="bgcolor_6 br5 tc"><div className="">
                                    {this.state.contract ?
                                        <TextField
                                            fullWidth={true}
                                            placeholder=''
                                            onBlur={e => {
                                            this.changeContract(e.target.value,'arte_desc')
                                            }}
                                            onChange={e => {
                                            this.changeContract(e.target.value,'arte_desc')
                                            }}
                                            value={this.state.contract ? this.state.contract.arte_desc : ''}
                                            variant="outlined"
                                        />
                                        : '' }
                                    
                                    </div></Grid>
                                </Grid>
                            </Grid> 

                        </Grid> 

                    <div>

                    <br></br><br></br>
                    {
                    this.props.ce/1===1 || this.state.ce
                    ? 
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={3}>
                                <div className='assinado'>{this.props.ce_diretor/1 !== 0 ? this.props.ce_diretor : ''}</div>
                                <div className='tc mt10'>Diretor</div>
                        </Grid> 
                        <Grid item xs={3}>
                                <div className='assinado'>{this.props.ce_ep/1 !== 0 ? this.props.ce_ep : ''}</div>
                                <div className='tc mt10'>Produtor Executivo</div>
                        </Grid> 
                        <Grid item xs={3}>
                                <div className='assinado'>{this.props.ce_coord/1 !== 0 ? this.props.ce_coord : ''}</div>
                                <div className='tc mt10'>Coordenador</div>
                        </Grid> 
                        
                        <Grid item xs={3}>
                                <div className='assinado'>{this.props.ce_landscape/1 !== 0 ? this.props.ce_landscape : ''}</div>
                                <div className='tc mt10'>Landscape</div>
                        </Grid> 
                    </Grid> 
                    :
                    ''
                    }

                    </div>
                    
`                      <br></br><br></br><br></br><br></br>

                 
                    {this.props.ce/1===0 && this.state.ce===0

                    ? 
                                       
                    <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={9}>

                                </Grid>
                                <Grid item xs={3}>
                                <div className={" bt bgcolor_2"} onClick={()=>this.enviarContrato()}>Enviar Contrato</div>
                                </Grid>
                        </Grid>
                    </Grid>

                    : ''}
                    
                    </div>
                    :''
                    }

                    
                </ThemeProvider>
            
        )
    }
}
