import React, { Component} from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@mui/material/TextField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import './style.css';
import Meta from "../../components/meta";
import CurrencyFormat from "react-currency-format";
import {DialogTitle, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { PhotoCamera,InsertDriveFile } from "@material-ui/icons";
import Delete from "@material-ui/icons/Delete";
import { BarLoader } from "react-spinners";

import axios from "axios";

const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  overrides: {
      MuiTooltip: {
          tooltip: {
            fontSize: "11px",
            backgroundColor:'#666'
      }},
      MuiIconButton: {
        root: {
            color:'white',
          '&:hover': {
            color: '#4aedc4',
          }
        }
      }
    }

}
  
)
  


export default class Viewsheet extends Component {


  state = {
    token: 'tokenneutro',
    permission: localStorage['permission']/1,
    verba_id: this.props.match.params.id.split('_')[0],
    projeto_id: this.props.match.params.id.split('_')[1],
    loading:true,
    loadPerc:0,
    recibo:[],
    uploading:false
    
    
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
 

  async componentDidMount(){
  
    localStorage['neutro']=0;
    this.getInfo();
    
  }

  // PEGA INFORMAÇÕES DO BUDGET

  getInfo = async () => {

    this.toLoad()

      api.get('get/infoProjetos.php?id='+this.state.projeto_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
         
          this.setState({

            empresa_id:result.data.projeto[0].empresa_id,
            cliente:result.data.projeto[0].cliente,
            nome:result.data.projeto[0].nome
            
          })

          this.getVerbaInfo()
         
      }})
       

  }



  // GET INFO VERBA

  getVerbaInfo = () => {

    
    api.get('get/getVerbaInfo.php?id='+this.state.verba_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

    

        this.setState({
          verbaInfo:result.data.verba
        })

        this.getVerba()
      }})


  }


  // GET VERBA

  getVerba = () => {

    
    api.get('get/get.php?id='+this.state.verba_id+'&sheet=verba_cost&col_id=verba_id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {


        let listRecibos = result.data;
        let new_list = [];
      
        for(let i=0;i<listRecibos.length;i++){

          if(listRecibos[i].sub/1===0){
            new_list.push(listRecibos[i])
          }
        }

        this.setState({
          listRecibos:new_list
        })

        

        this.endLoad()
      }})


  }

    // ABRE ENTREGA

    enviarFechamento = () => {

      this.setState({
        enviaOpen:true
      })
  
    }

  // ABRE RECIBO

  abreRecibo = () => {

    this.setState({
      reciboOpen:true
    })

  }


  // FECHA RECIBO

  fechaRecibo = () => {

    this.setState({
      reciboOpen:false,
      enviaOpen:false
    })

  } 


  // UP RECIBO 

  upRecibo = (e,w) => {

    let recibo = this.state.recibo;

    recibo[w] = e.target.value;
  }



  // UPLOAD IMAGEM

  fileSelect = (event) => {
    this.setState({ uploading: true }); 

    const fd = new FormData();
    fd.append('image',event.target.files[0],event.target.files[0].name)
    axios.post('https://treatments.run/landscape/upload.php?id=0&sheet=no',fd).then(result => {
       
        this.setState({ 
            photo:result.data,
            uploading: false,
            comfoto:true
            }); 

            
    })
}



  // NOVO RECIBO

  addRecibo = () => {

    if(this.state.photo && this.state.recibo[0] && this.state.recibo[1]){

     api.get('https://treatments.run/landscape/insert/newRecibo.php?verba_id='+this.state.verba_id+'&nome='+this.state.recibo[0]+'&valor='+this.state.recibo[1]+'&img='+this.state.photo+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {

          this.setState({ 
            recibo:[],
            photo: false,
            reciboOpen:false
            }); 



            this.getVerba();

        }})
    }else{
      alert('Você precisa preencher os dois campos e subir a foto do recibo.')
    }
  }


  // DELETA RECIBO

  deletaRecibo = (id) => {

    api.get('delete/deleteActive.php?id='+id+'&sheet=verba_cost&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

          this.getVerba();

      }})
  }


  // ENTREGAR FECHAMENTO

  entregarFechamento = () => {

    this.toLoad();
    api.get('insert/entregarFechamento.php?verba_id='+this.state.verba_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

          
          window.location.reload()

      }})

  }


  // APROVAR FECHAMENTO

  aprovarFechamento = () => {

    this.toLoad();
    api.get('insert/aprovarFechamento.php?user_id='+localStorage['usuario_id']+'&verba_id='+this.state.verba_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        window.location.reload()
         

      }})

  }

  // RE ABRIR

  reAbrir = () => {

    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&sheet=verba&value=0&col=status&id='+this.state.verba_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

         
        window.location.reload()

      }})
  }

  render(){

  return (

    
    <ThemeProvider theme={theme}>
       
<Meta title="Projetos"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo">
      <Grid container spacing={3}>
            
            <Grid item xs={5}><div className="titulo"><span className="color_1">{this.state.projeto_id+' '}</span>{this.state.cliente ? this.state.cliente+' '+this.state.nome : ''}</div></Grid>
            <Grid item xs={2}><div className="sub-titulo mt15">{this.state.verbaInfo ? this.state.verbaInfo[0]['plano'] : 0}</div></Grid>
            <Grid item xs={2}><div className="sub-titulo mt15"><CurrencyFormat value={this.state.verbaInfo ? this.state.verbaInfo[0]['valor'] : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
            {this.state.verbaInfo ? <Grid item xs={3}>{this.state.verbaInfo[0]['status']==='Em aberto' ? <div className="bt large bgcolor_1" onClick={()=>this.abreRecibo()}>Adicionar Recibo</div>: <div>{this.state.permission/1===0 ? <div className="bt large bgcolor_2" onClick={()=>this.reAbrir()}>Re-abrir</div> : ''}</div>}</Grid> : ''}
             
          </Grid>
          
          <div className="mt30"></div>

                {this.state.listRecibos ? this.state.listRecibos.map((recibo,objectId)=>(
                  <Grid key={objectId} container spacing={0} className="line-budget mb1">
                    <Grid item xs={5} className="bgcolor_8"><div className="ff-ro">{recibo.nome}</div></Grid>
                    <Grid item xs={5} className="bgcolor_7 tc"><div className="ff-ro"><CurrencyFormat value={recibo.valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                    <Grid item xs={1} className="tc bgcolor_8"><IconButton href={"https://treatments.run/landscape/img/upload/"+recibo.img} target="blank" fontSize="small"><InsertDriveFile/></IconButton></Grid>
                    <Grid item xs={1} className="tc bgcolor_7"><IconButton onClick={()=>this.deletaRecibo(recibo.id)} fontSize="small"><Delete/></IconButton></Grid>
                    
                    
                  </Grid>

                )) : ''}

                {this.state.listRecibos ?
                  <Grid container spacing={0} className="line-budget mb1">
                    <Grid item xs={5} className="bgcolor_3"><div className="ff-ro">Total de recibos</div></Grid>
                    <Grid item xs={5} className="bgcolor_3 tc b"><div className="ff-ro"><CurrencyFormat value={this.state.listRecibos.reduce((a,v) =>  a = a + (v.valor/1) , 0 )} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'}/></div></Grid>
                    <Grid item xs={2} className="bgcolor_3"></Grid>
                   
                    
                    
                  </Grid>

                 : ''}


                 <Grid container spacing={0} className="line-budget mt30">
                        <Grid item xs={9}></Grid>
                        {this.state.verbaInfo ? <Grid item xs={3}>{this.state.verbaInfo[0]['status']==='Em aberto' ? <div className="bt large bgcolor_2" onClick={()=>this.enviarFechamento()}>Enviar fechamento</div>: ''}</Grid>:''}
                        {this.state.verbaInfo ? <Grid item xs={3}>{this.state.verbaInfo[0]['status']==='Entregue' && this.state.permission<2 ? <div className="bt large bgcolor_1" onClick={()=>this.aprovarFechamento()}>Aprovar fechamento</div> : ''}</Grid>:''}
                        
                        
                 </Grid>


</div>


            <Dialog
                open={this.state.reciboOpen}
                onClose={()=>this.fechaRecibo()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>Novo Recibo</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                <Grid container spacing={1}>
                  
                  <Grid item xs={4}>

                  <TextField variant="outlined" label="Descrição" fullWidth={true}  placeholder="Descrição" onBlur={(e)=>this.upRecibo(e,0)}/>
                
                  </Grid>

                  <Grid item xs={4}>

                  <OutlinedInput variant="outlined" type="number" startAdornment={<InputAdornment position="start">R$</InputAdornment>} fullWidth={true} onBlur={(e)=>this.upRecibo(e,1)}/>
                
                  </Grid>

                  <Grid item xs={2}>
                  <IconButton variant="contained" color={this.state.comfoto ? "primary" : "secondary"} onClick={()=>this.fileInput.click()}>{this.state.uploading ? 
                          <BarLoader
                            size={30}
                            color={"#33ab9f"}
                            loading={this.state.uploading}
                          />

                          : <PhotoCamera className="mt5"/>}</IconButton>
            <input type="file" onChange={this.fileSelect} style={{display:'none'}} ref={fileInput => this.fileInput = fileInput}></input>
                  </Grid>
                  <Grid item xs={2}><div className="bt large bgcolor_1" onClick={()=>this.addRecibo()}>Adicionar</div></Grid>

                  <div className="mt20 wp100 mb30">

                    
                    
                  </div>
                      
                  
                </Grid>

                </div>                    
                </DialogContent>
                
              </Dialog>



              <Dialog
                open={this.state.enviaOpen}
                onClose={()=>this.fechaRecibo()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>Entregar fechamento</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                      Todos seus recibos foram adicionados?
                      <div className="mt30"></div>
                      <Grid container spacing={1}>
                        
                  
                        <Grid item xs={2}><div className="bt large bgcolor_5" onClick={()=>this.fechaRecibo()}>Cancelar</div></Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}><div className="bt large bgcolor_1" onClick={()=>this.entregarFechamento()}>Entregar</div></Grid>


                            
                        
                      </Grid>

                </div>                    
                </DialogContent>
                
              </Dialog>



    </ThemeProvider>
  )


}
}
  