import React,{Component} from 'react'
import Board from './Board';
import Card from './Card';
import Grid from '@material-ui/core/Grid';
import api from '../../services/api';
import './style.css';

export default class Pies extends Component {



  // UPDATE TRACKING

  upTracking = (w,c) => {
    
    
    this.props.up((w-1),c);
    api.get('/update/update.php?id='+c+'&value='+(w-1)+'&sheet=projeto&col=tracking&r='+Math.random(10000))
    
  }




    render(props){
    return (
        
        
        <Grid item xs={3}>
        <Board update={(w,c) => this.upTracking(w,c)} id={this.props.id} color={this.props.color}>
        <div className={"header-budget br5 bgcolor_"+this.props.id}>{this.props.label}{this.props.label==='Orçando' && localStorage['empresa_id']/1===1 ? <div className='r nano-titulo mt2'><a href="/pauta">ver pauta</a></div> :''}</div>
              <div className="board">
                
                {this.props.budgets.map((card,objectID) => (
                
                    <Card colorId={this.props.id} status={card.projeto_status} novaVersao={(w)=>this.props.novaVersao(w)} caiu={(w) => this.props.caiu(w)} key={card.objectID} duplicate={(w) => this.props.duplicarBudget(w)} open={() => this.props.abreOrcamento(card.id)} list={this.props.display===card.id ? this.props.budgetList : []} id={card.id} nome={card.cliente +' | '+card.nome} atendimento={card.atendimento_nome} open_date={card.open_date} office={this.props.office} draggable="true">
                    
                </Card>

                ))}

              </div>
          
        </Board>
    </Grid>

       
    )
    
}
}

