import React, { Component} from "react";

import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Meta from "../../components/meta";
import {Dialog, DialogContent, FormControlLabel, FormGroup, Grid} from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { zonedTimeToUtc } from 'date-fns-tz'
import Switch from '@material-ui/core/Switch';
import { TextInput } from "../../components/textField";



const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
 

}
  
)

  const date = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  const today = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  
  
  date.setDate(date.getDate() + 7);

export default class Report extends Component {


  state = {
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    loading:true,
    loadPerc:0,
    range:1,
    colorStatus:['bgcolor_5s','bgcolor_4','bgcolor_2'],
    horas:[
      {'value':0,'label':0},
      {'value':1,'label':1},
      {'value':2,'label':2},
      {'value':3,'label':3},
      {'value':4,'label':4},
      {'value':5,'label':5},
      {'value':6,'label':6}
      

  ],
  extrasHoras:0,
  extrasProjeto:0,
  extrasDia:0,
    selectionRange:({
      startDate: today,
      endDate: date,
      key: 'selection',
    }),
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  async componentDidMount(){
    
    this.loadEmpresas();
    
  }



  // LISTA DE ORCAMENTOS


  loadEmpresas = async () => {
    
    this.toLoad();

    api.get('get/getEmpresas.php?id=0&sheet=empresa&col_id=active&r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {
          
            this.setState({empresas: result.data});
            this.endLoad();

        }});
    
  }


  // FORMATE DATE

  formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
}


// EMPRESA ATIVA

ativaEmpresa = (e,id) => {

    let  v=0;

    if(e.target.checked === false){
        v=1;
      }


    api.get('update/update.php?value='+v+'&id='+id+'&sheet=empresa&col=active&r='+Math.random(10000)).then(result => {
        
            if (result.status === 200) {
                    
                    this.loadEmpresas();

            }});

    }


// ABRE JANELA

openNova = () => {
    this.setState({
        openNovaEmpresa:true
    })
}


// FECHA JANELA

fechaJanela = () => {
    this.setState({
        openNovaEmpresa:false
    })
}

// SALVAR DADOS EMPRESA 

setEmpresa = (e,w) => {
    if(w===0){
        this.setState({
            nomeEmpresa:e.target.value,
        })
    }
    if(w===1){
        this.setState({
            emailEmpresa:e.target.value,
        })
    }
    if(w===2){
        this.setState({
            senhaEmpresa:e.target.value,
        })
    }
}

// CRIAR EMPRESA

criaNovaEmpresa = () => {

    if(this.state.nomeEmpresa && this.state.emailEmpresa && this.state.senhaEmpresa){
        
       api.get('insert/newEmpresa.php?nome='+this.state.nomeEmpresa+'&email='+this.state.emailEmpresa+'&senha='+this.state.senhaEmpresa+'&r='+Math.random(10000)).then(result => {
      
            if (result.status === 200) {

                this.fechaJanela()
                this.loadEmpresas();

            }})
    }else{

        alert('Você precisa preencher todos os campos.')
    }
}



  render(){


  return (

   
    <ThemeProvider theme={theme}>
       
       
<Meta title="Empresas"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo">
        <Grid container spacing={3}>
                
                <Grid item xs={4}><div className="titulo">Empresas</div></Grid>

                <Grid item xs={5}></Grid>
       
        
       
        <Grid item xs={3}>
        <div className="bt bgcolor_1 large" onClick={()=>this.openNova()}>Criar nova empresa</div>
               
       
        </Grid>
        


        </Grid>

       
      <div className="mt50">
      
                    {this.state.empresas ? this.state.empresas.map((lines,objectId)=>(
                            <Grid key={objectId} container spacing={0} className="bgcolor_6 br5 mb1">
                                <Grid item xs={3}>
                                <div className="pd5">{
                                <TextInput 
                                autocomplete={false}
                                sheet="empresa"
                                id={lines.id}
                                col="nome"
                                label={'Nome da empresa' }
                                fullWidth={true}
                                defaultValue={ lines.nome }
                                
                              />

                                }</div>
                                </Grid>
                                <Grid item xs={2}>

                                <div className="pd5">
                                <TextInput 
                                autocomplete={false}
                                sheet="users"
                                id={lines.user_id}
                                col="nome"
                                label={'User master'}
                                fullWidth={true}
                                defaultValue={ lines.user_nome }
                                
                              />
                                    </div>
                                </Grid>

                                <Grid item xs={2}>

                                <div className="pd5">
                                <TextInput 
                                autocomplete={false}
                                sheet="users"
                                id={lines.user_id}
                                col="email"
                                label={'Email master'}
                                fullWidth={true}
                                defaultValue={ lines.user_email }
                                
                              />
                                    </div>
                                </Grid>

                                <Grid item xs={2}>

                                <div className="pd5">
                                <TextInput 
                                autocomplete={false}
                                sheet="users"
                                id={lines.user_id}
                                col="senha"
                                label={'Senha master'}
                                fullWidth={true}
                                defaultValue={ lines.senha }
                                
                              />
                                    </div>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1}>
                                    
                                <FormGroup collumn className="mt15">

                                        <FormControlLabel
                                        control={<Switch checked={lines.active === '0' ? true : false} onChange={(e) => this.ativaEmpresa(e,lines.id)} name="Ativa" id='0' />}
                                        label="Ativa"
                                        />

                                        </FormGroup>

                                        
                                </Grid>

                                
                                
                             </Grid>
                    )):''}


            <Dialog
                    open={this.state.openNovaEmpresa}
                    onClose={()=>this.fechaJanela()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={'md'}
                    >
                    <div className="pd30">
                    <DialogContent>
                    
                    <div className="mini-titulo mb20">Criar nova empresa</div>
                    
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <TextField autoFocus={true} variant="outlined" onBlur={(e)=>this.setEmpresa(e,0)} fullWidth={true} placeholder={'Nome da empresa'}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField variant="outlined" fullWidth={true} onBlur={(e)=>this.setEmpresa(e,1)} placeholder={'E-mail admin'}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField variant="outlined" fullWidth={true} onBlur={(e)=>this.setEmpresa(e,2)} placeholder={'Senha admin'}/>
                        </Grid>
                        
                          
                       
                    </Grid>

                    <div className="bt bgcolor_1 dialog w10 mt20" disableElevation onClick={()=>this.criaNovaEmpresa()} color="secondary">
                        Criar
                    </div>
                    <div className="bt bgcolor_5 w10 mt20" disableElevation onClick={()=>this.fechaJanela()} color="primary" autoFocus>
                        Cancelar
                    </div>
                    
                    </DialogContent>

                    
                    </div>
            </Dialog>


          </div>
   
          
          </div>

    </ThemeProvider>
  )


}
}