import React from 'react'
import Grid from '@material-ui/core/Grid';

export default function Title(props) {
    return (
        <div>
          <div className="header">
             <Grid container spacing={1}>
        
        <Grid item xs={9}>
            <div className="titulo">{props.titulo}</div>
            </Grid>
        </Grid>
        </div>
        </div>
    )
}
