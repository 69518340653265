import React, { Component } from "react";
import {Grid,ThemeProvider,createTheme,Tooltip,Zoom,Menu, MenuItem, ListItemIcon, ListItemText} from '@material-ui/core';
import api from '../../services/api';
import {Helmet} from 'react-helmet';
import Meta from "../../components/meta";
import ProgressBar from '../../components/progress';
import { withStyles } from "@material-ui/styles";
import { Add, Close } from "@material-ui/icons";
import {deepPurple,teal} from '@material-ui/core/colors';
import CurrencyFormat from "react-currency-format";
import { Redirect } from "react-router";

const theme = createTheme (
{
  palette: {
    type: "dark",
    primary: deepPurple,
    secondary: teal,
  
},
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
    
  }
}
)
let vn=0;

const StyledMenuItem = withStyles((theme) => ({
  root: {
      '&:focus': {
      backgroundColor: deepPurple,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: 'white',
      },
      },
  },
  }))(MenuItem);



export default class Office extends Component {


  state = {
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    id:this.props.match.params.id,
    versoes:'',
    salvo:true,
    salvarCarta:{'id':this.props.match.params.id},
    aproNomes:['Pré-Produção','Produção','Cenografia','Locação/Estúdio','Equipe Técnica','Equipamento','Alimentação/Transporte','Elenco','Passagem e Hospedagem','Pós Produção','Custos COVID','Outros'],
  

  }


  async componentDidMount(){
    
    this.setPage();
    this.budgetInfo(this.state.id);
    
  }



  // SALVA PAGINA

  setPage = async () => {

    
    const thisPage = '/budgets';
    localStorage.setItem("page",thisPage);
    api.get('update/setPage.php?id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&page='+thisPage+'&token='+this.state.token).then(result => {
        
      if (result.status === 200) {
       
        if(result.data==='LOGOUT'){
          window.location.href='/wrong';
        }
        
      }})
  }



  // LOADING

  toLoad = () => {
    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  

  // PEGA INFORMAÇÕES DO BUDGET

  budgetInfo = async (id) => {

    this.toLoad()
      
      api.get('get/office.php?id='+id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        
        if(result.data.office[0]){
          
          this.setState({

            projeto_id:result.data.budget[0].projeto_id,
            versao:result.data.budget[0].nome,
            projeto:result.data.projeto[0].nome,
            cliente:result.data.projeto[0].cliente,
            empresa:result.data.empresa[0].nome,
            office:result.data.office[0],
            versoes:result.data.office[0].versoes,
            titulos:result.data.office[0].titulos.split('`'),
            atendimento:result.data.atendimento[0].nome +' - '+result.data.atendimento[0].email,
            diretor:result.data.diretor[0].nome,
            legal:result.data.legal,
            showweather:result.data.office[0].showweather/1,
            legalHide:result.data.office[0].ingles,
            officeLoad:true
          })
        }else{
          
          this.setState({
            office:[{'valores':0,'versoes':''}],
            projeto_id:result.data.budget[0].projeto_id,
            versao:result.data.budget[0].nome,
            projeto:result.data.projeto[0].nome,
            cliente:result.data.projeto[0].cliente,
            empresa:result.data.empresa[0].nome,
            atendimento:result.data.atendimento[0].nome +' - '+result.data.atendimento[0].email,
            diretor:result.data.diretor[0].nome,
            legalHide:result.data.office[0].ingles,
            showweather:0
            //officeLoad:true
          })
          
        }

          setTimeout(() => {
           
            
          if(!this.state.office['valores']){
            // BUSCA VALORES ORIGINAIS
           
            this.valoresBudget();
          }else{
            
            this.setState({
              valores:this.state.office['valores'],
              weather: this.state.office['weather'],
              adiantamento1: this.state.office['adiantamento1'],
              adiantamento2: this.state.office['adiantamento2'],
              cancelamento: this.state.office['cancelamento']
            })
            this.endLoad();
          }

          



          }, 10);
          

      }})
       

  }







  valoresBudget = () => {
   
    this.setState({
      officeLoad:false
    })
   api.get('/get/get.php?id='+this.state.id+'&sheet=budget&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
       
        let salvarCarta = this.state.salvarCarta;

        salvarCarta['valores'] = 'R$' + result.data[0]['valor'];
        salvarCarta['weather'] = 'R$' + Math.floor((result.data[0]['valor']*0.4/50))*50;
        salvarCarta['adiantamento1'] = 'R$' + Math.floor((result.data[0]['valor']*0.3*1.153/50))*50;
        salvarCarta['adiantamento2'] = 'R$' + Math.floor((result.data[0]['valor']*0.4*1.153/50))*50;
        salvarCarta['cancelamento'] = 'R$' + Math.floor((result.data[0]['valor']*0.5/50))*50;
       
        
        this.setState({
          valores: <CurrencyFormat displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} value={result.data[0]['valor']}/>,
          weather: <CurrencyFormat displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} value={Math.floor((result.data[0]['valor']*0.4/50))*50}/>,
          adiantamento1: <CurrencyFormat displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} value={Math.floor((result.data[0]['valor']*0.3*1.153/50))*50}/>,
          adiantamento2: <CurrencyFormat displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} value={Math.floor((result.data[0]['valor']*0.4*1.153/50))*50}/>,
          cancelamento: <CurrencyFormat displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} value={Math.floor((result.data[0]['valor']*0.5/50))*50}/>,
          salvarCarta:salvarCarta,
          salvo:false,
          anchorEl: null
        })


        setTimeout(() => {
          this.setState({
            officeLoad:true,
          })
        }, 10);
        
      
        this.endLoad();

      }})

  }

  // UPDATE TEXT
  updateText = (e,w) => {

    
    let text = e.target.innerHTML.replace(/(?:\r\n|\r|\n)/g, '<br/>');;

    text = text.replace('<span>','');
    text = text.replace('</span>','');
    for(let i=0;i<200;i++){
      text = text.split(i+'"').join(i+'s');
    }

    for(let i=0;i<100;i++){
      text = text.split(i+"'").join(i+'m');
    }


    text = text.split("'").join('"');

    let salvarCarta = this.state.salvarCarta;
    salvarCarta[w] = text;

    this.setState({
      salvo:false
    })
    
      
  }

  // SALVAR CARTA

  save = () => {

    api.post('update/updateOffice.php?&r='+Math.random(10000),JSON.stringify({ 
      
      sheet:'office',
      dados: [this.state.salvarCarta]
    
    })).then(result => {

     
          
      if (result.status === 200) {
        
        this.setState({

          salvo:true,
        
        })

      }})


  }

 // ABRE MENU PRESET

 abreMenu = (event) => {


  this.setState({
      anchorEl: event.currentTarget
  })
  
  
};

// FECHA MENU PRESET

fechaMenu = (id) => {
  
  this.setState({
      anchorEl: null
  })
  };



// ADD VERSAO

novaVersao = (e) =>{
  
  let novaVersao = this.state.versoes+"<spam class='mt20 micro-titulo'>Versão "+vn+"</spam><br><spam class='mt5 valor-menor'>R$ "+vn+"</spam><br><br>";
  vn++;

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+novaVersao+'&id='+this.state.office.id+'&sheet=office&col=versoes&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      this.setState({
        versoes: novaVersao,
        anchorEl: null
      })
    
    }})


}


// UPDATE VERSAO
  
updateVersao = (e) =>{
  
  let text = e.target.innerHTML;
  for(let i=0;i<300;i+=5){
    text = text.split(i+'"').join(i+'s');
  }
  let salvaVersao = text.split("'").join('"');
  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+salvaVersao+'&id='+this.state.office.id+'&sheet=office&col=versoes&r='+Math.random(10000))

}

// UPDATE WEATHER

upWeather = () =>{
  let w =this.state.showweather;

    if(w/1===0){
      w=1;
    }else{
      w=0;
    }

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+w+'&id='+this.state.office.id+'&sheet=office&col=showweather&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {
      this.setState({
        showweather:w
      })
      this.fechaMenu();
    }})
}


// TITULOS INGLES

titulosIngles = () => {

  let titulos = 'Director`Sales`Deliverables`Media`Licensing period`Exclusivity`Estimate`Weather day`Postponement before PPM`Postponement after PPM`Canceling`Included`Not Included`Observations`Valid untill `Payment issue`Payment condition';

  titulos = titulos.split('`');

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+titulos+'&id='+this.state.office.id+'&sheet=office&col=titulos&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      this.setState({
        titulos: titulos,
        anchorEl: null,
        legalHide:true
      })
    
    }})

    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value=1&id='+this.state.office.id+'&sheet=office&col=ingles&r='+Math.random(10000))


}


render() {
    return (
  
      
      <ThemeProvider theme={theme}>
            {this.state.permission > 2 ? <Redirect to='/login'/> : ''}
<Meta title={"Office | "+this.state.id + ' - ' +this.state.cliente + ' ' + this.state.projeto} />
          <div className="conteudo print">
             
          <Helmet>
                      
                    
                      <style>
                        
                         {`
                              body {
                                  background: white;
                                  margin:0px;
                                  padding:0px;
                                  line-height:20px;
                                  font-size:11px;
                              }
  
  .dia-semana{
      width: 13vw;
      padding: 5px;
      font-size: 10px;
  }
  
  .box-calendar{
      width: 13vw;
      border: 1px solid rgb(165, 165, 165);
  }
  
  .box-calendar-off{
      width: 13vw;
      border: 1px solid rgb(172, 172, 172);
  }
  
  .box-calendar-high{
      width: 13vw;
      border: 1px solid #f0f0f0;
  }
  
  .box-calendar:hover{
      border: 1px solid #888;
  }
  .calendar-mes{
      display: flex;
      justify-content: space-between;
      
  }
  
  .box-calendar{
      background-image: -webkit-linear-gradient(top, #acacac, #acacac 18%, transparent 18%, transparent 100%);
  }
  
  .box-calendar.cor0{
      background-image: -webkit-linear-gradient(top, #8f8f8f, #8f8f8f 18%, transparent 18%, transparent 100%);
      border: 1px solid #5f5f5f;
  }
  
  .box-calendar.cor1{
      background-image: -webkit-linear-gradient(top, #A456FF, #A456FF 18%, transparent 18%, transparent 100%);
      border: 1px solid #5f5f5f;
      color: white;
  }
  
  
  .txt-dia{
      font-size: 10px;
      width: 100%;
      height: 40px;
      padding: 5px 5px;
      color: #f0f0f0;
  }
  
  .txt-dia.c2{
      
      color: #444;
  }
  
  .menu{
      display: none !important;
  }
  
  .footer{
      display: none !important;
  }
  
  body{
      background: white !important;
      color: #333 !important;
  }
  
  .print{
      color:#333
      
  }
  .sub-titulo{
    line-height: 30px;
  }
  .titulo{
      font-size: 50px;
      line-height: 50px;
  }
  

  .valor{
    font-size:30px;
    font-weight:800;
  }

  .valor-menor{
    font-size:20px;
    font-weight:800;
  }

  .conteudo.print{
    padding:0px;
    background:gray;
  }

  .bgcolor_10{
    background:black;
  }

  .box-extras{
    border-bottom: 1px solid #333;
    padding-top:5px;
    padding-bottom:5px;
  }

  .box-extras-assina{
    border-top: 1px solid #333;
    padding-top:5px;
    padding-bottom:5px;
  }

  .box-extras-assina-magma{

    border-top: 1px solid #333;
    padding:5px
  }

  .legal{
    font-size:8px;
    line-height: 13px;
  }

  .space-legal{
    margin-top:300px;
  }

  .page{
    background:url(https://treatments.run/landscape/img/magma_office.png);
    background-size:cover;
    width:793px;
    height: 1120px;
    padding-top:180px;
    margin:auto;
}

.page2{
    background:url(https://treatments.run/landscape/img/magma_office.png);
    background-size:cover;
    width:793px;
    height: 1120px;
    padding-top:70px;
    margin:auto;
}
  

  
                         `}
                         
                      </style>
                  </Helmet>

                  {this.state.loading ? <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/> : ''}
                  <div>
                  {this.state.salvo ? '' : <div onClick={()=>this.save()} className="bt bgcolor_1 bt-save-office">Salvar</div>}
                  <div className="page">
                  

                    <Grid container className="pd50">

                      
                      
                       
                      <Grid xs={5} className="mt40">
                        
                      </Grid>

                      <Grid xs={7} className="mt40">
                      <Grid container>

                      <Grid xs={5} className="">{this.state.cliente}</Grid>
                      <Grid xs={7} className="">{'Orc'+this.state.id}</Grid>

                      <Grid xs={5} className="mt10 up"><div className="" onBlur={(e)=>this.updateTitulo(e,0)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[0] : <div>{this.state.legal/1===0 ? 'DIRETOR' : 'Criativo'}</div>}</div></Grid>
                      <Grid xs={7} className="mt10">{this.state.officeLoad ? <div className="" contentEditable={true} onBlur={(e)=>this.updateText(e,'diretor')} dangerouslySetInnerHTML={{__html:this.state.office['diretor']}}></div> :''}
                        </Grid>
                      
                      <Grid xs={5} className="mt10 up"><div className="" onBlur={(e)=>this.updateTitulo(e,1)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[1] : ''}</div></Grid>
                      <Grid xs={7} className="mt10">{this.state.officeLoad ? <div className="" contentEditable={true} onBlur={(e)=>this.updateText(e,'atendimento')} dangerouslySetInnerHTML={{__html:this.state.office['atendimento']}}></div> :''}</Grid>

                      <div className="wp100 bloco_magma_entregas">
                      <Grid xs={6} className="mt10 up"><div className="" onBlur={(e)=>this.updateTitulo(e,2)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[2] : ''}</div></Grid>
                      <Grid xs={12} className="mt10">{this.state.officeLoad ? <div className="" contentEditable={true} onBlur={(e)=>this.updateText(e,'entregas')} dangerouslySetInnerHTML={{__html:this.state.office['entregas']}}></div> :''}</Grid>
                      </div>
                      
                      <div className="wp100 bloco_magma"></div>
                        <Grid xs={5} className="mt10 up"><div className="" onBlur={(e)=>this.updateTitulo(e,3)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[3] : ''}</div></Grid>
                        <Grid xs={7} className="mt10">{this.state.officeLoad ? <div className="" contentEditable={true} onBlur={(e)=>this.updateText(e,'midias')} dangerouslySetInnerHTML={{__html:this.state.office['midias']}}></div> :''}</Grid>
                      
                        <div className="wp100 bloco_magma"></div>
                      <Grid xs={5} className="mt10  up"><div className="" onBlur={(e)=>this.updateTitulo(e,4)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[4] : ''}</div></Grid>
                      <Grid xs={7} className="mt10">{this.state.officeLoad ? <div className="" contentEditable={true} onBlur={(e)=>this.updateText(e,'periodo')} dangerouslySetInnerHTML={{__html:this.state.office['periodo']}}></div> :''}</Grid>

                      <div className="wp100 bloco_magma"></div>
                      <Grid xs={5} className="mt10 up"><div className="" onBlur={(e)=>this.updateTitulo(e,5)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[5] : ''}</div></Grid>
                      <Grid xs={7} className="mt10">{this.state.officeLoad ? <div className="" contentEditable={true} onBlur={(e)=>this.updateText(e,'exclusividade')} dangerouslySetInnerHTML={{__html:this.state.office['exclusividade']}}></div> :''}</Grid>

                      <div className="wp100 bloco_magma"></div>
                      <Grid xs={5} className="mt10 up"><Tooltip title="Dois click para abrir o menu" placement="top" arrow TransitionComponent={Zoom}><div className="b magma-inv" onBlur={(e)=>this.updateTitulo(e,6)} onDoubleClick={(e)=>this.abreMenu(e)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[6] : ''}</div></Tooltip></Grid>
                      <Grid xs={7} className="mt10 mb30"><div className="b magma-inv" contentEditable={true} onBlur={(e)=>this.updateText(e,'valores')}>{this.state.valores}</div></Grid>

                      
                      

                      </Grid>
                      
                      
                      

                      
                        
                      {this.state.legal/1===0 ?
                        <div>
                          {this.state.showweather/1===0 ? 
                          <div>
                        <div className="wp100 bloco_magma2"></div>
                          <Grid container>
                            <Grid xs={5} onBlur={(e)=>this.updateTitulo(e,7)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[7] : ''}</Grid>
                            <Grid xs={7} className="" contentEditable={true} onBlur={(e)=>this.updateText(e,'weather')}>{this.state.officeLoad ? this.state.weather : ''}</Grid>
                          </Grid>
                        </div>
                        : ''}

                          <div className="wp100 bloco_magma2"></div>
                          <Grid container>
                            <Grid xs={5} onBlur={(e)=>this.updateTitulo(e,8)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[8] : ''}</Grid>
                            <Grid xs={7} className="" contentEditable={true} onBlur={(e)=>this.updateText(e,'adiantamento1')}>{this.state.officeLoad ? this.state.adiantamento1 : ''}</Grid>
                          </Grid>
                        

                          <div className="wp100 bloco_magma2"></div>
                          <Grid container>
                            <Grid xs={5} onBlur={(e)=>this.updateTitulo(e,9)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[9] : ''}</Grid>
                            <Grid xs={7} className="" contentEditable={true} onBlur={(e)=>this.updateText(e,'adiantamento2')}>{this.state.officeLoad ? this.state.adiantamento2 : ''}</Grid>
                          </Grid>
                       

                          <div className="wp100 bloco_magma2"></div>
                          <Grid container>
                            <Grid xs={5} onBlur={(e)=>this.updateTitulo(e,10)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[10] : ''}</Grid>
                            <Grid xs={7} className="" contentEditable={true} onBlur={(e)=>this.updateText(e,'cancelamento')}>{this.state.officeLoad ? this.state.cancelamento : ''}</Grid>
                          </Grid>
                        

                        </div>

                        :''}
                      </Grid>
                  </Grid>

                  
                  </div>
                 
                  <div className="page2">
                      <Grid container className="incluso-magma">
                            <Grid xs={12}>
                              <div className="b mb20 up" onBlur={(e)=>this.updateTitulo(e,13)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[13] : ''}</div>
                              {this.state.officeLoad ? <div className="wp100 incluso" contentEditable={true} onBlur={(e)=>this.updateText(e,'observacoes')} dangerouslySetInnerHTML={{__html:this.state.office['observacoes']}}></div> :''}
                            </Grid>
                            
                      </Grid>

                  </div>

                    
                  <div className="page2">
                      <Grid container className="incluso-magma">
                        <Grid xs={6}>
                        <div className="wp95 bloco_magma2"></div>
                          <div className="mb20 b up" onBlur={(e)=>this.updateTitulo(e,11)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[11] : ''}</div>
                          {this.state.officeLoad ? <div className="wp100 incluso" contentEditable={true} onBlur={(e)=>this.updateText(e,'incluso')} dangerouslySetInnerHTML={{__html:this.state.office['incluso']}}></div> :''}
                          
                          
                        </Grid>
                        
                        <Grid xs={6}>
                        <div className="wp95 bloco_magma2"></div>
                          <div className="mb20 b up" onBlur={(e)=>this.updateTitulo(e,12)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[12] : ''}</div>
                          {this.state.officeLoad ? <div className="wp100 incluso" contentEditable={true} onBlur={(e)=>this.updateText(e,'nao_incluso')} dangerouslySetInnerHTML={{__html:this.state.office['nao_incluso']}}></div> :''}
                          
                        </Grid>

                    </Grid>
                  </div>



                  <div className="page2">

                  
                  <Grid container className="incluso-magma">

                        <Grid xs={4}>
                        <div className="mt30 b up" onBlur={(e)=>this.updateTitulo(e,14)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[14] : ''}</div>
                        <div className="mt5" contentEditable={true} onBlur={(e)=>this.updateText(e,'validade')}>{this.state.officeLoad ? this.state.office['validade'] : ''}</div>
                        </Grid>
                        <Grid xs={4}>
                        <div className="mt30 b up" onBlur={(e)=>this.updateTitulo(e,15)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[15] : ''}</div>
                        <div className="mt5" contentEditable={true} onBlur={(e)=>this.updateText(e,'faturamento')}>{this.state.officeLoad ? this.state.office['faturamento'] : ''}</div>
                        </Grid>
                        <Grid xs={4}>
                        <div className="mt30 b up" onBlur={(e)=>this.updateTitulo(e,16)} contentEditable={true}>{this.state.officeLoad ? this.state.titulos[16] : ''}</div>
                        <div className="mt5" contentEditable={true} onBlur={(e)=>this.updateText(e,'pagamento')}>{this.state.officeLoad ? this.state.office['pagamento'] : ''}</div>
                        </Grid>
                        <div className="mt100"></div>
                        <Grid xs={6}>
                          <div className="box-extras-assina-magma mt100 tc b" contentEditable={true}>{this.state.empresa}</div>
                          
                        
                          
                        </Grid>
                        <Grid xs={6}>
                          <div className="box-extras-assina-magma mt100 tc b up">Cliente</div>
                          
                        </Grid>
                        
                        
                        <Grid xs={12}>
                        {this.state.legal/1===0 ? 
                        <div>
                          {this.state.legalHide/1===1 ? '' : 
                          <div>
                        <div className="mt30 space-legal mb20">Legal</div>
                        <Grid container spacing={3} className="legal pd10">
                          
                        <Grid xs className="pd5">
                          <spam className="b">01. Início da Produção</spam> - Só daremos início na produção após recebermos a aprovação do presente orçamento assinado pelo representante legal do cliente e/ou da agência, ou ainda mediante pedido de produção formalizado via email. Se após o pedido de produção tivermos alguma mudança de escopo ou roteiro, essa mudança será analisada e, se preciso, orçada à parte e o cronograma revisado.
                          <br/><br/>
                          <spam className="b">02. Cancelamento</spam> - Em caso de cancelamento da produção após seu início e até 1 dia antes da captação, a agência ou cliente serão responsáveis pelo pagamento de 50% do custo da produção. Em caso de cancelamento a partir do dia da filmagem, o valor será orçado pela produtora, de acordo com os gastos realizados até o momento, sendo o custo de cancelamento de no mínimo de 50% do valor total do projeto.
                          <br/><br/>
                          <spam className="b">03 . Faturamento</spam> - O faturamento do projeto deve se dar no máximo até a data de reunião de pré-produção com o cliente. Não será possível a realização da filmagem sem o faturamento do projeto. O prazo de vencimento será considerado a partir da aprovação do projeto, devendo ser descontado do prazo de pagamento o período utilizado para a realização do faturamento. A produtora se reserva o direito de bloquear a produção e suas devidas entregas, em qualquer fase do projeto, em caso de falta de faturamento e/ou pagamento.  
                          <br/>
                          </Grid>
                         
                          
                          <Grid xs className="pd5">
                          <spam className="b">04. Condecine</spam> - A taxa de Condecine será calculada de acordo com a tabela da Ancine, o tipo da peça e as mídias em que será veiculada, acrescida dos impostos da produtora. A produtora não se responsabiliza pelas taxas adicionais e/ou penalidades que possam ocorrer, caso haja alguma veiculação de uma peça ou mídia não prevista, e reserva-se o direito de cobrar do cliente anunciante e/ou agência os valores adicionais referidos. O custo de cópias de veiculação e taxa Condecine não estão inclusos no valor orçado, exceto se estiverem expressamente descritos. Caso esses custos não inclusos se apliquem ou sejam demandados, serão cobrados à parte.
                          <br/><br/>
                          <spam className="b">05. Prazo de produção</spam> - O prazo para realização da produção e entrega da obra, caso não seja especificamente determinado no presente orçamento, será estabelecido de comum acordo entre as partes, devendo estar previamente definido até a reunião de pré-produção através de um cronograma que cada parte integrante deste orçamento, bem como suas eventuais versões posteriores e mais atualizadas. 
                          <br/><br/>
                          <spam className="b">06. Weather day / contingency day</spam> - O valor definido para a diária adicional de filmagem ou gravação por motivo de “weather day" ou "contingency day”, quando necessário, será faturado juntamente com a segunda parcela, se houver. Ou à parte caso a condição de pagamento preveja parcela única, faturada anteriormente a referida diária adicional. 
                          <br/>
                          </Grid>
                          </Grid>
                        
                        
                        </div> 
                        }
                        </div> 
                        
                        :
                        
                        <div>
                          {this.state.legalHide/1===1 ? '' : 
                          <div>
                        <div className="mt30 micro-titulo space-legal">Legal</div>
                        <div className="mt5 legal">
                          
                        
                            
                          <spam className="b">01. Início do projeto</spam> - Só daremos início do projeto após recebermos a aprovação do presente orçamento assinado pelo representante legal do cliente e/ou da agência, ou ainda mediante pedido de produção formalizado via email. Se após o pedido de produção tivermos alguma mudança de escopo ou roteiro, essa mudança será analisada e, se preciso, orçada à parte e o cronograma revisado.
                          <br/>
                          <spam className="b">02. Cancelamento</spam> - Em caso de cancelamento da produção após seu início e até 1 dia antes da captação, a agência ou cliente serão responsáveis pelo pagamento de 50% do custo da produção. Em caso de cancelamento a partir do dia da filmagem, o valor será orçado pela produtora, de acordo com os gastos realizados até o momento, sendo o custo de cancelamento de no mínimo de 50% do valor total do projeto.
                          <br/>
                          <spam className="b">03 . Faturamento</spam> - O faturamento do projeto deve se dar no máximo até a data de reunião de kick-off com o cliente. O prazo de vencimento será considerado a partir da aprovação do projeto, devendo ser descontado do prazo de pagamento o período utilizado para a realização do faturamento. A empresa se reserva o direito de bloquear o cronograma e suas próximas entregas, em qualquer fase do projeto, em caso de falta de faturamento e/ou pagamento.  
                          <br/>
                          <spam className="b">04. Prazo de produção</spam> - O prazo para realização da produção e entrega da obra, caso não seja especificamente determinado no presente orçamento, será estabelecido de comum acordo entre as partes, devendo estar previamente definido até a reunião de pré-produção através de um cronograma que cada parte integrante deste orçamento, bem como suas eventuais versões posteriores e mais atualizadas. 
                          <br/>
                          
                        
                        </div>
                        
                        </div>
                      }
                        </div>
                        
                        }
                        </Grid>

                    </Grid>

                  </div>


                  </div>
            </div>
  


                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={()=>this.fechaMenu()}
                        >
                                <StyledMenuItem><ListItemText primary="" /></StyledMenuItem>
                                <StyledMenuItem onClick={(w)=>this.titulosIngles()}><ListItemIcon><Add fontSize="small" /></ListItemIcon><ListItemText primary="English version" /></StyledMenuItem>
                                <StyledMenuItem onClick={()=>this.valoresBudget()}><ListItemIcon><Add fontSize="small" /></ListItemIcon><ListItemText primary="Importar valores do budget" /></StyledMenuItem>
                                <StyledMenuItem onClick={(w)=>this.novaVersao()}><ListItemIcon><Add fontSize="small" /></ListItemIcon><ListItemText primary="Adicionar versão" /></StyledMenuItem>
                                <StyledMenuItem onClick={(w)=>this.upWeather()}><ListItemIcon><Add fontSize="small" /></ListItemIcon><ListItemText primary="Show/hide Weather" /></StyledMenuItem>
                                <StyledMenuItem onClick={(w)=>this.fechaMenu()}><ListItemIcon><Close fontSize="small" /></ListItemIcon><ListItemText primary="Fechar" /></StyledMenuItem>
                        </Menu>


      </ThemeProvider>
    )
  }
  
  }
  
  
  
