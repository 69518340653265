import React, { Component } from 'react'

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Meta from '../../components/meta'
import {DialogActions,Grid, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { deepPurple, teal } from '@material-ui/core/colors'
import api from '../../services/api'

const styles = {
    textInput: {
      marginRight: '10px',
      color: '#ffffff',
      // #F3C677
    },
    textInputInput: {
        color: '#ffffff',
      // #F3C677
    },
  };

  
  const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
              fontSize: "11px",
              backgroundColor:'#666'
        }},
        MuiIconButton: {
          root: {
              color:'white',
            '&:hover': {
              color: '#4aedc4',
            }
          }
        }
      }
    }
  )

export default class index extends Component {

    state = {
        rtvs: [],
        showDelete:null,
        template:[[],[],[],[]],
        qt:0,
        t:0,
        fnome:null,
        femail:null,
        modalRTV:null,
        newNome:null,
        newEmail:null

    }

    componentDidMount(){

        this.getRTVs()

    }

    isValidEmail(email) {
        // regular expression to match email format
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        // test the string against the regex pattern and return true if it matches
        return emailRegex.test(email);
      }

    reorderArray(arr, key) {
    // Sort the array based on the value of the key
    arr.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    
    return arr;
    }
    
    getRTVs = () =>{

        
        api.get('get/get.php?sheet=users&id=3&col_id=tipo&empresa_id=no&r='+Math.random(10000)).then(result => {
              
          if (result.status === 200) {
              
                let rtvFull = result.data;
                let rtvs = []
                for(let i=0;i<rtvFull.length;i++){
                    if(this.isValidEmail(rtvFull[i].email) && rtvFull[i].empresa_id!=2 && rtvFull[i].empresa_id!=17){
                        
                        let co = rtvFull[i].email.split('@')[1];
                        co = co.split('.')[0];
                        
                        rtvs.push({'id':rtvFull[i].id,'nome':rtvFull[i].nome,'email':rtvFull[i].email,'co':co,'sent':0})
                    }
                }
                
                this.setState({
                    rtvs:this.reorderArray(rtvs,'co')
                })
         

            }})
      }

      deletaBt(o){
        this.setState({
            showDelete:o
        })
      }
      deletaContato(id,o){
        api.get('delete/deleteActive.php?sheet=users&id='+id+'&r='+Math.random(10000)).then(result => {

            let rtvs = this.state.rtvs;

            rtvs.splice(o,1);

            this.setState({
                rtvs:rtvs
            })


        })
      }

      removeContato(o){

        let rtvs = this.state.rtvs;

            rtvs.splice(o,1);

            this.setState({
                rtvs:rtvs
            })

      }

      setTemplate(e,f,w){

        if(f===0){
            f='subject'
        }
        if(f===1){
            f='msg'
        }
        let template =this.state.template;
        template[w][f] = e.target.value

            this.setState({
                template:template
            })

            
      }

      enviarEmail(){
        
        let rtvs = this.state.rtvs
        let qt = this.state.qt
        let t = this.state.t
        
        let template =  this.state.template[t]
        
        

            
            
            
            
            let subject = this.state.template[t].subject
            let rtvNome = rtvs[qt]['nome'].split(' ')[0];
            let msg = template['msg'].replace('*',rtvNome).replace(/\n/g, "<br>");

                api.get('insert/email_spread.php?fnome='+this.state.fnome+'&femail='+this.state.femail+'&email='+rtvs[qt].email+'&nome='+rtvs[qt].nome+'&msg='+msg+'&subject='+subject+'&r='+Math.random(10000)).then(result => {
                
                if(result.status){
                    console.log(result.data)
                    qt++;
                    
                    if(qt<rtvs.length){

                        rtvs[qt]['sent']=1
                        t++;
                        if(t>3){
                            t=0
                        }
                        this.setState({
                            rtvs:rtvs,
                            qt:qt,
                            t:t
                        })

                        setTimeout(() => {
                        this.enviarEmail()
                        }, 1000);
                    }
                }
            })
            
            

            
            

        
      }

      setNome(e){
        this.setState({
            fnome:e.target.value
        })
      }

      setEmail(e){
        this.setState({
            femail:e.target.value
        })
      }

      abrirModal = () =>{

        this.setState({
            modalRTV:true
        })

      }

      fechaDialog = () => {

        this.setState({
            modalRTV:false
        })

      }


      setNewNome = (e) => {

        this.setState({
            newNome:e.target.value
        })

      }

      setNewEmail = (e) => {

        this.setState({
            newEmail:e.target.value
        })

      }

      novoRTV = () => {

        if(this.state.newNome && this.state.newEmail){
        api.get('insert/newContact.php?nome='+this.state.newNome+'&email='+this.state.newEmail+'&r='+Math.random(10000)).then(result => {
                
            if(result.status){

                this.setState({
                    modalRTV:false
                })
                this.getRTVs();

            }})
        }else{
            alert('Preencha os dois campos!')
        }

      }



  render() {
    return (
        <ThemeProvider theme={theme}>
       
        <Meta title="Spread"/>

        <div className="conteudo">


       

            <div className='titulo mb20'>Spread the news</div>
            
            <div className='w-full mt30'>

            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <div className='mt10'><TextField onBlur={(e)=>this.setNome(e)} variant="outlined" label="Nome de quem envia" fullWidth={true} placeholder={"Nome de quem envia"} /></div>
                </Grid>
                <Grid item xs={3}>
                <div className='mt10'><TextField onBlur={(e)=>this.setEmail(e)} variant="outlined" label="E-mail de quem envia" fullWidth={true} placeholder={"E-mail de quem envia"} /></div>
                </Grid>
            </Grid>
            <div className='mt20'></div>
            <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <div className='sub-titulo'>Template 1</div>
                        <div className='mt10'><TextField onBlur={(e)=>this.setTemplate(e,0,0)} variant="outlined" label="Subject 1" fullWidth={true} placeholder={"Subject 1"} /></div>
                        <div className='mt10'><TextField onBlur={(e)=>this.setTemplate(e,1,0)}  multiline minrows={2} inputProps={{ style: { color: "white" } }} variant="outlined"  label="Mensagem" fullWidth={true} placeholder={"Mensagem - use * para trocar pelo nome."} /></div>
                    
                    </Grid>

                    <Grid item xs={3}>
                        <div className='sub-titulo'>Template 2</div>
                        <div className='mt10'><TextField onBlur={(e)=>this.setTemplate(e,0,1)} variant="outlined" label="Subject 1" fullWidth={true} placeholder={"Subject 1"} /></div>
                        <div className='mt10'><TextField onBlur={(e)=>this.setTemplate(e,1,1)}  multiline minrows={2} inputProps={{ style: { color: "white" } }} variant="outlined"  label="Mensagem" fullWidth={true} placeholder={"Mensagem - use * para trocar pelo nome."} /></div>
                    
                    </Grid>

                    <Grid item xs={3}>
                        <div className='sub-titulo'>Template 3</div>
                        <div className='mt10'><TextField onBlur={(e)=>this.setTemplate(e,0,2)} variant="outlined" label="Subject 1" fullWidth={true} placeholder={"Subject 1"} /></div>
                        <div className='mt10'><TextField onBlur={(e)=>this.setTemplate(e,1,2)}  multiline minrows={2} inputProps={{ style: { color: "white" } }} variant="outlined"  label="Mensagem" fullWidth={true} placeholder={"Mensagem - use * para trocar pelo nome."} /></div>
                    
                    </Grid>

                    <Grid item xs={3}>
                        <div className='sub-titulo'>Template 4</div>
                        <div className='mt10'><TextField onBlur={(e)=>this.setTemplate(e,0,3)} variant="outlined" label="Subject 1" fullWidth={true} placeholder={"Subject 1"} /></div>
                        <div className='mt10'><TextField onBlur={(e)=>this.setTemplate(e,1,3)}  multiline minrows={2} inputProps={{ style: { color: "white" } }} variant="outlined"  label="Mensagem" fullWidth={true} placeholder={"Mensagem - use * para trocar pelo nome."} /></div>
                    
                    </Grid>



                   
            </Grid>
            </div>


            <div className='w-full mt20'>
            <div onClick={()=>this.abrirModal()} className='bt bgcolor_1 w-full mt20'>Novo Contato</div>

            <div className='sub-titulo mb10 mt30'>Contatos</div>
            {this.state.rtvs.map((rtv,obj)=>(

                <div>
                    {obj>0 ? 
                        <div>
                            {this.state.rtvs[obj-1].co!==rtv.co ?  <div className='micro-titulo mb10 mt20 caps'>{rtv.co}</div> : ''}
                        </div>
                    :
                        <div className='micro-titulo mb10 mt20 caps'>{rtv.co}</div>
                    }
                    
                    <div className={rtv.sent===0 ? 'flex w-full' : 'flex w-full color_2'}>
                    <div onClick={()=>this.removeContato(obj)} onDoubleClick={()=>this.deletaBt(rtv.id)} className='x b'>X</div>
                    {this.state.showDelete===rtv.id ? <div onClick={()=>this.deletaContato(rtv.id,obj)} className='br5 cl bgcolor_5 pd10'>Deletar?</div> : ''}
                    <div className='pd10'>{rtv.nome}</div>
                    <div className='pd10'>{rtv.email}</div>

                    </div>
                    
                </div>
            ))}

            <div onClick={()=>this.enviarEmail()} className='bt bgcolor_3 w-full mt20'>Enviar</div>
            </div>

            <Dialog
                open={this.state.modalRTV}
                onClose={()=>this.fechaDialog()}
                fullWidth={true}
                maxWidth={'sm'}
              
              >
                
                <DialogContent>

                <div className="sub-titulo w100">Criar novo contato</div>
                

                <div className='mt10'><TextField onBlur={(e)=>this.setNewNome(e)} variant="outlined" label="Nome" fullWidth={true} placeholder={"Nome"} /></div>
                <div className='mt10'><TextField onBlur={(e)=>this.setNewEmail(e)} variant="outlined" label="Nome" fullWidth={true} placeholder={"E-mail"} /></div>

                </DialogContent>
                
                <DialogActions>
                <div className="bt w20 bgcolor_5 mt30 mb30 ml20" onClick={()=>this.fechaDialog()} color="primary">
                    Cancelar
                  </div>

                  <div className="bt w20 bgcolor_2 mt30 mb30 mr20" onClick={()=>this.novoRTV()} color="primary">
                    Salvar
                  </div>
                  
                </DialogActions>

            </Dialog>
        </div>


        


        </ThemeProvider>
    )
  }
}
