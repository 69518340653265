import React, { Component } from 'react'
import { Helmet } from 'react-helmet';

export default class Meta extends Component {
  

    render(props) {
        return (
           
                <Helmet>
                    <title>{'Landscape | '+this.props.title}</title>
                </Helmet>
         
        )
    }
}
