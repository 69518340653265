import {
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  DialogTitle,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CheckOutlined } from "@material-ui/icons";
import CloudUpload from "@material-ui/icons/CloudUpload";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { Component } from "react";
import CurrencyFormat from "react-currency-format";
import InputMask from "react-input-mask";
import { BarLoader } from "react-spinners";
import Meta from "../../components/meta";
import ProgressBar from "../../components/progress";
import api from "../../services/api";
import "./style.css";
import ConvertAPI from "convertapi";

export const moneyFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

const convertapi = new ConvertAPI(process.env.REACT_APP_CONVERTAPI);

const theme = createTheme({
  typography: {
    fontFamily: "Space Grotesk, sans-serif",
    fontSize: 16,
  },
  palette: {
    type: "light",
    primary: {
      main: "#6468EF",
      light: "#8386F2",
    },
    secondary: {
      main: "#F4D768",
    },
  },
  overrides: {
    MuiButton: {
      label: {
        fontSize: "16px",
      },
    },
  },
});

export default class Viewsheet extends Component {
  state = {
    token: "tokenneutro",
    tokenUnecont: "",
    empresa_id: 1,
    permission: localStorage["permission"],
    id: this.props.match.params.id.replace(/\D/g, "") / 543456,
    nr_nf: 0,
    nf_emissao: 0,
    loading: true,
    loadPerc: 0,
    acessoNegado: 0,
    valorRetencao: 0,
    code: 0,
    login: false,
    total_pix: 0,
    valor_full: 0,
    juros: 0,
    aceita: 0,
    dialog: false,
    dialogUpload: false,
    dialogErro: false,
    dialogErroUne: false,
    opSeguir: true,
    dialogNF: false,
    uploading: false,
    mostraEscolhas: false,
    abreVencimento: false,
    pix_po_dados: [],
    checkCNPJ: 1,
    cnpj_produtora: "07842589000114",
    okCPF: false,
    acessaDireto: false,
    teste: false,
    img: null,
    modalImg: false,
    enviandoNF: false,
    cnpj: "",
    transfeera_client_id: process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SANTA,
    transfeera_secret: process.env.REACT_APP_TRANSFEERA_SECRET_SANTA,
  };

  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading: true,
      intervalLoad: setInterval(this.Loader, 200),
    });
  };

  endLoad = () => {
    this.setState({
      loadPerc: 100,
      loading: false,
      intervalLoad: false,
    });
  };

  Loader = () => {
    if (this.state.loadPerc < 100) {
      this.setState({
        loadPerc: this.state.loadPerc * 1.4,
      });
    }
  };

  // UPDATE ACESSO

  setCNPJAcesso = (e) => {
    this.setState({ cnpj: e.target.value });
  };

  // UPDATE CODE

  setCode = (e) => {
    this.setState({ codeForm: e.target.value });
  };

  // GET PJ

  componentDidMount() {
    if (this.state.id !== 0) {
      this.getPOs();
    } else {
      if (this.state.permission < 2) {
        this.setState({
          acessoNegado: 1,
        });
      }
    }
  }

  // GET POs

  getPOs = () => {
    api
      .get(
        "get/get.php?sheet=users&id=" +
          this.state.id +
          "&col_id=id&empresa_id=no&r=" +
          Math.random(10000)
      )
      .then((result) => {
        if (result.status === 200) {
          if (result.data[0]) {
            this.setState({
              id: result.data[0].id,
              cnpj: result.data[0].cnpj.replace(/\D/g, ""),
              cpf: result.data[0].cpf,
              fone: result.data[0].fone.replace(/\D/g, ""),
              email_pj: result.data[0].email,
              acessoNegado: 1,
            });

            if (this.state.acessaDireto === true) {
              this.acessar();
            }
          } else {
            alert("CNPJ não encontrado!");
          }
        }
      });
  };

  // TOKEN PIX

  tokenPix = (w, dt) => {
    axios({
      method: "post",
      url: "https://login-api.transfeera.com/authorization",
      params: {
        grant_type: "client_credentials",
        client_id: this.state.transfeera_client_id,
        client_secret: this.state.transfeera_secret,
      },
      data: {
        grant_type: "client_credentials",
        client_id: this.state.transfeera_client_id,
        client_secret: this.state.transfeera_secret,
      },
    })
      .then((response) => {
        // handle success

        this.setState({
          token: response.data.access_token,
        });

        setTimeout(() => {
          this.criarLote(w, dt);
        }, 1000);
      })
      .catch(function (error) {
        // handle error
      });
  };

  tokenUnecont = () => {
    api.get("get/unecont.php?action=token").then((result) => {
      if (result.status === 200) {
        this.setState({
          tokenUnecont: result.data.Token,
        });
      }
    });
  };

  // ACESSAR

  acessar = () => {
    this.toLoad();

    if (this.state.id === 0) {
      api
        .get(
          "get/get.php?sheet=users&id=" +
            this.state.cnpj.replace(/\D/g, "") +
            "&col_id=cnpj&empresa_id=no&r=" +
            Math.random(1000)
        )
        .then((result) => {
          if (result.status === 200) {
            this.setState({
              cnpj: this.state.cnpj.replace(/\D/g, ""),
              id: result.data[0]["id"],
              acessaDireto: true,
            });

            localStorage["pix"] = this.state.cnpj.replace(/\D/g, "");

            this.endLoad();
            this.getPOs();
          }
        });

      // ELSE
    } else {
      this.setState({
        code: 3,
      });
      let nr = 0;
      api
        .get(
          "get/acessaPix.php?cnpj=" +
            this.state.cnpj +
            "&r=" +
            Math.random(10000)
        )
        .then((result) => {
          if (result.status === 200) {
            if (result.data.id > 0) {
              nr = result.data.wpnr.replace(/\D/g, "");

              if (nr[0] === "0") {
                nr = nr.substring(1);
              }

              this.setState({
                code: result.data.code,
                wpnr: nr,
                id: result.data.id,
                nome: result.data.nome,
              });
            } else {
              alert("Nenhum pagamento em aberto neste CNPJ.");
            }

            nr = "+55" + nr;

            if (localStorage["pix"] !== this.state.cnpj) {
              this.enviarCodigo(nr, result.data.code);
            } else {
              this.tokenUnecont();
              this.getPix();
            }

            this.endLoad();
          }
        });
    }
  };

  // ENVIAR CODIGO

  enviarCodigo = (nr, code) => {
    axios({
      method: "post",
      url: "https://v1.nocodeapi.com/gustavogripe/twilio/FEltElVkNCZljFRG/sendSMS",
      params: {},
      data: {
        body: "Landscape - Código de acesso " + code,
        to: nr,
        from: "+19894557682",
      },
    });
  };

  // CODE

  acessarCode = () => {
    this.toLoad();
    if (this.state.code / 1 === this.state.codeForm / 1) {
      localStorage["pix"] = this.state.cnpj;

      this.tokenUnecont();
      this.getPix();
    }
  };

  // GET PIX

  getPix = () => {
    api
      .get("get/getPix.php?id=" + this.state.id + "&r=" + Math.random(10000))
      .then((result) => {
        if (result.status === 200) {
          this.setState({
            passado: result.data.passado,
            futuro: result.data.futuro,
            pix: result.data.pix,
            valor_total: result.data.valor_total,
            login: true,
          });

          this.endLoad();
        }
      });
  };

  // CHECK PASSADO

  toCheckPassado = (id) => {
    let passado = this.state.passado;
    passado[id]["check"] = 1 - passado[id]["check"];

    this.setState({
      passado: passado,
    });
    this.calcPix();
  };

  // CHECK FUTURO

  toCheckFuturo = (id) => {
    let futuro = this.state.futuro;
    futuro[id]["check"] = 1 - futuro[id]["check"];

    this.setState({
      futuro: futuro,
    });
    this.calcPix();
  };

  // CALC

  calcPix = () => {
    let futuro = this.state.futuro;
    let passado = this.state.passado;

    let total = 0;
    let juros = 0;
    let descNota = "";

    for (let i = 0; i < futuro.length; i++) {
      if (futuro[i]["check"] === 1) {
        total += futuro[i]["valor_pix"] / 1;
        juros += futuro[i]["valor"] / 1 - futuro[i]["valor_pix"] / 1;
        descNota +=
          "PO " +
          futuro[i]["id"] +
          " - " +
          futuro[i]["projeto_id"] +
          " " +
          futuro[i]["projeto"] +
          " - " +
          futuro[i]["plano_conta"] +
          "\n";
      }
    }

    for (let i = 0; i < passado.length; i++) {
      if (passado[i]["check"] === 1) {
        total += passado[i]["valor_pix"] / 1;
        juros += passado[i]["valor"] / 1 - passado[i]["valor_pix"] / 1;
        descNota +=
          "PO " +
          passado[i]["id"] +
          " - " +
          passado[i]["projeto_id"] +
          " " +
          passado[i]["projeto"] +
          " - " +
          passado[i]["plano_conta"] +
          "\n";
      }
    }

    this.setState({
      total_pix: total,
      juros: juros,
      descNota: descNota,
    });
  };

  // ACEITA

  toCheckAceita = () => {
    this.setState({
      aceita: 1 - this.state.aceita,
    });
  };

  // ABRE DIALOG

  openDialog = () => {
    if (this.state.total_pix > 0) {
      if (this.state.juros > 0) {
        if (this.state.aceita !== 0) {
          this.setState({
            dialog: true,
          });
        } else {
          alert("Você precisa marcar que está de acordo com o desconto.");
        }
      } else {
        this.setState({
          dialog: true,
        });
      }
    } else {
      alert("Você precisa selecionar, pelo menos, um cachê.");
    }
  };

  // ABRE DIALOG UPLOAD

  openDialogUpload = () => {
    this.setState({
      dialog: false,
      dialogUpload: true,
    });
  };

  // FECHA DIALOG

  fechaDialog = () => {
    this.setState({
      dialog: false,
      dialogUpload: false,
      dialogErro: false,
      dialogErroUne: false,
      dialogPix: false,
      dialogNF: false,
      mostraEscolhas: false,
      abreVencimento: false,
      modalImg: false,
      enviandoNF: false,
    });

    this.getPix();
  };

  // FECHA PIX

  fechaPix = () => {
    this.getPix();
    this.setState({
      dialogSuccess: false,
    });
  };

  // MARCA COPY

  marcaCopy = (w) => {
    if (w === 1) {
      this.setState({
        mcopy1: true,
      });
    }

    if (w === 2) {
      this.setState({
        mcopy2: true,
      });
    }

    if (w === 3) {
      this.setState({
        mcopy3: true,
      });
    }
    if (w === 4) {
      this.setState({
        mcopy4: true,
      });
    }
  };

  // CONVERTE PDF

  convertPDF_ = (url) => {
    api
      .get(
        "https://api.pdf.co/v1/pdf/convert/to/jpg/?x-api-key=gustavo@sant.at_3c67204699fbadc60dc92c7cbe884272ae70&url=" +
          url,
        JSON.stringify({})
      )
      .then((res) => {
        if (res.status === 200) {
          this.showImage(res.data.urls[0]);
          this.setState({
            nf: "OK",
          });
        }
      });
  };

  convertPDF = (url) => {
    convertapi
      .convert("jpg", { File: url })
      .then((result) => {
        this.showImage(result.file.url);

        // get converted file url

        // save to file
        //return result.file.save('/path/to/save/file.pdf');
      })

      .catch(function (e) {
        console.error(e.toString());
      });
  };

  // MOSTRAR IMAGEM

  showImage = (imageUrl) => {
    this.setState({
      img: imageUrl,
      modalImg: true,
    });
  };

  // DETECTAR TEXTO

  detectText = async (imageUrl) => {
    this.setState({
      enviandoNF: true,
      modalImg: false,
      nf: "OK",
    });

    const response = await axios.post(
      `https://vision.googleapis.com/v1/images:annotate?key=AIzaSyBQl29sfcNdboOFEgaokgtrM7Ma_jY72qk`,
      {
        requests: [
          {
            image: { source: { imageUri: imageUrl } },
            features: [{ type: "TEXT_DETECTION" }],
            imageContext: { languageHints: ["pt"] },
          },
        ],
      }
    );

    if (response.data.responses[0].textAnnotations) {
      let nfData = response.data.responses[0].textAnnotations;
      let cidade = "";
      let estado = "";
      let cnpj = 0;
      let cnpjSanta = 0;
      let tributacao = '0';
      let valor = 0;

      this.setState({
        enviandoNF: false,
      });

      // CONFERE SE É SP

      for (let i = 0; i < nfData.length; i++) {
        if (
          nfData[i]["description"] === "PREFEITURA" &&
          nfData[i + 1]["description"] === "DO" &&
          nfData[i + 2]["description"] === "MUNICÍPIO" &&
          nfData[i + 3]["description"] === "DE" &&
          nfData[i + 4]["description"] === "SÃO" &&
          nfData[i + 5]["description"] === "PAULO"
        ) {
          cidade = "São Paulo";
          estado = "SP";
        }

        if (
          nfData[i]["description"] === "Código" &&
          nfData[i + 1]["description"] === "do" &&
          nfData[i + 2]["description"] === "Serviço"
        ) {
          const serviceFilter = nfData.filter((item, index) => {
            return index > i && /^\d{5}$/.test(item?.description);
          }) || [];

          if (serviceFilter.length) {
            tributacao = serviceFilter[0].description;
          }
        }
      }

      // CONFERE SE É SP

      for (let i = 0; i < nfData.length; i++) {
        if (
          nfData[i]["description"] === "PREFEITURA" &&
          nfData[i + 1]["description"] === "MUNICIPAL" &&
          nfData[i + 2]["description"] === "DE" &&
          nfData[i + 3]["description"] === "BELÉM"
        ) {
          cidade = "Belém";
          estado = "PA";
        }
      }

      // CONFERE SE É POA
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "PORTO" &&
            nfData[i + 4]["description"] === "ALEGRE"
          ) {
            cidade = "Porto Alegre";

            estado = "RS";
          } else if (
            nfData[i]["description"] === "Prefeitura" &&
            nfData[i + 1]["description"] === "de" &&
            nfData[i + 2]["description"] === "Porto" &&
            nfData[i + 3]["description"] === "Alegre"
          ) {
            cidade = "Porto Alegre";

            estado = "RS";
          }

          if (
            nfData[i]["description"] === "Tributação" &&
            nfData[i + 1]["description"] === "Municipal"
          ) {
            tributacao = nfData[i + 3]["description"];
          }
        }
      }

      // CONFERE SE É SANTO ANDRE
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 3]["description"] === "SANTO" &&
            nfData[i + 4]["description"] === "ANDRÉ"
          ) {
            cidade = "Santo André";

            estado = "SP";
          }

          if (nfData[i]["description"] === "Atividade") {
            tributacao =
              nfData[i + 1]["description"] +
              " / " +
              nfData[i + 3]["description"] +
              " / " +
              nfData[i + 5]["description"];
            break;
          }
        }
      }

      // CONFERE SE É ROLANDIA
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "ROLÂNDIA" ||
            nfData[i]["description"] === "ROLANDIA" ||
            nfData[i]["description"] === "ROLANDIA/PR" ||
            nfData[i]["description"] === "ROLÂNDIA/PR"
          ) {
            cidade = "Rolândia";

            estado = "PR";
          }

          if (
            nfData[i]["description"] === "Código" &&
            nfData[i + 2]["description"] === "Serviço"
          ) {
            tributacao = nfData[i + 3]["description"];
            break;
          }
        }
      }

      // CONFERE SE É VITORIA DA CONQUISTA
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "Vitória" &&
            nfData[i + 1]["description"] === "da" &&
            nfData[i + 2]["description"] === "Conquista"
          ) {
            cidade = "Vitória da Conquista";

            estado = "ES";
          }

          if (
            nfData[i]["description"] === "Série" &&
            nfData[i + 1]["description"] === "SERVIÇO"
          ) {
            tributacao = nfData[i + 2]["description"];
            break;
          }
        }

        /* console.log(nfData)
                 console.log(cidade)
                console.log(estado)
                console.log(tributacao)*/
      }

      // CONFERE SE É CIDRERA
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "MUNICÍPIO" &&
            nfData[i + 1]["description"] === "DE" &&
            nfData[i + 2]["description"] === "CIDREIRA"
          ) {
            cidade = "Cidrera";
            estado = "RS";
          }

          if (
            nfData[i]["description"] === "Descrição" &&
            nfData[i + 1]["description"] === "da" &&
            nfData[i + 2]["description"] === "atividade"
          ) {
            tributacao = nfData[i + 2]["description"];
            break;
          }
        }

        /*console.log(nfData)
                  console.log(cidade)
                  console.log(estado)
                  console.log(tributacao)*/
      }

      // EMBU DAS ARTES

      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "DA" &&
            nfData[i + 2]["description"] === "ESTÂNCIA" &&
            nfData[i + 3]["description"] === "TURÍSTICA" &&
            nfData[i + 4]["description"] === "DE" &&
            nfData[i + 5]["description"] === "EMBU" &&
            nfData[i + 7]["description"] === "ARTES"
          ) {
            cidade = "Embu das Artes";

            estado = "SP";
          }

          if (nfData[i]["description"] === "CNAE") {
            tributacao = nfData[i + 2]["description"];
          }
        }
      }

      // CONFERE SE É CARAPICUIBA
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "CARAPICUIBA"
          ) {
            cidade = "Carapicuiba";

            estado = "SP";
          }

          if (
            nfData[i]["description"] === "Ativ" &&
            nfData[i + 2]["description"] === "Serviço"
          ) {
            tributacao = nfData[i + 4]["description"];
            break;
          }
        }
      }

      // CONFERE SE É BAURU
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "Prefeitura" &&
            nfData[i + 1]["description"] === "Municipal" &&
            nfData[i + 2]["description"] === "de" &&
            nfData[i + 3]["description"] === "Bauru"
          ) {
            cidade = "Bauru";

            estado = "SP";
          }

          if (
            nfData[i]["description"] === "Lista" &&
            nfData[i + 2]["description"] === "Serviço"
          ) {
            tributacao = nfData[i + 4]["description"];
            break;
          }
        }
      }

      // CONFERE SE É DUQUE DE CAXIAS
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "Prefeitura" &&
            nfData[i + 1]["description"] === "Municipal" &&
            nfData[i + 3]["description"] === "Duque" &&
            nfData[i + 5]["description"] === "Caxias"
          ) {
            cidade = "Duque de Caxias";

            estado = "RJ";
          }

          if (nfData[i]["description"] === "Atividade") {
            tributacao =
              nfData[i + 3]["description"] +
              " - " +
              nfData[i + 4]["description"];

            break;
          }
        }
      }

      // CONFERE SE É OSASCO
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "Prefeitura" &&
            nfData[i + 1]["description"] === "do" &&
            nfData[i + 2]["description"] === "Município" &&
            nfData[i + 3]["description"] === "de" &&
            nfData[i + 4]["description"] === "Osasco"
          ) {
            cidade = "Osasco";

            estado = "SP";
          }

          if (nfData[i]["description"] === "ATIVIDADE") {
            tributacao = nfData[i + 2]["description"];
            break;
          }
        }
      }

      // CONFERE SE É ITAPEVI
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "DO" &&
            nfData[i + 2]["description"] === "MUNICIPIO" &&
            nfData[i + 3]["description"] === "DE" &&
            nfData[i + 4]["description"] === "ITAPEVI"
          ) {
            cidade = "Itapevi";

            estado = "SP";
          }

          if (nfData[i]["description"] === "Atividade") {
            tributacao = nfData[i + 2]["description"];
            break;
          }
        }
      }

      // CONFERE SE É MARICA
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "MARICA"
          ) {
            cidade = "Marica";

            estado = "RJ";
          }

          if (
            nfData[i]["description"] === "Código" &&
            nfData[i + 1]["description"] === "Tributação" &&
            nfData[i + 2]["description"] === "Municipio"
          ) {
            tributacao = nfData[i + 4]["description"];

            break;
          }
        }
      }

      // CONFERE SE É LAURO DE FREITAS
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "LAURO" &&
            nfData[i + 4]["description"] === "DE" &&
            nfData[i + 5]["description"] === "FREITAS"
          ) {
            cidade = "Lauro de Freitas";
            estado = "BA";
            break;
          }
        }
      }

      // CONFERE SE É SALVADOR
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DO" &&
            nfData[i + 3]["description"] === "SALVADOR"
          ) {
            cidade = "Salvador";

            estado = "BA";
          }

          if (nfData[i]["description"] === "CNAE") {
            tributacao = nfData[i + 1]["description"];
          }
        }
      }

      // CONFERE SE É BARUERI
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "BARUERI"
          ) {
            cidade = "Barueri";
            estado = "SP";
          }

          if (
            (nfData[i]["description"] === "Codigo" ||
              nfData[i]["description"] === "Código") &&
            nfData[i + 1]["description"] === "Serviço"
          ) {
            tributacao = nfData[i + 2]["description"];
          }
        }
      }

      // CONFERE SE É BELO HORIZONTE
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "Prefeitura" &&
            nfData[i + 1]["description"] === "de" &&
            nfData[i + 2]["description"] === "Belo" &&
            nfData[i + 3]["description"] === "Horizonte"
          ) {
            cidade = "Belo Horizonte";
            estado = "MG";
          }

          if (nfData[i]["description"] === "CTISS") {
            tributacao =
              nfData[i + 2]["description"] + "/" + nfData[i + 4]["description"];
          }
        }
      }

      // CONFERE SE É CURITIBA
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "CURITIBA"
          ) {
            cidade = "Curitiba";
            estado = "PR";
          }

          if (
            nfData[i]["description"] === "Código" &&
            nfData[i + 1]["description"] === "da" &&
            nfData[i + 2]["description"] === "Atividade"
          ) {
            tributacao =
              nfData[i + 3]["description"] + "/" + nfData[i + 5]["description"];
            break;
          }
        }
      }

      // CONFERE SE É CAMPINAS
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "CAMPINAS"
          ) {
            cidade = "Campinas";
            estado = "SP";
          }

          if (
            nfData[i]["description"] === "CNAE" &&
            nfData[i + 1]["description"] === ":"
          ) {
            tributacao =
              nfData[i + 2]["description"] + "/" + nfData[i + 4]["description"];
            break;
          }
        }
      }

      // CONFERE SE É FLORIANOPOLIS
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "FLORIANOPOLIS" &&
            nfData[i + 1]["description"] === "-" &&
            nfData[i + 2]["description"] === "SC"
          ) {
            cidade = "Florianópolis";
            estado = "SC";
          }

          if (
            nfData[i]["description"] === "Cód" &&
            nfData[i + 2]["description"] === "Atividade"
          ) {
            tributacao = nfData[i + 3]["description"];
            break;
          }
        }
      }

      // CONFERE SE É GUARUJÁ
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DO" &&
            (nfData[i + 3]["description"] === "GUARUJÁ" ||
              nfData[i + 3]["description"] === "GUARUJA")
          ) {
            cidade = "Guarujá";
            estado = "SP";
          }

          if (nfData[i]["description"] === "Atividade") {
            tributacao = nfData[i + 1]["description"];
            break;
          }
        }
      }

      // CONFERE SE É GUARULHOS
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "GUARULHOS"
          ) {
            cidade = "Guarulhos";
            estado = "SP";
          }

          if (nfData[i]["description"] === "Atividade") {
            tributacao = nfData[i + 1]["description"];
            break;
          }
        }
      }

      // CONFERE SE É NOVO HAMBURGO
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "Prefeitura" &&
            nfData[i + 1]["description"] === "Municipal" &&
            nfData[i + 2]["description"] === "de" &&
            nfData[i + 3]["description"] === "Novo" &&
            nfData[i + 4]["description"] === "Hamburgo"
          ) {
            cidade = "Novo Hamburgo";
            estado = "RS";
          }

          if (
            nfData[i]["description"] === "Atividade" &&
            nfData[i + 1]["description"] === "do"
          ) {
            tributacao = nfData[i + 10]["description"];
            break;
          }
        }
      }

      // CONFERE SE É ROLANDIA
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "ROLANDIA"
          ) {
            cidade = "Rolandia";

            estado = "PR";
          }

          if (
            nfData[i]["description"] === "Código" &&
            nfData[i + 2]["description"] === "Serviço"
          ) {
            tributacao = nfData[i + 3]["description"];
            console.log(tributacao);
          }
        }
      }

      // CONFERE SE É ITU
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "DA" &&
            nfData[i + 2]["description"] === "ESTÂNCIA" &&
            nfData[i + 3]["description"] === "TURÍSTICA" &&
            nfData[i + 4]["description"] === "DE" &&
            nfData[i + 5]["description"] === "ITU"
          ) {
            cidade = "Itú";
            estado = "SP";
          }

          if (nfData[i]["description"] === "Atividade") {
            tributacao = nfData[i + 1]["description"];
            break;
          }
        }
      }

      if (cidade === "") {
        const cityWords = ['são', 'joão', 'meriti'];

        const hasAll = cityWords.every(city => nfData.some(item => city === (item.description || '').toLowerCase()))

        if (hasAll) {
          cidade = "São João de Meriti";
          estado = 'RJ';
        }

        const service = nfData.find((_, index) => {
          if (index < 4) {
            return false;
          }
          const lessThree = (nfData[index - 3].description || '').toLowerCase();
          const lessTwo = (nfData[index - 2].description || '').toLowerCase();
          const lessOne = (nfData[index - 1].description || '').toLowerCase();

          return lessThree === 'código' && lessTwo === 'do' && lessOne === 'serviço';
        })

        if (service && service.description) {
          tributacao = service.description;
        } 
      }

      // CONFERE SE É RJ
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "DA" &&
            nfData[i + 2]["description"] === "CIDADE" &&
            nfData[i + 3]["description"] === "DO" &&
            nfData[i + 4]["description"] === "RIO" &&
            nfData[i + 5]["description"] === "DE" &&
            nfData[i + 6]["description"] === "JANEIRO"
          ) {
            cidade = "Rio de Janeiro";
            estado = "RJ";
          }

          if (
            nfData[i]["description"] === "Serviço" &&
            nfData[i + 1]["description"] === "Prestado"
          ) {
            tributacao = nfData[i + 2]["description"];
            break;
          }
        }
      }

      // CONFERE SE É NATAL
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "Prefeitura" &&
            nfData[i + 1]["description"] === "Municipal" &&
            nfData[i + 2]["description"] === "do" &&
            nfData[i + 3]["description"] === "Natal"
          ) {
            cidade = "Natal";
            estado = "RN";
          }

          if (
            nfData[i]["description"] === "Serviços" &&
            nfData[i + 2]["description"] === "-"
          ) {
            tributacao = nfData[i + 1]["description"];
            break;
          }
        }
      }

      // CONFERE SE É SAO BERNARDO DO CAMPO
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "MUNICIPIO" &&
            nfData[i + 1]["description"] === "DE" &&
            nfData[i + 2]["description"] === "SAO" &&
            nfData[i + 3]["description"] === "BERNARDO" &&
            nfData[i + 4]["description"] === "DO" &&
            nfData[i + 5]["description"] === "CAMPO"
          ) {
            cidade = "São Bernardo do Campo";
            estado = "SP";
          }

          if (
            nfData[i]["description"] === "Lei" &&
            nfData[i + 1]["description"] === "116"
          ) {
            tributacao = nfData[i + 2]["description"];
            break;
          }
        }
        console.log(cidade);
        console.log(nfData);
        console.log(tributacao);
      }

      // CONFERE SE É SAO CAETANO DO SUL
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "SÃO" &&
            nfData[i + 4]["description"] === "CAETANO" &&
            nfData[i + 5]["description"] === "DO" &&
            nfData[i + 6]["description"] === "SUL"
          ) {
            cidade = "São Caetano do Sul";
            estado = "SP";
          }

          if (nfData[i]["description"] === "Atividade") {
            tributacao = nfData[i + 1]["description"];
            break;
          }
        }
      }

      // CONFERE SE É SAO GONÇALO
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "MUNICIPAL" &&
            nfData[i + 2]["description"] === "DE" &&
            nfData[i + 3]["description"] === "SÃO" &&
            nfData[i + 4]["description"] === "GONÇALO"
          ) {
            cidade = "São Gonçalo";
            estado = "RJ";
          }

          if (nfData[i]["description"] === "Atividade") {
            tributacao = nfData[i + 2]["description"];
            break;
          }
        }
      }

      // CONFERE SE É SAO JOSE DOS CAMPOS
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "PREFEITURA" &&
            nfData[i + 1]["description"] === "DE" &&
            nfData[i + 2]["description"] === "SÃO" &&
            nfData[i + 3]["description"] === "JOSÉ" &&
            nfData[i + 4]["description"] === "DOS" &&
            nfData[i + 5]["description"] === "CAMPOS"
          ) {
            cidade = "São José dos Campos";
            estado = "SP";
          }

          if (nfData[i]["description"] === "CNAE") {
            tributacao = nfData[i + 2]["description"];
            break;
          }
        }
      }

      // CONFERE SE É TABOAO DA SERRA
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "Prefeitura" &&
            nfData[i + 1]["description"] === "Municipal" &&
            nfData[i + 2]["description"] === "de" &&
            nfData[i + 3]["description"] === "Taboão" &&
            nfData[i + 4]["description"] === "da" &&
            nfData[i + 5]["description"] === "Serra"
          ) {
            cidade = "Taboão da Serra";
            estado = "SP";
          }
          if (
            nfData[i]["description"] === "Código" &&
            nfData[i + 1]["description"] === "do" &&
            nfData[i + 2]["description"] === "Serviço:"
          ) {
            tributacao = nfData[i + 3]["description"];
            break;
          }
        }
      }

      // CONFERE SE É ITAPECIRICA
      if (cidade === "") {
        for (let i = 0; i < nfData.length; i++) {
          if (
            nfData[i]["description"] === "Prefeitura" &&
            nfData[i + 1]["description"] === "do" &&
            nfData[i + 2]["description"] === "Município" &&
            nfData[i + 3]["description"] === "de" &&
            nfData[i + 4]["description"] === "Itapecerica" &&
            nfData[i + 5]["description"] === "da" &&
            nfData[i + 6]["description"] === "Serra"
          ) {
            cidade = "Itapecirica da Serra";
            estado = "SP";
          }

          if (
            nfData[i]["description"] === "Código" &&
            nfData[i + 1]["description"] === "do" &&
            nfData[i + 2]["description"] === "Serviço:"
          ) {
            tributacao = nfData[i + 3]["description"];
            break;
          }
        }
      }

      // ###########################################################
      // ###########################################################

      //  FIM DETECTA CIDADES

      // ###########################################################
      // ###########################################################

      let erro = [];

      // BUSCA CNPJ USER

      let cnpj_busca1 = this.state.cnpj.replace(/\D/g, "").substring(0, 8);
      let cnpj_busca2 = this.state.cnpj.replace(/\D/g, "").substring(8, 14);
      let cnpjFind1 = false;
      let cnpjFind2 = false;

      for (let i = 0; i < nfData.length; i++) {
        if (nfData[i]["description"].replace(/\D/g, "") === cnpj_busca1) {
          cnpjFind1 = true;
        }
        if (nfData[i]["description"].replace(/\D/g, "") === cnpj_busca2) {
          cnpjFind2 = true;
        }
      }

      if (cnpjFind1 && cnpjFind2) {
        cnpj = cnpj_busca1 + "" + cnpj_busca2;
      }

      // BUSCA CNPJ SANTA

      let cnpjCo_busca1 = this.state.cnpj_produtora
        .replace(/\D/g, "")
        .substring(0, 8);
      let cnpjCo_busca2 = this.state.cnpj_produtora
        .replace(/\D/g, "")
        .substring(8, 14);
      let cnpjCoFind1 = false;
      let cnpjCoFind2 = false;

      for (let i = 0; i < nfData.length; i++) {
        if (nfData[i]["description"].replace(/\D/g, "") === cnpjCo_busca1) {
          cnpjCoFind1 = true;
        }
        if (nfData[i]["description"].replace(/\D/g, "") === cnpjCo_busca2) {
          cnpjCoFind2 = true;
        }
      }

      if (cnpjCoFind1 && cnpjCoFind2) {
        cnpjSanta = cnpjCo_busca1 + "" + cnpjCo_busca2;
      } else {
        if (cnpjSanta !== this.state.cnpj_produtora) {
          erro.push("- O CNPJ da produtora não está correto.");
          this.setState({
            opSeguir: false,
          });
        }
      }

      // VALOR

      let valor_sistema = this.state.valor_check / 1;

      const moneyFormat = valor_sistema.toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      for (let i = 0; i < nfData.length; i++) {
        if (nfData[i]["description"] === moneyFormat) {
          valor = this.state.valor_check / 1;
        }
      }

      if (valor / 1 !== this.state.valor_check / 1) {
        erro.push("- O valor da nota fiscal não está correto.");
        this.setState({
          opSeguir: false,
        });
      }

      console.log("CNPJ SANTA", cnpjSanta);
      console.log("TRIB", tributacao);

      // ###########################################################
      // ###########################################################
          
      //  BUSCAR NR NOTA FISCAL
      
      // ###########################################################
      // ###########################################################

      let nr_nf = 0;
      
      if(nfData[14]['description']==='e'){
        console.log('NF NR:',nfData[15]['description'])
        nr_nf = nfData[15]['description'];
      }

      if(nr_nf===0){
        for(let i=200;i<260;i++){
          if(nfData[i]['description'].search('00000')>-1){
            console.log('NF NR:',nfData[i]['description'])
            nr_nf = nfData[i]['description'];
          }
        }
      }
      

      // ###########################################################
      // ###########################################################
          
      //  BUSCAR DATA DE EMISSÃO NOTA FISCAL
      
      // ###########################################################
      // ###########################################################


      let nf_emissao = '2020-01-01';

      for(let i=0;i<nfData.length;i++){
        if(nfData[i]['description'].split(':').length===3){
          
          nf_emissao = nfData[i-1]['description'];
          nf_emissao = nf_emissao.split('/')
          nf_emissao = nf_emissao[2]+'-'+nf_emissao[1]+'-'+nf_emissao[0];
        }
      }

      this.setState({
        nf_emissao:nf_emissao,
        nr_nf:nr_nf
      })
      // ###########################################################
      // ###########################################################

      //  ERROS

      // ###########################################################
      // ###########################################################

      if (cidade !== "") {
        // REGRAS

        if (cnpj !== this.state.cnpj.replace(/\D/g, "")) {
          erro.push("- O CNPJ da nota fiscal não bate com o seu");
          this.setState({
            opSeguir: false,
          });
        }

        if (cnpjSanta !== this.state.cnpj_produtora) {
          erro.push("- O CNPJ da produtora não está correto.");
          this.setState({
            opSeguir: false,
          });
        }

        if (valor / 1 !== this.state.valor_check / 1) {
          erro.push("- O valor da nota fiscal não está correto.");
          this.setState({
            opSeguir: false,
          });
        }
      } else {
        // BUSCA CNPJ FORNECEDOR

        let cnpjFor_busca1 = this.state.cnpj.replace(/\D/g, "").substring(0, 8);
        let cnpjFor_busca2 = this.state.cnpj
          .replace(/\D/g, "")
          .substring(8, 14);

        let cnpjForFind1 = false;
        let cnpjForFind2 = false;

        for (let i = 0; i < nfData.length; i++) {
          if (nfData[i]["description"].replace(/\D/g, "") === cnpjFor_busca1) {
            cnpjForFind1 = true;
          }
          if (nfData[i]["description"].replace(/\D/g, "") === cnpjFor_busca2) {
            cnpjForFind2 = true;
          }
        }

        if (cnpjForFind1 && cnpjForFind2) {
          cnpj = cnpjFor_busca1 + "" + cnpjFor_busca2;
        } else {
          erro.push("- O CNPJ da nota fiscal não bate com o seu");
          this.setState({
            opSeguir: false,
          });
        }

        if (
          cnpj === this.state.cnpj.replace(/\D/g, "") &&
          cnpjSanta === this.state.cnpj_produtora &&
          valor / 1 === this.state.valor_check / 1
        ) {
          cidade = "Não identificada";
          estado = "SP";
        } else {
          this.setState({
            opSeguir: true,
          });
        }
      }

      if (erro.length > 0 && this.state.teste === false) {
        api.get(
          "insert/email_erro.php?subject=Erro no sistema PIX&info=" +
            erro.join(" <br> ") +
            "&po_id=" +
            this.state.idUpload +
            "&nome=" +
            this.state.nome +
            "&cnpj=" +
            this.state.cnpj +
            "&nf_link=https://treatments.run/landscape/img/upload/" +
            this.state.foto
        );

        this.setState({
          dialogUpload: false,
          dialogErro: true,
          errors: erro,
          uploading: false,
        });
      } else {
        // ###########################################################
        // ###########################################################

        //  NF OK - AVANÇA

        // ###########################################################
        // ###########################################################

        if (!estado) {
          cidade = "Não identificada";
          estado = "SP";
        }
        setTimeout(() => {
          this.setState({
            nf_cidade: cidade,
            nf_estado: estado,
            nf_valor: valor,
            nf_cnpj: cnpj,
            nf_tributacao: tributacao,
            opSeguir: true,
          });
          this.nfOK();
        }, 20);
      }
    } else {
      // ###########################################################
      // ###########################################################

      //  ERRO UPLOAD IMG

      // ###########################################################
      // ###########################################################

      let erro = [];
      erro.push(
        "A imagem não parece ser uma Nota Fiscal. Se for um PDF, faça um print e tente novamente."
      );

      document.getElementById("file_input").value = null;
      if (this.state.teste === false) {
        api.get(
          "insert/email_erro.php?subject=Erro no sistema PIX&info=" +
            erro.join(" <br> ") +
            "&po_id=" +
            this.state.idUpload +
            "&nome=" +
            this.state.nome +
            "&cnpj=" +
            this.state.cnpj +
            "&nf_link=https://treatments.run/landscape/img/upload/" +
            this.state.foto
        );

        this.setState({
          dialogUpload: false,
          dialogErro: true,
          errors: erro,
          uploading: false,
        });
      }
    }
  };

  // UPLOAD
  fileSelect = (event) => {
    if (!event.target.files?.length) return;

    this.setState({ uploading: true });

    const fd = new FormData();
    fd.append("image", event.target.files[0], event.target.files[0].name);
    api
      .post("upload.php?id=" + this.state.idUpload + "&sheet=cashflow", fd)
      .then((res) => {
        // REGRAS NF

        if (
          res.data.split(".")[1] === "pdf" ||
          res.data.split(".")[1] === "PDF"
        ) {
          this.setState({
            foto: res.data,
          });

          this.convertPDF(
            "https://treatments.run/landscape/img/upload/" + res.data
          );
        } else {
          this.showImage(
            "https://treatments.run/landscape/img/upload/" + res.data
          );

          this.setState({
            nf: res.data,
            foto: res.data,
          });
        }
      });
  };

  // NF OK

  nfOK = () => {
    api
      .get(
        "insert/pix_info.php?po_id=" +
          this.state.idUpload +
          "&cidade=" +
          this.state.nf_cidade +
          "&estado=" +
          this.state.nf_estado +
          "&servico=" +
          this.state.nf_tributacao.split("-")[0] +
          "&r=" +
          Math.random(10000)
      )
      .then((result2) => {
        if (result2.status === 200) {
          this.setState({
            uploading: false,
          });
          this.getPix();
          this.abrePix(this.state.id_linha, this.state.w);
        }
      });
  };
  

  // CONVERTE CNPJ

  converteDados = (w, t) => {
    if (t === 0) {
      w = w.replace(/\D/g, "");
      let neww = "";
      for (let i = 0; i < w.length; i++) {
        neww += w[i];
        if (i === 1) {
          neww += ".";
        }
        if (i === 4) {
          neww += ".";
        }
        if (i === 7) {
          neww += "/";
        }
        if (i === 11) {
          neww += "-";
        }
      }
      return neww;
    }

    if (t === 1) {
      w = w.replace(/\D/g, "");

      let neww = "";
      for (let i = 0; i < w.length; i++) {
        neww += w[i];
        if (i === 2) {
          neww += ".";
        }
        if (i === 5) {
          neww += ".";
        }
        if (i === 8) {
          neww += "-";
        }
      }
      return neww;
    }
  };

  // DEFINE CHAVE

  toCheckChave = (w) => {
    if (w === 0) {
      this.setState({
        checkCNPJ: true,
        checkEmail: false,
        checkMob: false,
        checkCPF: false,
      });
    }

    if (w === 1) {
      this.setState({
        checkCNPJ: false,
        checkEmail: true,
        checkMob: false,
        checkCPF: false,
      });
    }

    if (w === 2) {
      this.setState({
        checkCNPJ: false,
        checkEmail: false,
        checkMob: true,
        checkCPF: false,
      });
    }
    if (w === 3) {
      this.setState({
        checkCNPJ: false,
        checkEmail: false,
        checkMob: false,
        checkCPF: true,
      })
    }
  };

  // EXECUTAR PIX

  executarPix(w, dt) {
    this.toLoad();
    this.tokenPix(w, dt);
  }

  // CRIAR LOTE

  criarLote = (w, dt) => {
    api.get(
      "insert/email_erro.php?subject=Start sistema PIX&info=COMEÇOU PIX&po_id=" +
        this.state.idUpload +
        "&nome=" +
        this.state.nome +
        "&cnpj=" +
        this.state.cnpj +
        "&nf_link=https://treatments.run/landscape/img/upload/" +
        this.state.foto
    );

    let dataPo = this.state.pix_po_dados;

    axios({
      method: "post",
      url: "https://api.transfeera.com/batch",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "User-Agent": "Santa Transmedia (giovanna@sant.at)",
        "Content-Type": "application/json",
      },
      data: {
        name:
          dataPo["id"] +
          " " +
          dataPo["projeto"] +
          " - " +
          dataPo["plano_conta"],
      },
    })
      .then((response) => {
        // handle success

        this.setState({
          id_lote: response.data.id,
        });

        setTimeout(() => {
          this.criarPix(w, dt);
        }, 10);
      })
      .catch(function (error) {
        // handle error
        let errorAlert = [error];

        api.get(
          "insert/email_erro.php?subject=Erro no sistema PIX&info=ERRO CRIAR LOTE -" +
            error +
            "&po_id=" +
            this.state.idUpload +
            "&nome=" +
            this.state.nome +
            "&cnpj=" +
            this.state.cnpj +
            "&nf_link=https://treatments.run/landscape/img/upload/" +
            this.state.foto
        );

        this.setState({
          error: errorAlert,
          dialogErro: true,
        });
      });
  };

  // CRIAR PIX

  criarPix = (w, dt) => {
    let dataPo = this.state.pix_po_dados;

    let chave_pix = this.state.cnpj;
    chave_pix = chave_pix.replace(/[^0-9]/g, "");
    let tipo_chave = "CNPJ";

    if (this.state.checkEmail) {
      chave_pix = this.state.email_pj;
      tipo_chave = "EMAIL";
    }

    if (this.state.checkMob) {
      chave_pix = this.state.fone;
      chave_pix = chave_pix.replace(/[^0-9]/g, "");
      tipo_chave = "TELEFONE";
    }

    if(this.state.checkCPF){
      chave_pix = this.state.cpf;
      chave_pix = chave_pix.replace(/[^0-9]/g, '')
      tipo_chave = 'CPF';
    }

    if (w === 0) {
      dataPo["valor_pix"] = dataPo["valor_pix"] / 1 - this.state.valorRetencao;
    } else {
      dataPo["valor_pix"] = dataPo["valor"] / 1 - this.state.valorRetencao;
    }

    dataPo["valor_pix"] = dataPo["valor_pix"].toFixed(2);

    axios({
      method: "post",
      url:
        "https://api.transfeera.com/batch/" + this.state.id_lote + "/transfer",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "User-Agent": "Santa Transmedia (giovanna@sant.at)",
        "Content-Type": "application/json",
      },
      data: {
        value: dataPo["valor_pix"],
        payment_date: dt,
        idempotency_key: dataPo["id"],
        pix_description:
          dataPo["id"] +
          " " +
          dataPo["projeto"] +
          " - " +
          dataPo["plano_conta"],
        destination_bank_account: {
          pix_key_type: tipo_chave,
          pix_key: chave_pix,
          email: this.state.email_pj,
        },
      },
    })
      .then((response) => {
        // handle success

        this.transferir(w);
      })
      .catch(function (error) {
        // handle error

        api.get(
          "insert/email_erro.php?subject=Erro no sistema PIX&info=ERRO CRIAR PIX -" +
            error +
            "&po_id=" +
            dataPo["id"] +
            "&nome=" +
            this.state.email_pj +
            "&cnpj=" +
            this.state.cnpj +
            "&nf_link=https://treatments.run/landscape/img/upload/" +
            this.state.foto
        );

        let errorAlert = [error];
        this.setState({
          error: errorAlert,
          dialogErro: true,
        });
      });
  };

  transferir = (w) => {
    axios({
      method: "post",
      url: "https://api.transfeera.com/batch/" + this.state.id_lote + "/close",
      headers: {
        Authorization: "Bearer " + this.state.token,
        "User-Agent": "Santa Transmedia (giovanna@sant.at)",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // handle success

        this.salvarPixBD(w);
      })
      .catch(function (error) {
        api.get(
          "insert/email_erro.php?subject=Erro no sistema PIX&info=ERRO EXECUTAR PIX -" +
            error +
            "&po_id=" +
            this.state.idUpload +
            "&nome=" +
            this.state.nome +
            "&cnpj=" +
            this.state.cnpj +
            "&nf_link=https://treatments.run/landscape/img/upload/" +
            this.state.foto
        );

        // handle error
        console.log(error);
      });
  };

  // SALVAR PIX NO BANCO

  salvarPixBD = (w) => {
    let dataPo = this.state.pix_po_dados;

    let chave_pix = this.state.cnpj;

    if (this.state.checkEmail) {
      chave_pix = this.state.email_pj;
    }
    if (this.state.checkMob) {
      chave_pix = this.state.fone;
    }
    api
      .post(
        "insert/newPix.php?r=" + Math.random(10000),
        JSON.stringify({
          dataPo: dataPo,
          pj_id: this.state.id,
          valor_pix: this.state.total_pix,
          juros: this.state.juros,
          chave_pix: chave_pix,
          foto: this.state.foto,
          nr_nf:this.state.nr_nf,
          nf_emissao:this.state.nf_emissao,
          valor_full: this.state.valor_full,
          w: w,
        })
      )
      .then((result) => {
        if (result.status === 200) {
          if (w === 0) {
            this.setState({
              dialogPix: false,
              dialogSuccess: true,
            });
          } else {
            this.setState({
              dialogPix: false,
              dialogNF: false,
              abreVencimento: true,
            });
          }
        }

        this.getPix();
        this.endLoad();
      });
  };

  // ID UPLOAD

  setIdUpload = (id, valor, w, id_linha, data_recebe, cnpj_produtora) => {
    let client_id = process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SANTA;
    let secret = process.env.REACT_APP_TRANSFEERA_SECRET_SANTA;

    if (cnpj_produtora === "17765426000139") {
      client_id = process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SAM;
      secret = process.env.REACT_APP_TRANSFEERA_SECRET_SAM;
    }

    if (cnpj_produtora === "35101686000191") {
      client_id = process.env.REACT_APP_TRANSFEERA_CLIENT_ID_WOLF;
      secret = process.env.REACT_APP_TRANSFEERA_SECRET_WOLF;
    }

    let plano_conta_id = 0;

    if(w===0){
      plano_conta_id = this.state.passado[id_linha].plano_conta_id
    } else{
      plano_conta_id = this.state.futuro[id_linha].plano_conta_id
    }


    this.setState({
      idUpload: id,
      data_recebe: data_recebe,
      valor_check: valor,
      w: w,
      plano_conta_id,
      id_linha: id_linha,
      cnpj_produtora: cnpj_produtora,
      transfeera_client_id: client_id,
      transfeera_secret: secret,
    });

    this.fileInput.click();
  };

  // NOVO PIX DIALOG

  abrePix = (id, w) => {
    //  this.abreDoPix(id,w)

    let linhas = this.state.futuro;

    if (w === 0) {
      linhas = this.state.passado;
    }

    let aceita = false;

    if (linhas[id]["valor"] - linhas[id]["valor_pix"] === 0) {
      aceita = true;
    }

    this.setState({
      pix_po_dados: linhas[id],
      total_pix: linhas[id]["valor_pix"] - this.state.valorRetencao,
      juros: linhas[id]["valor"] - linhas[id]["valor_pix"],
      dialogNF: true,
      aceita: aceita,
      idUpload: linhas[id]["id"],
    });

    api
      .get(
        "get/get.php?sheet=pix_info&id=" +
          linhas[id]["id"] +
          "&col_id=po_id&empresa_id=no&r=" +
          Math.random(1000)
      )
      .then((result) => {
        if (result.status === 200) {
          console.log(1);
          if (result.data.length > 0) {
            let servico = result.data[0].servico.split("-")[0];
            let cidade = result.data[0].cidade;
            let estado = result.data[0].estado;

            console.log("cidade", cidade);
            console.log("estado", estado);
            console.log("info cidade e estado", servico);

            api
              .get(
                "get/get.php?sheet=pix_info&id=" +
                  servico +
                  "&col_id=servico&empresa_id=no&r=" +
                  Math.random(1000)
              )
              .then((result_serv) => {
                if (result_serv.status === 200) {
                  let flagError = 1

                  if(result_serv.data.length>0){
                     flagError = result_serv.data[0].flagError;
                  }

                  if (flagError / 1 > 0) {
                    // VAI PRA UNECONT
                    api
                      .get(
                        "get/unecont.php?action=enviar_doc&token=" +
                          this.state.tokenUnecont +
                          "&po=" +
                          linhas[id]["id"] +
                          "&data=" +
                          linhas[id]["dt_competencia"] +
                          "&cnpj_pj=" +
                          this.state.cnpj +
                          "&cnpj_produtora=" +
                          linhas[id]["cnpj_produtora"] +
                          "&codigo_servico=" +
                          servico +
                          "&valor=" +
                          linhas[id]["valor"] +
                          "&cidade=" +
                          cidade +
                          "&estado=" +
                          estado +
                          "&r=" +
                          Math.random(1000)
                      )
                      .then((result2) => {
                        if (result2.status === 200) {
                          if (
                            result2.data.ListaRetornoDocumento[0].DocumentoId >
                              0 &&
                            result2.data !== "GOLPE"
                          ) {
                            api
                              .get(
                                "update/updatePixInfo.php?doc_id=" +
                                  result2.data.ListaRetornoDocumento[0]
                                    .DocumentoId +
                                  "&po_id=" +
                                  linhas[id]["id"] +
                                  "&r=" +
                                  Math.random(1000)
                              )
                              .then((result3) => {
                                // WAITING FROM UNECONT
                                this.waitUnecont(
                                  id,
                                  w,
                                  result2.data.ListaRetornoDocumento[0]
                                    .DocumentoId
                                );
                              });
                          } else {
                            this.fechaDialog();

                            if (result2.data === "GOLPE") {
                              window.location.reload();
                            }

                            if (
                              result2.data.ListaRetornoDocumento[0]
                                .MensagemRetorno ===
                              "Não foi informado um CNPJ/CPF de Prestador válido"
                            ) {
                              this.setState({
                                opSeguir: false,
                              });
                            }

                            this.setState({
                              errors: [
                                result2.data.ListaRetornoDocumento[0]
                                  .MensagemRetorno,
                              ],
                              dialogErro: true,
                            });
                          }
                        }
                      });
                  } else {
                    // VAI DIRETO SEM UNECONT

                    // CONFERE PO

                    api
                      .get(
                        "get/get.php?sheet=cashflow&id=" +
                          linhas[id]["id"] +
                          "&col_id=id&empresa_id=no&r=" +
                          Math.random(1000)
                      )
                      .then((result_po) => {
                        if (result_po.status === 200) {
                     
                          // CONFERE NOVAMENTE SE JÁ NÃO FOI PAGO
                          if (result_po.data[0].cashflow_status / 1 === 2) {
                            window.location.reload();
                          } else {
                            axios
                              .get(
                                "https://publica.cnpj.ws/cnpj/" +
                                  this.state.cnpj
                              )
                              .then((result_cnpj) => {
                                if (result_cnpj.status === 200) {
                                  let resultCNPJ =
                                    result_cnpj.data.estabelecimento
                                      .situacao_cadastral;
                                  let simples = result_cnpj.data.socios.length;

                                  if (simples > 0) {
                                    this.setState({
                                      okCPF: false,
                                    });
                                  } else {
                                    this.setState({
                                      okCPF: true,
                                    });
                                  }

                                  if (resultCNPJ !== "Ativa") {
                                    alert(
                                      "Seu CNPJ está inválido na Receita Federal."
                                    );
                                    window.location.reload();
                                  } else {
                                    // CONTINUA

                                    this.abreDecide(id, w);
                                  }
                                }
                              })
                              .catch((error) => {
                                alert("Erro na analise.");
                              });
                          }
                        }
                      });
                  }
                }
              });
          } else {
            let errors = [
              "A sua nota fiscal não foi validada. Escreve para financeiro@fore.today.",
            ];
            if (this.state.teste === false) {
              api.get(
                "insert/email_erro.php?subject=Erro no sistema PIX&info=" +
                  errors.join(" <br> ") +
                  "&po_id=" +
                  this.state.idUpload +
                  "&nome=" +
                  this.state.nome +
                  "&cnpj=" +
                  this.state.cnpj +
                  "&nf_link=https://treatments.run/landscape/img/upload/" +
                  this.state.foto
              );

              this.setState({
                dialogErro: true,
                dialogPix: false,
                errors: errors,
              });
            }
          }
        }
      });
  };
  

  // ESPERA RETORNO DA UNECONT

  waitUnecont = (id, w, docid) => {
    api
      .get(
        "get/get.php?sheet=pix_info&id=" +
          docid +
          "&col_id=doc_id&empresa_id=no&r=" +
          Math.random(1000)
      )
      .then((result) => {
        if (result.status === 200) {
          // WAITING FROM UNECONT

          if (!result.data[0].file) {
            setTimeout(() => {
              this.waitUnecont(id, w, docid);
            }, 5000);
          } else {
            api
              .get(
                "get/getXML.php?xml=" +
                  result.data[0].file +
                  "&r=" +
                  Math.random(1000)
              )
              .then((result) => {
                if (result.status === 200) {
                  if (result.data.Erro / 1 === 0) {
                    // SE DER CERTO O CALCULO, CONFERE STATUS DO PRESTADOR

                    if (
                      result.data.ResultadoCalculo.Prestador
                        .ProblemaDadosCadastrais === "0"
                    ) {
                      if (
                        result.data.ResultadoCalculo.PrestadorOptanteSimei ===
                        "1"
                      ) {
                        this.setState({
                          okCPF: true,
                        });
                      }

                      if (
                        result.data.ResultadoCalculo
                          .PrestadorOptanteSimplesNacional === "1"
                      ) {
                        this.setState({
                          okCPF: true,
                        });
                      }
                      // CONFERE RETENCOES

                      let TemRetencaoISS =
                        result.data.ResultadoCalculo.TemRetencaoISS;
                      let TemRetencaoCSRF =
                        result.data.ResultadoCalculo.TemRetencaoCSRF;
                      let TemRetencaoIRRF =
                        result.data.ResultadoCalculo.TemRetencaoIRRF;
                      let TemRetencaoINSS =
                        result.data.ResultadoCalculo.TemRetencaoINSS;

                      let retencao = [];
                      let valorRetencao = 0;

                      if (TemRetencaoISS / 1 === 1) {
                        valorRetencao +=
                          result.data.ResultadoCalculo.ValorISS / 1;
                        retencao.push(
                          "Retenção " +
                            result.data.ResultadoCalculo.AliquotaIss +
                            "% ISS: R$ " +
                            result.data.ResultadoCalculo.ValorISS
                        );
                      }

                      if (TemRetencaoCSRF / 1 === 1) {
                        valorRetencao +=
                          result.data.ResultadoCalculo.ValorPIS / 1;
                        valorRetencao +=
                          result.data.ResultadoCalculo.ValorCOFINS / 1;
                        valorRetencao +=
                          result.data.ResultadoCalculo.ValorCSLL / 1;

                        retencao.push(
                          "Retenção " +
                            result.data.ResultadoCalculo.AliquotaPIS +
                            "% PIS: R$ " +
                            result.data.ResultadoCalculo.ValorPIS
                        );
                        retencao.push(
                          "Retenção " +
                            result.data.ResultadoCalculo.AliquotaCOFINS +
                            "% COFINS: R$ " +
                            result.data.ResultadoCalculo.ValorCOFINS
                        );
                        retencao.push(
                          "Retenção " +
                            result.data.ResultadoCalculo.AliquotaCSLL +
                            "% CSLL: R$ " +
                            result.data.ResultadoCalculo.ValorCSLL
                        );
                      }

                      if (TemRetencaoIRRF / 1 === 1) {
                        valorRetencao +=
                          result.data.ResultadoCalculo.ValorIRRF / 1;
                        retencao.push(
                          "Retenção " +
                            result.data.ResultadoCalculo.AliquotaIRRF +
                            "% IRRF: R$ " +
                            result.data.ResultadoCalculo.ValorIRRF
                        );
                      }

                      if (TemRetencaoINSS / 1 === 1) {
                        valorRetencao +=
                          result.data.ResultadoCalculo.ValorINSS / 1;
                        retencao.push(
                          "Retenção " +
                            result.data.ResultadoCalculo.AliquotaINSS +
                            "% INSS: R$ " +
                            result.data.ResultadoCalculo.ValorINSS
                        );
                      }

                      this.setState({
                        valorRetencao: valorRetencao,
                        retencao: retencao,
                      });

                      this.abreDecide(id, w);
                    } else {
                      // ERRO NO DOC
                      let errors = [
                        result.data.ResultadoCalculo.Prestador
                          .MotivoProblemaDadosCadastrais,
                      ];

                      if (this.state.teste === false) {
                        api.get(
                          "insert/email_erro.php?subject=Erro no sistema PIX&info=" +
                            result.data.ResultadoCalculo.Prestador
                              .MotivoProblemaDadosCadastrais +
                            "&po_id=" +
                            this.state.idUpload +
                            "&nome=" +
                            this.state.nome +
                            "&cnpj=" +
                            this.state.cnpj +
                            "&nf_link=https://treatments.run/landscape/img/upload/" +
                            this.state.foto
                        );

                        this.setState({
                          dialogNF: false,
                          dialogErroUne: true,
                          dialogPix: false,
                          errors: errors,
                        });
                      }
                    }
                  } else {
                    if (
                      result.data.CodigoErro / 1 !== 16 ||
                      result.data.MensagemErro ===
                        "Houve um problema no processamento da sua requisição. Por favor, entre em contato com o suporte."
                    ) {
                      // ERRO NO DOC
                      let errors = [result.data.MensagemErro];

                      api.get(
                        "insert/email_erro.php?subject=Erro no sistema PIX&info=" +
                          result.data.MensagemErro +
                          "&po_id=" +
                          this.state.idUpload +
                          "&nome=" +
                          this.state.nome +
                          "&cnpj=" +
                          this.state.cnpj +
                          "&nf_link=https://treatments.run/landscape/img/upload/" +
                          this.state.foto
                      );

                      this.setState({
                        dialogErroUne: true,
                        dialogPix: false,
                        errors: errors,
                      });
                    } else {
                      let erroOk =
                        "Deu erro no código, mas o sistema deixou prosseguir.";
                      if (
                        result.data.MensagemErro ===
                        "Houve um problema no processamento da sua requisição. Por favor, entre em contato com o suporte."
                      ) {
                        erroOk = "Erro problema de processamento Unecont.";
                      }

                      api.get(
                        "insert/email_erro.php?subject=Erro no sistema PIX&info=" +
                          erroOk +
                          "&po_id=" +
                          this.state.idUpload +
                          "&nome=" +
                          this.state.nome +
                          "&cnpj=" +
                          this.state.cnpj +
                          "&nf_link=https://treatments.run/landscape/img/upload/" +
                          this.state.foto
                      );

                      this.abreDecide(id, w);
                    }
                  }
                }
              });
          }
        }
      });
  };

  // ABRE DECIDE

  abreDecide = (id, w) => {
    let linhas = this.state.futuro;

    if (w === 0) {
      linhas = this.state.passado;
    }

    let aceita = false;

    if (linhas[id]["valor"] - linhas[id]["valor_pix"] === 0) {
      aceita = true;
    }

    this.setState({
      pix_po_dados: linhas[id],
      valor_full: linhas[id]["valor"],
      total_pix: linhas[id]["valor_pix"] - this.state.valorRetencao,
      juros: linhas[id]["valor"] - linhas[id]["valor_pix"],
      idDoPix: id,
      wDoPix: w,
      aceita: aceita,
      mostraEscolhas: true,
      idUpload: linhas[id]["id"],
    });
  };

  // AGENDAR

  agendar = (w) => {
    api.get(
      'update/updateNF.php?id='+this.state.idUpload+'&nr_nf='+this.state.nr_nf+'&nf_emissao='+this.state.nf_emissao+'&w='+w+'&nf_link=https://treatments.run/landscape/img/upload/'+this.state.foto
    );

    this.setState({
      w: w,
      dialogPix: false,
      dialogNF: false,
      abreVencimento: true,
    });
  };
  // ABRE JANELA REALIZAR PIX

  abreDoPix = (id, w) => {
    this.setState({
      dialogNF: false,
      dialogPix: true,
    });
  };

  // ESPERAR VENCIMENTO

  esperarVencimento() {
    this.setState({
      dialogNF: false,
      dialogPix: false,
      abreVencimento: true,
    });
  }

  // RAW DATE

  rawDate = (w) => {
    w = w.split("/");
    w = w[2] + "-" + w[1] + "-" + w[0];

    return w;
  };

  render() {
    return (
      <div id="fore-root">
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link
          href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <ThemeProvider theme={theme}>
          <Meta title="PIX" />

          <ProgressBar
            perc={this.state.loading ? this.state.loadPerc : 100}
            op={this.state.loading ? 1 : 0}
          />
          <input
            id="file_input"
            type="file"
            onChange={this.fileSelect}
            style={{ display: "none" }}
            ref={(fileInput) => (this.fileInput = fileInput)}
          ></input>
          {this.state.login ? (
            <div className="conteudo">
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <div className="titulo">
                    <span className="color_1">{}</span>
                    {this.state.nome ? this.state.nome : ""}
                  </div>
                </Grid>

                <Grid item xs={2}></Grid>
                <Grid item xs={3}></Grid>
              </Grid>

              <div className="mt30"></div>
              <div className="sub-titulo mt30 mb30">Vencidos</div>
              <div className="hidemob">
                <Grid container spacing={3}>
                  <Grid item lg={2}>
                    <div className="b">Data</div>
                  </Grid>
                  <Grid item lg={4}>
                    <div className="b">Projeto</div>
                  </Grid>
                  <Grid item lg={2}>
                    <div className="b">Função</div>
                  </Grid>
                  <Grid item lg={2}>
                    <div className="b">Valor NF</div>
                  </Grid>
                  <Grid item lg={2}>
                    <div className="b">Nota fiscal</div>
                  </Grid>
                </Grid>
              </div>

              {this.state.passado
                ? this.state.passado.map((passado, objectId) => (
                    <Grid key={objectId} container spacing={3}>
                      <Grid item xs={4} lg={2}>
                        <div className="mt15">{passado.data}</div>
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <div className="mt15 eli">
                          {passado.projeto_id + " " + passado.projeto}
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <div className="mt15 eli">{passado.plano_conta}</div>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <div className="mt15">
                          <CurrencyFormat
                            value={passado.valor}
                            displayType={"text"}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={"R$"}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <div className="mt5">
                          {passado.nf_link ? (
                            <Button
                              fullWidth={true}
                              variant="contained"
                              color="secondary"
                            >
                              NF OK
                            </Button>
                          ) : (
                            <Button
                              sx={{ minHeight: "40px" }}
                              color="primary"
                              fullWidth={true}
                              onClick={() =>
                                this.setIdUpload(
                                  passado.id,
                                  passado.valor,
                                  0,
                                  objectId,
                                  passado.data,
                                  passado.cnpj_produtora
                                )
                              }
                              variant="contained"
                              endIcon={
                                this.state.uploading &&
                                this.state.idUpload === passado.id ? (
                                  ""
                                ) : (
                                  <CloudUpload />
                                )
                              }
                            >
                              {this.state.uploading &&
                              this.state.idUpload === passado.id
                                ? "ㅤ"
                                : "Upload"}
                              <BarLoader
                                size={30}
                                color={"#6468EF"}
                                loading={
                                  this.state.uploading &&
                                  this.state.idUpload === passado.id
                                }
                              />
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  ))
                : ""}

              <hr />

              <div className="sub-titulo mt30 mb30">A Receber</div>
              <div className="hidemob">
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <div className="b">Data</div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="b">Projeto</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="b">Função</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="b">Valor NF</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="b">Nota fiscal</div>
                  </Grid>
                </Grid>
              </div>

              {this.state.futuro
                ? this.state.futuro.map((futuro, objectId) => (
                    <Grid key={objectId} container spacing={3}>
                      <Grid item xs={12} lg={2}>
                        <div className="mt15">
                          {futuro.data + " - Faltam " + futuro.falta + " dias"}
                        </div>
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <div className="mt15">
                          {futuro.projeto_id + " " + futuro.projeto}
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <div className="mt15">{futuro.plano_conta}</div>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <div className="mt15">
                          <CurrencyFormat
                            value={futuro.valor}
                            displayType={"text"}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={"R$"}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <div className="mt5">
                          {futuro.nf_link ? (
                            <Button
                              fullWidth={true}
                              variant="contained"
                              color="secondary"
                            >
                              NF OK
                            </Button>
                          ) : (
                            <Button
                              fullWidth={true}
                              onClick={() =>
                                this.setIdUpload(
                                  futuro.id,
                                  futuro.valor,
                                  1,
                                  objectId,
                                  futuro.data,
                                  futuro.cnpj_produtora
                                )
                              }
                              variant="contained"
                              endIcon={<CloudUpload />}
                            >
                              {this.state.uploading ? (
                                <BarLoader
                                  size={30}
                                  color={"#6468EF"}
                                  loading={this.state.uploading}
                                />
                              ) : (
                                "Upload"
                              )}
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  ))
                : ""}
              <hr />

              <div className="sub-titulo mt30 mb30">PIX Recebidos</div>
              <div className="hidemob">
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <div className="b">Data</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="b">Projeto</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="b">Função</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="b">Valor Original</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="b">Pix Imediato</div>
                  </Grid>
                </Grid>
              </div>

              {this.state.pix
                ? this.state.pix.map((pix, objectId) => (
                    <div key={objectId}>
                      {pix.po.map((po, objectId2) => (
                        <Grid key={objectId2} container spacing={3}>
                          <Grid item xs={3}>
                            <div>{po.data}</div>
                          </Grid>

                          <Grid item xs={12} lg={3}>
                            <div className="">
                              {po.projeto_id + " " + po.projeto}
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={2}>
                            <div className="">{po.plano_conta}</div>
                          </Grid>
                          <Grid item xs={12} lg={2}>
                            <div className="">
                              <CurrencyFormat
                                value={po.valor_pix}
                                displayType={"text"}
                                decimalScale={2}
                                thousandSeparator={true}
                                prefix={"R$"}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={2}>
                            <div className="">
                              <CurrencyFormat
                                value={po.valor}
                                displayType={"text"}
                                decimalScale={2}
                                thousandSeparator={true}
                                prefix={"R$"}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      ))}

                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                          <div className="b">{pix.data}</div>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <div className="b">PIX Imediato</div>
                        </Grid>
                        <Grid item xs={12} lg={2}></Grid>
                        <Grid item xs={12} lg={2}></Grid>
                        <Grid item xs={12} lg={2}>
                          <div className="b">
                            <CurrencyFormat
                              value={pix.valor_pago}
                              displayType={"text"}
                              decimalScale={2}
                              thousandSeparator={true}
                              prefix={"R$"}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))
                : ""}
            </div>
          ) : (
            <div>
              {this.state.acessoNegado === 1 ? (
                <div className="full bgcolor_fore">
                  <ProgressBar
                    perc={this.state.loading ? this.state.loadPerc : 100}
                    op={this.state.loading ? 1 : 0}
                  />
                  <div className="center w50">
                    {this.state.code === 0 ? (
                      <div>
                        {this.state.code !== 3 ? (
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={9}>
                              <Typography variant="h5">
                                Confirme seu CNPJ para acessar seus cachês.
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <InputMask
                                mask="99.999.999/9999-99"
                                disabled={false}
                                maskChar=" "
                                onBlur={(e) => this.setCNPJAcesso(e)}
                                defaultValue={
                                  this.state.cnpj ? this.state.cnpj : ""
                                }
                              >
                                {() => (
                                  <TextField
                                    variant="outlined"
                                    label="CNPJ"
                                    kind="cnpj"
                                    fullWidth={true}
                                    inputProps={
                                      this.state.id > 0
                                        ? { readOnly: true }
                                        : { readOnly: false }
                                    }
                                    placeholder={"CNPJ"}
                                  />
                                )}
                              </InputMask>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                              <Button
                                variant="contained"
                                size="large"
                                fullWidth
                                onClick={() => this.acessar()}
                                color="primary"
                              >
                                Avançar
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div>
                        {this.state.code !== 3 ? (
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={9}>
                              <div className="l mt10">
                                Digite o código que você recebeu via SMS no
                                número:
                              </div>
                              <div className="b l ml10 mt10">
                                {this.state.wpnr.substring(0, 2) +
                                  " " +
                                  this.state.wpnr.substring(2, 12)}
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <TextField
                                variant="outlined"
                                onBlur={(e) => this.setCode(e)}
                                label="Code"
                                fullWidth={true}
                                placeholder={"Code"}
                              />
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                              <Button
                                variant="contained"
                                size="large"
                                fullWidth
                                onClick={() => this.acessarCode()}
                                color="primary"
                              >
                                Avançar
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          <Dialog
            on
            open={this.state.abreVencimento}
            // open
            onClose={(_, reason) =>
              reason !== "backdropClick" && this.fechaDialog()
            }
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle disableTypography>
              <Typography variant="h4">
                OK! Agendado.
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Typography variant="h6" gutterBottom>
                O pagamento foi agendado e você vai receber na data do vencimento!
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" variant="contained" onClick={() => this.fechaDialog()}>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            on
            open={this.state.dialogUpload}
            // open
            onClose={(_, reason) =>
              reason !== "backdropClick" && this.fechaDialog()
            }
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle disableTypography>
              <Typography variant="h4">
                Passo 2 | Faça upload da sua nota fiscal
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              {this.state.nf ? (
                <div className="h100 mb100">
                  {this.state.nf_ok ? (
                    <div className="mini-titulo">
                      <CheckOutlined color="secondary"></CheckOutlined> Tudo ok
                      com a nota fiscal!
                    </div>
                  ) : (
                    <div>
                      Analisando dados da Nota Fiscal...
                      <Button>
                        <BarLoader size={30} color={"#6468EF"} loading={true} />
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="mt20 h100 mb100">
                  <Button
                    color="primary"
                    onClick={() => this.fileInput.click()}
                    variant="contained"
                    endIcon={<CloudUpload />}
                  >
                    Anexe sua nota fiscal
                    <BarLoader
                      size={30}
                      color={"#6468EF"}
                      loading={this.state.uploading}
                    />
                  </Button>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="secondary" variant="contained" onClick={() => this.fechaDialog()}>
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            on
            open={this.state.dialogErro}
            onClose={(_, reason) =>
              reason !== "backdropClick" && this.fechaDialog()
            }
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle disableTypography>
              <Typography variant="h4" display="inline">
                Passo 2 | 
              </Typography>
              <Typography variant="h4" color="error" display="inline">
                {" "}Erro!
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <div className="h100 mb20">
               <Typography variant="h6" gutterBottom>Encontramos alguns erros:</Typography>

                <div className="mb20">
                  {this.state.errors
                    ? this.state.errors.map((erros, objectId) => (
                        <Typography key={objectId} variant="body1" className="mb10">
                          {objectId + 1 + ". " + erros}
                        </Typography>
                      ))
                    : ""}
                </div>
              </div>
              <div className="b mb10">
                ** Se sua NF for um arquivo PDF, faça um prinscreen para melhor
                performance da leitura digital.
              </div>
            </DialogContent>
            <DialogActions>
              {this.state.opSeguir ? (
                <>
                  {this.state.plano_conta_id / 1 !== 52 && (
                    <Button color="secondary" variant="contained" onClick={() => this.agendar(1)}>
                      Enviar mesmo assim
                    </Button>
                  )}
                  <Button color="primary" variant="contained" onClick={() => this.fechaDialog()}>
                    Tentar novamente
                  </Button>
                </>
              ) : (
                <>
                  <Button color="primary" variant="contained" onClick={() => this.fechaDialog()}>
                    Tentar novamente
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            on
            open={this.state.dialogErroUne}
            onClose={(_, reason) =>
              reason !== "backdropClick" && this.fechaDialog()
            }
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle disableTypography>
              <Typography variant="h4" color="error">
                Erro!
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <div className="mt20 h100">
                <Typography gutterBottom variant="body1">
                  Encontramos um erro ao validar seus dados fiscais!
                </Typography>

                <div className="mb20">
                  {this.state.errors
                    ? this.state.errors.map((erros, objectId) => (
                        <Typography key={objectId} variant="body1" className="mb10">
                          {objectId + 1 + ". " + erros}
                        </Typography>
                      ))
                    : ""}
                </div>
              </div>
              <Typography variant="subtitle2">
                ** Escreva pra financeiro@fore.today para achar uma solução.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="secondary" onClick={() => this.fechaDialog()}>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            on
            open={this.state.dialogPix}
            onClose={(_, reason) =>
              reason !== "backdropClick" && this.fechaDialog()
            }
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle disableTypography>
              <Typography variant="h4">
                Faça o PIX
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <div className="">
                {this.state.valorRetencao > 0 ? (
                  <div>
                    <Typography variant="body1" gutterBottom>
                      Sua Nota Fiscal tem retenção de impostos:
                    </Typography>
                    <br />
                    <Typography variant="body1" gutterBottom className="mb20">
                    {this.state.retencao
                      ? this.state.retencao.map((retencao, objectId) => (
                          <div key={objectId} className="mb10">
                            {objectId + 1 + ". " + retencao}
                          </div>
                        ))
                      : ""}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
                <br />
                <Typography variant="body1" className="mb20">
                  Agora é só escolher a chave PIX e receber{" "}
                  <span className="b">
                    {moneyFormat(this.state.total_pix)}
                  </span>{' '}
                  na sua conta.
                </Typography>
                <div className="ml10 pd10 mt10">
                  <Grid container spacing={2}>
                    <RadioGroup row defaultValue="cnpj">
                      <FormControlLabel
                        onClick={() => this.toCheckChave(0)}
                        value="cnpj"
                        control={<Radio />}
                        label={
                          this.state.cnpj
                            ? "CNPJ " + this.converteDados(this.state.cnpj, 0)
                            : ""
                        }
                      />
                      {this.state.cpf && this.state.okCPF && 
                        <FormControlLabel 
                          className="mr30" 
                          onClick={()=>this.toCheckChave(3)} 
                          value="cpf" 
                          control={<Radio />} 
                          label={this.state.cpf ? "CPF "+this.state.cpf:''} 
                        /> 
                      }
                      {this.state.email_pj && this.state.okCPF ? (
                        <FormControlLabel
                          className="mr30"
                          onClick={() => this.toCheckChave(1)}
                          value="email"
                          control={<Radio />}
                          label={
                            this.state.email_pj
                              ? "E-mail " + this.state.email_pj
                              : ""
                          }
                        />
                      ) : (
                        ""
                      )}
                      {this.state.fone && this.state.okCPF ? (
                        <FormControlLabel
                          className="mr30"
                          onClick={() => this.toCheckChave(2)}
                          value="celular"
                          control={<Radio />}
                          label={
                            this.state.fone ? "Celular " + this.state.fone : ""
                          }
                        />
                      ) : (
                        ""
                      )}
                    </RadioGroup>
                  </Grid>
                </div>
                {this.state.juros > 0 ? (
                  <div className="mt20">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="label" className="mt20">
                          <Checkbox
                            checked={this.state.aceita}
                            onChange={() => this.toCheckAceita()}
                            color="primary"
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />{" "}
                          Concordo em reduzir{" "}
                          <span className="b">
                            {moneyFormat(this.state.juros)}
                          </span>{" "}
                          dos meus cachês para receber o valor imediatamente.
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  ""
                )}

                <Grid item md={3} lg={3}>
                  
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              {this.state.aceita && !this.state.loading ? (
                <Button
                  fullWidth={true}
                  onClick={() =>
                    this.executarPix(
                      0,
                      new Date().toISOString().split("T")[0]
                    )
                  }
                  variant="contained"
                  color="primary"
                >
                  Fazer o PIX agora!
                </Button>
              ) : (
                <Button
                  fullWidth={true}
                  variant="contained"
                  disabled
                  color="secondary"
                >
                  Fazer o PIX agora!
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            on
            open={this.state.dialogNF}
            // open
            onClose={(_, reason) =>
              reason !== "backdropClick" && this.fechaDialog()
            }
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle disableTypography>
              {this.state.mostraEscolhas ? (
                <Typography variant="h4">
                  Nota fiscal válida!
                </Typography>
              ) : (
                <Typography variant="h4">Preparando o pagamento...</Typography>
              )}
            </DialogTitle>
            <DialogContent dividers>
              {this.state.mostraEscolhas ? (
                <div className="">
                  {this.state.valorRetencao > 0 ? (
                    <div>
                      <Typography variant="h6">
                        Sua Nota Fiscal tem retenção de impostos:
                      </Typography>
                      <br />
                      <Typography variant="body1" gutterBottom className="mb20">
                        {this.state.retencao
                          ? this.state.retencao.map((retencao, objectId) => (
                              <div key={objectId} className="mb10">
                                {objectId + 1 + ". " + retencao}
                              </div>
                            ))
                          : ""}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="mt20">
                    <Typography variant="h5" gutterBottom>
                      Você pode decidir:
                    </Typography>
                  </div>
                </div>
              ) : (
                <div>
                  <Typography variant="body1" className="mt30">
                    Aguarde um instante. Estamos analisando sua empresa, sua
                    nota fiscal e preparando os dados para o pagamento.
                  </Typography>
                  <div className="progress-wrapper">
                    <BarLoader size={30} width="100%" color={"#6468EF"} loading={true} />
                  </div>
                  <div className="mb50"> </div>
                </div>
              )}
            </DialogContent >
            {this.state.mostraEscolhas && (
              <DialogActions>
                {this.state.plano_conta_id / 1 !== 52 ? (
                  <Button variant="contained" onClick={()=>this.agendar(0)} fullWidth>
                    Receber {moneyFormat(this.state.valor_full)} dia {this.state.data_recebe}
                  </Button>
                ) : (
                  <Button variant="contained" onClick={()=>this.agendar(0)} fullWidth>
                    Receber {moneyFormat(this.state.valor_full)} no prazo combinado no seu acordo.
                  </Button>
                )}
                
                <Button 
                  onClick={() =>
                    this.abreDoPix(this.state.idDoPix, this.state.wDoPix)
                  } 
                  variant="contained" 
                  color="primary" 
                  fullWidth>
                  Receber {moneyFormat(this.state.total_pix)} hoje mesmo
                </Button>
              </DialogActions>
            )}
          </Dialog>

          <Dialog
            on
            open={this.state.dialogSuccess}
            onClose={(_, reason) =>
              reason !== "backdropClick" && this.fechaPix()
            }
            fullWidth={true}
            maxWidth={"sm"}
          >
            <DialogTitle disableTypography>
              <Typography variant="h4">Sucesso!</Typography>
            </DialogTitle>
            <DialogContent dividers>
              {/* <div className="sub-titulo w100 tc mt50 mb50">
                Sucesso! O PIX estará na sua conta em minutos.
              </div> */}
              <Typography className="mt50" variant="h6">
                O PIX estará na sua conta em minutos.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" variant="contained" onClick={() => this.fechaPix()}
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            on
            open={this.state.modalImg}
            onClose={(_, reason) =>
              reason !== "backdropClick" && this.fechaDialog()
            }
            fullWidth={true}
            maxWidth={"md"}
            scroll="paper"
          >
            <DialogTitle disableTypography>
              <Typography variant="h4">Confira sua nota fiscal.</Typography>
            </DialogTitle>
            <DialogContent dividers>
              <div>
                <img
                  className="img_nf"
                  alt="nota fiscal"
                  src={this.state.img}
                ></img>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.fechaDialog()}
              >
                Recomeçar
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => this.detectText(this.state.img)}
              >
                Continuar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog on open={this.state.enviandoNF} fullWidth={true} maxWidth={"sm"}>
            <DialogContent dividers>
              <div className="mt20 mb20">
                <Typography variant="h3">Enviando NF...</Typography>
              </div>
            </DialogContent>
          </Dialog>
        </ThemeProvider>
      </div>
    );
  }
}
