import React, { Component, } from "react";
import Grid from '@material-ui/core/Grid';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import './style.css';


const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },

}
  
)



function Title() {
  
  return (
      <div>
        <div className="header">
           <Grid container spacing={1}>
      
      <Grid item xs={4}>
          <div className="titulo">Receitas</div>
          </Grid>
      </Grid>
      </div>
      </div>
  )
}



export default class Receitas extends Component {

  

  state = {
    token: localStorage.getItem("tokens"),
    optionUsers: [],
    classes: ({
      root: {
        flexGrow: 0,
      
        
      },
      paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        padding: 0,
        margin: -2,
    
       
      },
      control: {
        padding: 10,
        marginTop: 5,
        background: "#fc3"
      }
    })
    
  }
 




  
   

  render(){

    const classes = this.state.classes;

  return (

    
    <ThemeProvider theme={theme}>
      
      <div className={classes.root}>
          
          <ProgressBar/>
          
          <div className="conteudo">
            <Title/>
          </div>

      </div>
    

    </ThemeProvider>
  )
}

}
  
 