import React, { Component } from 'react'
import { ResponsiveLine } from '@nivo/line'
import './style.css';

  

export default class LineGraph extends Component {
   
  constructor(props){
    super();
    this.state = {
        //data:data
      data:props.data,
      //color:props.color,
      //angle:270
    }
  }


  
    render(props) {
        return (
            
                
          
<ResponsiveLine
      data={this.state.data}
      margin={{ top: 30, right: 0, bottom: 50, left: 0 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'transportation',
          legendOffset: 36,
          legendPosition: 'middle'
      }}
      axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'count',
          legendOffset: -40,
          legendPosition: 'middle'
      }}
      colors={d => d.color}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor', modifiers: [] }}
      pointLabelYOffset={-12}
      useMesh={false}
      legends={[]}
      theme={{
        
        grid: {
          line: {
            stroke: "grey",
            strokeWidth: 0.5,
            strokeDasharray: "4 4"
          }
        }
      }}
  />

      
      
            
        )
    }
}

