import React, { Component} from "react";

import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Meta from "../../components/meta";
import {Menu, MenuItem, ListItemText, Grid, IconButton, Tooltip, Zoom} from "@material-ui/core";
import { zonedTimeToUtc } from 'date-fns-tz'
import { TextInput,MyDateTimePicker } from "../../components/textField";
import Calendar from "../budget/calendar_coordenacao";
import { Videocam } from "@material-ui/icons";
import { ContentPasteGo } from "@mui/icons-material";
import { withStyles } from '@material-ui/core/styles';
const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
 

}
  
)

const StyledMenuItem = withStyles((theme) => ({
  root: {
      '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
      },
      },
  },
  }))(MenuItem);

  const date = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  const today = zonedTimeToUtc(new Date(), 'America/Sao_Paulo');
  
  
  date.setDate(date.getDate() + 7);

export default class Report extends Component {


  state = {
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    loading:true,
    loadPerc:0,
    range:1,
    colorStatus:['bgcolor_5s','bgcolor_4','bgcolor_2'],
    horas:[
      {'value':0,'label':0},
      {'value':1,'label':1},
      {'value':2,'label':2},
      {'value':3,'label':3},
      {'value':4,'label':4},
      {'value':5,'label':5},
      {'value':6,'label':6}
      

  ],
  extrasHoras:0,
  extrasProjeto:0,
  extrasDia:0,
    selectionRange:({
      startDate: today,
      endDate: date,
      key: 'selection',
    }),
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  async componentDidMount(){
    
    this.loadPauta();
    
  }


  // ABRE MENU PRESET

  abreMenu = (event,w,id) => {

 
    this.toLoad()
    let budgets = [];
    let ct = event.currentTarget;
    api.get('/get/get.php?id='+w+'&sheet=budget&col_id=projeto_id&empresa_id='+id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
       
        budgets = result.data

        
        this.setState({
          budgets:budgets,
          anchorEl: ct
      })
     
          this.endLoad()

      }})
    
    


    
    
  };

// FECHA MENU PRESET

fechaMenu = (id) => {
    
    this.setState({
        anchorEl: null
    })
    };




  // LISTA DE ORCAMENTOS


  loadPauta = async () => {
    
    this.toLoad();

    api.get('get/getPauta.php?r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {
          
            this.setState({
              pauta: result.data.budgets,
              projetos_ativos:result.data.projetos
            });
            this.endLoad();

        }});
    
  }

// SHOOTING

shooting = (id) => {
  
    
  api.get('update/update.php?sheet=projeto&id='+id+'&col=shooting&value=0&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
    
    if (result.status === 200) {
        
      this.loadPauta()

      }})

}

// VER PROJETO

verProjeto = (id) => {
  window.location.href='view/'+id
  
}


changeDate = (e,id,o) => {

  this.toLoad()
  let dt = new Date(e)
  dt = dt.toISOString()
  
  api.get('update/update.php?id='+id+'&sheet=projeto&col=urgencia&value='+dt+'&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {

        let pauta = this.state.pauta;
        pauta[o].urgencia = dt;
        this.setState({pauta: pauta});
        this.endLoad();

    }});

}

openBudget = (id) => {
  window.open('sheet/'+id)
  this.fechaMenu()
}

  render(){


  return (

   
    <ThemeProvider theme={theme}>
       
<Meta title="Pauta Coordenação"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo_free">
        <Grid container spacing={3}>
                
                <Grid item xs={4}><div className="titulo">Pauta Budgets</div></Grid>

                <Grid item xs={5}></Grid>
       
        
       
        <Grid item xs={3}>
        
        </Grid>
        
        </Grid>

       
      <div className="mt50">
      
                    {this.state.pauta ? this.state.pauta.map((lines,objectId)=>(
                            <Grid onClick={(e,w)=>this.abreMenu(e,lines.id,lines.empresa_id)}  key={objectId} container spacing={0} className="bgcolor_6 br5 mb5 cl">
                                <Grid item xs={2}>
                                
                                <div className="pd20 eli">{lines.id + ' ' + lines.cliente+' - '+lines.nome}</div>
                                </Grid>
                                <Grid item xs={2}>

                                <div className="pd20 bgcolor_7">{lines.produtora}</div>
                                
                                    
                                </Grid>

                                <Grid item xs={2}>

                                <div className="pd20 eli">{lines.atendimento}</div>
                                </Grid>

                                <Grid item xs={2}>

                                <div className="pd20 bgcolor_7 eli">{lines.diretor}</div>
                                </Grid>

                                <Grid item xs={2}>

                                
                                    <TextInput 
                                        autocomplete={false}
                                        sheet="projeto"
                                        id={lines.id}
                                        col="coordenador"
                                        label={'Responsável'}
                                        fullWidth={true}
                                        defaultValue={ lines.coordenador }
                                    
                                    />
                                    
                                </Grid>
                                <Grid item xs={2}>

                                    <MyDateTimePicker 
                                          onChange={(e)=>this.changeDate(e,lines.id,objectId)} 
                                          view={["year", "month","date"]}  
                                          format="dd/MM/yyyy" 
                                          label="Entrega de orçamento" 
                                          startDate={lines.urgencia}
                                    />

                                </Grid>
                                

                                
                                
                             </Grid>
                    )):''}


          </div>
   
          <hr/>
          <Grid container spacing={3}>
                
                <Grid item xs={4}><div className="titulo mt30">Pauta Projetos</div></Grid>

                <Grid item xs={5}></Grid>
       
        
       
        <Grid item xs={3}>
        
               
       
        </Grid>
        


        </Grid>

       
      <div className="mt50">
      
                    {this.state.projetos_ativos ? this.state.projetos_ativos.map((lines,objectId)=>(
                            <Grid key={objectId} container spacing={0} className="bgcolor_6 br5 mb5 cl">
                                <Grid item xs={2}>
                                <div className="pd20">{lines.produtora}</div>
                                </Grid>
                                <Grid item xs={2}>

                                
                                <div className="pd20 bgcolor_7">{lines.cliente}</div>
                                    
                                </Grid>

                                <Grid item xs={2}>

                                <div className="pd20 eli">{lines.atendimento}</div>
                                </Grid>

                                <Grid item xs={2}>

                                <div className="pd20 bgcolor_7 eli">{lines.diretor}</div>
                                </Grid>

                                <Grid item xs={3}>

                                
                                    <TextInput 
                                        autocomplete={false}
                                        sheet="projeto"
                                        id={lines.id}
                                        col="coordenador"
                                        label={'Responsável'}
                                        fullWidth={true}
                                        defaultValue={ lines.coordenador }
                                    
                                    />
                                    
                                </Grid>
                                <Grid item xs={1}>

                                <div className="mt5 r">
                                  <Tooltip title="Shooting" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.shooting(lines.id)} color={"secondary"}  target="_blank"><Videocam/></IconButton></Tooltip>
                                  <Tooltip title="Ver projeto" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.verProjeto(lines.id)}  target="_blank"><ContentPasteGo/></IconButton></Tooltip>
                                  
                                </div>
                                </Grid>
                                

                                
                                
                             </Grid>



                    )):''}


          </div>
          
          <hr/>
          </div>
          
          <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={()=>this.fechaMenu()}
                        >
                            
                        {this.state.budgets ? this.state.budgets.map((budgets,objectId)=>(
                               <StyledMenuItem key={objectId} onClick={()=>this.openBudget(budgets.id)}><ListItemText primary={budgets.nome + ' | R$ '+budgets.valor} /></StyledMenuItem>
                       )):''}
                                
                        </Menu>

          <Calendar/>

    </ThemeProvider>
  )


}
}