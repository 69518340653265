import React, { Component} from "react";
import Grid from '@material-ui/core/Grid';
import {MyDatePicker,AutoComp,AutoCompFree,MySelect} from '../../components/textField';
import TextField from '@mui/material/TextField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import FullBoard from './FullBoard';
import Unidades from './Unidades';
import Pies from './Pies';
import BarReport from './BarReport';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import './style.css';
import Meta from "../../components/meta";
import CurrencyFormat from "react-currency-format"
import { Redirect } from "react-router";



const meses = [
'Janeiro',
'Fevereiro',
'Março',
'Abril',
'Maio',
'Junho',
'Julho',
'Agosto',
'Setembro',
'Outubro',
'Novembro',
'Dezembro'
];


const motivos = [
  {'value':0,"label":"Custo"},
  {'value':1,"label":"Escolha da criação"},
  {'value':2,"label":"Tratamento"},
  {'value':3,"label":"Escolha do cliente"},
  {'value':4,"label":"Relacionamento"},
  {'value':5,"label":"Projeto Cancelado"}
];


const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
 

}
  
)




export default class Budget extends Component {


  state = {
    token: localStorage.getItem("tokens"),
    empresa_id: localStorage['empresa_id'],
    usuario_id: localStorage['usuario_id'],
    permission: localStorage['permission'],
    loading:true,
    loadPerc:0,
    motivo:0,
    idNewBudget:0,
    motivoTexto:'',
    listUnidades:[],
    listUnidadesSelect:[],
    listDiretoresSelect:[],
    listAtendimento: [],
    budgets0: [],
    budgets1: [],
    budgets2: [],
    budgets3: [],
    budgetList:[],
    arrTotalMes:[],
    openNewBudget:false,
    openCaiuBudget:false,
    budgetCaiuId:0,
    mes: new Date(),
    display:0,
    eventos:0,
    classes: ({
      root: {
        flexGrow: 0,
      
        
      },
      paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        padding: 0,
        margin: -2,
    
       
      },
      control: {
        padding: 10,
        marginTop: 5,
        background: "#fc3"
      }
    })
    
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  async componentDidMount(){
    
    this.loadAtendimento();
    this.setState({ loading: true })
    this.setPage()
    this.loadUnidades()
    this.loadUnidadeSelect()


      

      
  }


    
// ULTIMA PÁGINA VISITADA

setPage = async () => {

  localStorage.setItem("page",'/budgets');
  api.get('update/setPage.php?page=/budgets&token='+this.state.token);

}


// DIALOG NOVO ORÇAMENTO

novoOrcamento = async () => {

  this.setState({openNewBudget:true})
}



// DIALOG CAIU ORÇAMENTO

caiuOrcamento = async (w) => {

  this.setState({
    openCaiuBudget:true,
    budgetCaiuId:w,
  })
}


  // DROPDOWN ATENDIMENTO

  loadAtendimento = async () => {
   
    this.toLoad()
   
    
    api.get('/get/getAtendimento.php?empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({listAtendimento:result.data})
          
          this.endLoad()

      }})
    

  }

  // DROPDOWN UNIDADES

  loadUnidadeSelect = async () => {
   
    this.toLoad()
   
    
    api.get('/get/getSelect.php?id='+this.state.empresa_id+'&sheet=unidades&col_id=empresa_id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
         
          this.setState({listUnidadesSelect:result.data})
          this.loadDiretoresSelect();
         
          

      }})
    

  }

  // DROPDOWN DIRETORES

  loadDiretoresSelect = () => {
   
    
    api.get('get/getSelect.php?id=0&sheet=users&col_id=diretores&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({listDiretoresSelect:result.data})
          
          this.loadRank();

         

      }})
     

  }


  // RANK

  loadRank = async () => {
   
    
   
    
    api.get('/get/rank.php?ano='+this.state.mes.getFullYear()+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({
            rankAno:result.data.ano,
            rankAll:result.data.all,
            
            
          })
          
         

      }})
    

  }



  // EMPRESA

  loadEmpresa = async () => {

    this.toLoad()
    
    api.get('/get/get.php?id='+this.state.empresa_id+'&sheet=empresa&col_id=id&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
        let eventos = result.data[0].eventos;

          this.setState({eventos:eventos})
          
          this.endLoad()

      }})
      
      this.setState({atendimento_id:0})

  }

  // UNIDADES

  loadUnidades = async (mes) => {

    this.toLoad()

    this.loadEmpresa()
    
    api.get('/get/get.php?id='+this.state.empresa_id+'&sheet=unidades&col_id=empresa_id&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({listUnidades:result.data})
          
          this.endLoad()

      }})
      
      this.setState({atendimento_id:0})

      for(let i=0; i<this.state.listUnidades.length; i++){

              if(this.state.listUnidades[i]['value']===localStorage['usuario_id']){
                this.setState({atendimento_id:this.state.listUnidades[i]['value']})
              }
      }

      this.loadProjetos(this.state.atendimento_id,0,mes);


  }



  // LISTA DE ORCAMENTOS


  loadProjetos = async (a,id,mes) => {
    
    this.toLoad()
    
    if(!mes){
      let nMes = this.state.mes.getMonth()/1 + 1;

      if(nMes<10){
        nMes='0'+nMes;
      }
      mes = this.state.mes.getFullYear()+'-'+(nMes);
    }

    api.get('/get/projetos.php?usuario_id='+this.state.usuario_id+'&id='+id+'&mes='+mes+'&status=0&atendimento_id='+a+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {

          
            this.setState({
              budgets0:result.data.orcando,
              budgets1:result.data.goodvibes,
              budgets2:result.data.acompanhando,
              budgets3:result.data.poucaschances,
              total_mes:result.data.total_mes[0]['valor'],
              total_ano:result.data.total_ano[0]['valor'],
              office:result.data.office,
              live:result.data.live
        })
        
          
          this.endLoad()

      }

      
    })
    
      

  }


 
  // LISTA VERSÕES ORÇAMENTO

  abreOrcamento = (w) => {
  
    this.toLoad()
    
    api.get('/get/get.php?id='+w+'&sheet=budget&col_id=projeto_id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
      
          this.setState({
            budgetList:result.data,
            display: w
          })
         
          this.endLoad()

      }})

    
  }


  // MUDA DATA

  changeData = async (e) => {
   
    
    if(this.state.mes.getFullYear()!==e.getFullYear() || this.state.mes.getMonth()!==e.getMonth()){
      
      this.setState({
        mes:e,
        listUnidades:[],
      })

      let mes = e.getMonth()+1;
      if(mes<10){
        mes = '0'+mes;
      }

      let ano_mes = e.getFullYear()+'-'+mes
      //this.loadProjetos(0,0,ano_mes)
      
      this.loadUnidades(ano_mes)
    }
    
  }
   
  // MUDA ATENDIMENTO

  changeAtendimento = (e) => {
    this.setState({
      atendimento_id:e
    })
    this.loadProjetos(e,0);
  }

// MUDA BUDGET

  changeBudget = (e) => {
    this.loadProjetos(0,e);

}

// SETA CLIENTE

changeCliente = (e) =>{
  this.setState({newCliente:e})
}

// MUDA MOTIVO

changeMotivo = (e) => {
  
  this.setState({
    motivo:e
  })

}

// MUDA TEXTO MOTIVO

changeMotivoTexto = (e) => {
  
  this.setState({
    motivoTexto:e
  })

}


// DUPLICAR BUDGET

duplicarBudget = (w) => {
  
  api.get('insert/duplicarBudget.php?id='+w+'&r='+Math.random(10000)).then(result => {

    if (result.status === 200) {
      this.abreOrcamento(this.state.display)
      
    }

  })
}



  handleClose = (w) => {
    

    this.setState({
      openNewBudget:false,
      openCaiuBudget:false
    })
    
  }

  handleConfima = (w) =>{

      if(w===0){
        if(this.state.newCliente===undefined || this.state.newAtendimento===undefined || this.state.newRtv===undefined || this.state.newEmailrtv===undefined || this.state.newunidade===undefined){
          this.setState({alertNewBudget:'*** Você precisa preencher todos os campos'})
        }else{

          this.toLoad()
          
          api.get('insert/newBudget.php?nome='+this.state.newNome+'&diretor_id='+this.state.newDiretor+'&atendimento_id='+this.state.newAtendimento+'&cliente='+this.state.newCliente+'&agencia='+this.state.newAgencia+'&rtv='+this.state.newRtv+'&email-rtv='+this.state.newEmailrtv+'&unidade_id='+this.state.newunidade+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
  
          if(result.status===200){

            console.log(result.data)
            if(this.state.eventos/1===0){
              this.loadProjetos(this.state.atendimento_id,0);
              this.endLoad()
              this.setState({
                openNewBudget:false,
              })
            }else{
              //window.location.href = '/projetos'
            }

            }
          })
      }


    }


      
      else{
        
        
        api.get('update/caiuBudget.php?id='+this.state.budgetCaiuId+'&usuario_id='+localStorage['usuario_id']+'&motivo='+motivos[this.state.motivo]['label']+'&msg='+this.state.motivoTexto+'&r='+Math.random(10000)).then(result => {
  
            if(result.status===200){

              
              
                this.loadProjetos(this.state.atendimento_id,0);
                this.setState({
                  openCaiuBudget:false
                })

                

               
              }
            })

           


            


        }
        
      


       



    }

    
    
  changeNew = (e,w) => {
    
    if(w==='nome'){this.setState({newNome:e})}
    if(w==='cliente'){this.setState({newCliente:e})}
    if(w==='diretor'){this.setState({newDiretor:e})}
    if(w==='agencia'){this.setState({newAgencia:e})}
    if(w==='atendimento'){this.setState({newAtendimento:e})}
    if(w==='rtv'){this.setState({newRtv:e})}
    if(w==='email-rtv'){this.setState({newEmailrtv:e})}
    if(w==='unidade'){this.setState({newunidade:e})}
    
  }

  upTracking = (w,c) => {
    
    const change=[];
    const tempArr0=[];
    const tempArr1=[];
    const tempArr2=[];
    const tempArr3=[];

    
    for(let i=0; i<this.state.budgets0.length; i++){

      
      if(this.state.budgets0[i]['id']===c){
          change.push({'id':this.state.budgets0[i]['id'],'nome':this.state.budgets0[i]['nome'],'cliente':this.state.budgets0[i]['cliente'],'atendimento_nome':this.state.budgets0[i]['atendimento_nome'],'open_date':this.state.budgets0[i]['open_date']});
      }else{
        tempArr0[i] = {'id':this.state.budgets0[i]['id'],'nome':this.state.budgets0[i]['nome'],'cliente':this.state.budgets0[i]['cliente'],'atendimento_nome':this.state.budgets0[i]['atendimento_nome'],'open_date':this.state.budgets0[i]['open_date']};
        
      }

    }

    

    for(let i=0; i<this.state.budgets1.length; i++){
      if(this.state.budgets1[i]['id']===c){
        change.push({'id':this.state.budgets1[i]['id'],'nome':this.state.budgets1[i]['nome'],'cliente':this.state.budgets1[i]['cliente'],'atendimento_nome':this.state.budgets1[i]['atendimento_nome'],'open_date':this.state.budgets1[i]['open_date']});
      }else{
        tempArr1[i] = {'id':this.state.budgets1[i]['id'],'nome':this.state.budgets1[i]['nome'],'cliente':this.state.budgets1[i]['cliente'],'atendimento_nome':this.state.budgets1[i]['atendimento_nome'],'open_date':this.state.budgets1[i]['open_date']};
      }
    }

    for(let i=0; i<this.state.budgets2.length; i++){
      if(this.state.budgets2[i]['id']===c){
        change.push({'id':this.state.budgets2[i]['id'],'nome':this.state.budgets2[i]['nome'],'cliente':this.state.budgets2[i]['cliente'],'atendimento_nome':this.state.budgets2[i]['atendimento_nome'],'open_date':this.state.budgets2[i]['open_date']});
      }else{
        tempArr2[i] = {'id':this.state.budgets2[i]['id'],'nome':this.state.budgets2[i]['nome'],'cliente':this.state.budgets2[i]['cliente'],'atendimento_nome':this.state.budgets2[i]['atendimento_nome'],'open_date':this.state.budgets2[i]['open_date']};
      }
    }

    for(let i=0; i<this.state.budgets3.length; i++){
      if(this.state.budgets3[i]['id']===c){
        change.push({'id':this.state.budgets3[i]['id'],'nome':this.state.budgets3[i]['nome'],'cliente':this.state.budgets3[i]['cliente'],'atendimento_nome':this.state.budgets3[i]['atendimento_nome'],'open_date':this.state.budgets3[i]['open_date']});
      }else{
        tempArr3[i] = {'id':this.state.budgets3[i]['id'],'nome':this.state.budgets3[i]['nome'],'cliente':this.state.budgets3[i]['cliente'],'atendimento_nome':this.state.budgets3[i]['atendimento_nome'],'open_date':this.state.budgets3[i]['open_date']};
      }
    }

   
    if(w===0){
      tempArr0.push(change[0]);
    }
    if(w===1){
      tempArr1.push(change[0]);
    }
    if(w===2){
      tempArr2.push(change[0]);
    }
    if(w===3){
      tempArr3.push(change[0]);
    }

    this.setState({

      budgets0: tempArr0,
      budgets1: tempArr1,
      budgets2: tempArr2,
      budgets3: tempArr3,

    })

  }

  // SE FOR FAZIA A BUSCA

  checkifEmpty = (e) => {

    if(e===""){
    
    this.loadProjetos()
    }
  }

  // NOVA VERSAO
  novaVersao = (w) =>{
    
    api.get('insert/novoFromBudget.php?id='+w+'&r='+Math.random(10000)).then(result => {

      if (result.status === 200) {
        
        this.abreOrcamento(this.state.display)
      }
  
    })
  }
  // VOID

  void = () => {
    
  }

  render(){

    const classes = this.state.classes;

  return (

    
    <ThemeProvider theme={theme}>
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
       {this.state.permission > 2 ? <Redirect to='/login'/> : ''}
<Meta title="Budget"/>
      <div className={classes.root}>
      
        
      <div className="conteudo">
          
          <Grid container spacing={3}>
            
            <Grid item xs={3}><div className="titulo">Budget</div></Grid>
              <Grid item xs={3}><div className="bt large bgcolor_1" onClick={()=>this.novoOrcamento()}>Novo</div></Grid>
              <Grid item xs={3}><AutoComp empresa_id={this.state.empresa_id} sheet="projeto" tipo="projeto" label="Busque pelo budget" blur={(e)=>this.checkifEmpty(e)} change={(e)=>this.changeBudget(e)}/></Grid>
              <Grid item xs={3}><MySelect list={this.state.listAtendimento} initValue={this.state.atendimento_id} first_item="Ver todos" label="Atendimento" change={(e)=>this.changeAtendimento(e)}/></Grid>
          </Grid>

      <hr/>

        <Grid container spacing={1}>
          <FullBoard id="1" novaVersao={(w)=>this.novaVersao(w)} duplicarBudget={(w)=>this.duplicarBudget(w)} up={(w,c)=>this.upTracking(w,c)} caiu={(w)=>this.caiuOrcamento(w)} label="Orçando" color="#A456FF" budgets={this.state.budgets0} display={this.state.display} budgetList={this.state.budgetList} abreOrcamento={(e)=>this.abreOrcamento(e)} office={this.state.office} />
          <FullBoard id="2" novaVersao={(w)=>this.novaVersao(w)} duplicarBudget={(w)=>this.duplicarBudget(w)} up={(w,c)=>this.upTracking(w,c)} caiu={(w)=>this.caiuOrcamento(w)} label="Good Vibes" color="#7FDCA4" budgets={this.state.budgets1} display={this.state.display} budgetList={this.state.budgetList} abreOrcamento={(e)=>this.abreOrcamento(e)} office={this.state.office} />
          <FullBoard id="3" novaVersao={(w)=>this.novaVersao(w)} duplicarBudget={(w)=>this.duplicarBudget(w)} up={(w,c)=>this.upTracking(w,c)} caiu={(w)=>this.caiuOrcamento(w)} label="Acompanhando" color="#A3D7C9" budgets={this.state.budgets2} display={this.state.display} budgetList={this.state.budgetList} abreOrcamento={(e)=>this.abreOrcamento(e)} office={this.state.office} />
          <FullBoard id="4" novaVersao={(w)=>this.novaVersao(w)} duplicarBudget={(w)=>this.duplicarBudget(w)} up={(w,c)=>this.upTracking(w,c)} caiu={(w)=>this.caiuOrcamento(w)} label="Quase perdendo" color="#F9D48E" budgets={this.state.budgets3} display={this.state.display} budgetList={this.state.budgetList} abreOrcamento={(e)=>this.abreOrcamento(e)} office={this.state.office} />
        </Grid>


        <hr/>
        
        <Grid container spacing={3}>
        
            <Grid item xs={3}><div className="titulo">{meses[this.state.mes.getMonth()]}</div></Grid>
            <Grid item xs={6}><div className="sub-titulo mt15">{this.state.total_mes ? <CurrencyFormat value={this.state.total_mes}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> :<CurrencyFormat value={0}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
            <Grid item xs={3}><MyDatePicker onChange={(e)=>this.changeData(e)} view={["year", "month"]}  format="MMM/yyyy" label="Busque pelo mês" startDate={''}/></Grid>
        
        </Grid>

        {this.state.loading ? '' : <Pies view="0" mes={this.state.mes}/>}
                  
        {this.state.listUnidades.map((unidades,objectId)=>(

            <Unidades key={objectId} nome={unidades.nome} unidade_id={unidades.id} empresa_id={this.state.empresa_id} mes={this.state.mes}/>

        ))}

        <Grid container spacing={3}>
        
          <Grid item xs={3}><div className="titulo">{this.state.mes.getFullYear()}</div></Grid>
          <Grid item xs={6}><div className="sub-titulo mt15">{this.state.total_ano ? <CurrencyFormat value={this.state.total_ano}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> :<CurrencyFormat value={0}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
          
        </Grid>

        {this.state.loading ? '' : <Pies view="1" mes={this.state.mes}/>}
        

        <div className="sub-titulo mt30 mb30">Ranking do ano</div>
        {this.state.rankAno ? this.state.rankAno.map((rank,objectId)=>(
          <div>{objectId<20 ? <BarReport key={objectId} valor={"R$ "+rank.valor} agencia={rank.agencia}  perc={rank.valor/this.state.rankAno[0]['valor']*100} color="#A456FF"/> : ''}</div>
        )) : ''}
        
          <hr/>
        <div className="sub-titulo mb30">Ranking geral</div>
        
          {this.state.rankAno ? this.state.rankAll.map((rank,objectId)=>(

          <div>{objectId<20 ? <BarReport key={objectId} valor={"R$ "+rank.valor} agencia={rank.agencia}  perc={rank.valor/this.state.rankAll[0]['valor']*100} color="#7FDCA4"/> : ''}</div>
          

          )) : ''}
          
        </div>

        


        
        <Dialog
        open={this.state.openNewBudget}
        onClose={()=>this.handleClose(0)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <div className="pd30">
        <DialogContent>
        <Grid container spacing={3}>
        <Grid item xs>
          <div className="mini-titulo">Novo Budget</div> </Grid>
          <Grid item xs><div className="tr color_5">{this.state.alertNewBudget ? this.state.alertNewBudget : ''}</div></Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs>
                  
                  {this.state.eventos/1===0 ? 
                    <div className="mt20">
                        <AutoCompFree sheet="projeto" tipo="cliente" label="Cliente" blur={(e)=>this.changeNew(e,'cliente')} change={()=>this.void()}/>
                    </div>

                    :

                    <div className="mt20">
                      <AutoComp sheet="users" tipo="cliente" label="Cliente" blur={(e)=>this.changeCliente(e)} change={()=>this.void()}/>
                    </div>
                  }

                <div className="mt20">
                <TextField
                    autoComplete="off"
                    fullWidth={true}
                    label="Nome do projeto"
                    onBlur={e => {
                      this.changeNew(e.target.value,'nome')
                    }}
                   
                    defaultValue=""
                    variant="outlined"
                  />
                </div>
              
               
                <div className="mt20">
                    <AutoCompFree sheet="users" tipo="rtv" label="Solicitante" change={()=>this.void()} blur={(e)=>this.changeNew(e,"rtv")}/>
                </div>

                <div className="mt20">
                    <AutoCompFree sheet="users" tipo="email-rtv" label="E-mail Solicitante" blur={(e)=>this.changeNew(e,"email-rtv")} change={()=>this.void()}/>
                </div>

                
              
            </Grid>

            <Grid item xs>
            {this.state.eventos/1===0 ? 
                <div className="mt20">
                    <AutoCompFree sheet="projeto" tipo="agencia" label="Agencia" change={()=>this.void()} blur={(e)=>this.changeNew(e,"agencia")}/>
                </div>
                :''
                }
               
                {this.state.eventos/1===0 ? 
                <div className="mt20">
                <AutoCompFree sheet="users" tipo="diretores" label="Diretores" change={()=>this.void()} blur={(e)=>this.changeNew(e,"diretor")}/>
                </div>
                :''
                }
               
                
                <div className="mt20">
                <MySelect list={this.state.listAtendimento} initValue={this.state.atendimento_id} label="Atendimento" change={(e)=>this.changeNew(e,"atendimento")}/>
                </div>

                <div className="mt20">
                  <MySelect list={this.state.listUnidadesSelect} initValue={''} label="Unidade" change={(e)=>this.changeNew(e,"unidade")}/>
                </div>

              
            </Grid>
          </Grid>
          
          <div className="bt bgcolor_1 dialog w10 mt20" disableElevation onClick={()=>this.handleConfima(0)} color="secondary">
            Criar
          </div>
          <div className="bt bgcolor_5 w10 mt20" disableElevation onClick={()=>this.handleClose(0)} color="primary" autoFocus>
            Cancelar
          </div>
          
          </DialogContent>
        </div>
      </Dialog>


      



      <Dialog
        open={this.state.openCaiuBudget}
        onClose={()=>this.handleClose(1)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <div className="pd30">
        <DialogContent>
        
          <div className="mini-titulo">Nåo aprovou</div>
          <Grid container spacing={3}>
            <Grid item xs>
                
                <div className="mt20">
                  <MySelect list={motivos} initValue={0} label="Motivo" change={(e)=>this.changeMotivo(e)}/>
                
                </div>
              
                <div className="mt20">
                <TextField
                    fullWidth={true}
                    id="outlined-multiline-static"
                    label="Motivo"
                    onBlur={e => {
                      this.changeMotivoTexto(e.target.value)
                    }}
                    multiline
                    minrows={4}
                    defaultValue=""
                    variant="outlined"
                  />
                </div>
                
              
            </Grid>

            
          </Grid>
        
          <div className="bt bgcolor_1 dialog w10 mt20" disableElevation onClick={()=>this.handleConfima(1)} color="secondary">
            Enviar
          </div>
          <div className="bt bgcolor_5 w10 mt20" disableElevation onClick={()=>this.handleClose(1)} color="primary" autoFocus>
            Cancelar
          </div>
          
          </DialogContent>
        </div>
      </Dialog>



          </div>



    </ThemeProvider>
  )
}

}
  
 