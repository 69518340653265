import React from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import {TextInput,MoneyInput,MySelect,TextInputDelete,AutoComp} from '../../components/textField';
import api from '../../services/api';
import { Delete } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { TextField } from "@material-ui/core";

const optionComissao = [{"value":"0","label":"Sobre a produção Live Action"},{"value":"1","label":"Sobre a Post"},{"value":"2","label":"Sobre toda a produção"},{"value":"3","label":"Sobre a receita"},{"value":"4","label":"Sobre a produção mais taxas"},{"value":"5","label":"Valor fixo"}]

export default function BudgetConfig(props) {

  const sw = [];

  if(props.a_live_action === '1'){sw[0] = false;}else{sw[0] = true;}
  if(props.a_time_interno === '1'){sw[1] = false;}else{sw[1] = true;}
  if(props.a_fornecedores_post === '1'){sw[2] = false;}else{sw[2] = true;}
  if(props.a_copias === '1'){sw[3] = false;}else{sw[3] = true;}
  if(props.a_ancine === '1'){sw[4] = false;}else{sw[4] = true;}
  if(props.crosscompany === '1'){sw[5] = true;}else{sw[5] = false;}
  

  const [state, setState] = React.useState({
    live_action: sw[0],
    time_interno: sw[1],
    fornecedores_post: sw[2],
    copias: sw[3],
    ancine: sw[4],
    crosscompany: sw[5],
    listComissao: props.comissao,
    empresa_id: props.empresa_id,
    listed:0,
    loading:false

  });



  // CHANGE CROSSCOMPANY

  const changeCrossCompany = (event,v) => {
    
    if(event.target.checked === true){
      v=1;
    }else{
      v=0;
    }
  api.get('update/update.php?value='+v+'&id='+props.unidade_id+'&sheet=unidades&col=crosscompany&r='+Math.random(10000))
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  // ADICIONAR

  async function adicionarBD(bd){
   
    api.get('insert/comissao.php?sheet='+bd+'&id='+props.unidade_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {

    if(bd === "comissao"){
      
      state.listComissao.push({"id":result.data,"unidade_id":props.unidade_id,"cargo":"Novo",'percentual':0,'tipo':0})
      setState({ ...state, ['listComissao']: state.listComissao });
      
    }

    }})

  }
 
  const handleChange = (event,v) => {
    
    if(event.target.checked === false){
      v=1;
    }else{
      v=0;
    }

    api.get('update/update.php?value='+v+'&id='+props.unidade_id+'&sheet=unidades&col=a_'+event.target.name+'&r='+Math.random(10000))
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  function changeSplitComissao(e,id,obj,obj2){

    let cos = state.listComissao[obj].split;
    cos[obj2].id=e
    let save_split = cos[0].id;
    for(let i=1;i<cos.length;i++){
      save_split+=','+cos[i].id
    }

 

    api.get('update/update.php?value='+save_split+'&id='+state.listComissao[obj].id+'&sheet=comissao&col=split&r='+Math.random(10000)).then(result => {
      window.location.reload()
    })
    
  };

  function updateShare(v,obj,obj2){

   
    let share = state.listComissao[obj].split;
    share[obj2].share=v
    let save_share = '';
    save_share = share[0].share
    for(let i=1;i<share.length;i++){
      save_share+=','+share[i].share
    }

    api.get('update/update.php?value='+save_share+'&id='+state.listComissao[obj].id+'&sheet=comissao&col=share&r='+Math.random(10000)).then(result => {
      window.location.reload()
    })
  }


  function deleteSplit(e,obj,obj2){

    let cos = state.listComissao[obj].split;

    cos.splice(obj2,1)

    let save_split;
    let save_share;
    if(cos.length>0){
      save_split = cos[0].id;
      for(let i=1;i<cos.length;i++){
        save_split+=','+cos[i].id
      }

      save_share = cos[0].share;
      for(let i=1;i<cos.length;i++){
        save_share+=','+cos[i].share
      }
  }else{
    save_split='';
    save_share='';
  }

 

    api.get('update/update.php?value='+save_split+'&id='+state.listComissao[obj].id+'&sheet=comissao&col=split&r='+Math.random(10000)).then(result => {
      api.get('update/update.php?value='+save_share+'&id='+state.listComissao[obj].id+'&sheet=comissao&col=share&r='+Math.random(10000)).then(result => {
      window.location.reload()
      })
    })
    
    
  }



  function newSplit(obj,empresa_id){
    
    this.setState({
      loading:true
    })

    let cos = state.listComissao[obj].split;

    let share = state.listComissao[obj].share;

    let save_split = 0;

    let save_share = share+',0';

    api.get('/get/get.php?id='+props.empresa_id+'&sheet=users&col_id=empresa_id&empresa_id='+props.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        save_split = result.data[0].id

        for(let i=0;i<cos.length;i++){
          save_split+=','+cos[i].id
        }

        api.get('update/update.php?value='+save_split+'&id='+state.listComissao[obj].id+'&sheet=comissao&col=split&r='+Math.random(10000)).then(result => {
          api.get('update/update.php?value='+save_share+'&id='+state.listComissao[obj].id+'&sheet=comissao&col=share&r='+Math.random(10000)).then(result => {
            window.location.reload()
          })
        })


      }})


    
    

  }

  function voidF(e){
    console.log(0)
  }

  
  return (
    <div>
      <div className="mini-titulo mb20">{props.nome}</div>
      <Grid item xs={5}>
      <FormGroup collumn>

      <FormControlLabel
        control={<Switch checked={state.live_action} onChange={handleChange} name="live_action" id='0' />}
        label="Live Action"
      />
      <FormControlLabel
        control={<Switch checked={state.time_interno} onChange={handleChange} name="time_interno" id='1' />}
        label="Time interno"
      />
      <FormControlLabel
        control={<Switch checked={state.fornecedores_post} onChange={handleChange} name="fornecedores_post" id='2'/>}
        label="Fornecedores"
      />
      <FormControlLabel
        control={<Switch checked={state.copias} onChange={handleChange} name="copias" id='3'/>}
        label="Cópias"
      />
      <FormControlLabel
        control={<Switch checked={state.ancine} onChange={handleChange} name="ancine" id='4'/>}
        label="Ancine"
      />

    </FormGroup>
    </Grid>

    <div className="micro-titulo mt20 mb10">Crosscompany{state.crosscompany}</div>
    <Grid item xs={5}>
      <FormGroup collumn>

      <FormControlLabel
        control={<Switch checked={state.crosscompany} onChange={changeCrossCompany} name="crosscompany" id='5' />}
        label="Sim"
      />
     

    </FormGroup>
    </Grid>


    <div className="mt50">
    <TextInput 
      type="text" 
      kind="perc"
      id={props.unidade_id}
      sheet="unidades"
      col="impostos"
      label="Impostos %"
      fullWidth={true}
      placeholder="Impostos" 
      defaultValue={props.impostos+"%"}
      />

      </div>


      <div className="mt50">
      <TextInput
      kind="perc"
      id={props.unidade_id}
      sheet="unidades"
      col="live_action"
      type="text" 
      label="Lucro Live Action"
      fullWidth={true}
      defaultValue={props.live_action+"%"}
      />
      </div>

      <div className="mt20">

      <TextInput 
      kind="perc"
      id={props.unidade_id}
      sheet="unidades"
      col="time_interno"
      type="text" 
      label="Lucro Time Interno"
      fullWidth={true}
      defaultValue={props.time_interno+"%"}
      />
      </div>

      <div className="mt20">
      <TextInput 
      kind="perc"
      id={props.unidade_id}
      sheet="unidades"
      col="fornecedores_post"
      type="text" 
      label="Lucro Freelancers Post"
      fullWidth={true}
      defaultValue={props.fornecedores_post+"%"}
      />
      </div>

      <div className="mt20">
      <TextInput 
      kind="perc"
      id={props.unidade_id}
      sheet="unidades"
      col="copias"
      type="text" 
      label="Lucro Cópias"
      fullWidth={true}
      defaultValue={props.copias+"%"}
      />
      </div>

      <div className="mt20">
      <TextInput 
      kind="perc"
      id={props.unidade_id}
      sheet="unidades"
      col="ancine"
      type="text" 
      label="Lucro Ancine"
      fullWidth={true}
      defaultValue={props.ancine+"%"}
      />
      </div>

      <div className="mt20">
      <TextInput 
      id={props.unidade_id}
      sheet="unidades"
      col="sobrelucro"
      kind="perc"
      type="text" 
      label="Sobrelucro operacional"
      fullWidth={true}
      placeholder="Sobrelucro" 
      defaultValue={props.sobrelucro+"%"}
      />
      </div>

      <div className="mt20">
      <MoneyInput 
      kind="text"
      id={props.unidade_id}
      sheet="unidades"
      col="custo_hora"
      type="text" 
      label="Custo hora"
      fullWidth={true}
      defaultValue={props.custo_hora}
      />
      </div>


      <div className="mt50 mb10">
      <div className="mini-titulo">Comissões</div>
      </div>
      
      {state.listComissao.map((com,objectID) => (
      
      <Grid container spacing={3} key={objectID}>
        
        
        <Grid item xs={12}>
          <div className="mt30">
            
              <TextInputDelete
                  sheet="comissao"
                  id={com.id}
                  col="cargo"
                  type="text" 
                  fullWidth={true}
                  defaultValue={com.cargo}
                  clickFunction={(c,o,i) => props.clickFunction('comissao',objectID,com.id)}
              />
          </div>

          </Grid><Grid item xs={4}>
            <TextInput 
              sheet="comissao"
              id={com.id}
              col="percentual"
              kind={com.tipo<4 ? 'perc' : 'money' }
              label={com.tipo<4 ? 'Percentual %' : 'Valor R$' }
              fullWidth={true}
              defaultValue={com.tipo<5 ? com.percentual+'%' : 'R$ '+com.percentual }
              />

          </Grid><Grid item xs={8}>
            <MySelect
            sheet="comissao"
            id={com.id}
            col="tipo"
            save={1}
            label="Regra de negócio" 
            initValue={com.tipo} 
            list={optionComissao}
            />
          </Grid>

          <Grid item xs={12}>

          {com.split ? com.split.map((split,objectID2) => (

            <Grid container spacing={1} key={objectID2}>
                <Grid item xs={9}>
                    <div className="mb10">
                      
                        <AutoComp empresa_id={props.empresa_id} sheet="users" tipo="comissao" label={split.nome} blur={()=>voidF()} change={(e)=>changeSplitComissao(e,split.id,objectID,objectID2)}/>

                  </div>
                </Grid>

                <Grid item xs={2}>
                  <TextField 
                    fullWidth={true}
                    variant="outlined"
                    color="primary"
                    autoComplete='off'
                    placeholder="%"
                    defaultValue={split.share}
                    onBlur={
                      (e)=>updateShare(e.target.value,objectID,objectID2)
                    }
                    
                  />

                  
                </Grid>

                <Grid item xs={1}>
                <IconButton onClick={(e)=>deleteSplit(e,objectID,objectID2)} className="mt10"><Delete/></IconButton>
                </Grid>

            </Grid>
              )):''}

            <Grid item xs={12}>
              {!state.loading ? 
                <div onClick={()=>newSplit(objectID)} className="bt bgcolor_2">Split</div>

              :
              <div className="bt bgcolor_2">Loading...</div>
              }

            </Grid>
          </Grid>


      </Grid>

        

      ))}

        <Grid item xs={12}>
          <div className="mt30"><div className="bt bgcolor_1" onClick={() => adicionarBD('comissao')}>Adicionar comissão</div></div>
        </Grid>

    </div>


  );
      
}